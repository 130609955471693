// File: src/components/chat/ChatMessages.jsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  useMediaQuery,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  keyframes,
  TextField
} from '@mui/material';
import html2canvas from 'html2canvas';

// ========================
// Altri componenti interni
// ========================
import ActionButtons from './ActionButtons';
import MediaTypeDialog from './MediaTypeDialog';
import MediaDisplay from './MediaDisplay';
import apiClient from '../../api/apiClient';
import gameTypeIds from '../../config/gameTypeIds'; // mappa agent -> UUID

// ========================
// Librerie per Markdown
// ========================
import showdown from 'showdown';

// =========================
// Librerie per Word/PDF
// =========================
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf'; // jsPDF compatibile con Safari vecchi

// ========================
// Configurazione Showdown
// ========================
const showdownConverter = new showdown.Converter({
  flavor: 'github', // Abilita GFM (tabelle, block code, ecc.)
  tables: true,
  ghCodeBlocks: true,
});

// Animazione pallini "typing"
const typing = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

// Spinner di caricamento
const LoadingIndicator = () => (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100%',
    gap: 2
  }}>
    <CircularProgress 
      size={40} 
      thickness={3} 
      sx={{ color: '#DF4634' }} 
    />
    <Typography variant="body1" color="#DF4634" fontWeight="medium">
      Caricamento in corso...
    </Typography>
  </Box>
);

/* ===========================================================
   MINI-COMPONENTI PER I GIOCHI
   (QUIZ, FILLTHEBOX, SELECTGROUP, MATCHPAIRS, REARRANGE, CROSSWORD)
   =========================================================== */

// ---------------- FILLTHEBOX MINI ----------------
const FillTheBoxMini = ({ fillData }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleSelect = (exerciseId, optionId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [exerciseId]: optionId,
    }));
  };

  if (!fillData?.fill_the_box?.length) {
    return <Typography>Formato FillTheBox non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {fillData.fill_the_box.map((exercise) => (
        <Card
          key={exercise.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
              {exercise.sentence}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {exercise.options.map((option) => {
                const isSelected = selectedAnswers[exercise.id] === option.id;
                return (
                  <Tooltip key={option.id} title={option.text} arrow>
                    <Chip
                      label={option.text}
                      clickable
                      onClick={() => handleSelect(exercise.id, option.id)}
                      sx={{
                        fontWeight: 500,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        ...(isSelected
                          ? {
                              backgroundColor: '#DF4634',
                              color: '#ffffff',
                              '&:hover': {
                                backgroundColor: '#E57373',
                              },
                            }
                          : {
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                              },
                            }),
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- QUIZ MINI ----------------
const QuizMini = ({ quizData }) => {
  const [answers, setAnswers] = useState({});

  const handleSelectMultipleChoice = (questionId, optionId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));
  };

  const handleChangeOpenEnded = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  if (!quizData?.quiz?.length) {
    return <Typography>Formato Quiz non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {quizData.quiz.map((q) => {
        if (q.type === 'multiple_choice') {
          const selectedOption = answers[q.id];
          return (
            <Card
              key={q.id}
              variant="outlined"
              sx={{
                marginBottom: '1.5rem',
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1rem',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', justifyContent: 'center' }}>
                  {q.options.map((option) => {
                    const isSelected = selectedOption === option.id;
                    return (
                      <Tooltip key={option.id} title={option.text} arrow>
                        <Chip
                          label={option.text}
                          clickable
                          onClick={() => handleSelectMultipleChoice(q.id, option.id)}
                          sx={{
                            fontWeight: 500,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease-in-out',
                            ...(isSelected
                              ? {
                                  backgroundColor: '#DF4634',
                                  color: '#ffffff',
                                  '&:hover': {
                                    backgroundColor: '#E57373',
                                  },
                                }
                              : {
                                  '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                                  },
                                }),
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          );
        } else if (q.type === 'open_ended') {
          const openEndedAnswer = answers[q.id] || '';
          return (
            <Card
              key={q.id}
              variant="outlined"
              sx={{
                marginBottom: '1.5rem',
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1rem',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <TextField
                  value={openEndedAnswer}
                  onChange={(e) => handleChangeOpenEnded(q.id, e.target.value)}
                  placeholder="Scrivi la tua risposta qui..."
                  multiline
                  rows={3}
                  fullWidth
                  sx={{ marginTop: '0.5rem' }}
                />
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Typography key={q.id} sx={{ marginBottom: '1rem' }}>
              Tipo di domanda non riconosciuto.
            </Typography>
          );
        }
      })}
    </Box>
  );
};

// ---------------- SELECTGROUP MINI ----------------
const SelectGroupMini = ({ data }) => {
  if (!data?.selectgroup?.length) {
    return <Typography>Formato SelectGroup non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Trascina o assegna ogni parola al gruppo corretto (versione semplificata):
      </Typography>
      {data.selectgroup.map((group) => (
        <Card
          key={group.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {group.topic}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {group.words.map((wd, i) => (
                <Chip
                  key={`${wd}-${i}`}
                  label={wd}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                    },
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- MATCHPAIRS MINI ----------------
const MatchPairsMini = ({ data }) => {
  if (!data?.matchpairs?.length) {
    return <Typography>Formato MatchPairs non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Abbina ogni concetto di sinistra con quello corrispondente a destra (versione semplificata):
      </Typography>
      {data.matchpairs.map((item) => (
        <Card
          key={item.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {item.topic}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
              {item.pairs.map((pair, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #e0e0e0',
                    padding: '0.5rem',
                    borderRadius: '0.5rem'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {pair.left}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#999' }}>
                    ⟶
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {pair.right}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- REARRANGE MINI ----------------
const RearrangeMini = ({ data }) => {
  if (!data?.rearrange?.length) {
    return <Typography>Formato Rearrange non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Trascina o riordina le parole per formare la frase corretta (versione semplificata):
      </Typography>
      {data.rearrange.map((item) => (
        <Card
          key={item.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {item.topic}
            </Typography>
            {/* Parole mescolate */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '0.5rem' }}>
              {item.words_shuffled.map((word, idx) => (
                <Chip
                  key={idx}
                  label={word}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                    },
                  }}
                />
              ))}
            </Box>
            {/* Frase corretta */}
            <Typography variant="body1" sx={{ color: '#999' }}>
              Frase corretta: {item.final_sentence}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- CROSSWORD MINI ----------------
const CrosswordMini = ({ data }) => {
  if (!data?.crossword?.grid || !data?.crossword?.words) {
    return <Typography>Formato Crossword non valido.</Typography>;
  }

  const { grid, words, legend } = data.crossword;
  const cellNumbers = {};

  words.forEach((w) => {
    const rowIndex = w.row - 1;
    const colIndex = w.col - 1;
    cellNumbers[`${rowIndex}-${colIndex}`] = w.number;
  });

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
        {`Cruciverba ${grid[0].length} x ${grid.length}`}
      </Typography>

      <Box component="table" sx={{ borderCollapse: 'collapse', marginBottom: '1rem' }}>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => {
                const cellNumber = cellNumbers[`${rowIndex}-${colIndex}`];
                return (
                  <td
                    key={colIndex}
                    style={{
                      border: '1px solid #333',
                      width: '30px',
                      height: '30px',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      backgroundColor: cell === '-' ? '#666666' : '#f9f9f9',
                      color: '#000',
                      fontWeight: 'bold',
                      position: 'relative',
                    }}
                  >
                    {cell !== '-' ? cell : ''}
                    {cellNumber && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 2,
                          fontSize: '0.7rem',
                          fontWeight: 'bold',
                          color: '#DF4634',
                        }}
                      >
                        {cellNumber}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Box>

      <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
        Definizioni:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'pre-wrap',
          backgroundColor: '#f7f7f7',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #ccc',
          marginBottom: '1rem'
        }}
      >
        {legend}
      </Typography>
    </Box>
  );
};

// ---------------- MINDMAP MINI ----------------
const MindMapMini = ({ data }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState({});
  const [zoom, setZoom] = useState(1);

  // Palette di colori diversi per categorie
  const categoryColors = [
    '#DF4634', // Rosso Minerva (originale)
    '#2E7D32', // Verde scuro
    '#1976D2', // Blu
    '#7B1FA2', // Viola
    '#C2185B', // Rosa scuro
    '#F57C00', // Arancione
    '#0097A7', // Turchese
    '#5D4037', // Marrone
    '#455A64', // Blu grigio
  ];

  useEffect(() => {
    if (!data?.nodes) return;
    
    const initialExpandedState = {};
    const setInitialExpanded = (nodes, level = 0) => {
      if (!nodes) return;
      nodes.forEach(node => {
        initialExpandedState[node.id] = true; // Tutti i nodi aperti di default, l'utente può chiuderli manualmente
        if (node.children && node.children.length > 0) {
          setInitialExpanded(node.children, level + 1);
        }
      });
    };
    
    setInitialExpanded(data.nodes);
    setExpanded(initialExpandedState);
  }, [data?.nodes]);

  if (!data?.nodes || !data?.title) {
    return <Typography>Formato MindMap non valido.</Typography>;
  }

  const toggleNodeExpansion = (nodeId) => {
    setExpanded(prev => ({
      ...prev,
      [nodeId]: !prev[nodeId]
    }));
  };

  const renderNode = (node, level = 0, isLast = false, parentExpanded = true, colorIndex = 0) => {
    if (!node) return null;
    
    const isNodeExpanded = expanded[node.id];
    const hasChildren = node.children && node.children.length > 0;
    
    // Usa un colore diverso per ogni nodo principale e i suoi figli
    const baseColor = categoryColors[colorIndex % categoryColors.length];
    
    const colors = {
      bg: level === 0 ? baseColor : level === 1 ? adjustColor(baseColor, 10) : level === 2 ? adjustColor(baseColor, 20) : adjustColor(baseColor, 30),
      text: '#FFFFFF', // Tutti i testi sempre bianchi
      line: level === 0 ? baseColor : adjustColor(baseColor, 10),
    };

    // Sfondo per il contenitore dei figli con una tonalità più chiara del colore base
    // Ma abbastanza visibili come mostrato nell'immagine
    const containerBgColor = level === 0 
      ? adjustColor(baseColor, 30) // Visibile per il livello 1
      : level === 1 
        ? adjustColor(baseColor, 40) // Ancora visibile per il livello 2
        : adjustColor(baseColor, 50); // Più visibile per i livelli più profondi

    return (
      <Box 
        key={node.id} 
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          marginBottom: isLast ? 0 : '0.25rem',
          gap: '0.75rem'
        }}
      >
        {/* Nodo */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          position: 'relative',
          width: '160px', // Larghezza standardizzata per tutti i nodi
        }}>
          {/* Contenitore del nodo */}
          <Box 
            onClick={() => hasChildren && toggleNodeExpansion(node.id)}
            data-expanded={isNodeExpanded ? "true" : "false"}
            data-node-id={node.id}
            data-action={hasChildren ? "toggle-node" : ""}
            sx={{
              width: '100%',
              padding: '0.3rem 0.75rem',
              backgroundColor: level === 0 ? '#FFFFFF' : colors.bg,
              border: level === 0 ? `2px solid ${colors.bg}` : 'none',
              borderRadius: '0.75rem',
              cursor: hasChildren ? 'pointer' : 'default',
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              transition: 'all 0.2s',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
              '&:hover': hasChildren ? {
                transform: 'scale(1.02)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
              } : {}
            }}
          >
            <Typography 
              sx={{ 
                color: level === 0 ? colors.bg : colors.text,
                fontSize: level === 0 ? '0.9rem' : '0.8rem',
                fontWeight: level === 0 ? 600 : 500,
                flex: 1,
                marginRight: hasChildren ? '0.5rem' : 0
              }}
            >
              {node.label}
            </Typography>
            
            {hasChildren && (
              <Typography 
                sx={{ 
                  color: level === 0 ? colors.bg : colors.text,
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                  opacity: 0.8,
                  transform: isNodeExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s'
                }}
              >
                ▼
              </Typography>
            )}
          </Box>
        </Box>

        {/* Contenitore dei figli con colore di sfondo diverso per ogni livello */}
        {hasChildren && isNodeExpanded && (
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.2rem',
              border: `1px solid ${adjustColor(colors.bg, 60)}`,
              borderRadius: '0.75rem',
              padding: '0.3rem',
              backgroundColor: containerBgColor,
              width: 'auto',
              minWidth: level === 0 ? '400px' : 'auto',
            }}
          >
            {node.children.map((child, index) => renderNode(
              child,
              level + 1,
              index === node.children.length - 1,
              isNodeExpanded,
              colorIndex // Mantiene lo stesso indice di colore per i figli
            ))}
          </Box>
        )}
      </Box>
    );
  };

  // Funzione per schiarire o scurire un colore
  const adjustColor = (hexColor, percent) => {
    const num = parseInt(hexColor.replace('#', ''), 16);
    const r = (num >> 16) + percent;
    const g = ((num >> 8) & 0x00FF) + percent;
    const b = (num & 0x0000FF) + percent;
    
    // Assicurati che i valori siano tra 0 e 255
    const newR = Math.min(255, Math.max(0, r));
    const newG = Math.min(255, Math.max(0, g));
    const newB = Math.min(255, Math.max(0, b));
    
    return '#' + ((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1);
  };

  const getMaxDepth = (nodes, currentDepth = 0) => {
    if (!nodes || nodes.length === 0) return currentDepth;
    let maxDepth = currentDepth;
    nodes.forEach(node => {
      if (node.children && node.children.length > 0) {
        const childDepth = getMaxDepth(node.children, currentDepth + 1);
        maxDepth = Math.max(maxDepth, childDepth);
      }
    });
    return maxDepth;
  };

  const maxDepth = getMaxDepth(data.nodes);
  const isComplexMap = maxDepth > 3;

  const toggleAllNodes = (expandAll) => {
    const newExpandedState = {};
    const processNodes = (nodes) => {
      if (!nodes) return;
      nodes.forEach(node => {
        newExpandedState[node.id] = expandAll;
        if (node.children && node.children.length > 0) {
          processNodes(node.children);
        }
      });
    };
    processNodes(data.nodes);
    setExpanded(newExpandedState);
  };

  return (
    <Box 
      sx={{ 
        position: 'relative',
        marginY: '1.5rem',
        width: '100%',
        maxWidth: '100vw',
        backgroundColor: '#AFAFAF40',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        border: '1px solid #EEEEEE',
        boxShadow: '0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05)',
        overflow: 'auto',
        minHeight: '400px'
      }}
    >
      {/* Titolo */}
      <Typography 
        variant="h6"
        sx={{ 
          textAlign: 'center',
          fontWeight: 600,
          color: '#333',
          padding: '0.5rem',
          borderRadius: '0.75rem',
          backgroundColor: '#f8f8f8',
          border: '2px solid #DF4634',
          maxWidth: '70%',
          margin: '0 auto 1rem auto',
          fontSize: '1rem'
        }}
      >
        {data.title}
      </Typography>

      {/* Controlli per la mappa */}
      {isComplexMap && (
        <Box 
          sx={{ 
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            display: 'flex', 
            gap: '0.5rem',
            zIndex: 2
          }}
        >
          <Box 
            onClick={() => toggleAllNodes(true)}
            data-action="expand-all"
            sx={{ 
              fontSize: '0.7rem', 
              padding: '0.2rem 0.4rem',
              border: '1px solid #DDDDDD', 
              borderRadius: '4px', 
              cursor: 'pointer',
              backgroundColor: '#FFFFFF',
              '&:hover': { backgroundColor: '#F5F5F5' }
            }}
          >
            Espandi Tutti
          </Box>
          <Box 
            onClick={() => toggleAllNodes(false)}
            data-action="collapse-all"
            sx={{ 
              fontSize: '0.7rem', 
              padding: '0.2rem 0.4rem',
              border: '1px solid #DDDDDD', 
              borderRadius: '4px', 
              cursor: 'pointer',
              backgroundColor: '#FFFFFF',
              '&:hover': { backgroundColor: '#F5F5F5' }
            }}
          >
            Comprimi Tutti
          </Box>
        </Box>
      )}
      
      {/* Contenitore della mappa con zoom e scroll orizzontale */}
      <Box 
        sx={{ 
          transform: `scale(${zoom})`,
          transformOrigin: 'center left',
          transition: 'transform 0.3s',
          overflowX: 'auto',
          overflowY: 'hidden',
          height: '100%',
          '&::-webkit-scrollbar': {
            height: '6px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '3px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: '#555'
            }
          }
        }}
      >
        <Box sx={{ 
          minWidth: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1rem',
          padding: '1rem'
        }}>
          {data.nodes.map((node, index) => renderNode(
            node, 
            0,
            index === data.nodes.length - 1,
            true,
            index // Ogni nodo principale ha un indice di colore diverso
          ))}
        </Box>
      </Box>
      
      {/* Crediti */}
      <Typography 
        variant="caption" 
        sx={{ 
          position: 'absolute',
          bottom: '0.5rem',
          right: '1rem',
          color: '#999999',
          fontSize: '0.65rem'
        }}
      >
        Mappa mentale creata da Minerva Education AI
      </Typography>
    </Box>
  );
};

/* ===========================================================
   FUNZIONI DI RICONOSCIMENTO E PARSER DEI MESSAGGI
   =========================================================== */
const isQuizMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.quiz && Array.isArray(parsed.quiz);
  } catch {
    return false;
  }
};

const isFillTheBoxMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.fill_the_box && Array.isArray(parsed.fill_the_box);
  } catch {
    return false;
  }
};

const isSelectGroupMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.selectgroup && Array.isArray(parsed.selectgroup);
  } catch {
    return false;
  }
};

const isMatchPairsMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.matchpairs && Array.isArray(parsed.matchpairs);
  } catch {
    return false;
  }
};

const isRearrangeMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.rearrange && Array.isArray(parsed.rearrange);
  } catch {
    return false;
  }
};

const isCrosswordMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return (
      parsed.crossword &&
      parsed.crossword.grid &&
      Array.isArray(parsed.crossword.grid) &&
      parsed.crossword.words
    );
  } catch {
    return false;
  }
};

const isMindMapMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.title && parsed.nodes && Array.isArray(parsed.nodes);
  } catch {
    return false;
  }
};

// Parser
const parseQuiz = (message) => JSON.parse(message.text);
const parseFillTheBox = (message) => JSON.parse(message.text);
const parseSelectGroup = (message) => JSON.parse(message.text);
const parseMatchPairs = (message) => JSON.parse(message.text);
const parseRearrange = (message) => JSON.parse(message.text);
const parseCrossword = (message) => JSON.parse(message.text);
const parseMindMap = (message) => JSON.parse(message.text);

// ===========================================================
// COMPONENTE PRINCIPALE: ChatMessages
// ===========================================================
const ChatMessages = ({
  messages,
  loading,
  containerRef,
  chatTitle,
  selectedOption,
  handleMediaSearch,
  showVideo,
  agentExamples,
  onExampleClick,
  onCreateUDA,
  tutorialFocusOnUDA = false,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  // Dialog & snackbar
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('image');
  const [currentMessage, setCurrentMessage] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  
  // Riferimenti per catturare le mappe mentali come immagini
  const mindMapRefs = useRef({});

  const showSuccessSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const openMediaDialog = (message) => {
    setCurrentMessage(message);
    setMediaDialogOpen(true);
  };
  const handleMediaSearchConfirm = (mediaType) => {
    setMediaDialogOpen(false);
    setSelectedMediaType(mediaType);
    handleMediaSearch(currentMessage, mediaType);
  };

  const handleOpenSaveDialog = () => {
    setCustomTitle('');
    setSaveDialogOpen(true);
  };
  const handleCloseSaveDialog = () => {
    setSaveDialogOpen(false);
  };
  const handleConfirmSave = () => {
    onSaveGame(currentMessage?.text, customTitle);
    setSaveDialogOpen(false);
  };

  const onSaveGame = async (messageText, userChosenTitle) => {
    try {
      const data = JSON.parse(messageText);
      const gameTypeId = gameTypeIds[selectedOption];
      if (!gameTypeId) {
        setSnackbarMessage('Tipo di gioco non supportato o non configurato.');
        setSnackbarOpen(true);
        return;
      }

      const finalTitle = userChosenTitle || data.title || 'Nuovo Gioco';
      const payload = {
        game_type_id: gameTypeId,
        title: finalTitle,
        data: data,
      };

      await apiClient.post('/api/interactive-games/games/', payload);
      showSuccessSnackbar('Gioco salvato con successo!');
    } catch (err) {
      console.error('Errore nel salvataggio del gioco:', err);
      if (err.response && err.response.data) {
        setSnackbarMessage(`Errore nel salvataggio: ${JSON.stringify(err.response.data)}`);
      } else {
        setSnackbarMessage('Errore generico nel salvataggio del gioco.');
      }
      setSnackbarOpen(true);
    }
  };

  // -----------------------------------
  // Funzioni di copia e download (PDF/Word)
  // -----------------------------------
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccessSnackbar('Testo copiato negli appunti!');
      })
      .catch((err) => {
        console.error('Errore nella copia: ', err);
        setSnackbarMessage('Errore nella copia del testo.');
        setSnackbarOpen(true);
      });
  };

  const sanitizeFileName = (name) => name.replace(/[^a-z0-9_\-]/gi, '_');

  // Scarica PDF con jsPDF
  const handleDownloadPDF = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    const rawHtml = showdownConverter.makeHtml(markdownText || '');
    const styledHtml = `
      <style>
        table {
          border-collapse: collapse;
          width: 100%;
          margin: 1rem 0;
        }
        th, td {
          border: 1px solid #ccc;
          padding: 8px;
          text-align: left;
        }
      </style>
      ${rawHtml}
    `;

    const doc = new jsPDF({
      unit: 'pt',
      format: 'a4',
    });

    doc.html(styledHtml, {
      x: 10,
      y: 10,
      callback: function (doc) {
        doc.save(`${safeTitle}.pdf`);
      },
      autoPaging: true,
      width: 500,
      windowWidth: 1000,
    });
  };

  // Scarica Word .docx con HTML "completo"
  const handleDownloadWord = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    const rawHtml = showdownConverter.makeHtml(markdownText || '');
    const styledHtml = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8" />
  <style>
    table {
      border-collapse: collapse;
      width: 100%;
      margin: 1rem 0;
    }
    th, td {
      border: 1px solid #ccc;
      padding: 8px;
      text-align: left;
    }
  </style>
</head>
<body>
  ${rawHtml}
</body>
</html>
    `;

    const docxBlob = htmlDocx.asBlob(styledHtml);
    saveAs(docxBlob, `${safeTitle}.docx`);
  };

  // Esempi random
  const [randomExamples, setRandomExamples] = useState([]);
  useEffect(() => {
    if (agentExamples?.length && randomExamples.length === 0) {
      const shuffled = [...agentExamples].sort(() => 0.5 - Math.random());
      setRandomExamples(shuffled.slice(0, 2));
    }
  }, [agentExamples, randomExamples.length]);

  // Funzione per gestire il download della mappa mentale
  const handleDownloadMindMap = async (format, messageText) => {
    try {
      const messageId = currentMessage?.id;
      const mindMapElement = mindMapRefs.current[messageId];
      
      if (!mindMapElement) {
        throw new Error("Elemento mappa mentale non trovato");
      }
      
      // FASE 1: Espandiamo la mappa originale visibile all'utente
      // Troviamo e clicchiamo il pulsante "Espandi Tutti" nella mappa originale
      const originalExpandAllButton = mindMapElement.querySelector('[data-action="expand-all"]');
      if (originalExpandAllButton) {
        originalExpandAllButton.click();
        
        // Attendiamo che la mappa si espanda completamente
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      
      // FASE 2: Misuriamo le dimensioni della mappa espansa
      // Troviamo il contenitore principale dei nodi
      const nodesContainer = mindMapElement.querySelector('div[style*="minWidth: fit-content"]');
      // Calcoliamo la larghezza effettiva, considerando una dimensione minima
      const effectiveWidth = Math.max(
        nodesContainer ? nodesContainer.scrollWidth : 1000,
        mindMapElement.scrollWidth,
        1000
      );
      
      // FASE 3: Creiamo un clone ottimizzato
      const clonedMindMap = mindMapElement.cloneNode(true);
      
      // Impostiamo dimensioni basate sul contenuto reale
      Object.assign(clonedMindMap.style, {
        position: 'absolute',
        left: '-9999px',
        top: '-9999px',
        width: `${effectiveWidth}px`,
        height: 'auto',
        transform: 'none',
        overflow: 'visible',
        zIndex: '-1000',
        backgroundColor: '#FFFFFF'
      });
      
      // Aggiungiamo il clone al body
      document.body.appendChild(clonedMindMap);
      
      // FASE 4: Ottimizziamo il clone per la renderizzazione
      // Rimuoviamo tutti i vincoli di overflow nel clone
      const allBoxes = clonedMindMap.querySelectorAll('[style*="overflow"]');
      allBoxes.forEach(box => {
        box.style.overflow = 'visible';
        box.style.maxHeight = 'none';
        box.style.maxWidth = 'none';
      });
      
      // Come fallback, espandiamo anche i container nel clone
      const allChildContainers = clonedMindMap.querySelectorAll('div[style*="flexDirection: column"]');
      allChildContainers.forEach(container => {
        container.style.display = 'flex';
      });
      
      // Ci assicuriamo che tutti i nodi siano renderizzati come espansi
      const allNodes = clonedMindMap.querySelectorAll('[data-action="toggle-node"]');
      allNodes.forEach(node => {
        node.setAttribute('data-expanded', 'true');
        const parentBox = node.closest('div');
        if (parentBox && parentBox.nextElementSibling) {
          parentBox.nextElementSibling.style.display = 'flex';
        }
      });
      
      // Attendiamo per permettere al DOM di aggiornarsi
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // FASE 5: Catturiamo l'immagine con dimensioni ottimizzate
      const canvas = await html2canvas(clonedMindMap, {
        backgroundColor: '#FFFFFF',
        scale: 2,
        logging: false,
        useCORS: true,
        allowTaint: true,
        width: effectiveWidth,
        height: clonedMindMap.scrollHeight || 1500,
        scrollX: 0,
        scrollY: 0
      });
      
      // Rimuoviamo il clone
      document.body.removeChild(clonedMindMap);
      
      // FASE 6: Salviamo l'immagine
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      const filename = sanitizeFileName(`mindmap_${chatTitle || 'minerva'}_${Date.now()}.png`);
      
      link.download = filename;
      link.href = imgData;
      link.click();
      
      showSuccessSnackbar('Mappa mentale scaricata come immagine');
    } catch (error) {
      console.error("Errore nel download della mappa mentale:", error);
      setSnackbarMessage('Errore nel download della mappa mentale');
      setSnackbarOpen(true);
    }
  };

  // Render
  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: isMobile ? '1rem' : '2rem',
        overflowWrap: 'break-word',
        position: 'relative',
        marginTop: isMobile ? '0' : '-1.5rem',
      }}
    >
      {/* Dialog di selezione media */}
      <MediaTypeDialog
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
        selectedMediaType={selectedMediaType}
        onConfirm={handleMediaSearchConfirm}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog "Salva Gioco" */}
      {saveDialogOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: '2rem',
              borderRadius: '1rem',
              minWidth: '300px',
            }}
          >
            <Typography sx={{ marginBottom: '1rem' }}>
              Scegli il titolo da assegnare al gioco:
            </Typography>
            <input
              type="text"
              value={customTitle}
              onChange={(e) => setCustomTitle(e.target.value)}
              style={{ width: '100%', marginBottom: '1rem' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
              <button onClick={handleCloseSaveDialog}>Annulla</button>
              <button onClick={handleConfirmSave}>Salva</button>
            </Box>
          </Box>
        </Box>
      )}

      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {messages.length === 0 && (
            <Typography sx={{ marginTop: '2rem', textAlign: 'center', color: '#666' }}>
              Non ci sono messaggi in questa chat.
            </Typography>
          )}

          {messages.map((message, index) => {
            // Se c'è un allegato immagine => estrai la url
            let imageUrl = '';
            if (message.attachments?.length) {
              const imageAttachment = message.attachments.find((att) => {
                const ext = att.file_name.split('.').pop().toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
              });
              if (imageAttachment) {
                imageUrl = imageAttachment.file_url || '';
                if (!imageUrl && imageAttachment.file) {
                  imageUrl = URL.createObjectURL(imageAttachment.file);
                }
              }
            }

            // Se c'è la stringa --UDA-- => stile differenziato
            const isUDA = message.sender === 'assistant' && (message.text || '').includes('--UDA--');

            // Verifica se il messaggio è una mappa mentale
            const isMapMessage = isMindMapMessage(message);
            
            // Assegna un ID al messaggio se non presente (per i riferimenti React)
            if (!message.id) {
              message.id = `msg-${index}-${Date.now()}`;
            }

            return (
              <Box
                key={index}
                sx={{
                  marginBottom: isMobile ? '1rem' : '2.5rem',
                  position: 'relative',
                  marginTop: index === 0 ? '2rem' : '0',
                }}
              >
                {/* Se assistant sta digitando */}
                {message.sender === 'assistant' && message.isTyping ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      textAlign: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? '2rem' : '2.8125rem',
                        height: isMobile ? '2rem' : '2.8125rem',
                        margin: '0 0.625rem 0 0',
                        backgroundColor: '#DF4634',
                        borderRadius: '1.875rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.625rem 0.9375rem',
                        borderRadius: '1.875rem',
                        boxShadow: '0px 0.25rem 0.25rem rgba(0,0,0,0.25)',
                        backgroundColor: '#ffffff',
                        maxWidth: '83.5%',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                        padding: isMobile ? '1rem' : '1.5rem',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.2s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.4s',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  // Messaggio "normale"
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection:
                          message.sender === 'user' ? 'row-reverse' : 'row',
                        textAlign: 'left',
                      }}
                    >
                      {/* Avatar */}
                      <Box
                        component="img"
                        src={
                          message.sender === 'user'
                            ? '/menu_icon/profile.png'
                            : '/chat_icon/minerva_robot.png'
                        }
                        sx={{
                          width: isMobile ? '2rem' : '2.8125rem',
                          height: isMobile ? '2rem' : '2.8125rem',
                          margin:
                            message.sender === 'user'
                              ? isMobile
                                ? '0 0 0 0.5rem'
                                : '0 0 0 0.625rem'
                              : isMobile
                              ? '0 0.5rem 0 0'
                              : '0 0.625rem 0 0',
                          backgroundColor:
                            message.sender === 'user' ? '#000000' : '#DF4634',
                          borderRadius: '1.875rem',
                        }}
                      />

                      {/* Bolla messaggio */}
                      <Box
                        sx={{
                          position: 'relative',
                          paddingX: isMobile ? '2rem' : '2rem',
                          paddingY: isMobile ? '1rem' : '0rem',
                          borderRadius: '1.875rem',
                          boxShadow: '0px 0.25rem 0.25rem rgba(0,0,0,0.25)',
                          backgroundColor: isUDA ? '#D4E5D5' : '#ffffff',
                          color: '#000000',
                          display: 'inline-block',
                          maxWidth:
                            message.sender === 'user' ? '70%' : '83.5%',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 400,
                          fontSize: isMobile ? '0.85rem' : '0.875rem',
                          lineHeight: '1.2rem',
                        }}
                      >
                        {/* Se il messaggio è un "gioco" JSON o mappa mentale */}
                        {isFillTheBoxMessage(message) ? (
                          <FillTheBoxMini fillData={parseFillTheBox(message)} />
                        ) : isQuizMessage(message) ? (
                          <QuizMini quizData={parseQuiz(message)} />
                        ) : isSelectGroupMessage(message) ? (
                          <SelectGroupMini data={parseSelectGroup(message)} />
                        ) : isMatchPairsMessage(message) ? (
                          <MatchPairsMini data={parseMatchPairs(message)} />
                        ) : isRearrangeMessage(message) ? (
                          <RearrangeMini data={parseRearrange(message)} />
                        ) : isCrosswordMessage(message) ? (
                          <CrosswordMini data={parseCrossword(message)} />
                        ) : isMindMapMessage(message) ? (
                          <div ref={el => mindMapRefs.current[message.id] = el}>
                            <MindMapMini data={parseMindMap(message)} />
                          </div>
                        ) : (
                          // Altrimenti => Markdown (Showdown)
                          (() => {
                            console.log("DEBUG testo messaggio:", JSON.stringify(message.text));
                          
                            // Usa trim() per rimuovere spazi e newline iniziali/finali
                            const cleanedText = (message.text || '') + '\n' + '\n';

                            // Converte in HTML
                            const rawHtml = showdownConverter.makeHtml(cleanedText);
                          
                            // Azzera i margini dei paragrafi per evitare 'spazi extra'
                            const styledHtml = `
                              <style>
                                p {
                                  margin: 0;
                                  padding: 0;
                                }
                                table {
                                  border-collapse: collapse;
                                  width: 100%;
                                  margin: 1rem 0;
                                }
                                th, td {
                                  border: 1px solid #ccc;
                                  padding: 8px;
                                  text-align: left;
                                }
                              </style>
                              ${rawHtml}
                            `;
                          
                            return (
                              <div 
                                style={{ whiteSpace: 'pre-wrap'}}  // o rimuovi proprio questa prop
                                dangerouslySetInnerHTML={{ __html: styledHtml }} 
                              />
                            );
                          })()                          
                        )}

                        {/* Se isInitial => esempi random */}
                        {message.isInitial && agentExamples && randomExamples.length > 0 && (
                          <Box
                            sx={{
                              marginTop: '-1rem',
                              marginBottom: '1rem',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              gap: '1rem',
                              justifyContent: 'center',
                            }}
                          >
                            {randomExamples.map((example, idx) => (
                              <Card
                                key={idx}
                                variant="outlined"
                                sx={{
                                  backgroundColor: '#fff',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: '0.5rem',
                                  boxShadow: 'none',
                                  padding: '0.5rem 1rem',
                                  cursor: 'pointer',
                                  transition: 'background-color 0.3s',
                                  minWidth: '150px',
                                  flex: '1 1 150px',
                                  maxWidth: '280px',
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                  },
                                }}
                                onClick={() => onExampleClick(example)}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '0.875rem',
                                    color: '#333',
                                    textAlign: 'center',
                                  }}
                                >
                                  {example}
                                </Typography>
                              </Card>
                            ))}
                          </Box>
                        )}

                        {/* Attachments diversi da immagini */}
                        {message.attachments && message.attachments.length > 0 && (
                          <Box sx={{ marginTop: '0.5rem', width: '100%' }}>
                            {message.attachments.map((attachment, idx) => {
                              const fileExtension = attachment.file_name
                                .split('.')
                                .pop()
                                .toLowerCase();

                              if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                return null; // gestito sotto come immagine
                              } else {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '0.5rem',
                                      backgroundColor: '#f5f5f5',
                                      padding: '0.5rem 1rem',
                                      borderRadius: '0.5rem',
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ marginLeft: '0.5rem', flexGrow: 1 }}
                                    >
                                      {attachment.file_name}
                                    </Typography>
                                  </Box>
                                );
                              }
                            })}

                            {/* Se c'è un'immagine */}
                            {imageUrl && (
                              <Box
                                sx={{
                                  marginBottom: '0.5rem',
                                  textAlign: 'left',
                                  position: 'relative',
                                  width: isMobile ? '95%' : '20rem',
                                  height: 'auto',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt="chat_image"
                                  style={{
                                    width: isMobile ? '95%' : '20rem',
                                    height: 'auto',
                                    borderRadius: '0.5rem',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {/* ActionButtons (copia, doc, pdf, cerca media, salva gioco)
                        solo se assistente e non isTyping/isInitial */}
                    {message.sender === 'assistant' && !message.isTyping && !message.isInitial && (
                      <ActionButtons
                        messageText={message.text}
                        onCopy={handleCopy}
                        onDownloadWord={handleDownloadWord}
                        onDownloadPDF={handleDownloadPDF}
                        onSearchMaterial={() => openMediaDialog(message)}
                        isMobile={isMobile}
                        disableSearch={false}
                        imageUrl={imageUrl}
                        selectedOption={selectedOption}
                        onSaveGame={() => {
                          setCurrentMessage(message);
                          handleOpenSaveDialog();
                        }}
                        onCreateUDA={onCreateUDA}
                        tutorialFocusOnUDA={tutorialFocusOnUDA}
                        isMindMap={isMapMessage}
                        onDownloadMindMap={(format) => {
                          setCurrentMessage(message);
                          handleDownloadMindMap(format, message.text);
                        }}
                      />
                    )}

                    {/* Media caricato da handleMediaSearch */}
                    {message.media && (
                      <MediaDisplay
                        media={message.media}
                        selectedMediaType={selectedMediaType}
                      />
                    )}
                  </>
                )}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ChatMessages;
