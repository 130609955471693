import React, { useState, useEffect, useRef } from 'react';
import './styles/ScripturaGame.css';

const ScripturaGame = ({ gameData, onComplete }) => {
  const [content, setContent] = useState({
    type: 'text',
    data: '',
  });
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const textContainerRef = useRef(null);

  // Stampiamo le informazioni essenziali per il debug
  useEffect(() => {
    console.log('ScripturaGame - gameData:', gameData);
    console.log('ScripturaGame - chatId disponibile:', gameData.chatId);
  }, [gameData]);

  useEffect(() => {
    // Analizza il contenuto per determinare se è un testo semplice o un video
    if (gameData && gameData.text) {
      try {
        // Tenta di analizzare il testo come JSON
        const jsonData = JSON.parse(gameData.text);
        if (jsonData.type === 'youtube' && jsonData.videoId) {
          setContent({
            type: 'youtube',
            data: jsonData.videoId,
            description: jsonData.description || 'Video'
          });
        } else {
          // Se non è un formato riconosciuto, trattalo come testo
          setContent({
            type: 'text',
            data: gameData.text
          });
        }
      } catch (error) {
        // Se non è possibile analizzare come JSON, è un testo normale
        setContent({
          type: 'text',
          data: gameData.text
        });
      }
    }
  }, [gameData]);

  // Gestisce l'evento di scroll per nascondere l'indicatore quando si inizia a scorrere
  useEffect(() => {
    const handleScroll = () => {
      if (textContainerRef.current) {
        // Nascondiamo l'indicatore quando l'utente scorre
        setShowScrollIndicator(false);
      }
    };

    // Aggiungiamo l'event listener all'elemento del contenitore di testo
    const textContainer = textContainerRef.current;
    if (textContainer && content.type === 'text') {
      textContainer.addEventListener('scroll', handleScroll);
    }

    // Pulizia dell'event listener quando il componente si smonta
    return () => {
      if (textContainer) {
        textContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [content.type]);

  const handleComplete = () => {
    if (onComplete && gameData) {
      // Verifichiamo che il chatId sia disponibile 
      if (gameData.chatId) {
        console.log('ScripturaGame - Invio completamento con chatId:', gameData.chatId);
        
        // Inviamo sia il type (per compatibilità) che chatId (per identificazione univoca)
        // e aggiungiamo un punteggio del 100% poiché questo tipo di gioco è sempre corretto
        onComplete({
          type: 'scrittura',
          chatId: gameData.chatId, // L'ID specifico di questa chat
          score: 100, // Punteggio sempre 100% per questo tipo di gioco
          completed: true,
          // Aggiungiamo informazione sul contenuto
          contentType: content.type,
          details: {
            contentType: content.type,
            isAutomaticallyCorrect: true,
            message: "Contenuto visualizzato correttamente"
          }
        });
      } else {
        // Se non abbiamo il chatId, avvisiamo nella console
        console.warn('ScripturaGame - ATTENZIONE: chatId non disponibile nel gameData!');
        console.log('gameData completo:', gameData);
        
        // Completiamo comunque il gioco, ma senza chatId
        onComplete({
          type: 'scrittura',
          score: 100, // Punteggio sempre 100% anche in questo caso
          completed: true,
          // Aggiungiamo un flag per indicare che il chatId manca
          missingChatId: true,
          contentType: content.type,
          details: {
            contentType: content.type,
            isAutomaticallyCorrect: true,
            message: "Contenuto visualizzato correttamente"
          }
        });
      }
    }
  };

  // Rendering condizionale in base al tipo di contenuto
  const renderContent = () => {
    if (content.type === 'youtube') {
      return (
        <div className="scriptura-youtube-container">
          <h3>{content.description || 'Video'}</h3>
          <div className="youtube-embed">
            <iframe
              width="100%"
              height="480"
              src={`https://www.youtube.com/embed/${content.data}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
            />
          </div>
        </div>
      );
    } else {
      // Testo con formattazione preservata
      return (
        <div className="scriptura-text-container" ref={textContainerRef}>
          <pre className="scriptura-text-content">
            {content.data}
          </pre>
          {showScrollIndicator && content.data.length > 100 && (
            <div className="scriptura-scroll-indicator">
              Continua a leggere
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="scriptura-game">
      <div className="scriptura-content">
        {renderContent()}
        
        <button 
          className="scriptura-button"
          onClick={handleComplete}
        >
          Completato
        </button>
      </div>
    </div>
  );
};

export default ScripturaGame; 