// File: src/components/chat/GameComponents.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Chip, Tooltip, TextField, Button, IconButton, Select, MenuItem, FormControl, InputLabel, Badge } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// Icone per i diversi tipi di giochi
import LiveHelpIcon from '@mui/icons-material/LiveHelp'; // per Quiz
import TextFieldsIcon from '@mui/icons-material/TextFields'; // per FillTheBox
import CategoryIcon from '@mui/icons-material/Category'; // per SelectGroup
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'; // per MatchPairs
import SortIcon from '@mui/icons-material/Sort'; // per Rearrange
import GridOnIcon from '@mui/icons-material/GridOn'; // per Crossword
import TextSnippetIcon from '@mui/icons-material/TextSnippet'; // per Writing
import showdown from 'showdown';

// Configurazione Showdown per markdown
const showdownConverter = new showdown.Converter({
  flavor: 'github', // Abilita GFM (tabelle, block code, ecc.)
  tables: true,
  ghCodeBlocks: true,
});

// -------------- FILLTHEBOX --------------
export const FillTheBoxMini = ({ fillData }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showSolution, setShowSolution] = useState(false);

  const handleSelect = (exerciseId, optionId) => {
    if (!showSolution) {
      setSelectedAnswers((prev) => ({ ...prev, [exerciseId]: optionId }));
    }
  };

  const toggleSolution = () => {
    setShowSolution(!showSolution);
    
    // Se attivo la soluzione, seleziono automaticamente le risposte corrette
    if (!showSolution) {
      const correctAnswers = {};
      fillData.fill_the_box.forEach((exercise) => {
        const correctOption = exercise.options.find(opt => opt.is_correct);
        if (correctOption) {
          correctAnswers[exercise.id] = correctOption.id;
        }
      });
      setSelectedAnswers(correctAnswers);
    }
  };

  if (!fillData?.fill_the_box?.length) {
    return <Typography>Formato FillTheBox non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per FillTheBox */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#E3F2FD', // Colore di sfondo distintivo per FillTheBox (blu chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <TextFieldsIcon sx={{ color: '#2196F3' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1565C0' }}>
            Completa la frase
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#1565C0', 
            borderColor: '#1565C0',
            backgroundColor: showSolution ? '#1565C0' : 'transparent',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: showSolution ? '#0d47a1' : 'rgba(21, 101, 192, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      {fillData.fill_the_box.map((exercise) => (
        <Card
          key={exercise.id}
          variant="outlined"
          sx={{ 
            marginBottom: '1.5rem', 
            borderRadius: '1rem',
            borderLeft: '4px solid #2196F3', // Bordo laterale blu distintivo
            boxShadow: '0 2px 4px rgba(33, 150, 243, 0.2)' // Ombra leggermente blu
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
              {exercise.sentence}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {exercise.options.map((option) => {
                const isSelected = selectedAnswers[exercise.id] === option.id;
                const isCorrect = option.is_correct;
                const isCorrectHighlighted = showSolution && isCorrect;
                
                return (
                  <Tooltip key={option.id} title={option.text} arrow>
                    <Chip
                      label={option.text}
                      clickable
                      onClick={() => handleSelect(exercise.id, option.id)}
                      sx={{
                        ...(isSelected || isCorrectHighlighted
                          ? {
                              backgroundColor: isCorrectHighlighted ? '#4CAF50' : '#2196F3', // Verde se è la risposta corretta evidenziata
                              color: '#ffffff',
                            }
                          : {
                              borderColor: '#2196F3',
                              border: '1px solid'
                            }),
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// -------------- FILLTHEBOX EDITABLE --------------
export const FillTheBoxEditable = ({ fillData, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(fillData)));

  const handleSentenceChange = (id, newSentence) => {
    const updatedData = { ...editedData };
    const exerciseIndex = updatedData.fill_the_box.findIndex(ex => ex.id === id);
    if (exerciseIndex !== -1) {
      updatedData.fill_the_box[exerciseIndex].sentence = newSentence;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleOptionChange = (exerciseId, optionId, newText) => {
    const updatedData = { ...editedData };
    const exerciseIndex = updatedData.fill_the_box.findIndex(ex => ex.id === exerciseId);
    if (exerciseIndex !== -1) {
      const optionIndex = updatedData.fill_the_box[exerciseIndex].options.findIndex(opt => opt.id === optionId);
      if (optionIndex !== -1) {
        updatedData.fill_the_box[exerciseIndex].options[optionIndex].text = newText;
        setEditedData(updatedData);
        onUpdate(updatedData);
      }
    }
  };

  const handleCorrectOptionChange = (exerciseId, optionId) => {
    const updatedData = { ...editedData };
    const exerciseIndex = updatedData.fill_the_box.findIndex(ex => ex.id === exerciseId);
    if (exerciseIndex !== -1) {
      // Reset all options to false
      updatedData.fill_the_box[exerciseIndex].options.forEach(opt => {
        opt.is_correct = false;
      });
      
      // Set the selected option to true
      const optionIndex = updatedData.fill_the_box[exerciseIndex].options.findIndex(opt => opt.id === optionId);
      if (optionIndex !== -1) {
        updatedData.fill_the_box[exerciseIndex].options[optionIndex].is_correct = true;
        setEditedData(updatedData);
        onUpdate(updatedData);
      }
    }
  };

  const handleAddOption = (exerciseId) => {
    const updatedData = { ...editedData };
    const exerciseIndex = updatedData.fill_the_box.findIndex(ex => ex.id === exerciseId);
    if (exerciseIndex !== -1) {
      const newOptionId = `opt_${Date.now()}`;
      updatedData.fill_the_box[exerciseIndex].options.push({
        id: newOptionId,
        text: "Nuova opzione",
        is_correct: false
      });
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleDeleteOption = (exerciseId, optionId) => {
    const updatedData = { ...editedData };
    const exerciseIndex = updatedData.fill_the_box.findIndex(ex => ex.id === exerciseId);
    if (exerciseIndex !== -1) {
      updatedData.fill_the_box[exerciseIndex].options = 
        updatedData.fill_the_box[exerciseIndex].options.filter(opt => opt.id !== optionId);
      
      // Se è stata eliminata l'opzione corretta, imposta come corretta la prima rimanente
      if (updatedData.fill_the_box[exerciseIndex].options.length > 0 && 
          !updatedData.fill_the_box[exerciseIndex].options.some(opt => opt.is_correct)) {
        updatedData.fill_the_box[exerciseIndex].options[0].is_correct = true;
      }
      
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddExercise = () => {
    const updatedData = { ...editedData };
    const newId = Date.now();
    updatedData.fill_the_box.push({
      id: newId,
      sentence: "Nuova frase da completare",
      options: [
        { id: `opt_${newId}_1`, text: "Opzione 1", is_correct: true },
        { id: `opt_${newId}_2`, text: "Opzione 2", is_correct: false }
      ]
    });
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleDeleteExercise = (id) => {
    const updatedData = { ...editedData };
    updatedData.fill_the_box = updatedData.fill_the_box.filter(ex => ex.id !== id);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  if (!editedData?.fill_the_box?.length) {
    return <Typography>Formato FillTheBox non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {editedData.fill_the_box.map((exercise) => (
        <Card
          key={exercise.id}
          variant="outlined"
          sx={{ marginBottom: '1.5rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}
        >
          <IconButton 
            size="small" 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
            onClick={() => handleDeleteExercise(exercise.id)}
          >
            <DeleteIcon />
          </IconButton>
          <CardContent>
            <TextField
              fullWidth
              label="Frase da completare"
              variant="outlined"
              value={exercise.sentence}
              onChange={(e) => handleSentenceChange(exercise.id, e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>Opzioni (seleziona l'opzione corretta):</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
              {exercise.options.map((option) => (
                <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem', padding: '5px' }}>
                  <Chip
                    label={option.is_correct ? "Corretta" : ""}
                    variant={option.is_correct ? "filled" : "outlined"}
                    color={option.is_correct ? "primary" : "default"}
                    sx={{
                      backgroundColor: option.is_correct ? '#DF4634' : '',
                      cursor: 'pointer',
                      minWidth: '80px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    onClick={() => handleCorrectOptionChange(exercise.id, option.id)}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    value={option.text}
                    onChange={(e) => handleOptionChange(exercise.id, option.id, e.target.value)}
                  />
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: '#DF4634',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                      marginLeft: '10px',
                      '&:hover': { 
                        backgroundColor: 'rgba(255,255,255,1)',
                        boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                      },
                      '&:disabled': {
                        color: 'rgba(0, 0, 0, 0.26)',
                        backgroundColor: 'transparent'
                      }
                    }}
                    onClick={() => handleDeleteOption(exercise.id, option.id)}
                    disabled={exercise.options.length <= 2}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button 
              variant="outlined" 
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleAddOption(exercise.id)}
              sx={{ 
                borderColor: '#DF4634', 
                color: '#DF4634',
                '&:hover': { borderColor: '#E57373', backgroundColor: 'rgba(223,70,52,0.1)' }
              }}
            >
              Aggiungi opzione
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button 
        variant="contained" 
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddExercise}
        sx={{ 
          backgroundColor: '#DF4634', 
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#E57373' }
        }}
      >
        Aggiungi esercizio
      </Button>
    </Box>
  );
};

// -------------- QUIZ --------------
export const QuizMini = ({ quizData }) => {
  const [answers, setAnswers] = useState({});
  const [showSolution, setShowSolution] = useState(false);

  const handleSelectMultipleChoice = (questionId, optionId) => {
    if (!showSolution) {
      setAnswers((prev) => ({ ...prev, [questionId]: optionId }));
    }
  };

  const handleChangeOpenEnded = (questionId, value) => {
    if (!showSolution) {
      setAnswers((prev) => ({ ...prev, [questionId]: value }));
    }
  };
  
  const toggleSolution = () => {
    setShowSolution(!showSolution);
    
    // Se attivo la soluzione, seleziono automaticamente le risposte corrette per le domande a scelta multipla
    if (!showSolution) {
      const correctAnswers = { ...answers };
      quizData.quiz.forEach((q) => {
        if (q.type === 'multiple_choice') {
          const correctOption = q.options.find(opt => opt.is_correct);
          if (correctOption) {
            correctAnswers[q.id] = correctOption.id;
          }
        }
      });
      setAnswers(correctAnswers);
    }
  };

  if (!quizData?.quiz?.length) {
    return <Typography>Formato Quiz non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per Quiz */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#F9FBE7', // Colore di sfondo distintivo per Quiz (giallo chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <LiveHelpIcon sx={{ color: '#FFC107' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#F57F17' }}>
            Quiz
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#F57F17', 
            borderColor: '#FFC107',
            backgroundColor: showSolution ? '#FFC107' : 'transparent',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: showSolution ? '#F57F17' : 'rgba(255, 193, 7, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      {quizData.quiz.map((q) => {
        if (q.type === 'multiple_choice') {
          const selectedOption = answers[q.id];
          return (
            <Card key={q.id} variant="outlined" sx={{ 
              marginBottom: '1.5rem', 
              borderRadius: '1rem',
              borderLeft: '4px solid #FFC107', // Bordo laterale giallo distintivo
              boxShadow: '0 2px 4px rgba(255, 193, 7, 0.2)' // Ombra leggermente gialla
            }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                  {q.options.map((option) => {
                    const isSelected = selectedOption === option.id;
                    const isCorrect = option.is_correct;
                    const isCorrectHighlighted = showSolution && isCorrect;
                    
                    return (
                      <Tooltip key={option.id} title={option.text} arrow>
                        <Chip
                          label={option.text}
                          clickable
                          onClick={() => handleSelectMultipleChoice(q.id, option.id)}
                          sx={{
                            ...(isSelected || isCorrectHighlighted
                              ? {
                                  backgroundColor: isCorrectHighlighted ? '#4CAF50' : '#FFC107', // Verde se è la risposta corretta evidenziata
                                  color: isCorrectHighlighted ? '#FFFFFF' : '#000000',
                                }
                              : {
                                  borderColor: '#FFC107',
                                  border: '1px solid'
                                }),
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          );
        } else if (q.type === 'open_ended') {
          const openEndedAnswer = answers[q.id] || '';
          return (
            <Card key={q.id} variant="outlined" sx={{ 
              marginBottom: '1.5rem', 
              borderRadius: '1rem',
              borderLeft: '4px solid #FFC107', // Bordo laterale giallo distintivo
              boxShadow: '0 2px 4px rgba(255, 193, 7, 0.2)' // Ombra leggermente gialla
            }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <TextField
                  value={openEndedAnswer}
                  onChange={(e) => handleChangeOpenEnded(q.id, e.target.value)}
                  multiline
                  rows={3}
                  fullWidth
                  disabled={showSolution}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#FFC107',
                      },
                      '&:hover fieldset': {
                        borderColor: '#FFC107',
                      },
                    },
                  }}
                />
                {showSolution && q.correctAnswer && (
                  <Box sx={{ 
                    mt: 2, 
                    p: 1, 
                    borderRadius: '0.5rem', 
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    border: '1px solid #4CAF50' 
                  }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#2E7D32' }}>
                      Risposta corretta:
                    </Typography>
                    <Typography variant="body2">
                      {q.correctAnswer}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          );
        } else {
          return <Typography key={q.id}>Tipo di domanda non riconosciuto.</Typography>;
        }
      })}
    </Box>
  );
};

// -------------- QUIZ EDITABLE --------------
export const QuizEditable = ({ quizData, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(quizData)));

  const handleQuestionChange = (id, newQuestion) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === id);
    if (questionIndex !== -1) {
      updatedData.quiz[questionIndex].question = newQuestion;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleOptionChange = (questionId, optionId, newText) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === questionId);
    if (questionIndex !== -1) {
      const optionIndex = updatedData.quiz[questionIndex].options.findIndex(opt => opt.id === optionId);
      if (optionIndex !== -1) {
        updatedData.quiz[questionIndex].options[optionIndex].text = newText;
        setEditedData(updatedData);
        onUpdate(updatedData);
      }
    }
  };

  const handleCorrectOptionChange = (questionId, optionId) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === questionId);
    if (questionIndex !== -1 && updatedData.quiz[questionIndex].type === 'multiple_choice') {
      // Reset all options to false
      updatedData.quiz[questionIndex].options.forEach(opt => {
        opt.is_correct = false;
      });
      
      // Set the selected option to true
      const optionIndex = updatedData.quiz[questionIndex].options.findIndex(opt => opt.id === optionId);
      if (optionIndex !== -1) {
        updatedData.quiz[questionIndex].options[optionIndex].is_correct = true;
        setEditedData(updatedData);
        onUpdate(updatedData);
      }
    }
  };

  const handleAddOption = (questionId) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === questionId);
    if (questionIndex !== -1 && updatedData.quiz[questionIndex].type === 'multiple_choice') {
      const letterOptions = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
      const currentCount = updatedData.quiz[questionIndex].options.length;
      const nextLetter = letterOptions[currentCount] || `option_${Date.now()}`;
      
      updatedData.quiz[questionIndex].options.push({
        id: nextLetter,
        text: "Nuova opzione",
        is_correct: false
      });
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleDeleteOption = (questionId, optionId) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === questionId);
    if (questionIndex !== -1) {
      updatedData.quiz[questionIndex].options = 
        updatedData.quiz[questionIndex].options.filter(opt => opt.id !== optionId);
      
      // Se è stata eliminata l'opzione corretta, imposta come corretta la prima rimanente
      if (updatedData.quiz[questionIndex].options.length > 0 && 
          !updatedData.quiz[questionIndex].options.some(opt => opt.is_correct)) {
        updatedData.quiz[questionIndex].options[0].is_correct = true;
      }
      
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddQuestion = (type = 'multiple_choice') => {
    const updatedData = { ...editedData };
    const newId = updatedData.quiz.length > 0 ? 
      Math.max(...updatedData.quiz.map(q => q.id)) + 1 : 1;
    
    if (type === 'multiple_choice') {
      updatedData.quiz.push({
        id: newId,
        type: 'multiple_choice',
        question: "Nuova domanda a scelta multipla",
        options: [
          { id: "a", text: "Opzione 1", is_correct: true },
          { id: "b", text: "Opzione 2", is_correct: false }
        ]
      });
    } else if (type === 'open_ended') {
      updatedData.quiz.push({
        id: newId,
        type: 'open_ended',
        question: "Nuova domanda a risposta aperta",
        options: []
      });
    }
    
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleDeleteQuestion = (id) => {
    const updatedData = { ...editedData };
    updatedData.quiz = updatedData.quiz.filter(q => q.id !== id);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleChangeQuestionType = (questionId, newType) => {
    const updatedData = { ...editedData };
    const questionIndex = updatedData.quiz.findIndex(q => q.id === questionId);
    
    if (questionIndex !== -1 && updatedData.quiz[questionIndex].type !== newType) {
      updatedData.quiz[questionIndex].type = newType;
      
      // Reset options based on type
      if (newType === 'multiple_choice') {
        updatedData.quiz[questionIndex].options = [
          { id: "a", text: "Opzione 1", is_correct: true },
          { id: "b", text: "Opzione 2", is_correct: false }
        ];
      } else if (newType === 'open_ended') {
        updatedData.quiz[questionIndex].options = [];
      }
      
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  if (!editedData?.quiz?.length) {
    return <Typography>Formato Quiz non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {editedData.quiz.map((q) => (
        <Card 
          key={q.id} 
          variant="outlined" 
          sx={{ marginBottom: '1.5rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}
        >
          <IconButton 
            size="small" 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
            onClick={() => handleDeleteQuestion(q.id)}
          >
            <DeleteIcon />
          </IconButton>
          <CardContent>
            <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipo di domanda</InputLabel>
              <Select
                value={q.type}
                label="Tipo di domanda"
                onChange={(e) => handleChangeQuestionType(q.id, e.target.value)}
              >
                <MenuItem value="multiple_choice">Scelta multipla</MenuItem>
                <MenuItem value="open_ended">Risposta aperta</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              fullWidth
              label="Domanda"
              variant="outlined"
              value={q.question}
              onChange={(e) => handleQuestionChange(q.id, e.target.value)}
              multiline
              rows={2}
              sx={{ marginBottom: '1rem' }}
            />
            
            {q.type === 'multiple_choice' && (
              <>
                <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>
                  Opzioni (seleziona l'opzione corretta):
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
                  {q.options.map((option) => (
                    <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem', padding: '5px' }}>
                      <Chip
                        label={option.is_correct ? "Corretta" : ""}
                        variant={option.is_correct ? "filled" : "outlined"}
                        color={option.is_correct ? "primary" : "default"}
                        sx={{
                          backgroundColor: option.is_correct ? '#DF4634' : '',
                          cursor: 'pointer',
                          minWidth: '80px',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        onClick={() => handleCorrectOptionChange(q.id, option.id)}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        value={option.text}
                        onChange={(e) => handleOptionChange(q.id, option.id, e.target.value)}
                      />
                      <IconButton 
                        size="small" 
                        sx={{ 
                          color: '#DF4634',
                          backgroundColor: 'rgba(255,255,255,0.8)',
                          marginLeft: '10px',
                          '&:hover': { 
                            backgroundColor: 'rgba(255,255,255,1)',
                            boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                          },
                          '&:disabled': {
                            color: 'rgba(0, 0, 0, 0.26)',
                            backgroundColor: 'transparent'
                          }
                        }}
                        onClick={() => handleDeleteOption(q.id, option.id)}
                        disabled={q.options.length <= 2}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                <Button 
                  variant="outlined" 
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleAddOption(q.id)}
                  sx={{ 
                    borderColor: '#DF4634', 
                    color: '#DF4634',
                    '&:hover': { borderColor: '#E57373', backgroundColor: 'rgba(223,70,52,0.1)' }
                  }}
                >
                  Aggiungi opzione
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      ))}
      
      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
        <Button 
          variant="contained" 
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => handleAddQuestion('multiple_choice')}
          sx={{ 
            backgroundColor: '#DF4634', 
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#E57373' }
          }}
        >
          Aggiungi domanda a scelta multipla
        </Button>
        
        <Button 
          variant="contained" 
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => handleAddQuestion('open_ended')}
          sx={{ 
            backgroundColor: '#DF4634', 
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#E57373' }
          }}
        >
          Aggiungi domanda a risposta aperta
        </Button>
      </Box>
    </Box>
  );
};

// -------------- MATCHPAIRS --------------
// Gioco "abbina coppie" temporaneamente disabilitato
/*
export const MatchPairsMini = ({ data }) => {
  const [showSolution, setShowSolution] = useState(false);
  
  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };
  
  if (!data?.matchpairs?.length) {
    return <Typography>Formato MatchPairs non valido.</Typography>;
  }
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#F3E5F5',
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <CompareArrowsIcon sx={{ color: '#9C27B0' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#6A1B9A' }}>
            Abbina coppie
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#6A1B9A', 
            borderColor: '#9C27B0',
            backgroundColor: showSolution ? '#9C27B0' : 'transparent',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: showSolution ? '#6A1B9A' : 'rgba(156, 39, 176, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      {data.matchpairs.map((item) => (
        <Card key={item.id} variant="outlined" sx={{ 
          marginBottom: '1.5rem', 
          borderRadius: '1rem',
          borderLeft: '4px solid #9C27B0',
          boxShadow: '0 2px 4px rgba(156, 39, 176, 0.2)'
        }}>
          <CardContent>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              marginBottom: '0.5rem',
              color: '#6A1B9A'
            }}>
              {item.topic}
            </Typography>
            {item.pairs.map((pair, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  marginBottom: '0.5rem',
                  backgroundColor: showSolution ? 'rgba(156, 39, 176, 0.1)' : (index % 2 === 0 ? '#F3E5F5' : 'transparent'),
                  padding: '0.25rem 0.5rem',
                  borderRadius: '0.25rem',
                  border: showSolution ? '1px solid #9C27B0' : 'none'
                }}
              >
                <Typography variant="body2" sx={{ 
                  fontWeight: 500, 
                  color: showSolution ? '#6A1B9A' : 'inherit' 
                }}>
                  {pair.left}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: showSolution ? '#9C27B0' : '#9C27B0',
                  fontWeight: showSolution ? 'bold' : 'normal'
                }}>
                  {showSolution ? "=" : "⟶"}
                </Typography>
                <Typography variant="body2" sx={{ 
                  fontWeight: 500,
                  color: showSolution ? '#6A1B9A' : 'inherit'
                }}>
                  {pair.right}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// MATCHPAIRS EDITABLE - componente disabilitato
export const MatchPairsEditable_old = ({ data, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(data)));

  const handleTopicChange = (id, newTopic) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.matchpairs.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      updatedData.matchpairs[itemIndex].topic = newTopic;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handlePairChange = (itemId, pairIndex, side, newText) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.matchpairs.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.matchpairs[itemIndex].pairs[pairIndex][side] = newText;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddPair = (itemId) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.matchpairs.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.matchpairs[itemIndex].pairs.push({
        left: "Elemento sinistro",
        right: "Elemento destro"
      });
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleDeletePair = (itemId, pairIndex) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.matchpairs.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.matchpairs[itemIndex].pairs.splice(pairIndex, 1);
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddItem = () => {
    const updatedData = { ...editedData };
    const newId = Date.now();
    updatedData.matchpairs.push({
      id: newId,
      topic: "Nuovo abbinamento",
      pairs: [
        { left: "Elemento sinistro 1", right: "Elemento destro 1" },
        { left: "Elemento sinistro 2", right: "Elemento destro 2" }
      ]
    });
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleDeleteItem = (id) => {
    const updatedData = { ...editedData };
    updatedData.matchpairs = updatedData.matchpairs.filter(item => item.id !== id);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  if (!editedData?.matchpairs?.length) {
    return <Typography>Formato MatchPairs non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {editedData.matchpairs.map((item) => (
        <Card 
          key={item.id} 
          variant="outlined" 
          sx={{ marginBottom: '1.5rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}
        >
          <IconButton 
            size="small" 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
            onClick={() => handleDeleteItem(item.id)}
          >
            <DeleteIcon />
          </IconButton>
          <CardContent>
            <TextField
              fullWidth
              label="Titolo dell'abbinamento"
              variant="outlined"
              value={item.topic}
              onChange={(e) => handleTopicChange(item.id, e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>Coppie di elementi:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
              {item.pairs.map((pair, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
                  <TextField
                    label="Elemento sinistro"
                    size="small"
                    fullWidth
                    value={pair.left}
                    onChange={(e) => handlePairChange(item.id, index, 'left', e.target.value)}
                  />
                  <Typography sx={{ margin: '0 0.5rem' }}>⟷</Typography>
                  <TextField
                    label="Elemento destro"
                    size="small"
                    fullWidth
                    value={pair.right}
                    onChange={(e) => handlePairChange(item.id, index, 'right', e.target.value)}
                  />
                  <IconButton 
                    size="small" 
                    sx={{ color: '#DF4634' }}
                    onClick={() => handleDeletePair(item.id, index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button 
              variant="outlined" 
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleAddPair(item.id)}
              sx={{ 
                borderColor: '#DF4634', 
                color: '#DF4634',
                '&:hover': { borderColor: '#E57373', backgroundColor: 'rgba(223,70,52,0.1)' }
              }}
            >
              Aggiungi coppia
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button 
        variant="contained" 
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddItem}
        sx={{ 
          backgroundColor: '#DF4634', 
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#E57373' }
        }}
      >
        Aggiungi abbinamento
      </Button>
    </Box>
  );
};
*/

// Componenti temporanei per sostituire MatchPairsMini e MatchPairsEditable mentre sono commentati
export const MatchPairsMini = ({ data }) => {
  return (
    <Box sx={{ 
      padding: '1rem', 
      border: '1px dashed #9C27B0',
      borderRadius: '0.5rem',
      backgroundColor: '#f9f9f9',
      marginTop: '1rem' 
    }}>
      <Typography variant="subtitle1" color="error">
        Il gioco "Abbina coppie" è temporaneamente disabilitato e sarà disponibile prossimamente.
      </Typography>
    </Box>
  );
};

export const MatchPairsEditable = ({ data, onUpdate }) => {
  return (
    <Box sx={{ 
      padding: '1rem', 
      border: '1px dashed #9C27B0',
      borderRadius: '0.5rem',
      backgroundColor: '#f9f9f9',
      marginTop: '1rem' 
    }}>
      <Typography variant="subtitle1" color="error">
        L'editor per il gioco "Abbina coppie" è temporaneamente disabilitato e sarà disponibile prossimamente.
      </Typography>
    </Box>
  );
};

// -------------- REARRANGE --------------
export const RearrangeMini = ({ data }) => {
  const [showSolution, setShowSolution] = useState(false);
  
  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };
  
  if (!data?.rearrange?.length) {
    return <Typography>Formato Rearrange non valido.</Typography>;
  }
  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per Rearrange */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#FFF3E0', // Colore di sfondo distintivo per Rearrange (arancione chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <SortIcon sx={{ color: '#FF9800' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#E65100' }}>
            Riordina elementi
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#E65100', 
            borderColor: '#FF9800',
            backgroundColor: showSolution ? '#FF9800' : 'transparent',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: showSolution ? '#E65100' : 'rgba(255, 152, 0, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      {data.rearrange.map((item) => (
        <Card key={item.id} variant="outlined" sx={{ 
          marginBottom: '1.5rem', 
          borderRadius: '1rem',
          borderLeft: '4px solid #FF9800', // Bordo laterale arancione distintivo
          boxShadow: '0 2px 4px rgba(255, 152, 0, 0.2)' // Ombra leggermente arancione
        }}>
          <CardContent>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              marginBottom: '0.5rem',
              color: '#E65100'
            }}>
              {item.topic}
            </Typography>
            
            {showSolution && (
              <Box sx={{ 
                p: 1.5, 
                backgroundColor: 'rgba(255, 152, 0, 0.1)', 
                borderRadius: '0.5rem',
                border: '1px solid #FF9800',
                marginBottom: '1rem'
              }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#E65100', marginBottom: '0.5rem' }}>
                  Soluzione:
                </Typography>
                <Typography>{item.final_sentence}</Typography>
              </Box>
            )}
            
            <Box sx={{ 
              display: 'flex', 
              gap: '0.5rem', 
              flexWrap: 'wrap',
              backgroundColor: '#FFF3E0',
              padding: '0.5rem',
              borderRadius: '0.5rem'
            }}>
              {item.words_shuffled.map((word, idx) => (
                <Chip 
                  key={idx} 
                  label={word} 
                  sx={{ 
                    backgroundColor: '#FF9800',
                    color: 'white',
                    fontWeight: 500,
                    opacity: showSolution ? 0.7 : 1,
                    '&:hover': {
                      backgroundColor: '#FB8C00'
                    }
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// -------------- REARRANGE EDITABLE --------------
export const RearrangeEditable = ({ data, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(data)));

  const handleTopicChange = (id, newTopic) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.rearrange.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      updatedData.rearrange[itemIndex].topic = newTopic;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleFinalSentenceChange = (id, newSentence) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.rearrange.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      updatedData.rearrange[itemIndex].final_sentence = newSentence;
      
      // Aggiorno anche words_shuffled dividendo la frase in parole e mischiandole
      const words = newSentence.split(/\s+/).filter(w => w.trim() !== '');
      updatedData.rearrange[itemIndex].words_shuffled = [...words].sort(() => Math.random() - 0.5);
      
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleShuffledWordChange = (itemId, wordIndex, newWord) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.rearrange.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.rearrange[itemIndex].words_shuffled[wordIndex] = newWord;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddWord = (itemId) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.rearrange.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.rearrange[itemIndex].words_shuffled.push("Nuova parola");
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleDeleteWord = (itemId, wordIndex) => {
    const updatedData = { ...editedData };
    const itemIndex = updatedData.rearrange.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      updatedData.rearrange[itemIndex].words_shuffled.splice(wordIndex, 1);
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddItem = () => {
    const updatedData = { ...editedData };
    const newId = Date.now();
    const exampleSentence = "Questa è una frase di esempio da riordinare";
    const shuffledWords = exampleSentence.split(" ").sort(() => Math.random() - 0.5);
    
    updatedData.rearrange.push({
      id: newId,
      topic: "Nuovo riordinamento",
      final_sentence: exampleSentence,
      words_shuffled: shuffledWords
    });
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleDeleteItem = (id) => {
    const updatedData = { ...editedData };
    updatedData.rearrange = updatedData.rearrange.filter(item => item.id !== id);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  if (!editedData?.rearrange?.length) {
    return <Typography>Formato Rearrange non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {editedData.rearrange.map((item) => (
        <Card 
          key={item.id} 
          variant="outlined" 
          sx={{ marginBottom: '1.5rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}
        >
          <IconButton 
            size="small" 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
            onClick={() => handleDeleteItem(item.id)}
          >
            <DeleteIcon />
          </IconButton>
          <CardContent>
            <TextField
              fullWidth
              label="Titolo del riordinamento"
              variant="outlined"
              value={item.topic}
              onChange={(e) => handleTopicChange(item.id, e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            
            <TextField
              fullWidth
              label="Frase completa (ordinata)"
              variant="outlined"
              value={item.final_sentence}
              onChange={(e) => handleFinalSentenceChange(item.id, e.target.value)}
              multiline
              sx={{ marginBottom: '1rem' }}
            />
            
            <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>Parole mescolate:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
              {item.words_shuffled.map((word, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem', padding: '5px' }}>
                  <TextField
                    fullWidth
                    size="small"
                    value={word}
                    onChange={(e) => handleShuffledWordChange(item.id, index, e.target.value)}
                  />
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: '#DF4634',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                      marginLeft: '10px',
                      '&:hover': { 
                        backgroundColor: 'rgba(255,255,255,1)',
                        boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                      }
                    }}
                    onClick={() => handleDeleteWord(item.id, index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
            
            <Button 
              variant="outlined" 
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleAddWord(item.id)}
              sx={{ 
                borderColor: '#DF4634', 
                color: '#DF4634',
                '&:hover': { borderColor: '#E57373', backgroundColor: 'rgba(223,70,52,0.1)' }
              }}
            >
              Aggiungi parola
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button 
        variant="contained" 
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddItem}
        sx={{ 
          backgroundColor: '#DF4634', 
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#E57373' }
        }}
      >
        Aggiungi riordinamento
      </Button>
    </Box>
  );
};

// -------------- CROSSWORD --------------
export const CrosswordMini = ({ data }) => {
  const [showSolution, setShowSolution] = useState(false);
  
  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };
  
  if (!data?.crossword?.grid || !data?.crossword?.words) {
    return <Typography>Formato Crossword non valido.</Typography>;
  }
  const { grid, words, legend } = data.crossword;

  // Mappa per mostrare i numeretti
  const cellNumbers = {};
  words.forEach((w) => {
    const r = w.row - 1;
    const c = w.col - 1;
    cellNumbers[`${r}-${c}`] = w.number;
  });

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per Crossword */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#EFEBE9', // Colore di sfondo distintivo per Crossword (marrone chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <GridOnIcon sx={{ color: '#795548' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#4E342E' }}>
            Cruciverba
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#4E342E', 
            borderColor: '#795548',
            backgroundColor: showSolution ? '#795548' : 'transparent',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: showSolution ? '#4E342E' : 'rgba(121, 85, 72, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      <Card variant="outlined" sx={{ 
        marginBottom: '1.5rem', 
        borderRadius: '1rem',
        borderLeft: '4px solid #795548', // Bordo laterale marrone distintivo
        boxShadow: '0 2px 4px rgba(121, 85, 72, 0.2)' // Ombra leggermente marrone
      }}>
        <CardContent>
          <Typography variant="h6" sx={{ 
            marginBottom: '1rem', 
            color: '#4E342E',
            borderBottom: '2px solid #795548',
            paddingBottom: '0.25rem'
          }}>{`Cruciverba ${grid[0].length} x ${grid.length}`}</Typography>
          <Box 
            component="table" 
            sx={{ 
              borderCollapse: 'collapse', 
              marginBottom: '1rem',
              margin: '0 auto'
            }}
          >
            <tbody>
              {grid.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => {
                    const cellNumber = cellNumbers[`${rowIndex}-${colIndex}`];
                    // In modalità soluzione, mostro sempre le lettere
                    const cellContent = showSolution && cell !== '-' ? cell : '';
                    
                    return (
                      <td
                        key={colIndex}
                        style={{
                          border: '1px solid #5D4037',
                          width: '30px',
                          height: '30px',
                          textAlign: 'center',
                          position: 'relative',
                          backgroundColor: cell === '-' ? '#795548' : '#EFEBE9',
                          color: '#3E2723',
                          fontWeight: 'bold'
                        }}
                      >
                        {cellContent}
                        {cellNumber && (
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 2,
                              fontSize: '0.7rem',
                              fontWeight: 'bold',
                              color: '#D84315',
                            }}
                          >
                            {cellNumber}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Box>
          <Typography variant="subtitle2" sx={{ 
            fontWeight: 'bold',
            color: '#4E342E'
          }}>
            Definizioni:
          </Typography>
          <Typography variant="body2" sx={{ 
            whiteSpace: 'pre-wrap', 
            backgroundColor: '#EFEBE9', 
            padding: '0.5rem',
            borderRadius: '0.25rem'
          }}>
            {legend}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

// -------------- CROSSWORD EDITABLE --------------
export const CrosswordEditable = ({ data, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(data)));
  
  // Per semplicità, permettiamo di modificare solo il titolo, le definizioni (legend) e le parole
  // La modifica della griglia direttamente sarebbe troppo complessa per un'interfaccia utente semplice
  
  const handleLegendChange = (newLegend) => {
    const updatedData = { ...editedData };
    updatedData.crossword.legend = newLegend;
    setEditedData(updatedData);
    onUpdate(updatedData);
  };
  
  const handleWordChange = (index, field, value) => {
    const updatedData = { ...editedData };
    updatedData.crossword.words[index][field] = field === 'number' || field === 'row' || field === 'col' 
      ? parseInt(value, 10) 
      : value;
    setEditedData(updatedData);
    onUpdate(updatedData);
  };
  
  // Funzione per aggiungere una nuova parola
  const handleAddWord = () => {
    const updatedData = { ...editedData };
    const newNumber = Math.max(...updatedData.crossword.words.map(w => w.number)) + 1;
    
    updatedData.crossword.words.push({
      number: newNumber,
      word: "parola",
      clue: "Definizione per la nuova parola",
      direction: "across", // o "down"
      row: 1,
      col: 1
    });
    
    setEditedData(updatedData);
    onUpdate(updatedData);
  };
  
  // Funzione per eliminare una parola
  const handleDeleteWord = (index) => {
    const updatedData = { ...editedData };
    updatedData.crossword.words.splice(index, 1);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  if (!editedData?.crossword?.grid || !editedData?.crossword?.words) {
    return <Typography>Formato Crossword non valido.</Typography>;
  }
  
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
        Modifica Cruciverba {editedData.crossword.grid[0].length} x {editedData.crossword.grid.length}
      </Typography>
      
      <Box sx={{ marginBottom: '1.5rem' }}>
        {/* Visualizzazione della griglia (non modificabile) */}
        <Card variant="outlined" sx={{ marginBottom: '1rem', borderRadius: '1rem' }}>
          <CardContent>
            <Typography variant="subtitle2">Griglia del cruciverba (vista sola lettura):</Typography>
            <Box component="table" sx={{ borderCollapse: 'collapse', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              <tbody>
                {editedData.crossword.grid.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => {
                      const cellNumber = editedData.crossword.words.find(w => w.row-1 === rowIndex && w.col-1 === colIndex)?.number;
                      return (
                        <td
                          key={colIndex}
                          style={{
                            border: '1px solid #333',
                            width: '30px',
                            height: '30px',
                            textAlign: 'center',
                            position: 'relative',
                            backgroundColor: cell === '-' ? '#666666' : '#f9f9f9'
                          }}
                        >
                          {cell !== '-' ? cell : ''}
                          {cellNumber && (
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 2,
                                fontSize: '0.7rem',
                                fontWeight: 'bold',
                                color: '#DF4634',
                              }}
                            >
                              {cellNumber}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Box>
            <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#666', fontSize: '0.8rem' }}>
              Nota: la modifica diretta della griglia non è supportata. Modifica le parole e le definizioni sotto.
            </Typography>
          </CardContent>
        </Card>
        
        {/* Modifica delle definizioni (legend) */}
        <Card variant="outlined" sx={{ marginBottom: '1.5rem', borderRadius: '1rem' }}>
          <CardContent>
            <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>Definizioni:</Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              value={editedData.crossword.legend}
              onChange={(e) => handleLegendChange(e.target.value)}
              placeholder="Inserisci le definizioni del cruciverba, numerate"
              variant="outlined"
            />
          </CardContent>
        </Card>
        
        {/* Elenco delle parole */}
        <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
          Parole nel cruciverba:
        </Typography>
        {editedData.crossword.words.map((word, index) => (
          <Card key={index} variant="outlined" sx={{ marginBottom: '1rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}>
            <IconButton 
              size="small" 
              sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
              onClick={() => handleDeleteWord(index)}
            >
              <DeleteIcon />
            </IconButton>
            <CardContent>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 1 }}>
                <TextField
                  label="Numero"
                  size="small"
                  type="number"
                  value={word.number}
                  onChange={(e) => handleWordChange(index, 'number', e.target.value)}
                  sx={{ width: '100px' }}
                />
                <TextField
                  label="Parola"
                  size="small"
                  value={word.word}
                  onChange={(e) => handleWordChange(index, 'word', e.target.value)}
                  sx={{ flexGrow: 1 }}
                />
                <FormControl sx={{ minWidth: '150px' }}>
                  <InputLabel>Direzione</InputLabel>
                  <Select
                    size="small"
                    value={word.direction}
                    label="Direzione"
                    onChange={(e) => handleWordChange(index, 'direction', e.target.value)}
                  >
                    <MenuItem value="across">Orizzontale</MenuItem>
                    <MenuItem value="down">Verticale</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 1 }}>
                <TextField
                  label="Riga"
                  size="small"
                  type="number"
                  value={word.row}
                  onChange={(e) => handleWordChange(index, 'row', e.target.value)}
                  sx={{ width: '100px' }}
                />
                <TextField
                  label="Colonna"
                  size="small"
                  type="number"
                  value={word.col}
                  onChange={(e) => handleWordChange(index, 'col', e.target.value)}
                  sx={{ width: '100px' }}
                />
              </Box>
              
              <TextField
                fullWidth
                label="Definizione"
                size="small"
                value={word.clue}
                onChange={(e) => handleWordChange(index, 'clue', e.target.value)}
                sx={{ mt: 1 }}
              />
            </CardContent>
          </Card>
        ))}
        
        <Button 
          variant="contained" 
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddWord}
          sx={{ 
            backgroundColor: '#DF4634', 
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#E57373' }
          }}
        >
          Aggiungi parola
        </Button>
      </Box>
    </Box>
  );
};

// -------------- YOUTUBE --------------
export const YoutubeMini = ({ data }) => {
  if (!data?.videoId) {
    return <Typography>Formato YouTube non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per YouTube */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '0.5rem', 
        marginBottom: '0.5rem',
        backgroundColor: '#FFEBEE', // Colore di sfondo distintivo per YouTube (rosso chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ color: '#F44336' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#F44336">
            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
          </svg>
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#C62828' }}>
          Video
        </Typography>
      </Box>
      
      <Card
        variant="outlined"
        sx={{ 
          marginBottom: '1.5rem', 
          borderRadius: '1rem',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          backgroundColor: '#ffffff',
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ padding: '0.75rem' }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
          }}>
            <Box sx={{ 
              width: '80px',
              height: '45px',
              backgroundColor: '#000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '0.25rem',
              overflow: 'hidden'
            }}>
              <img 
                src={`https://img.youtube.com/vi/${data.videoId}/default.jpg`} 
                alt="YouTube thumbnail"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Typography variant="body2" sx={{ 
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {data.description || 'Video YouTube'}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

// Componente per modificare i video YouTube
export const YoutubeEditable = ({ data, onUpdate }) => {
  const [videoUrl, setVideoUrl] = React.useState('');
  const [description, setDescription] = React.useState(data.description || '');
  const [videoId, setVideoId] = React.useState(data.videoId || '');
  
  // Regex per estrarre l'ID del video dall'URL di YouTube
  const extractYoutubeVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  };
  
  // Verifica se un URL YouTube è valido
  const isValidYoutubeUrl = (url) => {
    // Regex per URL YouTube validi
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}|youtu\.be\/[a-zA-Z0-9_-]{11})(&.*)?$/;
    return youtubeRegex.test(url);
  };

  // Quando l'URL cambia, estrai l'ID del video
  const handleUrlChange = (e) => {
    const url = e.target.value;
    setVideoUrl(url);
    
    if (isValidYoutubeUrl(url)) {
      const id = extractYoutubeVideoId(url);
      if (id) {
        setVideoId(id);
        // Aggiorna l'oggetto dati completo
        onUpdate({
          type: 'youtube',
          videoId: id,
          description: description
        });
      }
    }
  };
  
  // Quando la descrizione cambia, aggiorna l'oggetto dati
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    onUpdate({
      type: 'youtube',
      videoId: videoId,
      description: newDescription
    });
  };
  
  // All'inizializzazione, genera l'URL completo dal videoId
  React.useEffect(() => {
    if (data.videoId) {
      setVideoUrl(`https://www.youtube.com/watch?v=${data.videoId}`);
    }
  }, [data.videoId]);
  
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#C62828' }}>
        Modifica Video YouTube
      </Typography>
      
      <TextField
        label="URL YouTube"
        value={videoUrl}
        onChange={handleUrlChange}
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="https://www.youtube.com/watch?v=..."
        error={videoUrl !== '' && !isValidYoutubeUrl(videoUrl)}
        helperText={videoUrl !== '' && !isValidYoutubeUrl(videoUrl) ? "URL YouTube non valido" : ""}
        InputProps={{
          sx: { borderRadius: '0.5rem' }
        }}
      />
      
      {videoId && (
        <Box sx={{ my: 2, p: 1, border: '1px solid #e0e0e0', borderRadius: '0.5rem' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Anteprima:
          </Typography>
          <Box sx={{ 
            position: 'relative',
            paddingBottom: '56.25%', // Aspect ratio 16:9
            height: 0,
            overflow: 'hidden',
            borderRadius: '4px'
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Box>
      )}
      
      <TextField
        label="Descrizione (opzionale)"
        value={description}
        onChange={handleDescriptionChange}
        fullWidth
        multiline
        rows={3}
        margin="normal"
        variant="outlined"
        placeholder="Descrivi brevemente il contenuto del video..."
        InputProps={{
          sx: { borderRadius: '0.5rem' }
        }}
      />
    </Box>
  );
};

// -------------- WRITING --------------
export const WritingMini = ({ data }) => {
  if (!data?.text) {
    return <Typography>Formato testo non valido.</Typography>;
  }

  // Prepara il testo per la conversione in markdown
  const cleanedText = (data.text || '') + '\n\n';
  
  // Converti in HTML usando showdown (la configurazione viene dal componente principale)
  const rawHtml = showdownConverter.makeHtml(cleanedText);
  
  // Azzera i margini dei paragrafi per evitare 'spazi extra'
  const styledHtml = `
    <style>
      p {
        margin: 0;
        padding: 0;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        margin: 1rem 0;
      }
      th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
    </style>
    ${rawHtml}
  `;

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per Writing */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '0.5rem', 
        marginBottom: '0.5rem',
        backgroundColor: '#E8F5E9', // Colore di sfondo distintivo per Writing (verde chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <TextSnippetIcon sx={{ color: '#4CAF50' }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#2E7D32' }}>
          Testo
        </Typography>
      </Box>
      
      <Card
        variant="outlined"
        sx={{ 
          marginBottom: '1.5rem', 
          borderRadius: '1rem',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          backgroundColor: '#ffffff'
        }}
      >
        <CardContent sx={{ padding: '0.75rem' }}>
          <div
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: styledHtml }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

// -------------- SELECTGROUP --------------
export const SelectGroupMini = ({ data }) => {
  const [showSolution, setShowSolution] = useState(false);
  
  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };
  
  if (!data?.selectgroup?.length) {
    return <Typography>Formato SelectGroup non valido.</Typography>;
  }
  return (
    <Box sx={{ marginTop: '1rem' }}>
      {/* Header distintivo per SelectGroup */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        backgroundColor: '#E8F5E9', // Colore di sfondo distintivo per SelectGroup (verde chiaro)
        padding: '0.5rem',
        borderRadius: '0.5rem'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <CategoryIcon sx={{ color: '#4CAF50' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#2E7D32' }}>
            Raggruppa elementi
          </Typography>
        </Box>
        
        <Button 
          variant={showSolution ? "contained" : "outlined"}
          size="small"
          onClick={toggleSolution}
          sx={{ 
            color: showSolution ? 'white' : '#2E7D32', 
            borderColor: '#4CAF50',
            backgroundColor: showSolution ? '#4CAF50' : 'transparent',
            '&:hover': {
              backgroundColor: showSolution ? '#2E7D32' : 'rgba(76, 175, 80, 0.1)'
            }
          }}
        >
          {showSolution ? "Nascondi soluzione" : "Mostra soluzione"}
        </Button>
      </Box>
      
      {data.selectgroup.map((group) => (
        <Card key={group.id} variant="outlined" sx={{ 
          marginBottom: '1.5rem', 
          borderRadius: '1rem',
          borderLeft: '4px solid #4CAF50', // Bordo laterale verde distintivo
          boxShadow: '0 2px 4px rgba(76, 175, 80, 0.2)' // Ombra leggermente verde
        }}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ 
              fontWeight: 600,
              backgroundColor: '#E8F5E9',
              padding: '0.25rem 0.5rem',
              borderRadius: '0.25rem',
              display: 'inline-block',
              marginBottom: '0.5rem'
            }}>
              {group.topic}
            </Typography>
            <Box sx={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', marginTop: '0.5rem' }}>
              {group.words.map((wd, i) => (
                <Chip 
                  key={`${wd}-${i}`} 
                  label={wd} 
                  sx={{
                    backgroundColor: showSolution ? '#4CAF50' : 'transparent',
                    color: showSolution ? '#FFFFFF' : 'inherit',
                    borderColor: '#4CAF50',
                    border: showSolution ? 'none' : '1px solid'
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// -------------- SELECTGROUP EDITABLE --------------
export const SelectGroupEditable = ({ data, onUpdate }) => {
  const [editedData, setEditedData] = useState(JSON.parse(JSON.stringify(data)));

  const handleTopicChange = (id, newTopic) => {
    const updatedData = { ...editedData };
    const groupIndex = updatedData.selectgroup.findIndex(group => group.id === id);
    if (groupIndex !== -1) {
      updatedData.selectgroup[groupIndex].topic = newTopic;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleWordChange = (groupId, wordIndex, newWord) => {
    const updatedData = { ...editedData };
    const groupIndex = updatedData.selectgroup.findIndex(group => group.id === groupId);
    if (groupIndex !== -1) {
      updatedData.selectgroup[groupIndex].words[wordIndex] = newWord;
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddWord = (groupId) => {
    const updatedData = { ...editedData };
    const groupIndex = updatedData.selectgroup.findIndex(group => group.id === groupId);
    if (groupIndex !== -1) {
      updatedData.selectgroup[groupIndex].words.push("Nuova parola");
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleDeleteWord = (groupId, wordIndex) => {
    const updatedData = { ...editedData };
    const groupIndex = updatedData.selectgroup.findIndex(group => group.id === groupId);
    if (groupIndex !== -1) {
      updatedData.selectgroup[groupIndex].words.splice(wordIndex, 1);
      setEditedData(updatedData);
      onUpdate(updatedData);
    }
  };

  const handleAddGroup = () => {
    const updatedData = { ...editedData };
    const newId = Date.now();
    updatedData.selectgroup.push({
      id: newId,
      topic: "Nuovo gruppo",
      words: ["Parola 1", "Parola 2", "Parola 3"]
    });
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleDeleteGroup = (id) => {
    const updatedData = { ...editedData };
    updatedData.selectgroup = updatedData.selectgroup.filter(group => group.id !== id);
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  if (!editedData?.selectgroup?.length) {
    return <Typography>Formato SelectGroup non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {editedData.selectgroup.map((group) => (
        <Card 
          key={group.id} 
          variant="outlined" 
          sx={{ marginBottom: '1.5rem', borderRadius: '1rem', position: 'relative', paddingTop: '20px' }}
        >
          <IconButton 
            size="small" 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#DF4634', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 10, '&:hover': { backgroundColor: 'rgba(255,255,255,1)' } }}
            onClick={() => handleDeleteGroup(group.id)}
          >
            <DeleteIcon />
          </IconButton>
          <CardContent>
            <TextField
              fullWidth
              label="Titolo del gruppo"
              variant="outlined"
              value={group.topic}
              onChange={(e) => handleTopicChange(group.id, e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>Parole nel gruppo:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
              {group.words.map((word, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem', padding: '5px' }}>
                  <TextField
                    fullWidth
                    size="small"
                    value={word}
                    onChange={(e) => handleWordChange(group.id, index, e.target.value)}
                  />
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: '#DF4634',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                      marginLeft: '10px',
                      '&:hover': { 
                        backgroundColor: 'rgba(255,255,255,1)',
                      }
                    }}
                    onClick={() => handleDeleteWord(group.id, index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ mt: 1 }}
              onClick={() => handleAddWord(group.id)}
            >
              Aggiungi parola
            </Button>
          </CardContent>
        </Card>
      ))}
      
      <Button
        fullWidth
        variant="outlined"
        startIcon={<AddCircleOutlineIcon />}
        sx={{ mt: 2 }}
        onClick={handleAddGroup}
      >
        Aggiungi nuovo gruppo
      </Button>
    </Box>
  );
};

