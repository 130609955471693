import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Header';
import MobileMenu from '../MobileMenu';
import Footer from '../Footer';
import FloatingMenu from '../FloatingMenu';
import GameNavigationBar from './GameNavigationBar';
import GameContainer from './GameContainer';
import ScoreSummary from './ScoreSummary';
import GameDetails from './GameDetails';
import ResetConfirmModal from './ResetConfirmModal';
import apiClient from '../../api/apiClient';
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setAccessToken,
} from '../../api/authUtils';
import { jwtDecode } from 'jwt-decode';
import './styles/InteractiveGame.css';

// Componente principale della pagina
const InteractiveGamePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  // Struttura per i giochi completati: Array di oggetti con struttura standardizzata
  const [completedGames, setCompletedGames] = useState([]);
  const [showScoreSummary, setShowScoreSummary] = useState(false);
  const [selectedGameDetails, setSelectedGameDetails] = useState(null);
  // Stato per il modal di conferma reset
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  // Stato per il feedback di copia URL
  const [showShareFeedback, setShowShareFeedback] = useState(false);

  const { gameId } = useParams();
  const navigate = useNavigate();

  // Check token validity on mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      // Ogni volta che cambia l'ID del gioco, resettiamo lo stato
      setCurrentGameIndex(0);
      setCompletedGames([]);
      setShowScoreSummary(false);
      setSelectedGameDetails(null);
      
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            // Access token ancora valido
            setIsLoggedIn(true);
          } else if (refreshToken) {
            // Access token scaduto, provo a rinnovarlo con il refresh token
            try {
              const response = await apiClient.post('/auth/refresh', { refreshToken });
              if (response.data && response.data.accessToken) {
                setAccessToken(response.data.accessToken);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la verifica del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }

      // Recuperiamo i risultati salvati in localStorage
      try {
        const savedResults = localStorage.getItem(`game_results_${gameId}`);
        if (savedResults) {
          const parsedResults = JSON.parse(savedResults);
          console.log('Caricati risultati salvati da localStorage:', parsedResults);
          setCompletedGames(parsedResults);
        }
      } catch (e) {
        console.error('Errore nel caricamento da localStorage:', e);
      }

      // Carica i dati del gioco
      await fetchGameData();
    };

    checkAuthStatus();
  }, [gameId]);

  // Gestione scroll per menu flottante
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 300) {
      setShowFloatingMenu(true);
    } else {
      setShowFloatingMenu(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const fetchGameData = async () => {
    if (!gameId) {
      setError("ID del gioco mancante");
      setLoading(false);
      return;
    }

    try {
      // URL corretto identificato dai test
      const response = await apiClient.get(`/api/advantage_games/games/${gameId}/`);
      if (response.data) {
        setGameData(response.data);
        console.log("Dati del gioco caricati con successo:", response.data);
      } else {
        setError("Nessun dato ricevuto dal server");
      }
    } catch (error) {
      console.error("Errore durante il caricamento del gioco:", error);
      setError(`Errore durante il caricamento del gioco: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Funzione che gestisce il completamento di un gioco
  const handleGameComplete = (result) => {
    // Log completo dei dati ricevuti
    console.log('===== GIOCO COMPLETATO =====');
    console.log('Dati ricevuti dal gioco:', result);
    console.log('Tipo di punteggio ricevuto:', typeof result.score, 'Valore:', result.score);
    
    if (!result || !result.chatId) {
      console.error('ERRORE: Dati mancanti o chatId mancante!', result);
      return;
    }
    
    // Assicuriamoci che il punteggio sia un numero valido
    let numericScore = 0;
    if (result.score !== undefined) {
      numericScore = Number(result.score);
      if (isNaN(numericScore)) {
        console.error('ERRORE: Il punteggio non è un numero valido!', result.score);
        numericScore = 0;
      }
    } else if (result.type === 'scrittura') {
      numericScore = 100;
    }
    
    // Creiamo una struttura standardizzata per tutti i tipi di gioco
    const standardizedResult = {
      // Metadati comuni a tutti i giochi
      type: result.type || 'unknown',
      chatId: result.chatId,
      uniqueId: `${result.type}_${result.chatId}_${Date.now()}`,
      completedAt: new Date().toISOString(),
      completed: true,
      
      // Punteggio (convertito sempre a numero)
      score: numericScore,
      
      // Conserviamo la proprietà goToSummary se presente
      goToSummary: result.goToSummary || false,
      
      // Statistiche specifiche per quiz e giochi con domande
      correctAnswers: result.correctAnswers !== undefined ? Number(result.correctAnswers) : undefined,
      totalQuestions: result.totalQuestions !== undefined ? Number(result.totalQuestions) : undefined,
      
      // Risultati dettagliati, specifici per tipo di gioco
      details: {
        ...(result.details || {}),
        gameType: result.type,
        completedAt: new Date().toISOString()
      }
    };
    
    // Aggiungiamo dettagli specifici per tipo di gioco
    switch (result.type) {
      case 'quiz':
        // Ci assicuriamo di includere i risultati dettagliati delle domande
        standardizedResult.questionResults = result.questionResults || [];
        break;
      case 'matchpairs':
        standardizedResult.pairResults = result.pairResults || [];
        break;
      case 'rearrange':
        standardizedResult.arrangementResults = result.arrangementResults || [];
        break;
      case 'crossword':
        standardizedResult.wordResults = result.wordResults || [];
        // Assicuriamoci che il punteggio sia corretto nel caso del cruciverba
        if (result.score !== undefined) {
          standardizedResult.score = Number(result.score);
        }
        break;
      case 'selectgroup':
        standardizedResult.groupResults = result.groupResults || [];
        break;
      case 'fillthebox':
        standardizedResult.results = result.results || [];
        break;
      case 'scrittura':
        // Per i contenuti di testo/video, aggiungiamo info sul tipo
        standardizedResult.contentType = result.contentType || 'text';
        break;
    }
    
    // Cerchiamo se esiste già un risultato per questo chatId
    const existingIndex = completedGames.findIndex(game => game.chatId === standardizedResult.chatId);
    
    // Creiamo una nuova copia dell'array per mantenere l'immutabilità
    let newCompletedGames;
    
    if (existingIndex !== -1) {
      // Aggiorniamo il risultato esistente
      newCompletedGames = [...completedGames];
      newCompletedGames[existingIndex] = standardizedResult;
    } else {
      // Aggiungiamo un nuovo risultato
      newCompletedGames = [...completedGames, standardizedResult];
    }
    
    // Salviamo i risultati aggiornati nello stato
    setCompletedGames(newCompletedGames);
    
    // Salviamo i risultati anche in localStorage per persistenza
    try {
      // Verifichiamo che stiamo salvando dati validi
      if (!Array.isArray(newCompletedGames)) {
        console.error('ERRORE: newCompletedGames non è un array!', newCompletedGames);
        return;
      }
      
      // Verifichiamo che ogni gioco abbia un punteggio numerico valido
      newCompletedGames.forEach((game, index) => {
        if (typeof game.score !== 'number') {
          console.warn(`Gioco all'indice ${index} ha un punteggio non numerico:`, game.score);
          game.score = Number(game.score) || 0;
        }
      });
      
      const serializedGames = JSON.stringify(newCompletedGames);
      localStorage.setItem(`game_results_${gameId}`, serializedGames);
      
      // Verifichiamo che il salvataggio sia avvenuto correttamente
      const savedData = localStorage.getItem(`game_results_${gameId}`);
      if (!savedData) {
        console.error('ERRORE: Dati non trovati in localStorage dopo il salvataggio!');
      }
    } catch (e) {
      console.error('Errore nel salvataggio in localStorage:', e);
    }
    
    // Passiamo al prossimo gioco se disponibile
    if (standardizedResult.goToSummary) {
      // Verifichiamo se tutti i giochi sono stati completati
      const allGamesCompleted = gameData.game_data.chats.every(chat => 
        [...newCompletedGames].some(game => game.chatId === chat.id)
      );
      
      console.log("Tutti i giochi sono stati completati?", allGamesCompleted);
      
      if (allGamesCompleted) {
        // Solo se tutti i giochi sono stati completati, mostriamo il riepilogo
        setShowScoreSummary(true);
        setSelectedGameDetails(null);
      } else {
        // Altrimenti troviamo il primo gioco non completato e ci spostiamo lì
        const firstIncompleteGameIndex = gameData.game_data.chats.findIndex(chat => 
          ![...newCompletedGames].some(game => game.chatId === chat.id)
        );
        
        if (firstIncompleteGameIndex !== -1) {
          console.log("Reindirizzamento al primo gioco non completato:", firstIncompleteGameIndex);
          setCurrentGameIndex(firstIncompleteGameIndex);
        } else {
          // Nel caso improbabile in cui non riusciamo a trovare un gioco non completato
          // ma allGamesCompleted è false, semplicemente andiamo al primo gioco
          setCurrentGameIndex(0);
        }
      }
    } else if (gameData && gameData.game_data && gameData.game_data.chats &&
        currentGameIndex < gameData.game_data.chats.length - 1) {
      setCurrentGameIndex(currentGameIndex + 1);
    } else {
      // Se siamo all'ultimo gioco ma non abbiamo il flag goToSummary
      // verifichiamo se tutti i giochi sono stati completati
      const allGamesCompleted = gameData.game_data.chats.every(chat => 
        [...newCompletedGames].some(game => game.chatId === chat.id)
      );
      
      if (allGamesCompleted) {
        // Se tutti i giochi sono completati, mostriamo il riepilogo
        setShowScoreSummary(true);
        setSelectedGameDetails(null);
      } else {
        // Altrimenti troviamo il primo gioco non completato
        const firstIncompleteGameIndex = gameData.game_data.chats.findIndex(chat => 
          ![...newCompletedGames].some(game => game.chatId === chat.id)
        );
        
        if (firstIncompleteGameIndex !== -1) {
          setCurrentGameIndex(firstIncompleteGameIndex);
        }
      }
    }
  };

  // Funzione per gestire la consegna finale
  const handleSubmitFinalResults = () => {
    // Verifichiamo se tutti i giochi sono stati completati
    const allGamesCompleted = gameData.game_data.chats.every(chat => 
      completedGames.some(game => game.chatId === chat.id)
    );
    
    if (allGamesCompleted) {
      // Solo se tutti i giochi sono stati completati, mostriamo il riepilogo
      setShowScoreSummary(true);
      setSelectedGameDetails(null);
    } else {
      // Altrimenti troviamo il primo gioco non completato e ci spostiamo lì
      const firstIncompleteGameIndex = gameData.game_data.chats.findIndex(chat => 
        !completedGames.some(game => game.chatId === chat.id)
      );
      
      if (firstIncompleteGameIndex !== -1) {
        console.log("Reindirizzamento al primo gioco non completato:", firstIncompleteGameIndex);
        setCurrentGameIndex(firstIncompleteGameIndex);
      }
    }
  };

  // Funzione per navigare tra i giochi
  const handleNavigateToGame = (index) => {
    setCurrentGameIndex(index);
    setShowScoreSummary(false);
    setSelectedGameDetails(null);
  };

  // Funzione per tornare alla schermata dei giochi
  const handleBackToGames = () => {
    setShowScoreSummary(false);
    setSelectedGameDetails(null);
  };

  // Funzione per mostrare i dettagli di un gioco specifico
  const handleShowGameDetails = (gameResult, chat) => {
    // Se il gioco non è stato completato, crea un oggetto risultato vuoto
    if (!gameResult) {
      gameResult = {
        chatId: chat.id,
        game_type: chat.agent,
        score: 0,
        correctAnswers: 0,
        totalQuestions: 0,
        completed_at: new Date().toISOString(),
        wordResults: []
      };
    }
    
    setSelectedGameDetails({ gameResult, chat });
  };

  // Funzione per tornare al riepilogo generale
  const handleBackToSummary = () => {
    setSelectedGameDetails(null);
  };

  // Funzione per resettare completamente il gioco
  const handleResetGame = () => {
    // Mostra il modal di conferma
    setShowResetConfirm(true);
  };
  
  // Funzione per eseguire effettivamente il reset dopo la conferma
  const confirmReset = () => {
    // Cancella i punteggi salvati
    setCompletedGames([]);
    
    // Rimuovi i dati dal localStorage
    try {
      localStorage.removeItem(`game_results_${gameId}`);
    } catch (e) {
      console.error('Errore nella rimozione da localStorage:', e);
    }
    
    // Torna alla schermata dei giochi e imposta il primo gioco
    setShowScoreSummary(false);
    setSelectedGameDetails(null);
    setCurrentGameIndex(0);
    
    // Chiudi il modal
    setShowResetConfirm(false);
  };
  
  // Funzione per annullare il reset
  const cancelReset = () => {
    setShowResetConfirm(false);
  };

  // Funzione per copiare l'URL corrente negli appunti
  const handleShareGame = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setShowShareFeedback(true);
        setTimeout(() => {
          setShowShareFeedback(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Errore durante la copia dell\'URL:', error);
      });
  };

  return (
    <div className="interactive-game-page">
      <Header isLoggedIn={isLoggedIn} onMenuToggle={toggleMobileMenu} />
      {mobileMenuOpen && <MobileMenu isLoggedIn={isLoggedIn} onClose={toggleMobileMenu} />}
      
      <main className="game-content">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="game-container"
        >
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Caricamento in corso...</p>
            </div>
          ) : error ? (
            <div className="error-container">
              <h2>Si è verificato un errore</h2>
              <p>{error}</p>
              <button onClick={() => navigate('/')}>Torna alla Home</button>
            </div>
          ) : gameData ? (
            <>
              <div className="game-header">
                <h1>{gameData.title || "Gioco Interattivo"}</h1>
                <button 
                  className="share-button" 
                  onClick={handleShareGame}
                  aria-label="Condividi"
                >
                  <img 
                    src="/interactivegame/share.png" 
                    alt="Condividi" 
                    className="share-icon" 
                  />
                  Condividi
                  {showShareFeedback && <span className="share-feedback">URL copiato!</span>}
                </button>
              </div>
              
              {/* Barra di navigazione tra i giochi - nascosta quando si visualizza il riepilogo */}
              {!showScoreSummary && (
                <GameNavigationBar 
                  chats={gameData.game_data.chats}
                  currentGameIndex={currentGameIndex}
                  completedGames={completedGames}
                  onNavigate={handleNavigateToGame}
                />
              )}
              
              <div className="game-area">
                {showScoreSummary ? (
                  selectedGameDetails ? (
                    <GameDetails 
                      gameResult={selectedGameDetails.gameResult} 
                      chat={selectedGameDetails.chat}
                      onBackToSummary={handleBackToSummary}
                      onNavigateToGame={handleNavigateToGame}
                    />
                  ) : (
                    <ScoreSummary 
                      gameData={gameData}
                      completedGames={completedGames}
                      onShowGameDetails={handleShowGameDetails}
                      onBackToGames={handleBackToGames}
                      onResetGame={handleResetGame}
                    />
                  )
                ) : (
                  <GameContainer 
                    gameData={gameData}
                    currentGameIndex={currentGameIndex}
                    completedGames={completedGames}
                    onGameComplete={handleGameComplete}
                    onSubmitFinalResults={handleSubmitFinalResults}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="no-data-container">
              <h2>Nessun dato disponibile</h2>
              <p>Non sono stati trovati dati per questo gioco.</p>
              <button onClick={() => navigate('/')}>Torna alla Home</button>
            </div>
          )}
        </motion.div>
      </main>
      
      <Footer />
      {showFloatingMenu && <FloatingMenu isLoggedIn={isLoggedIn} />}
      
      {/* Modal di conferma per il reset */}
      <ResetConfirmModal 
        isVisible={showResetConfirm} 
        onConfirm={confirmReset} 
        onCancel={cancelReset} 
      />
    </div>
  );
};

export default InteractiveGamePage;

// Funzione helper per il colore del punteggio
export const getScoreColor = (score) => {
  if (score >= 80) return '#19696A';
  if (score >= 60) return '#FF9800';
  return '#df4634';
};

