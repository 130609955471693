import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PublishIcon from '@mui/icons-material/Publish';
import LaunchIcon from '@mui/icons-material/Launch';
import apiClient from '../../api/apiClient';
import { getMostRecentChatByAgent, isValidYoutubeUrl, extractYoutubeVideoId } from './utils';
import { createSubChat as createSubChatUtil } from './createSubChat';

// Importazione dei componenti
import GlobalChatEmptyState from './GlobalChatEmptyState';
import SubChatList from './SubChatList';
import GlobalChatDetail from './GlobalChatDetail';
import AgentDialog from './AgentDialog';
import AgentDialogMobile from './AgentDialogMobile';
import TextDialog from './TextDialog';
import VideoDialog from './VideoDialog';
import DeleteDialog from './DeleteDialog';
import PublishDialog from './PublishDialog';

/**
 * Componente principale per la gestione delle GlobalChat
 */
const GlobalChatOverview = () => {
  const { globalChatId } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  // Stato e load
  const [subChats, setSubChats] = useState([]);
  const [globalTitle, setGlobalTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isPublished, setIsPublished] = useState(false);
  const [gameId, setGameId] = useState(null);

  // Dialog di scelta agent
  const [agentDialogOpen, setAgentDialogOpen] = useState(false);
  const [agentDialogMobileOpen, setAgentDialogMobileOpen] = useState(false);

  // Modal di dettaglio chat
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [lastAssistantMessage, setLastAssistantMessage] = useState(null);
  const [detailLoading, setDetailLoading] = useState(false);

  // Stato form
  const [newUserMessage, setNewUserMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [generating, setGenerating] = useState(false);

  // Edit per l'assistant
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState('');

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');

  // Dialog di conferma eliminazione
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  // Stato per il modale del testo
  const [textDialogOpen, setTextDialogOpen] = useState(false);
  const [textSource, setTextSource] = useState('ai');
  const [customTextTitle, setCustomTextTitle] = useState('');
  const [customText, setCustomText] = useState('');

  // Stato per il modale video
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [videoSource, setVideoSource] = useState('youtube');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [videoDescription, setVideoDescription] = useState('');

  // Stato per il dialogo di pubblicazione
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [publishTitle, setPublishTitle] = useState('');
  const [publishing, setPublishing] = useState(false);
  const [publishedGameId, setPublishedGameId] = useState(null);

  /* =============================
     useEffect fetchGlobalChat
     =============================*/
  useEffect(() => {
    fetchGlobalChat(true);
    // eslint-disable-next-line
  }, [globalChatId]);

  // Quando selectedChat cambia, carico l'ultimo messaggio
  useEffect(() => {
    if (selectedChat) {
      loadLastAssistantMessage(selectedChat.id);
    } else {
      setLastAssistantMessage(null);
      setDetailLoading(false);
    }
  }, [selectedChat]);

  /* =============================
     1) Carica GlobalChat
     =============================*/
  async function fetchGlobalChat(showLoadingAnimation = true) {
    if (showLoadingAnimation) {
      setLoading(true);
    }
    
    try {
      const res = await apiClient.get(`/api/advantage_games/globalchats/${globalChatId}/`);
      setGlobalTitle(res.data.title || `GlobalChat #${globalChatId}`);
      setSubChats(res.data.chats || []);
      setIsPublished(res.data.is_published || false);
      setGameId(res.data.game_id || null);
      setPublishedGameId(res.data.game_id || null);
      
      if (initialLoad) {
        setInitialLoad(false);
      }
    } catch (err) {
      console.error('Errore fetchGlobalChat:', err);
      showSnackbar('Errore caricamento GlobalChat');
    } finally {
      if (showLoadingAnimation) {
        setLoading(false);
      }
    }
  }

  /* =============================
     2) Crea subChat
     =============================*/
  async function createSubChat(agentKey) {
    return await createSubChatUtil(globalChatId, agentKey, setSubChats, showSnackbar);
  }

  /* =============================
     3) Carica l'ultimo messaggio
     =============================*/
  async function loadLastAssistantMessage(chatId) {
    try {
      const res = await apiClient.get(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${chatId}/messages/`
      );
      const allMessages = res.data || [];
      // filtra i messaggi assistant
      const assistantMsgs = allMessages.filter((m) => m.sender === 'assistant');
      // prendi l'ultimo
      const lastOne = assistantMsgs.length > 0 ? assistantMsgs[assistantMsgs.length - 1] : null;
      setLastAssistantMessage(lastOne);
    } catch (err) {
      console.error('Errore loadLastAssistantMessage:', err);
      showSnackbar('Errore caricamento ultimi messaggi');
      setLastAssistantMessage(null);
    } finally {
      setDetailLoading(false);
    }
  }

  /* =============================
     4) Invia messaggio utente
     =============================*/
  async function handleSendMessage(e) {
    e.preventDefault();
    if (!selectedChat) return;
    
    // Per messaggi normali, controlla il testo
    if (selectedChat.agent !== 'scrittura' && !newUserMessage.trim() && files.length === 0) return;

    // Mostro lo spinner / blocco
    setGenerating(true);

    try {
      const formData = new FormData();
      formData.append('text', newUserMessage);
      files.forEach((f) => formData.append('files', f));

      // Se è una chat di tipo "scrittura" con opzione manual, aggiungi i parametri speciali
      if (selectedChat.agent === 'scrittura' && textSource === 'manual') {
        formData.append('use_text_as_response', 'true');
        formData.append('assistant_text', customText);
      }

      // Pulisco form
      setNewUserMessage('');
      setFiles([]);

      // Chiamata backend
      const res = await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${selectedChat.id}/messages/send/`,
        formData
      );
      const data = res.data;
      
      // --- Disattiva lo spinner qui, appena arriva la risposta --- 
      setGenerating(false);
      // --------------------------------------------------------------

      if (data.assistant_message) {
        // è un oggetto con "id, text, attachments..."
        setLastAssistantMessage({ ...data.assistant_message, sender: 'assistant' });
      } else if (data.message) {
        // fallback se il backend tornasse "message" = stringa
        setLastAssistantMessage({
          id: Date.now() + 1,
          sender: 'assistant',
          text: data.message
        });
      }

      // Ricarico la globalChat per aggiornare anteprima, senza mostrare l'animazione
      fetchGlobalChat(false);
      
      // Se era una chat di scrittura con testo manuale, chiudi il modale
      if (selectedChat.agent === 'scrittura' && textSource === 'manual') {
        setTextDialogOpen(false);
        setCustomText('');
        setCustomTextTitle('');
      }
      
    } catch (err) {
      console.error('Errore handleSendMessage:', err);
      showSnackbar("Errore nell'invio del messaggio");
      setGenerating(false); // Assicurati che lo spinner si fermi anche in caso di errore
    }
  }

  /* =============================
     5) Edit messaggio dell'assistente
     =============================*/
  function startEdit() {
    if (!lastAssistantMessage) return;
    setEditedText(lastAssistantMessage.text || '');
    setEditMode(true);
  }

  async function handleSaveEdit() {
    if (!lastAssistantMessage) return;
    try {
      const msgId = lastAssistantMessage.id;
      // Chiamata PATCH
      const res = await apiClient.patch(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${selectedChat.id}/messages/${msgId}/edit/`,
        { text: editedText }
      );
      const updated = res.data;
      // Aggiorno local
      setLastAssistantMessage(updated);
      setEditMode(false);

      // Anche qui ricarico subchats per aggiornare anteprima, senza mostrare l'animazione
      fetchGlobalChat(false);

      showSnackbar("Testo modificato con successo!");
    } catch (err) {
      console.error('Errore salvataggio edit:', err);
      showSnackbar("Impossibile salvare la modifica");
    }
  }

  function cancelEdit() {
    setEditMode(false);
    setEditedText('');
  }

  // Funzione per aggiornare il testo JSON dei componenti editabili
  function handleEditableUpdate(updatedData) {
    setEditedText(JSON.stringify(updatedData, null, 2));
  }

  /* =============================
     Apertura dialog agent
     =============================*/
  function openAgentDialog() {
    if (isMobile) {
      setAgentDialogMobileOpen(true);
    } else {
      setAgentDialogOpen(true);
    }
  }
  
  function closeAgentDialog() {
    setAgentDialogOpen(false);
    setAgentDialogMobileOpen(false);
  }
  
  async function handleChooseAgent(agentKey) {
    // Chiusura del dialog immediata per evitare problemi di rendering
    setAgentDialogOpen(false);
    setAgentDialogMobileOpen(false);
    
    // Gestione speciale per "scrittura"
    if (agentKey === 'scrittura') {
      try {
        // Creo una nuova chat e attendo che sia completata
        const createdChat = await createSubChat('scrittura');
        
        // Se la creazione è fallita, esci
        if (!createdChat) return;
        
        // Poi apriamo il modal per il testo
        setTextDialogOpen(true);
      } catch (err) {
        console.error('Errore creazione chat per testo:', err);
        showSnackbar('Errore nella creazione della chat per il testo');
      }
    } 
    // Gestione speciale per "video", ma usando l'agente "scrittura"
    else if (agentKey === 'video') {
      try {
        // Creo una nuova chat e attendo che sia completata
        const createdChat = await createSubChat('scrittura');
        
        // Se la creazione è fallita, esci
        if (!createdChat) return;
        
        // Poi apriamo il modal per il video
        setVideoDialogOpen(true);
      } catch (err) {
        console.error('Errore creazione chat per video:', err);
        showSnackbar('Errore nella creazione della chat per il video');
      }
    }
    else {
      // Comportamento standard per gli altri agenti
      createSubChat(agentKey);
    }
  }

  // Funzione per inviare testo manuale
  async function handleSubmitCustomText() {
    if (!customText.trim()) {
      showSnackbar('Inserisci un testo valido');
      return;
    }
    
    try {
      // Dopo il refresh, prendi l'ultima chat creata
      const mostRecentTextChat = getMostRecentChatByAgent(subChats, 'scrittura');
      
      if (!mostRecentTextChat) {
        showSnackbar('Errore: Chat non trovata dopo la creazione');
        return;
      }
      
      // Imposta la chat selezionata e prepara per l'invio del messaggio
      setSelectedChat(mostRecentTextChat);
      setNewUserMessage(customTextTitle || 'Ecco il mio testo personalizzato');
      setTextSource('manual');
      
      // Invia il messaggio 
      const formData = new FormData();
      formData.append('text', customTextTitle || 'Ecco il mio testo personalizzato');
      formData.append('use_text_as_response', 'true');
      formData.append('assistant_text', customText);
      
      setGenerating(true);
      
      const res = await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${mostRecentTextChat.id}/messages/send/`,
        formData
      );
      
      // Gestisci la risposta
      const data = res.data;
      if (data.assistant_message) {
        setLastAssistantMessage({ ...data.assistant_message, sender: 'assistant' });
      }
      
      // Ricarico la globalChat per aggiornare anteprima, senza mostrare l'animazione
      await fetchGlobalChat(false);
      
      // Chiudi il modale e resetta stati
      setTextDialogOpen(false);
      setCustomText('');
      setCustomTextTitle('');
      setGenerating(false);
      
    } catch (err) {
      console.error('Errore invio testo personalizzato:', err);
      showSnackbar('Errore durante l\'invio del testo');
      setGenerating(false);
    }
  }

  function handleCloseTextDialog() {
    setTextDialogOpen(false);
    setTextSource('ai');
    setCustomText('');
    setCustomTextTitle('');
  }

  async function openTextChat() {
    try {
      // Trova l'ultima chat creata
      const mostRecentTextChat = getMostRecentChatByAgent(subChats, 'scrittura');
      
      if (mostRecentTextChat) {
        // Creo un evento fittizio con stopPropagation
        const fakeEvent = { stopPropagation: () => {} };
        openDetail(mostRecentTextChat, fakeEvent);
        setTextDialogOpen(false);
      } else {
        showSnackbar('Errore: impossibile trovare la chat');
      }
    } catch (err) {
      console.error('Errore apertura chat di testo:', err);
      showSnackbar('Errore nell\'apertura della chat');
    }
  }

  /* =============================
     Funzioni per la gestione dei video
     =============================*/
  function handleCloseVideoDialog() {
    setVideoDialogOpen(false);
    setVideoSource('youtube');
    setVideoUrl('');
    setVideoFile(null);
    setVideoDescription('');
  }

  function handleVideoFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
    } else {
      showSnackbar('Seleziona un file video valido');
      setVideoFile(null);
    }
  }

  async function handleSubmitVideo() {
    // Validazione
    if (!isValidYoutubeUrl(videoUrl)) {
      showSnackbar('Inserisci un URL YouTube valido');
      return;
    }
    
    if (!videoDescription.trim()) {
      showSnackbar('Inserisci una descrizione per il video');
      return;
    }
    
    // Trova la chat più recente per l'agente scrittura (usato anche per i video)
    const mostRecentVideoChat = getMostRecentChatByAgent(subChats, 'scrittura');
    
    if (!mostRecentVideoChat) {
      showSnackbar('Errore: Chat non trovata');
      return;
    }
    
    // Prepara il contenuto del messaggio
    const videoId = extractYoutubeVideoId(videoUrl);
    if (!videoId) {
      showSnackbar('URL YouTube non valido');
      return;
    }
    
    // Formato JSON per il video di YouTube
    const videoContent = JSON.stringify({
      type: 'youtube',
      videoId: videoId,
      description: videoDescription
    });
    
    // Imposta la chat selezionata e prepara per l'invio del messaggio
    setSelectedChat(mostRecentVideoChat);
    
    // Per YouTube, possiamo inviare direttamente come testo
    setNewUserMessage('Ecco un video da YouTube');
    
    // Imposta il messaggio personalizzato dell'assistente
    const formData = new FormData();
    formData.append('text', 'Ecco un video da YouTube');
    formData.append('use_text_as_response', 'true');
    formData.append('assistant_text', videoContent);
    
    try {
      setGenerating(true);
      
      // Chiamata backend
      const res = await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${mostRecentVideoChat.id}/messages/send/`,
        formData
      );
      
      const data = res.data;
      if (data.assistant_message) {
        setLastAssistantMessage({ ...data.assistant_message, sender: 'assistant' });
      }
      
      // Ricarico la globalChat per aggiornare anteprima, senza mostrare l'animazione
      fetchGlobalChat(false);
      
      // Chiudi il modale
      handleCloseVideoDialog();
    } catch (err) {
      console.error('Errore invio video:', err);
      showSnackbar('Errore durante l\'invio del video');
      setGenerating(false); // Assicurati che lo spinner si fermi anche in caso di errore
    }
  }

  /* =============================
     Apertura/chiusura modal
     =============================*/
  function openDetail(chat, e) {
    // Controllo se e è definito prima di usarlo
    if (e && typeof e.stopPropagation === 'function') {
      e.stopPropagation();
    }
    
    // Non apriamo il dettaglio se la chat è temporanea (ancora in fase di creazione)
    if (chat.temp) {
      return; // Non facciamo nulla quando la chat è temporanea
    }
    
    setDetailLoading(true);
    setSelectedChat(chat);
    setDetailOpen(true);
  }
  function closeDetail() {
    setDetailOpen(false);
    setSelectedChat(null);
    setLastAssistantMessage(null);
    // Annulla la modifica se era attiva
    if (editMode) {
      setEditMode(false);
      setEditedText('');
    }
  }

  /* =============================
     Elimina chat
     =============================*/
  function handleOpenDeleteDialog(chat, e) {
    e.stopPropagation();
    setChatToDelete(chat);
    setDeleteDialogOpen(true);
  }
  function handleCloseDeleteDialog() {
    setDeleteDialogOpen(false);
    setChatToDelete(null);
  }
  async function handleConfirmDelete() {
    if (!chatToDelete) return;
    try {
      await apiClient.delete(
        `/api/advantage_games/globalchats/${globalChatId}/chats/${chatToDelete.id}/delete/`
      );
      showSnackbar('Chat eliminata con successo');
      fetchGlobalChat(true);
    } catch (err) {
      console.error('Errore eliminazione chat:', err);
      showSnackbar('Errore nella eliminazione della chat');
    } finally {
      handleCloseDeleteDialog();
    }
  }

  /* =============================
     6) Gestione riordinamento chat
     =============================*/
  async function handleMoveUp(chat, index) {
    if (index === 0) return; // già in cima
    const prevChat = subChats[index - 1];
    
    // Aggiornamento ottimistico - scambio locale immediato
    const newSubChats = [...subChats];
    newSubChats[index] = prevChat; // L'elemento precedente va nella posizione corrente
    newSubChats[index - 1] = chat; // L'elemento corrente va nella posizione precedente
    
    // Aggiorno lo stato locale immediatamente
    setSubChats(newSubChats);
    
    try {
      // Chiamata API asincrona
      await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/chats/swap-positions/`,
        {
          chat_id1: chat.id,
          chat_id2: prevChat.id
        }
      );
      
      // Non serve più showSnackbar per ridurre il rumore visivo
      // Non ricarico più fetchGlobalChat() perché abbiamo già aggiornato lo stato
    } catch (err) {
      console.error('Errore nel riordinamento:', err);
      showSnackbar('Errore nel riordinamento delle attività');
      
      // In caso di errore, ripristino lo stato originale - qui è meglio mostrare l'animazione
      await fetchGlobalChat(true);
    }
  }

  async function handleMoveDown(chat, index) {
    if (index === subChats.length - 1) return; // già in fondo
    const nextChat = subChats[index + 1];
    
    // Aggiornamento ottimistico - scambio locale immediato
    const newSubChats = [...subChats];
    newSubChats[index] = nextChat; // L'elemento successivo va nella posizione corrente
    newSubChats[index + 1] = chat; // L'elemento corrente va nella posizione successiva
    
    // Aggiorno lo stato locale immediatamente
    setSubChats(newSubChats);
    
    try {
      // Chiamata API asincrona
      await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/chats/swap-positions/`,
        {
          chat_id1: chat.id,
          chat_id2: nextChat.id
        }
      );
      
      // Non serve più showSnackbar per ridurre il rumore visivo
      // Non ricarico più fetchGlobalChat() perché abbiamo già aggiornato lo stato
    } catch (err) {
      console.error('Errore nel riordinamento:', err);
      showSnackbar('Errore nel riordinamento delle attività');
      
      // In caso di errore, ripristino lo stato originale - qui è meglio mostrare l'animazione
      await fetchGlobalChat(true);
    }
  }

  /* =============================
     Snackbar
     =============================*/
  function showSnackbar(msg) {
    setSnackbarMsg(msg);
    setSnackbarOpen(true);
  }
  function closeSnackbar() {
    setSnackbarOpen(false);
    setSnackbarMsg('');
  }

  /* =============================
     7) Gestione pubblicazione gioco
     =============================*/
  function openPublishDialog() {
    // Utilizziamo il titolo della GlobalChat come default
    setPublishTitle(globalTitle || `Verifica #${globalChatId}`);
    setPublishDialogOpen(true);
    // Reset publishedGameId per mostrare sempre il form di pubblicazione
    setPublishedGameId(null);
  }

  function closePublishDialog() {
    setPublishDialogOpen(false);
    setPublishTitle('');
  }

  async function handlePublishGame() {
    if (!publishTitle.trim()) {
      showSnackbar('Inserisci un titolo per il gioco');
      return;
    }

    setPublishing(true);
    try {
      const res = await apiClient.post(
        `/api/advantage_games/globalchats/${globalChatId}/publish/`,
        { title: publishTitle }
      );
      
      setPublishedGameId(res.data.game_id);
      setGameId(res.data.game_id);
      setIsPublished(true);
      showSnackbar('Gioco pubblicato con successo!');
    } catch (err) {
      console.error('Errore pubblicazione gioco:', err);
      showSnackbar('Errore durante la pubblicazione del gioco');
    } finally {
      setPublishing(false);
    }
  }

  function LoadingState() {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '70vh' 
      }}>
        <CircularProgress 
          size={40} 
          thickness={3} 
          sx={{ color: '#DF4634' }} 
        />
        <Typography variant="body1" color="#DF4634" fontWeight="medium">
          Caricamento in corso...
        </Typography>
      </Box>
    );
  }

  // Componente principale render con AgentDialog sempre disponibile
  return (
    <>
      {loading ? (
        <LoadingState />
      ) : subChats.length === 0 ? (
        <GlobalChatEmptyState 
          onOpenAgentDialog={openAgentDialog} 
          onOpenPublishDialog={openPublishDialog}
          onChooseAgent={handleChooseAgent}
        />
      ) : (
        <Box sx={{ padding: '1rem', height: '100%', overflowY: 'auto' }}>
          {/* Header con titolo e pulsanti */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 4,
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 0 }
          }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mt: 2 }}>
              {globalTitle || `Verifica #${globalChatId}`}
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isPublished && gameId && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<LaunchIcon />}
                  onClick={() => navigate(`/games/${gameId}`)}
                  sx={{
                    borderRadius: '1.5rem',
                    textTransform: 'none',
                    boxShadow: '0 2px 8px rgba(25,118,210,0.2)',
                    '&:hover': {
                      boxShadow: '0 4px 10px rgba(25,118,210,0.3)',
                    }
                  }}
                >
                  Apri Verifica
                </Button>
              )}
              
              <Button
                variant="contained"
                color="success"
                startIcon={<PublishIcon />}
                onClick={openPublishDialog}
                sx={{
                  borderRadius: '1.5rem',
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(76,175,80,0.3)',
                  '&:hover': {
                    backgroundColor: '#2e7d32',
                    boxShadow: '0 6px 14px rgba(76,175,80,0.4)',
                  }
                }}
              >
                {isPublished ? 'Aggiorna' : 'Pubblica'}
              </Button>
              
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={openAgentDialog}
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1.5rem',
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(223,70,52,0.3)',
                  '&:hover': {
                    backgroundColor: '#E57373',
                    boxShadow: '0 6px 14px rgba(223,70,52,0.4)',
                  }
                }}
              >
                Aggiungi Attività
              </Button>
            </Box>
          </Box>

          {/* Lista sub-chats */}
          <Box sx={{ marginTop: '1rem' }}>
            <SubChatList 
              subChats={subChats}
              onOpenDetail={openDetail}
              onOpenDeleteDialog={handleOpenDeleteDialog}
              onMoveUp={handleMoveUp}
              onMoveDown={handleMoveDown}
            />

            {/* Pulsante + in fondo */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <IconButton
                onClick={openAgentDialog}
                sx={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '30px',
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#E57373',
                    boxShadow: '0 6px 14px rgba(0,0,0,0.25)',
                  },
                }}
              >
                <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Box>

            {/* Modali e dialogs specifici per la visualizzazione normale */}
            <GlobalChatDetail 
              open={detailOpen}
              onClose={closeDetail}
              selectedChat={selectedChat}
              lastAssistantMessage={lastAssistantMessage}
              detailLoading={detailLoading}
              editMode={editMode}
              onStartEdit={startEdit}
              onSaveEdit={handleSaveEdit}
              onCancelEdit={cancelEdit}
              editedText={editedText}
              onChangeEditedText={setEditedText}
              handleEditableUpdate={handleEditableUpdate}
              newUserMessage={newUserMessage}
              onChangeNewUserMessage={setNewUserMessage}
              generating={generating}
              onSendMessage={handleSendMessage}
            />

            <DeleteDialog 
              open={deleteDialogOpen}
              chatToDelete={chatToDelete}
              onClose={handleCloseDeleteDialog}
              onConfirmDelete={handleConfirmDelete}
            />

            <TextDialog 
              open={textDialogOpen}
              onClose={handleCloseTextDialog}
              textSource={textSource}
              onChangeTextSource={setTextSource}
              customTextTitle={customTextTitle}
              onChangeCustomTextTitle={setCustomTextTitle}
              customText={customText}
              onChangeCustomText={setCustomText}
              onSubmitCustomText={handleSubmitCustomText}
              onOpenTextChat={openTextChat}
            />

            <VideoDialog 
              open={videoDialogOpen}
              onClose={handleCloseVideoDialog}
              videoSource={videoSource}
              onChangeVideoSource={setVideoSource}
              videoUrl={videoUrl}
              onChangeVideoUrl={setVideoUrl}
              videoDescription={videoDescription}
              onChangeVideoDescription={setVideoDescription}
              onSubmitVideo={handleSubmitVideo}
            />

            {/* Snackbar */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={closeSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert onClose={closeSnackbar} severity="info" sx={{ width: '100%' }}>
                {snackbarMsg}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      )}

      {/* Dialogs comuni a tutti gli stati - sempre montati indipendentemente dallo stato */}
      <AgentDialog 
        open={agentDialogOpen} 
        onClose={closeAgentDialog} 
        onChooseAgent={handleChooseAgent} 
      />

      <AgentDialogMobile 
        open={agentDialogMobileOpen} 
        onClose={closeAgentDialog} 
        onChooseAgent={handleChooseAgent} 
      />

      <PublishDialog 
        open={publishDialogOpen}
        onClose={closePublishDialog}
        publishTitle={publishTitle}
        onChangeTitle={setPublishTitle}
        onPublish={handlePublishGame}
        publishing={publishing}
        publishedGameId={publishedGameId}
      />
    </>
  );
};

export default GlobalChatOverview; 