import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AGENTS, GAME_ICONS } from './constants';
import AgentDialog from './AgentDialog';
import AgentDialogMobile from './AgentDialogMobile';

/**
 * Componente che mostra lo stato iniziale quando non ci sono sub-chat
 */
const GlobalChatEmptyState = ({ 
  onOpenAgentDialog, 
  onChooseAgent
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [agentDialogOpen, setAgentDialogOpen] = useState(false);
  const [agentDialogMobileOpen, setAgentDialogMobileOpen] = useState(false);

  const handleOpenAgentDialog = () => {
    if (isMobile) {
      setAgentDialogMobileOpen(true);
    } else {
      setAgentDialogOpen(true);
    }
  };

  const handleCloseAgentDialog = () => {
    setAgentDialogOpen(false);
    setAgentDialogMobileOpen(false);
  };

  const handleChooseAgent = (agentKey) => {
    setAgentDialogOpen(false);
    setAgentDialogMobileOpen(false);
    onChooseAgent(agentKey);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: { xs: 'flex-start', md: 'center' },
        padding: { xs: '1rem', sm: '2rem' },
        paddingTop: { xs: '2.5rem', sm: '3rem' },
        maxWidth: '900px',
        margin: '0 auto',
        marginTop: { xs: '2rem', sm: 0 },
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {/* NUOVA INTERFACCIA MIGLIORATA */}
      <Card sx={{ 
        width: '100%', 
        borderRadius: { xs: '0.75rem', sm: '1rem' }, 
        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        overflow: 'visible',
        position: 'relative',
        mb: 4,
        mt: { xs: 2, sm: 2.5 },
        border: '1px solid #DF4634'
      }}>
        
        <CardContent sx={{ pt: 4, px: { xs: 2, sm: 3 } }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 2, md: 3 },
            mb: 3
          }}>
            {!isMobile && (
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <img 
                  src="/people/giochi.png" 
                  alt="Verifica Interattiva" 
                  style={{ 
                    width: '150px', 
                    height: '150px',
                    filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.2))'
                  }} 
                />
              </Box>
            )}
            
            <Box sx={{ flex: 2 }}>
              <Typography variant={isMobile ? "h6" : "h5"} sx={{ mb: 2, color: '#DF4634', fontWeight: 'bold' }}>
                Benvenuto nelle Verifiche Interattive
              </Typography>
              
              <Typography variant="body1" sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: 'inherit' } }}>
                Le verifiche interattive ti permettono di combinare diverse attività di gioco in un'unica verifica completa:
              </Typography>
              
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1,
                justifyContent: 'center'
              }}>
                {AGENTS.map(agent => (
                  <Chip
                    key={agent.key}
                    icon={
                      <Box 
                        component="img" 
                        src={GAME_ICONS[agent.key]} 
                        alt={agent.label}
                        sx={{ width: isMobile ? 16 : 20, height: isMobile ? 16 : 20 }}
                      />
                    }
                    label={agent.label}
                    sx={{ 
                      borderColor: '#DF4634',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      fontSize: { xs: '0.75rem', sm: 'inherit' },
                      height: { xs: '28px', sm: 'inherit' }
                    }}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          </Box>
          
          <Box sx={{ 
            borderRadius: { xs: '0.75rem', sm: '1rem' },
            padding: { xs: '0.75rem', sm: '1rem' },
            backgroundColor: 'rgba(223,70,52,0.05)',
            border: '1px dashed #DF4634',
            mb: 3
          }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, fontSize: { xs: '0.9rem', sm: 'inherit' } }}>
              Come funziona:
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 0.5, sm: 1 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <Box sx={{ 
                  width: { xs: 24, sm: 30 }, 
                  height: { xs: 24, sm: 30 }, 
                  borderRadius: { xs: 12, sm: 15 }, 
                  backgroundColor: '#DF4634', 
                  color: 'white', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.8rem', sm: 'inherit' }
                }}>1</Box>
                <Typography sx={{ fontSize: { xs: '0.85rem', sm: 'inherit' } }}>
                  Clicca sul pulsante "+" per scegliere il tipo di gioco da creare
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <Box sx={{ 
                  width: { xs: 24, sm: 30 }, 
                  height: { xs: 24, sm: 30 }, 
                  borderRadius: { xs: 12, sm: 15 }, 
                  backgroundColor: '#DF4634', 
                  color: 'white', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.8rem', sm: 'inherit' }
                }}>2</Box>
                <Typography sx={{ fontSize: { xs: '0.85rem', sm: 'inherit' } }}>
                  Descrivi all'AI cosa vuoi creare e l'AI genererà il gioco per te
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <Box sx={{ 
                  width: { xs: 24, sm: 30 }, 
                  height: { xs: 24, sm: 30 }, 
                  borderRadius: { xs: 12, sm: 15 }, 
                  backgroundColor: '#DF4634', 
                  color: 'white', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.8rem', sm: 'inherit' }
                }}>3</Box>
                <Typography sx={{ fontSize: { xs: '0.85rem', sm: 'inherit' } }}>
                  Aggiungi più giochi per completare la tua verifica interattiva
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                <Box sx={{ 
                  width: { xs: 24, sm: 30 }, 
                  height: { xs: 24, sm: 30 }, 
                  borderRadius: { xs: 12, sm: 15 }, 
                  backgroundColor: '#DF4634', 
                  color: 'white', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.8rem', sm: 'inherit' }
                }}>4</Box>
                <Typography sx={{ fontSize: { xs: '0.85rem', sm: 'inherit' } }}>
                  Puoi modificare, eliminare o creare nuovi giochi in qualsiasi momento
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          padding: { xs: '0.75rem', sm: '1rem' },
          backgroundColor: 'rgba(223,70,52,0.05)',
          borderTop: '1px solid rgba(223,70,52,0.1)'
        }}>
          <Button
            onClick={handleOpenAgentDialog}
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            sx={{
              backgroundColor: '#DF4634',
              color: '#FFFFFF',
              borderRadius: '2rem',
              padding: { xs: '0.5rem 1rem', sm: '0.75rem 2rem' },
              boxShadow: '0 4px 10px rgba(223,70,52,0.3)',
              transition: 'all 0.3s ease',
              fontSize: { xs: '0.85rem', sm: 'inherit' },
              width: { xs: '100%', sm: 'auto' },
              maxWidth: '280px',
              '&:hover': {
                backgroundColor: '#E57373',
                boxShadow: '0 6px 14px rgba(223,70,52,0.4)',
                transform: 'translateY(-2px)'
              },
            }}
          >
            Crea il tuo primo gioco
          </Button>
        </Box>
      </Card>

      <AgentDialog
        open={agentDialogOpen}
        onClose={handleCloseAgentDialog}
        onChooseAgent={handleChooseAgent}
      />
      
      <AgentDialogMobile
        open={agentDialogMobileOpen}
        onClose={handleCloseAgentDialog}
        onChooseAgent={handleChooseAgent}
      />
    </Box>
  );
};

export default GlobalChatEmptyState; 