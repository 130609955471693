// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/Header.css';
import PropTypes from 'prop-types';
import useDeleteAccount from '../api/profile/useDeleteAccount';
import useSupportEmail from '../api/auth/useSupportEmail';
import { removeTokens } from '../api/authUtils';
import { FaRobot } from 'react-icons/fa';

import DeleteAccountModal from './DeleteAccountModal';
import SupportModal from './SupportModal';
import useCustomerPortal from '../api/subscription/useCustomerPortal';
import useGetProfile from '../api/profile/useGetProfile';

/* --- IMPORT da Material UI per la tendina --- */
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Header = ({
  isLoggedIn,
  setIsLoggedIn,
  mobileMenuOpen,
  setMobileMenuOpen,
  backgroundColor = 'white',
  textColor = '#2b2e34',
  logoImage = '/logo/logogrey.svg',
}) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [gamesMenuOpen, setGamesMenuOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const { deleteAccount, loading: deleteLoading, error: deleteError, success: deleteSuccess } = useDeleteAccount();
  const { sendSupportEmail, loading: supportLoading, error: supportError, message: supportMessage } = useSupportEmail();
  const { openCustomerPortal } = useCustomerPortal();
  const { profile } = useGetProfile();

  useEffect(() => {
    if (profile) {
      setUserEmail(profile.email);
    }
  }, [profile]);

  const paymentMethodDisplay = profile?.payment_method_display;

  const handleLogout = () => {
    removeTokens();
    setIsLoggedIn(false);
    navigate('/', { replace: true });
  };

  const handleProfileClick = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleAccountDelete = async () => {
    try {
      await deleteAccount();
      removeTokens();
      setIsLoggedIn(false);
      navigate('/', { replace: true });
    } catch (err) {
      console.error("Errore nella cancellazione dell'account:", err);
    }
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    const message = e.target.message.value;
    if (!message) return;
    try {
      await sendSupportEmail(message);
      e.target.reset();
      setTimeout(() => {
        setSupportModalOpen(false);
      }, 2000);
    } catch (err) {
      // L'errore viene gestito nel custom hook
    }
  };

  return (
    <header className="header" style={{ backgroundColor }}>
      <div className="container">
        
        {/* LOGO */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <div className="logo-container">
            <div className="logo-text" style={{ color: textColor }}>
              Minerva
            </div>
            <img
              src={logoImage}
              alt="Minerva Logo"
              className={`logo-image ${logoImage === '/logo/logobianco.svg' ? 'logo-image-white' : ''}`}
            />
          </div>
        </Link>

        {/* Icona hamburger per il menu mobile */}
        <div className="hamburger-icon" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <img src="/menu_icon/menu.png" alt="Menu" />
        </div>

        {/* MENU DI NAVIGAZIONE */}
        <nav className="menu">
          <Link to="/" style={{ color: textColor }}>
            Home
          </Link>
          <Link to="/resources" style={{ color: textColor }}>
            Risorse
          </Link>

          {/* SEZIONE "Giochi" CON HOVER */}
          <div
            className="games-menu"
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setGamesMenuOpen(true)}
            onMouseLeave={() => setGamesMenuOpen(false)}
          >
            {/* Label "Giochi" con freccetta */}
            <span
              style={{
                color: textColor,
                fontSize: 'clamp(10px, 2vw, 20px)',
                fontWeight: 300,
                lineHeight: '30px',
                padding: '10px 15px',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              Giochi
              <ExpandMoreIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
            </span>

            {/* Tendina in stile "agentButton" */}
            {gamesMenuOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '2.5rem',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '1.25rem',
                  width: '14rem',
                  zIndex: 2000,
                  border: '1px solid #DF4634',
                  padding: '0.4rem 0.5rem',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* LINK 1: Modelli Giochi */}
                  <Link
                    to="/games/models"
                    style={{ textDecoration: 'none' }}
                    onClick={() => setGamesMenuOpen(false)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // testo centrato
                        backgroundColor: '#FFFFFF',
                        borderRadius: '1.25rem',
                        padding: '0.3rem',
                        marginBottom: '0.2rem',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(223, 70, 52, 0.1)',
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#000000',
                          fontWeight: 'bold',
                          fontSize: '0.9rem',
                          textAlign: 'center', // centrato
                        }}
                      >
                        Modelli Giochi
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#666666',
                          fontSize: '0.75rem',
                          textAlign: 'center', // centrato
                        }}
                      >
                        Visualizza i modelli di base
                      </Typography>
                    </Box>
                  </Link>

                  {/* LINK 2: Giochi Community */}
                  <Link
                    to="/games/community"
                    style={{ textDecoration: 'none' }}
                    onClick={() => setGamesMenuOpen(false)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // testo centrato
                        backgroundColor: '#FFFFFF',
                        borderRadius: '1.25rem',
                        padding: '0.3rem',
                        marginBottom: '0.2rem',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(223, 70, 52, 0.1)',
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#000000',
                          fontWeight: 'bold',
                          fontSize: '0.9rem',
                          textAlign: 'center',
                        }}
                      >
                        Giochi Community
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#666666',
                          fontSize: '0.75rem',
                          textAlign: 'center',
                        }}
                      >
                        Dai un’occhiata ai giochi condivisi
                      </Typography>
                    </Box>
                  </Link>

                  {/*
// LINK 3: I Miei Giochi (solo se loggato)
// Per il momento è commentato perché vogliamo attivarlo più tardi.

{isLoggedIn && (
  <Link
    to="/games/my"
    style={{ textDecoration: 'none' }}
    onClick={() => setGamesMenuOpen(false)}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // testo centrato
        backgroundColor: '#FFFFFF',
        borderRadius: '1.25rem',
        padding: '0.3rem',
        marginBottom: '0.2rem',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(223, 70, 52, 0.1)',
        },
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: '#000000',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          textAlign: 'center',
        }}
      >
        I Miei Giochi
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#666666',
          fontSize: '0.75rem',
          textAlign: 'center',
        }}
      >
        Accedi ai tuoi giochi personali
      </Typography>
    </Box>
  </Link>
)}
*/}

                </Box>
              </Box>
            )}
          </div>
          {/* FINE SEZIONE GIOCHI */}

          <Link to="/abbonamento" style={{ color: textColor }}>
            Abbonamento
          </Link>

          <Link to="/tutorial" style={{ color: textColor }}>
            Tutorial
          </Link>
        </nav>

        {/* AZIONI UTENTE (profile, login, ecc.) */}
        <div className="user-actions">
          <Link to="/chat" className="ai-button">
            <FaRobot />
            Chat
          </Link>
          <div className="divider"></div>
          {isLoggedIn ? (
            <div className="profile-menu">
              <div className="profile-link" onClick={handleProfileClick}>
                <img src="/menu_icon/profile.png" alt="Profilo" className="profile-image" />
              </div>
              {profileMenuOpen && (
                <div className="profile-dropdown">
                  <Link to="/profile" className="profile-item">
                    <img
                      src="/menu_icon/profile_white.png"
                      alt="Profile Icon"
                      className="profile-item-icon"
                    />
                    Profilo
                  </Link>
                  <Link to="/change-password" className="profile-item">
                    <img
                      src="/menu_icon/change_password.png"
                      alt="Change Password Icon"
                      className="profile-item-icon"
                    />
                    Cambia password
                  </Link>
                  {profile && paymentMethodDisplay !== null && paymentMethodDisplay !== 'Carta Docente' && (
                    <div
                      className="profile-item"
                      onClick={() => {
                        openCustomerPortal();
                        setProfileMenuOpen(false);
                      }}
                    >
                      <img
                        src="/menu_icon/card_white.png"
                        alt="Change Card Icon"
                        className="profile-item-icon"
                      />
                      Cambia Piano
                    </div>
                  )}
                  <div
                    className="profile-item"
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setProfileMenuOpen(false);
                    }}
                  >
                    <img
                      src="/menu_icon/delete_account.png"
                      alt="Delete Account Icon"
                      className="profile-item-icon"
                    />
                    Elimina account
                  </div>
                  <div
                    className="profile-item"
                    onClick={() => {
                      setSupportModalOpen(true);
                      setProfileMenuOpen(false);
                    }}
                  >
                    <img
                      src="/menu_icon/questions.png"
                      alt="Questions Icon"
                      className="profile-item-icon"
                    />
                    Hai problemi?
                  </div>
                  <div className="profile-item" onClick={handleLogout}>
                    <img
                      src="/menu_icon/exit_white.png"
                      alt="Logout Icon"
                      className="profile-item-icon"
                    />
                    Esci
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link to="/welcomepage" className="minerva-join-button">
              Accedi
            </Link>
          )}
        </div>
      </div>

      {/* MODALI */}
      <DeleteAccountModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleAccountDelete}
        loading={deleteLoading}
        error={deleteError}
        success={deleteSuccess}
      />

      <SupportModal
        isOpen={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
        onSubmit={handleSupportSubmit}
        loading={supportLoading}
        error={supportError}
        message={supportMessage}
        userEmail={userEmail}
      />
    </header>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  logoImage: PropTypes.string,
};

export default Header;
