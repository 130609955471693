// src/components/MobileMenu.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/MobileMenu.css';
import { TextField } from '@mui/material';
import useDeleteAccount from '../api/profile/useDeleteAccount';
import useSupportEmail from '../api/auth/useSupportEmail';
import useGetProfile from '../api/profile/useGetProfile';
import { removeTokens } from '../api/authUtils';
import useCustomerPortal from '../api/subscription/useCustomerPortal';

const MobileMenu = ({ isLoggedIn, setIsLoggedIn, setMobileMenuOpen }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [gamesOpen, setGamesOpen] = useState(false); // Toggle per la sezione Giochi

  const navigate = useNavigate();

  const { deleteAccount, loading: deleteLoading, error: deleteError, success: deleteSuccess } =
    useDeleteAccount();
  const { sendSupportEmail, loading: supportLoading, error: supportError, message: supportMessage } =
    useSupportEmail();
  const { profile } = useGetProfile();
  const { openCustomerPortal } = useCustomerPortal();

  useEffect(() => {
    if (profile) {
      setUserEmail(profile.email);
    }
  }, [profile]);

  const paymentMethodDisplay = profile?.payment_method_display;

  const handleLogout = () => {
    removeTokens();
    setIsLoggedIn(false);
    setMobileMenuOpen(false);
    // Ricarica la pagina o reindirizza
    window.location.reload(); 
    navigate('/', { replace: true });
  };

  const handleAccountDelete = async () => {
    try {
      await deleteAccount();
      setIsLoggedIn(false);
      navigate('/', { replace: true });
    } catch (err) {
      console.error("Errore nella cancellazione dell'account:", err);
    }
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    const message = e.target.message.value;
    if (!message) return;

    try {
      await sendSupportEmail(message);
      e.target.reset();
      setTimeout(() => {
        setSupportModalOpen(false);
      }, 2000);
    } catch (err) {
      // Già gestito dal custom hook
    }
  };

  return (
    <>
      {/* Contenitore del menu mobile */}
      <div className={`mobile-menu ${deleteModalOpen || supportModalOpen ? 'menu-hidden' : ''}`}>
        {/* Pulsante (X) di chiusura */}
        <div className="close-icon" onClick={() => setMobileMenuOpen(false)}>
          <img src="/menu_icon/close.png" alt="Chiudi" />
        </div>

        {/* Contenuto scorrevole del menu */}
        <div className="mobile-menu-items">
          {/* Link base */}
          <Link
            to="/"
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-menu-item"
          >
            <img src="/menu_icon/home_white.png" alt="Home Icon" className="mobile-menu-icon" />
            Home
          </Link>

          <Link
            to="/resources"
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-menu-item"
          >
            <img src="/menu_icon/resources.png" alt="Risorse Icon" className="mobile-menu-icon" />
            Risorse
          </Link>

          {/* SEZIONE GIOCHI (toggle) */}
          <div
            className="mobile-menu-item"
            onClick={() => setGamesOpen(!gamesOpen)}
          >
            <img src="/menu_icon/games_white.png" alt="Giochi Icon" className="mobile-menu-icon" />
            Giochi
          </div>
          {gamesOpen && (
            <div className="mobile-submenu">
              <Link
                to="/games/models"
                className="mobile-submenu-item"
                onClick={() => {
                  setGamesOpen(false);
                  setMobileMenuOpen(false);
                }}
              >
                Modelli Giochi
              </Link>
              <Link
                to="/games/community"
                className="mobile-submenu-item"
                onClick={() => {
                  setGamesOpen(false);
                  setMobileMenuOpen(false);
                }}
              >
                Giochi Community
              </Link>
              {/*
                Commentato finché non vuoi mostrare "I Miei Giochi"
              
              {isLoggedIn && (
                <Link
                  to="/games/my"
                  className="mobile-submenu-item"
                  onClick={() => {
                    setGamesOpen(false);
                    setMobileMenuOpen(false);
                  }}
                >
                  I Miei Giochi
                </Link>
              )}
              */}
            </div>
          )}

          <Link
            to="/abbonamento"
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-menu-item"
          >
            <img
              src="/menu_icon/subscription_white.png"
              alt="Abbonamento Icon"
              className="mobile-menu-icon"
            />
            Abbonamento
          </Link>

          
          <Link
            to="/tutorial"
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-menu-item"
          >
            <img src="/menu_icon/tutorial.png" alt="Chat Icon" className="mobile-menu-icon" />
            Tutorial
          </Link>            
          

          <Link
            to="/chat"
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-menu-item"
          >
            <img src="/menu_icon/ai_white.png" alt="Chat Icon" className="mobile-menu-icon" />
            Chat AI
          </Link>

          {/* Se l'utente è loggato */}
          {isLoggedIn ? (
            <>
              <div className="mobile-menu-divider"></div>

              <Link
                to="/profile"
                onClick={() => setMobileMenuOpen(false)}
                className="mobile-menu-item"
              >
                <img
                  src="/menu_icon/profile_white.png"
                  alt="Profile Icon"
                  className="mobile-menu-icon"
                />
                Il mio profilo
              </Link>

              <Link
                to="/change-password"
                onClick={() => setMobileMenuOpen(false)}
                className="mobile-menu-item"
              >
                <img
                  src="/menu_icon/change_password.png"
                  alt="Change Password Icon"
                  className="mobile-menu-icon"
                />
                Cambia password
              </Link>

              {/* Cambia piano (solo se non è Carta Docente) */}
              {profile && paymentMethodDisplay !== null && paymentMethodDisplay !== 'Carta Docente' && (
                <div
                  className="mobile-menu-item"
                  onClick={() => {
                    openCustomerPortal();
                    setMobileMenuOpen(false);
                  }}
                >
                  <img
                    src="/menu_icon/card_white.png"
                    alt="Change Card Icon"
                    className="mobile-menu-icon"
                  />
                  Cambia Piano
                </div>
              )}

              <div
                className="mobile-menu-item"
                onClick={() => setDeleteModalOpen(true)}
              >
                <img
                  src="/menu_icon/delete_account.png"
                  alt="Delete Account Icon"
                  className="mobile-menu-icon"
                />
                Elimina account
              </div>

              <div
                className="mobile-menu-item"
                onClick={() => setSupportModalOpen(true)}
              >
                <img
                  src="/menu_icon/questions.png"
                  alt="Support Icon"
                  className="mobile-menu-icon"
                />
                Hai problemi?
              </div>

              <div className="mobile-menu-item" onClick={handleLogout}>
                <img src="/menu_icon/exit_white.png" alt="Logout Icon" className="mobile-menu-icon" />
                Esci
              </div>
            </>
          ) : (
            <Link
              to="/welcomepage"
              onClick={() => setMobileMenuOpen(false)}
              className="mobile-menu-item"
            >
              <img src="/menu_icon/login.png" alt="Login Icon" className="mobile-menu-icon" />
              Accedi
            </Link>
          )}
        </div>
      </div>

      {/* Modale di conferma eliminazione account */}
      {deleteModalOpen && (
        <div className="modal-overlay" onClick={() => setDeleteModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Sei sicuro di voler eliminare il tuo account?</h2>
            <p>Questa azione è irreversibile e cancellerà tutti i tuoi dati.</p>
            {deleteError && <p className="error-message">{deleteError}</p>}
            {deleteSuccess && <p className="success-message">Account eliminato con successo!</p>}
            <div className="modal-buttons">
              <button
                className="confirm-button"
                onClick={handleAccountDelete}
                disabled={deleteLoading}
              >
                {deleteLoading ? 'Eliminando...' : 'Conferma'}
              </button>
              <button className="cancel-button" onClick={() => setDeleteModalOpen(false)}>
                Annulla
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modale di supporto */}
      {supportModalOpen && (
        <div className="modal-overlay" onClick={() => setSupportModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Hai problemi?</h2>
            <p>
              Invia un'email a <strong>minervaeducationlab@gmail.com</strong> o utilizza il modulo sottostante.
            </p>
            <form onSubmit={handleSupportSubmit}>
              <TextField
                label="La tua Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={userEmail}
                disabled
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Messaggio"
                type="text"
                variant="outlined"
                fullWidth
                margin="normal"
                name="message"
                required
                multiline
                rows={4}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              {supportMessage && <p className="success-message">{supportMessage}</p>}
              {supportError && <p className="error-message">{supportError}</p>}
              <div className="modal-buttons">
                <button
                  className="confirm-button"
                  type="submit"
                  disabled={supportLoading}
                >
                  {supportLoading ? 'Invio...' : 'Invia'}
                </button>
                <button
                  className="cancel-button"
                  type="button"
                  onClick={() => setSupportModalOpen(false)}
                >
                  Annulla
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
