import React, { useState, useEffect } from 'react';
import './styles/QuizGame.css';

const QuizGame = ({ quizData, onComplete, isLastGame, shouldGoToSummary = false, previousAnswers = null }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  
  // Chiave unica per il localStorage basata sul chatId
  const getLocalStorageKey = () => {
    return quizData && quizData.chatId ? `quiz_partial_answers_${quizData.chatId}` : null;
  };

  // Carica le risposte dal localStorage o dalle risposte precedenti
  useEffect(() => {
    // Reset dello stato quando cambia il chatId (cioè quando si passa a un nuovo quiz)
    if (quizData && quizData.chatId) {
      console.log('QuizGame: Cambio di quiz rilevato, gestisco lo stato', quizData.chatId);
      
      // Chiave per il localStorage
      const lsKey = getLocalStorageKey();
      let loadedAnswers = {};
      
      // Priorità 1: Controlla se ci sono risposte parziali salvate in localStorage
      if (lsKey) {
        try {
          const savedAnswers = localStorage.getItem(lsKey);
          if (savedAnswers) {
            loadedAnswers = JSON.parse(savedAnswers);
            console.log('Risposte parziali caricate da localStorage:', loadedAnswers);
          }
        } catch (e) {
          console.error('Errore nel caricamento delle risposte da localStorage:', e);
        }
      }
      
      // Priorità 2: Se non ci sono risposte in localStorage ma ci sono risposte precedenti
      if (Object.keys(loadedAnswers).length === 0 && previousAnswers) {
        console.log('Risposte precedenti trovate, carico le risposte salvate:', previousAnswers);
        
        // Formatta le risposte precedenti nel formato corretto per userAnswers
        if (previousAnswers.questionResults) {
          previousAnswers.questionResults.forEach(question => {
            if (question.type === 'multiple_choice') {
              loadedAnswers[question.id] = { optionId: question.userAnswer };
            } else if (question.type === 'open_ended') {
              loadedAnswers[question.id] = { text: question.userAnswer };
            }
          });
          
          console.log('Risposte precedenti caricate:', loadedAnswers);
        }
      }
      
      // Imposta le risposte caricate nello stato
      setUserAnswers(loadedAnswers);
      setCurrentQuestionIndex(0);
      setShowResults(false);
      setScore(0);
    }
  }, [quizData.chatId, previousAnswers]); // Dipende da chatId e previousAnswers

  // Salva le risposte in localStorage ogni volta che vengono aggiornate
  useEffect(() => {
    const lsKey = getLocalStorageKey();
    if (lsKey && Object.keys(userAnswers).length > 0) {
      try {
        localStorage.setItem(lsKey, JSON.stringify(userAnswers));
        console.log('Risposte salvate in localStorage:', userAnswers);
      } catch (e) {
        console.error('Errore nel salvataggio delle risposte in localStorage:', e);
      }
    }
  }, [userAnswers]);

  const questions = quizData.quiz || [];

  const handleSelectAnswer = (questionId, optionId) => {
    setUserAnswers({
      ...userAnswers,
      [questionId]: { optionId }
    });
  };

  const handleChangeText = (questionId, text) => {
    setUserAnswers({
      ...userAnswers,
      [questionId]: { text }
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      calculateScore();
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateScore = () => {
    if (!quizData || !quizData.quiz) return;
    
    // Log per debug
    console.log('========== CALCOLO PUNTEGGIO QUIZ ==========');
    console.log('Chat ID corrente:', quizData.chatId);
    console.log('Risposte utente:', userAnswers);
    console.log('È l\'ultimo quiz?', isLastGame);
    console.log('Dovrebbe andare al riepilogo?', shouldGoToSummary);
    
    // Filtriamo solo le domande a scelta multipla per il calcolo del punteggio
    const multipleChoiceQuestions = quizData.quiz.filter(q => q.type === 'multiple_choice');
    const totalMultipleChoice = multipleChoiceQuestions.length;
    // Totalità delle domande (incluse quelle aperte)
    const totalQuestions = quizData.quiz.length;
    
    console.log('Calcolo punteggio quiz - domande a scelta multipla:', totalMultipleChoice);
    console.log('Totale domande (incluse aperte):', totalQuestions);
    
    let correctAnswers = 0;
    let questionResults = [];
    
    quizData.quiz.forEach(question => {
      if (question.type === 'multiple_choice') {
        const selectedAnswer = userAnswers[question.id];
        const correctOption = question.options.find(opt => opt.is_correct);
        
        // Verifichiamo se l'utente ha selezionato l'opzione corretta
        let isCorrect = false;
        if (selectedAnswer && correctOption) {
          isCorrect = selectedAnswer.optionId === correctOption.id;
          console.log(`Domanda ${question.id}: selezionato ${selectedAnswer.optionId}, corretto ${correctOption.id}, isCorrect=${isCorrect}`);
        }
        
        if (isCorrect) correctAnswers++;
        
        // Otteniamo il testo delle opzioni selezionate
        const selectedOptionText = selectedAnswer 
          ? question.options.find(opt => opt.id === selectedAnswer.optionId)?.text || 'Opzione sconosciuta'
          : 'Non risposto';
        
        questionResults.push({
          id: question.id,
          type: question.type,
          question: question.question,
          userAnswer: selectedAnswer ? selectedAnswer.optionId : null,
          userAnswerText: selectedOptionText,
          correctAnswer: correctOption ? correctOption.id : null,
          correctAnswerText: correctOption ? correctOption.text : 'Risposta non disponibile',
          isCorrect: isCorrect
        });
      } else if (question.type === 'open_ended') {
        // Per le domande aperte, non c'è una risposta corretta valutabile automaticamente
        const userAnswer = userAnswers[question.id] ? userAnswers[question.id].text : '';
        questionResults.push({
          id: question.id,
          type: question.type,
          question: question.question,
          userAnswer: userAnswer,
          userAnswerText: userAnswer,
          // Non valutiamo le risposte aperte, quindi non c'è isCorrect
          isCorrect: null
        });
      }
    });
    
    // Calcoliamo il punteggio solo sulle domande a scelta multipla
    // Se non ci sono domande a scelta multipla, il punteggio è 100%
    const finalScore = totalMultipleChoice > 0 
      ? Math.round((correctAnswers / totalMultipleChoice) * 100)
      : 100; // Se ci sono solo domande aperte, diamo punteggio pieno
    
    console.log('Quiz completato:', {
      correctAnswers,
      totalMultipleChoice,
      totalQuestions,
      score: finalScore,
      userAnswers,
      questionResults
    });
    
    // Non mostriamo più i risultati del singolo quiz
    // setShowResults(true);
    // setScore(finalScore);
    
    if (onComplete) {
      // Creiamo un oggetto risultato dettagliato
      const quizResult = {
        type: 'quiz',
        chatId: quizData.chatId,
        score: finalScore, // Usiamo direttamente finalScore e non lo stato 'score'
        correctAnswers: correctAnswers,
        // Usiamo totalMultipleChoice anziché totalQuestions per il conteggio totale
        // così le domande a risposta aperta non vengono conteggiate nel totale
        totalQuestions: totalMultipleChoice,
        totalQuestionsWithOpenEnded: totalQuestions, // Manteniamo anche il totale incluse le aperte
        multipleChoiceQuestions: totalMultipleChoice,
        questionResults: questionResults,
        completed: true,
        completedAt: new Date().toISOString(),
        // Aggiungiamo una struttura dettagli standardizzata
        details: {
          quizType: multipleChoiceQuestions.length > 0 
            ? (quizData.quiz.length > multipleChoiceQuestions.length ? 'misto' : 'scelta_multipla') 
            : 'domande_aperte',
          totalCorrect: correctAnswers,
          totalQuestions: totalMultipleChoice, // Anche qui usiamo totalMultipleChoice
          totalQuestionsWithOpenEnded: totalQuestions,
          multipleChoiceQuestions: totalMultipleChoice,
          openEndedQuestions: totalQuestions - totalMultipleChoice,
          percentage: finalScore
        }
      };
      
      // Se è l'ultimo quiz e tutti gli altri giochi sono completati,
      // impostiamo goToSummary a true per andare automaticamente al riepilogo
      if (shouldGoToSummary) { // Solo quando shouldGoToSummary è true (tutti gli altri quiz completati)
        quizResult.goToSummary = true;
        console.log('Condizione per andare al riepilogo soddisfatta, imposto goToSummary=true');
      }
      
      // Assicuriamoci che il punteggio sia esplicitamente un numero
      quizResult.score = Number(finalScore);
      
      console.log('Invio risultato quiz con punteggio esplicito:', quizResult);
      console.log('Score value:', quizResult.score, 'tipo:', typeof quizResult.score);
      
      // Verifichiamo che onComplete sia una funzione valida
      if (typeof onComplete === 'function') {
        // Alla fine del quiz, rimuoviamo le risposte parziali da localStorage
        // poiché ora sono salvate nei risultati completati
        const lsKey = getLocalStorageKey();
        if (lsKey) {
          try {
            localStorage.removeItem(lsKey);
            console.log('Risposte parziali rimosse da localStorage dopo completamento del quiz');
          } catch (e) {
            console.error('Errore nella rimozione delle risposte da localStorage:', e);
          }
        }
        
        onComplete(quizResult);
      } else {
        console.error('onComplete non è una funzione valida');
      }
    } else {
      console.error('onComplete non è definito');
    }
  };

  const handleRestart = () => {
    // Questa funzione non verrà più utilizzata direttamente dall'utente
    // ma potrebbe essere utile mantenerla per debug
    setUserAnswers({});
    setCurrentQuestionIndex(0);
    setShowResults(false);
    setScore(0);
    
    // Rimuoviamo anche le risposte parziali da localStorage
    const lsKey = getLocalStorageKey();
    if (lsKey) {
      try {
        localStorage.removeItem(lsKey);
      } catch (e) {
        console.error('Errore nella rimozione delle risposte da localStorage:', e);
      }
    }
  };

  // Se non ci sono domande, mostriamo un messaggio
  if (!questions || questions.length === 0) {
    return (
      <div className="quiz-game">
        <div className="quiz-message">
          <h2>Nessuna domanda disponibile</h2>
          <p>Questo quiz non contiene domande.</p>
        </div>
      </div>
    );
  }

  // In realtà, questa logica non sarà mai eseguita perché non impostiamo più showResults a true
  // Ma la manteniamo come fallback di sicurezza
  if (showResults) {
    // Invece di mostrare i risultati, completiamo automaticamente il quiz
    // Questo assicura che anche se per qualche motivo showResults diventa true,
    // passeremo comunque automaticamente al prossimo quiz
    if (onComplete && typeof onComplete === 'function') {
      // Calcola un punteggio di base
      const correctAnswers = questions.reduce((count, question) => {
        if (question.type === 'multiple_choice') {
          const userAnswer = userAnswers[question.id];
          if (userAnswer) {
            const selectedOption = question.options.find(opt => opt.id === userAnswer.optionId);
            if (selectedOption && selectedOption.is_correct) {
              return count + 1;
            }
          }
        }
        return count;
      }, 0);
      
      const totalMultipleChoice = questions.filter(q => q.type === 'multiple_choice').length;
      const score = totalMultipleChoice > 0 ? Math.round((correctAnswers / totalMultipleChoice) * 100) : 100;
      
      const result = {
        type: 'quiz',
        chatId: quizData.chatId,
        score: score,
        correctAnswers: correctAnswers,
        totalQuestions: totalMultipleChoice, // Usiamo solo le domande a scelta multipla
        totalQuestionsWithOpenEnded: questions.length,
        completed: true,
        completedAt: new Date().toISOString()
      };
      
      // Chiamiamo onComplete per passare al prossimo quiz
      setTimeout(() => onComplete(result), 0);
    }
    
    // Mostriamo un loader per indicare il passaggio al prossimo quiz
    return (
      <div className="quiz-game">
        <div className="quiz-loading">
          <div className="quiz-spinner"></div>
          <p>Passaggio al prossimo esercizio...</p>
        </div>
      </div>
    );
  }

  // Mostra la domanda corrente
  const currentQuestion = questions[currentQuestionIndex];
  const userAnswer = userAnswers[currentQuestion.id];

  const renderQuestion = () => {
    const question = questions[currentQuestionIndex];
    if (!question) return null;

    const isMultipleChoice = question.type === 'multiple_choice';
    const selectedOption = userAnswers[question.id] ? userAnswers[question.id].optionId : null;
    
    return (
      <div className="quiz-question">
        <h3 className="quiz-question-title">{question.question}</h3>
        
        {isMultipleChoice ? (
          <div className="quiz-options">
            {question.options.map(option => {
              const isSelected = selectedOption === option.id;
              // Determina se questa opzione era la risposta precedente
              const isPreviousAnswer = previousAnswers && previousAnswers.questionResults && 
                previousAnswers.questionResults.some(q => 
                  q.id === question.id && q.userAnswer === option.id
                );
              
              return (
                <div 
                  key={option.id} 
                  className={`quiz-option ${isSelected ? 'selected' : ''} ${isPreviousAnswer && !isSelected ? 'previous-answer' : ''}`}
                  onClick={() => handleSelectAnswer(question.id, option.id)}
                >
                  {option.text}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="quiz-open-answer">
            <textarea
              placeholder="Scrivi la tua risposta qui..."
              rows={6}
              value={userAnswers[question.id] ? userAnswers[question.id].text : ''}
              onChange={(e) => handleChangeText(question.id, e.target.value)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="quiz-game">
      <div className="quiz-progress">
        <div className="quiz-progress-text">
          <span><span className="current">{currentQuestionIndex + 1}</span> di {questions.length}</span>
        </div>
        <div className="quiz-progress-bar">
          <div 
            className="quiz-progress-fill" 
            style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
          ></div>
        </div>
      </div>

      {renderQuestion()}

      <div className="quiz-navigation">
        <button 
          className="quiz-button secondary"
          onClick={handlePrevQuestion}
          disabled={currentQuestionIndex === 0}
        >
          Indietro
        </button>
        <button 
          className="quiz-button"
          onClick={handleNextQuestion}
          disabled={
            currentQuestion.type === 'multiple_choice'
              ? !userAnswers[currentQuestion.id]?.optionId
              : !userAnswers[currentQuestion.id]?.text
          }
        >
          {currentQuestionIndex < questions.length - 1 ? 'Avanti' : 'Verifica Risposte'}
        </button>
      </div>
    </div>
  );
};

export default QuizGame; 