import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import FloatingMenu from './FloatingMenu';
import './styles/GameCommunityPage.css';

import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setAccessToken,
} from '../api/authUtils';
import { jwtDecode } from 'jwt-decode';
import apiClient from '../api/apiClient';

const GameCommunityPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const navigate = useNavigate();

  const checkAuthStatus = async () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken) {
      try {
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp && decoded.exp > currentTime) {
          setIsLoggedIn(true);
        } else if (refreshToken) {
          try {
            const response = await apiClient.post('/api/accounts/token/refresh/', {
              refresh: refreshToken,
            });
            if (response.status === 200) {
              const data = response.data;
              setAccessToken(data.access);
              setIsLoggedIn(true);
            } else {
              removeTokens();
              setIsLoggedIn(false);
            }
          } catch (error) {
            console.error('Errore refresh token:', error);
            removeTokens();
            setIsLoggedIn(false);
          }
        } else {
          removeTokens();
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Errore decodifica token:', error);
        removeTokens();
        setIsLoggedIn(false);
      }
    } else if (refreshToken) {
      try {
        const response = await apiClient.post('/api/accounts/token/refresh/', {
          refresh: refreshToken,
        });
        if (response.status === 200) {
          const data = response.data;
          setAccessToken(data.access);
          setIsLoggedIn(true);
        } else {
          removeTokens();
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Errore refresh token:', error);
        removeTokens();
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Se vuoi mostrare il menu flottante dopo uno scroll di 100px
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="game-community-page">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu 
          isLoggedIn={isLoggedIn} 
          setIsLoggedIn={setIsLoggedIn} 
          setMobileMenuOpen={setMobileMenuOpen} 
        />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
        />
      )}

      <main className="game-community-main">
        <section className="announcement">
          <h1>In arrivo Prossimamente</h1>
          <p>
            Presto sarà disponibile una collezione di giochi interattivi realizzati
            dalle nostre intelligenze artificiali, pensati per arricchire 
            l’esperienza didattica.
          </p>
          <p>
            Basati su argomenti fondamentali del curriculum della scuola italiana, 
            questi giochi saranno a disposizione di tutti: potrete utilizzarli così 
            come sono, sia in classe che a casa, oppure personalizzarli in base alle 
            vostre esigenze.
          </p>
          <p>
            Restate sintonizzati per scoprire tutte le novità!
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default GameCommunityPage;
