import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress
} from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';

/**
 * Dialog per la pubblicazione della verifica
 */
const PublishDialog = ({
  open,
  onClose,
  publishTitle,
  onChangeTitle,
  onPublish,
  publishing,
  publishedGameId
}) => {
  // Copia l'URL del gioco pubblicato negli appunti
  const handleCopyLink = () => {
    if (publishedGameId) {
      navigator.clipboard.writeText(`${window.location.origin}/games/${publishedGameId}`);
      // Nota: lo snackbar viene gestito dal componente principale
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        }
      }}
    >
      <DialogTitle sx={{ 
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '1.5rem',
        fontWeight: 'bold'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <PublishIcon color="success" />
          <Typography variant="h6">Pubblica Verifica Interattiva</Typography>
        </Box>
      </DialogTitle>
      
      <DialogContent sx={{ padding: '2rem' }}>
        {!publishedGameId ? (
          <>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Quando pubblichi una verifica, essa diventa accessibile tramite un link univoco che puoi condividere con gli studenti o colleghi.
            </Typography>
            
            <TextField
              label="Titolo della verifica"
              value={publishTitle}
              onChange={(e) => onChangeTitle(e.target.value)}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              autoFocus
              InputProps={{
                sx: { borderRadius: '0.5rem' }
              }}
            />
            
            <Box sx={{ 
              backgroundColor: '#e8f5e9', 
              borderRadius: '0.5rem', 
              padding: '1rem',
              mt: 3,
              border: '1px solid #c8e6c9',
              display: 'flex',
              alignItems: 'flex-start',
              gap: 2
            }}>
              <Box sx={{ color: 'success.main', mt: 0.5 }}>
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/>
                </svg>
              </Box>
              <Typography variant="body2">
                La pubblicazione include tutte le attività presenti in questa verifica. Gli studenti potranno svolgere le attività ma non modificarle.
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
              <Button 
                onClick={onClose} 
                variant="outlined"
              >
                Annulla
              </Button>
              <Button 
                onClick={onPublish} 
                variant="contained" 
                color="success"
                disabled={!publishTitle.trim() || publishing}
                startIcon={publishing ? <CircularProgress size={20} color="inherit" /> : <PublishIcon />}
              >
                {publishing ? 'Pubblicazione...' : 'Pubblica'}
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ 
              backgroundColor: '#e8f5e9',
              borderRadius: '50%',
              width: '80px',
              height: '80px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto 2rem auto'
            }}>
              <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4caf50"/>
              </svg>
            </Box>
            
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              Verifica pubblicata con successo!
            </Typography>
            
            <Typography variant="body1" sx={{ mb: 3 }}>
              La tua verifica è ora disponibile al seguente link:
            </Typography>
            
            <TextField
              value={`${window.location.origin}/games/${publishedGameId}`}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
                sx: { 
                  borderRadius: '0.5rem',
                  fontFamily: 'monospace',
                  backgroundColor: '#f5f5f5'
                }
              }}
            />
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button 
                onClick={handleCopyLink}
                variant="contained"
                color="primary"
                sx={{ mr: 2, borderRadius: '1.5rem' }}
              >
                Copia Link
              </Button>
              <Button 
                onClick={onClose}
                variant="outlined"
                sx={{ borderRadius: '1.5rem' }}
              >
                Chiudi
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PublishDialog; 