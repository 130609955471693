import React, { useState, useEffect } from 'react';
import './styles/RearrangeGame.css';

const RearrangeGame = ({ gameData, onComplete, isLastGame, onSubmitFinal, previousAnswers = null, shouldGoToNextGame = false }) => {
  const [exercises, setExercises] = useState([]);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [userArrangements, setUserArrangements] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [draggingWord, setDraggingWord] = useState(null);
  const [totalExercises, setTotalExercises] = useState(0);

  // Funzioni di utilità per la normalizzazione delle parole e frasi
  const normalizeWord = (str) => {
    return str.toLowerCase().trim().replace(/[.,!?;:]/g, '');
  };

  const normalizeSentence = (str) => {
    return str.toLowerCase().replace(/\s+/g, ' ').replace(/[.,!?;:]/g, '').trim();
  };

  // Chiave unica per il localStorage basata sul chatId
  const getLocalStorageKey = () => {
    return gameData && gameData.chatId ? `rearrange_partial_answers_${gameData.chatId}` : null;
  };

  useEffect(() => {
    if (gameData && gameData.rearrange && gameData.rearrange.length > 0) {
      setExercises(gameData.rearrange);
      // Reset esplicito dell'indice dell'esercizio corrente quando cambiano i dati del gioco
      setCurrentExerciseIndex(0);
      
      // Inizializza gli arrangiamenti dell'utente con alcune parole già posizionate correttamente
      const initialArrangements = {};
      gameData.rearrange.forEach(exercise => {
        console.log('Inizializzazione esercizio:', exercise.id, exercise.topic);
        
        // Inizializziamo l'area della frase con valori nulli
        const correctWords = exercise.final_sentence.split(' ');
        const sentence_area = Array(correctWords.length).fill(null);

        // Array per le parole da mantenere nelle opzioni
        let availableWords = [...exercise.words_shuffled];
        console.log('Parole iniziali disponibili:', availableWords);
        
        // Inseriamo esattamente 1/3 delle parole (arrotondando per eccesso)
        const numPreplaced = Math.ceil(correctWords.length / 3);
        console.log(`Preposizioneremo ${numPreplaced} parole su ${correctWords.length} (1/3 per eccesso)`);
        
        // Lista di indici disponibili nella frase
        const availableIndices = Array.from({ length: correctWords.length }, (_, i) => i);
        
        // Parole preposizionate
        const preplacedWords = [];
        
        // Processo di preposizionamento
        for (let i = 0; i < numPreplaced && availableIndices.length > 0; i++) {
          // Seleziona un indice casuale dalla lista di indici disponibili
          const randomIndexPos = Math.floor(Math.random() * availableIndices.length);
          const randomIndex = availableIndices[randomIndexPos];
          
          // Rimuovi l'indice dalla lista di indici disponibili
          availableIndices.splice(randomIndexPos, 1);
          
          // Ottieni la parola corretta per questa posizione
          const correctWord = correctWords[randomIndex];
          preplacedWords.push(correctWord);
          
          // Posiziona la parola nella frase
          sentence_area[randomIndex] = correctWord;
          
          console.log(`Preposizionata parola "${correctWord}" in posizione ${randomIndex}`);
        }
        
        // PASSO CRUCIALE: Rimuoviamo ESATTAMENTE le parole preposizionate dall'array availableWords
        for (const word of preplacedWords) {
          // Troviamo l'indice esatto della parola nell'array (prima occorrenza)
          // Utilizziamo normalizeWord per confrontare le parole senza punteggiatura
          const wordIndex = availableWords.findIndex(w => normalizeWord(w) === normalizeWord(word));
          if (wordIndex !== -1) {
            // Rimuovi solo questa occorrenza
            availableWords.splice(wordIndex, 1);
            console.log(`Rimossa parola "${word}" dalle opzioni disponibili`);
          } else {
            console.warn(`Non trovata parola "${word}" nelle opzioni disponibili`);
          }
        }
        
        console.log('Parole rimanenti dopo il filtraggio:', availableWords);
        
        // Verifica finale: non ci dovrebbero essere parole preposizionate tra le opzioni
        const remainingPreplaced = preplacedWords.filter(word => 
          availableWords.some(availableWord => availableWord === word)
        );
        if (remainingPreplaced.length > 0) {
          console.error('ERRORE: Parole preposizionate ancora nelle opzioni:', remainingPreplaced);
        }
        
        initialArrangements[exercise.id] = {
          words_area: availableWords,
          sentence_area: sentence_area,
          correctWords: correctWords
        };
      });
      
      // Carica risposte parziali da localStorage se disponibili
      loadArrangementsFromStorage(initialArrangements);
      
      setTotalExercises(gameData.rearrange.length);
    }
  }, [gameData]);

  // Carica le risposte parziali dal localStorage
  const loadArrangementsFromStorage = (initialArrangements) => {
    const lsKey = getLocalStorageKey();
    let loadedArrangements = initialArrangements;
    
    if (lsKey) {
      try {
        const savedArrangements = localStorage.getItem(lsKey);
        if (savedArrangements) {
          const parsedArrangements = JSON.parse(savedArrangements);
          console.log('RearrangeGame: Risposte caricate da localStorage:', parsedArrangements);
          loadedArrangements = parsedArrangements;
        }
      } catch (e) {
        console.error('RearrangeGame: Errore nel parsing delle risposte da localStorage:', e);
      }
    }
    
    // Priorità 2: Se non ci sono risposte in localStorage ma ci sono risposte precedenti
    if (Object.keys(loadedArrangements).length === initialArrangements.length && previousAnswers) {
      console.log('RearrangeGame: Carico risposte da previousAnswers:', previousAnswers);
      // Qui si potrebbero elaborare le risposte precedenti se necessario
      // Per ora manteniamo quelle iniziali
    }
    
    setUserArrangements(loadedArrangements);
  };

  // Salva le risposte in localStorage ogni volta che vengono aggiornate
  useEffect(() => {
    const lsKey = getLocalStorageKey();
    if (lsKey && Object.keys(userArrangements).length > 0) {
      try {
        localStorage.setItem(lsKey, JSON.stringify(userArrangements));
        console.log('RearrangeGame: Risposte salvate in localStorage:', userArrangements);
      } catch (e) {
        console.error('RearrangeGame: Errore nel salvataggio delle risposte in localStorage:', e);
      }
    }
  }, [userArrangements]);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleDragStart = (e, word, sourceArea, index = null) => {
    setDraggingWord({ word, sourceArea, index });
    e.dataTransfer.setData('text/plain', word);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = () => {
    setDraggingWord(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, targetArea, index = null) => {
    e.preventDefault();
    const word = e.dataTransfer.getData('text/plain');
    if (!draggingWord) return;

    const currentExercise = exercises[currentExerciseIndex];
    const currentArrangement = userArrangements[currentExercise.id];
    
    // Non fare nulla se il drop è nella stessa area di origine senza cambio di posizione
    if (draggingWord.sourceArea === targetArea && draggingWord.index === index) return;
    
    let updatedArrangement = { ...currentArrangement };
    
    // Rimuovi la parola dall'area di origine
    if (draggingWord.sourceArea === 'words_area') {
      updatedArrangement.words_area = updatedArrangement.words_area.filter(w => w !== draggingWord.word);
    } else {
      const newSentenceArea = [...updatedArrangement.sentence_area];
      newSentenceArea[draggingWord.index] = null;
      updatedArrangement.sentence_area = newSentenceArea;
    }
    
    // Aggiungi la parola all'area di destinazione
    if (targetArea === 'words_area') {
      updatedArrangement.words_area.push(draggingWord.word);
    } else if (index !== null && updatedArrangement.sentence_area[index] === null) {
      const newSentenceArea = [...updatedArrangement.sentence_area];
      newSentenceArea[index] = draggingWord.word;
      updatedArrangement.sentence_area = newSentenceArea;
    }
    
    // Aggiorna lo stato
    setUserArrangements({
      ...userArrangements,
      [currentExercise.id]: updatedArrangement
    });
    
    setDraggingWord(null);
  };

  const handleWordClick = (word, sourceArea, index = null) => {
    const currentExercise = exercises[currentExerciseIndex];
    const currentArrangement = userArrangements[currentExercise.id];
    
    let updatedArrangement = { ...currentArrangement };
    
    // Se la parola proviene dall'area delle parole disponibili
    if (sourceArea === 'words_area') {
      // Cerca il primo slot vuoto nell'area della frase
      const emptySlotIndex = updatedArrangement.sentence_area.findIndex(slot => slot === null);
      
      if (emptySlotIndex !== -1) {
        // Rimuovi la parola dall'area di origine
        updatedArrangement.words_area = updatedArrangement.words_area.filter(w => w !== word);
        
        // Aggiungi la parola allo slot vuoto
        const newSentenceArea = [...updatedArrangement.sentence_area];
        newSentenceArea[emptySlotIndex] = word;
        updatedArrangement.sentence_area = newSentenceArea;
      }
    } 
    // Se la parola proviene dall'area della frase
    else if (sourceArea === 'sentence_area' && index !== null) {
      // Rimuovi la parola dall'area della frase
      const newSentenceArea = [...updatedArrangement.sentence_area];
      newSentenceArea[index] = null;
      updatedArrangement.sentence_area = newSentenceArea;
      
      // Aggiungi la parola all'area delle parole disponibili
      updatedArrangement.words_area.push(word);
    }
    
    // Aggiorna lo stato
    setUserArrangements({
      ...userArrangements,
      [currentExercise.id]: updatedArrangement
    });
  };

  const handleVerify = () => {
    const currentExercise = exercises[currentExerciseIndex];
    // Filtriamo i valori null dall'array sentence_area prima di unirlo
    const userSentence = userArrangements[currentExercise.id].sentence_area.filter(word => word !== null).join(' ');
    
    // Confronta la frase creata dall'utente con quella corretta
    const normalizedUserSentence = normalizeSentence(userSentence);
    const normalizedCorrectSentence = normalizeSentence(currentExercise.final_sentence);
    
    const isCorrect = normalizedUserSentence === normalizedCorrectSentence;
    
    console.log(`RearrangeGame - Esercizio ${currentExerciseIndex + 1}/${exercises.length} completato:`);
    console.log(`Corretto: ${isCorrect ? 'Sì' : 'No'}`);
    console.log(`Frase utente: "${normalizedUserSentence}"`);
    console.log(`Frase corretta: "${normalizedCorrectSentence}"`);
    
    // Verifica se è l'ultimo esercizio
    const isLastExercise = currentExerciseIndex === exercises.length - 1;
    
    // Se non è l'ultimo esercizio, passa semplicemente al prossimo
    if (!isLastExercise) {
      setCurrentExerciseIndex(currentExerciseIndex + 1);
      return;
    }
    
    // Se è l'ultimo esercizio, calcola i risultati finali
    // Creiamo un array di risultati dettagliati per ogni esercizio
    const arrangementResults = exercises.map(exercise => {
      const userArrangement = userArrangements[exercise.id];
      const userSentence = userArrangement.sentence_area.filter(word => word !== null).join(' ');
      const correctSentence = exercise.final_sentence;
      
      // Confronta la frase creata dall'utente con quella corretta
      const normalizedUserSentence = normalizeSentence(userSentence);
      const normalizedCorrectSentence = normalizeSentence(correctSentence);
      
      const isCorrect = normalizedUserSentence === normalizedCorrectSentence;
      
      // Log dettagliato per debug
      console.log(`Esercizio ${exercise.id}: "${normalizedUserSentence}" vs "${normalizedCorrectSentence}" => ${isCorrect ? 'Corretto' : 'Errato'}`);
      
      // Dividiamo le frasi in parole per fornire i risultati come richiesto
      return {
        exerciseId: exercise.id,
        topic: exercise.topic,
        userArrangement: userArrangement.sentence_area.filter(word => word !== null),
        correctArrangement: correctSentence.split(/\s+/),
        isCorrect: isCorrect
      };
    });
    
    // Contiamo le risposte corrette
    const correctCount = arrangementResults.filter(result => result.isCorrect).length;
    
    // Calcoliamo il punteggio percentuale
    const percentageScore = Math.round((correctCount / exercises.length) * 100);
    
    console.log(`RearrangeGame - Punteggio finale: ${correctCount}/${exercises.length} (${percentageScore}%)`);
    
    // Creiamo l'oggetto risultato
    const result = {
      type: 'rearrange',
      chatId: gameData.chatId,
      score: percentageScore,
      correctAnswers: correctCount,
      totalQuestions: exercises.length,
      completedAt: new Date().toISOString(),
      completed: true, // Questo deve essere sempre true quando arriva qui
      details: {
        correctArrangements: correctCount,
        totalExercises: exercises.length,
        percentage: percentageScore,
        // Aggiungiamo informazione esplicita sullo stato di completamento
        completed: true,
        allAnswered: true
      },
      allExercisesAnswered: true, // Aggiunto per chiarezza
      arrangementResults: arrangementResults
    };
    
    console.log('RearrangeGame - Invio risultato finale:', result);

    // Rimuoviamo i dati parziali dal localStorage dopo il completamento
    const lsKey = getLocalStorageKey();
    if (lsKey) {
      try {
        localStorage.removeItem(lsKey);
        console.log(`RearrangeGame: Dati rimossi da localStorage con chiave ${lsKey}`);
      } catch (e) {
        console.error('RearrangeGame: Errore nella rimozione dei dati da localStorage:', e);
      }
    }
    
    // VERIFICA CRUCIALE: Se è l'ultimo gioco, passiamo direttamente al riepilogo
    if (isLastGame && onSubmitFinal) {
      console.log('RearrangeGame - È l\'ultimo gioco, invio risultato e vado al riepilogo');
      setIsSubmitting(true);
      
      // Inviamo il risultato
      if (onComplete) {
        onComplete(result);
      }
      
      // Chiamiamo immediatamente onSubmitFinal per passare al riepilogo
      onSubmitFinal();
      return;
    }
    
    // Se dobbiamo passare al gioco successivo (stesso tipo), non mostriamo i risultati
    if (shouldGoToNextGame) {
      console.log('RearrangeGame - Passaggio automatico al prossimo gioco');
    }
    
    // In ogni caso, inviamo i risultati all'app principale
    if (onComplete) {
      onComplete(result);
    }
  };

  if (!exercises || exercises.length === 0) {
    return (
      <div className="rearrange-game">
        <h2>Nessun esercizio disponibile</h2>
      </div>
    );
  }

  const currentExercise = exercises[currentExerciseIndex];
  const currentArrangement = userArrangements[currentExercise.id];

  // Se non abbiamo l'arrangement per l'esercizio corrente, mostriamo un messaggio di caricamento
  if (!currentArrangement) {
    return (
      <div className="rearrange-game">
        <div className="rearrange-loading">
          <p>Caricamento...</p>
        </div>
      </div>
    );
  }

  // Verifica se tutti gli slot della frase sono stati riempiti
  const allSlotsFilledOrPreplaced = currentArrangement.sentence_area.every(word => word !== null);

  // Rendering normale del gioco
  return (
    <div className="rearrange-game">
      <div className="rearrange-header">
        <h2>{currentExercise.topic}</h2>
        <div className="rearrange-progress">
          Esercizio {currentExerciseIndex + 1} di {exercises.length}
        </div>
      </div>
      
      <div className="rearrange-instructions">
        <p>Riordina le parole per formare una frase corretta</p>
      </div>
      
      {/* Area della frase da completare con box specifici */}
      <div className="rearrange-sentence-area">
        {currentArrangement.sentence_area.map((word, index) => (
          <div 
            key={`sentence-slot-${index}`}
            className={`rearrange-slot ${word ? 'filled' : 'empty'}`}
            onDragOver={!word ? handleDragOver : undefined}
            onDrop={!word ? (e) => handleDrop(e, 'sentence_area', index) : undefined}
          >
            {word ? (
              <div 
                className="rearrange-word in-sentence"
                draggable
                onDragStart={(e) => handleDragStart(e, word, 'sentence_area', index)}
                onDragEnd={handleDragEnd}
                onClick={() => handleWordClick(word, 'sentence_area', index)}
              >
                {word}
              </div>
            ) : (
              <div className="rearrange-slot-placeholder"></div>
            )}
          </div>
        ))}
      </div>
      
      {/* Area delle parole disponibili */}
      <div 
        className="rearrange-words-area"
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, 'words_area')}
      >
        {currentArrangement.words_area.map((word, index) => (
          <div 
            key={`word-${index}`}
            className="rearrange-word"
            draggable
            onDragStart={(e) => handleDragStart(e, word, 'words_area')}
            onDragEnd={handleDragEnd}
            onClick={() => handleWordClick(word, 'words_area')}
          >
            {word}
          </div>
        ))}
        {currentArrangement.words_area.length === 0 && (
          <div className="rearrange-placeholder">Nessuna parola rimanente</div>
        )}
      </div>
      
      <div className="rearrange-actions">
        <button 
          className="rearrange-button"
          disabled={!allSlotsFilledOrPreplaced}
          onClick={handleVerify}
        >
          {currentExerciseIndex < exercises.length - 1 ? 'Verifica e Continua' : 'Completa'}
        </button>
        
        {!allSlotsFilledOrPreplaced && (
          <p className="rearrange-hint">
            Completa tutti gli spazi vuoti nella frase
          </p>
        )}
      </div>
    </div>
  );
};

export default RearrangeGame; 