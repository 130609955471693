// MobileHeader.js
import React from 'react';
import { Box, IconButton } from '@mui/material';
import AgentSwitch from './agentSwitch';

const MobileHeader = ({
  didatticaOpen,
  setDidatticaOpen,
  selectedOption,
  handleOptionChange,
  toggleSidebar,
  handleNewChat, // Aggiunto handleNewChat tra le props
  isGlobalChatPage, // Aggiungo il parametro isGlobalChatPage
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centra il contenuto
        padding: '1rem',
        width: '100%',
      }}
    >
      {/* Pulsante del menu */}
      <IconButton onClick={toggleSidebar} sx={{ position: 'absolute', left: '1rem' }}>
        <img src="/menu_icon/menu.png" alt="Menu" style={{ width: '2rem', height: '2rem', transform: 'scaleX(-1)', }} />
      </IconButton>

      {/* Bottone dell'agente */}
      <AgentSwitch
        didatticaOpen={didatticaOpen}
        setDidatticaOpen={setDidatticaOpen}
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        isGlobalChatPage={isGlobalChatPage}
      />

      {/* Pulsante per creare una nuova chat */}
      <IconButton
        onClick={() => handleNewChat(selectedOption)}
        sx={{ position: 'absolute', right: '1rem' }}
      >
        <img src="/new-message.png" alt="Nuova Chat" style={{ marginRight: '1.5rem', width: '1.8rem', height: '1.8rem' }} />
      </IconButton>
    </Box>
  );
};

export default MobileHeader;


