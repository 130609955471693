import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/VideoComponentTutorial.css';

const VideoComponent = ({ videoSrc, customStyle }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Verifica se il video è un link di Google Drive
  const isGoogleDriveVideo = videoSrc.includes('drive.google.com');

  return (
    <>
      <div className="video-container" onClick={toggleFullscreen}>
        {isGoogleDriveVideo ? (
          <iframe
            className="background-video"
            src={videoSrc}
            style={customStyle}
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Google Drive Video"
            frameBorder="0"
          />
        ) : (
          <video
            className="background-video"
            src={videoSrc}
            style={customStyle}
            controls
            playsInline
          />
        )}
        <div className="video-overlay">
          <span className="expand-icon">⤢</span>
        </div>
      </div>

      {isFullscreen && (
        <div className="fullscreen-video-modal" onClick={toggleFullscreen}>
          <div className="fullscreen-video-container">
            {isGoogleDriveVideo ? (
              <iframe
                className="fullscreen-video"
                src={videoSrc}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Google Drive Video Fullscreen"
                frameBorder="0"
              />
            ) : (
              <video
                className="fullscreen-video"
                src={videoSrc}
                controls
                autoPlay
              />
            )}
            <button className="close-fullscreen" onClick={toggleFullscreen}>
              ✕
            </button>
          </div>
        </div>
      )}
    </>
  );
};

VideoComponent.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};

VideoComponent.defaultProps = {
  customStyle: {}, // Default se nessuno stile viene passato
};

export default VideoComponent;
