import React, { useState, useEffect, useRef } from 'react';
import './styles/CrosswordGame.css';

const CrosswordGame = ({ gameData, onComplete, isLastGame }) => {
  // Stato della griglia soluzione e della griglia utente
  const [solution, setSolution] = useState([]);
  const [userGrid, setUserGrid] = useState([]);
  const [cellStatus, setCellStatus] = useState([]);
  
  // Stato del gioco
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [gameCompleted, setGameCompleted] = useState(false);
  
  // Stato per le parole e numerazione delle celle
  const [words, setWords] = useState([]);
  const [cellNumbers, setCellNumbers] = useState({});
  
  // Stato per la parola e cella attiva
  const [activeWord, setActiveWord] = useState(null);
  const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
  const [activeDirection, setActiveDirection] = useState(0); // 0 per orizzontale, 1 per verticale
  
  // Stato per la vista della legenda
  const [activeTab, setActiveTab] = useState('orizzontali'); // 'orizzontali' o 'verticali'
  
  // Refs per gli input delle celle
  const cellRefs = useRef({});
  
  // Inizializza il cruciverba al montaggio del componente
  useEffect(() => {
    if (!gameData) return;
    
    let crosswordData = null;
    if (gameData.crossword) {
      crosswordData = gameData.crossword;
    } else if (gameData.data && gameData.data.crossword) {
      crosswordData = gameData.data.crossword;
    } else if (gameData.game_data && gameData.game_data.last_message && gameData.game_data.last_message.text) {
      try {
        const parsedData = JSON.parse(gameData.game_data.last_message.text);
        if (parsedData.crossword) {
          crosswordData = parsedData.crossword;
        }
      } catch (e) {
        console.error("Errore nel parsing dei dati del cruciverba:", e);
      }
    }
    
    if (!crosswordData || !crosswordData.grid || !crosswordData.words) {
      console.error("Dati del cruciverba non validi o mancanti");
      return;
    }
    
    // Salva la griglia soluzione
    setSolution(crosswordData.grid);
    
    // Crea la griglia utente: se la cella nella soluzione è '-' la manteniamo, altrimenti la lasciamo vuota
    const emptyGrid = crosswordData.grid.map(row =>
      row.map(cell => cell === '-' ? '-' : '')
    );
    setUserGrid(emptyGrid);
    
    // Inizializza lo stato delle celle (per evidenziare corrette/errate)
    const statusGrid = crosswordData.grid.map(row => row.map(() => undefined));
    setCellStatus(statusGrid);
    
    // Salva le parole
    setWords(crosswordData.words);
    
    // Costruisci la mappa dei numeri per le celle, utilizzando (row-1, col-1)
    const numbersMap = {};
    crosswordData.words.forEach(word => {
      const key = `${word.row - 1}-${word.col - 1}`;
      numbersMap[key] = word.number || word.id;
    });
    setCellNumbers(numbersMap);
    
    // Imposta la prima parola attiva (preferibilmente una parola orizzontale)
    const firstWord = crosswordData.words.find(word => word.vertical === 0) || crosswordData.words[0];
    if (firstWord) {
      setActiveWord(firstWord);
      setActiveCell({ row: firstWord.row - 1, col: firstWord.col - 1 });
      setActiveDirection(firstWord.vertical);
      setActiveTab(firstWord.vertical === 0 ? 'orizzontali' : 'verticali');
    }
    
    // Reset dello stato di gioco
    setShowResults(false);
    setScore(0);
    setGameCompleted(false);
  }, [gameData]);
  
  // Imposta il focus sulla cella attiva quando cambia
  useEffect(() => {
    if (showResults) return;
    
    const cellKey = `${activeCell.row}-${activeCell.col}`;
    const cellInput = cellRefs.current[cellKey];
    
    if (cellInput) {
      cellInput.focus();
    }
  }, [activeCell, showResults]);
  
  // Aggiorna la tab attiva quando cambia la direzione
  useEffect(() => {
    setActiveTab(activeDirection === 0 ? 'orizzontali' : 'verticali');
  }, [activeDirection]);
  
  // Determina se una cella è parte della parola attiva
  const isInActiveWord = (r, c) => {
    if (!activeWord) return false;
    
    const wordRow = activeWord.row - 1;
    const wordCol = activeWord.col - 1;
    const length = (activeWord.word || activeWord.answer).length;
    
    if (activeWord.vertical === 0) {
      // Parola orizzontale
      return r === wordRow && c >= wordCol && c < wordCol + length;
    } else {
      // Parola verticale
      return c === wordCol && r >= wordRow && r < wordRow + length;
    }
  };
  
  // Gestione dell'input in una cella
  const handleCellChange = (rowIndex, colIndex, value) => {
    // Accetta solo una lettera e convertila in maiuscolo
    const letter = value.replace(/[^a-zA-Z]/g, '').toUpperCase();
    setUserGrid(prev => {
      const newGrid = prev.map(row => row.slice());
      newGrid[rowIndex][colIndex] = letter;
      return newGrid;
    });
    
    // Se è stata inserita una lettera, sposta il focus alla cella successiva
    if (letter) {
      moveToNextCell(rowIndex, colIndex);
    }
  };
  
  // Funzione per spostare il focus alla cella successiva in base alla direzione attiva
  const moveToNextCell = (row, col) => {
    if (!activeWord) return;
    
    let nextRow = row;
    let nextCol = col;
    
    if (activeDirection === 0) {
      // Se la direzione è orizzontale, tenta di spostarsi a destra
      if (col + 1 < userGrid[0].length && solution[row][col + 1] !== '-') {
        nextCol = col + 1;
      } else {
        // Se siamo alla fine della parola orizzontale, cerchiamo la prossima parola orizzontale o restiamo fermi
        const currentWordEnd = activeWord.col - 1 + (activeWord.word || activeWord.answer).length - 1;
        if (col >= currentWordEnd) {
          const nextHorizontalWord = words.find(word => {
            if (word.vertical === 0) {
              const wordStart = { row: word.row - 1, col: word.col - 1 };
              return (wordStart.row > row) || (wordStart.row === row && wordStart.col > col);
            }
            return false;
          });
          
          if (nextHorizontalWord) {
            nextRow = nextHorizontalWord.row - 1;
            nextCol = nextHorizontalWord.col - 1;
            // Aggiorna anche la parola attiva
            setActiveWord(nextHorizontalWord);
          }
        }
      }
    } else {
      // Se la direzione è verticale, tenta di spostarsi in basso
      if (row + 1 < userGrid.length && solution[row + 1][col] !== '-') {
        nextRow = row + 1;
      } else {
        // Se siamo alla fine della parola verticale, cerchiamo la prossima parola verticale o restiamo fermi
        const currentWordEnd = activeWord.row - 1 + (activeWord.word || activeWord.answer).length - 1;
        if (row >= currentWordEnd) {
          const nextVerticalWord = words.find(word => {
            if (word.vertical === 1) {
              const wordStart = { row: word.row - 1, col: word.col - 1 };
              return (wordStart.col > col) || (wordStart.col === col && wordStart.row > row);
            }
            return false;
          });
          
          if (nextVerticalWord) {
            nextRow = nextVerticalWord.row - 1;
            nextCol = nextVerticalWord.col - 1;
            // Aggiorna anche la parola attiva
            setActiveWord(nextVerticalWord);
          }
        }
      }
    }
    
    if (nextRow !== row || nextCol !== col) {
      setActiveCell({ row: nextRow, col: nextCol });
    }
  };
  
  // Gestione del click su una cella
  const handleCellClick = (r, c) => {
    if (solution[r][c] === '-' || showResults) return;
    
    // Se clicchiamo sulla cella già attiva, cambiamo direzione
    if (r === activeCell.row && c === activeCell.col) {
      // Cerca una parola che attraversa questa cella nella direzione opposta all'attuale
      const newDirection = activeDirection === 0 ? 1 : 0;
      const otherWord = words.find(word => {
        const wordRow = word.row - 1;
        const wordCol = word.col - 1;
        const length = (word.word || word.answer).length;
        
        if (word.vertical === newDirection) {
          if (newDirection === 0) {
            // Parola orizzontale
            return r === wordRow && c >= wordCol && c < wordCol + length;
          } else {
            // Parola verticale
            return c === wordCol && r >= wordRow && r < wordRow + length;
          }
        }
        return false;
      });
      
      if (otherWord) {
        setActiveWord(otherWord);
        setActiveDirection(newDirection);
      }
    } else {
      setActiveCell({ row: r, col: c });
      
      // Trova una parola che attraversa questa cella
      // Prima prova a trovare una parola nella stessa direzione attuale
      let cellWord = words.find(word => {
        const wordRow = word.row - 1;
        const wordCol = word.col - 1;
        const length = (word.word || word.answer).length;
        
        if (word.vertical === activeDirection) {
          if (activeDirection === 0) {
            // Parola orizzontale
            return r === wordRow && c >= wordCol && c < wordCol + length;
          } else {
            // Parola verticale
            return c === wordCol && r >= wordRow && r < wordRow + length;
          }
        }
        return false;
      });
      
      // Se non trova nella stessa direzione, cerca in qualsiasi direzione
      if (!cellWord) {
        cellWord = words.find(word => {
          const wordRow = word.row - 1;
          const wordCol = word.col - 1;
          const length = (word.word || word.answer).length;
          
          if (word.vertical === 0) {
            // Parola orizzontale
            return r === wordRow && c >= wordCol && c < wordCol + length;
          } else {
            // Parola verticale
            return c === wordCol && r >= wordRow && r < wordRow + length;
          }
        });
      }
      
      if (cellWord) {
        setActiveWord(cellWord);
        setActiveDirection(cellWord.vertical);
      }
    }
  };
  
  // Verifica le risposte inserite e calcola il punteggio
  const handleCheckAnswers = () => {
    console.log("=== CALCOLO PUNTEGGIO CRUCIVERBA ===");
    
    if (!solution || !userGrid) {
      console.error("Solution o userGrid non disponibili");
      return;
    }
    
    // Salva l'ultimo input attivo prima di calcolare il punteggio
    // Questo risolve il problema quando l'utente verifica subito dopo aver inserito l'ultima lettera
    const syncActiveCell = () => {
      try {
        const inputElement = document.activeElement;
        if (inputElement && inputElement.tagName === "INPUT" && 
            inputElement.hasAttribute('data-row') && inputElement.hasAttribute('data-col')) {
          // Ottiene le coordinate dalla cella attiva
          const activeRow = parseInt(inputElement.getAttribute('data-row'));
          const activeCol = parseInt(inputElement.getAttribute('data-col'));
          const value = inputElement.value.replace(/[^a-zA-Z]/g, '').toUpperCase();
          
          // Aggiorna lo stato userGrid con l'ultimo valore inserito
          if (!isNaN(activeRow) && !isNaN(activeCol) && value) {
            console.log(`Sincronizzazione ultima cella attiva [${activeRow},${activeCol}] = "${value}"`);
            
            // Aggiorna direttamente l'array userGrid per il calcolo immediato
            if (userGrid[activeRow] && userGrid[activeRow][activeCol] !== value) {
              userGrid[activeRow][activeCol] = value;
              return true; // Indica che c'è stato un aggiornamento
            }
          }
        }
      } catch (error) {
        console.error("Errore durante la sincronizzazione della cella attiva:", error);
      }
      return false;
    };
    
    // Sincronizza l'ultima cella attiva
    const hasUpdated = syncActiveCell();
    
    // Se c'è stato un aggiornamento, aggiorniamo anche lo stato React
    if (hasUpdated) {
      setUserGrid([...userGrid]);
    }
    
    // Contatori per il calcolo del punteggio
    let filledCells = 0;    // Celle che l'utente ha compilato
    let emptyCells = 0;     // Celle che l'utente ha lasciato vuote
    let correctCells = 0;   // Celle compilate correttamente
    let wrongCells = 0;     // Celle compilate erroneamente
    let totalCells = 0;     // Totale delle celle da compilare (escluse quelle bloccate)
    
    // Stato per ogni cella (per la visualizzazione)
    const newStatus = [];
    
    // Scansiona ogni cella della griglia
    for (let r = 0; r < solution.length; r++) {
      newStatus[r] = [];
      
      for (let c = 0; c < solution[r].length; c++) {
        // Ignora le celle bloccate
        if (solution[r][c] === '-') {
          newStatus[r][c] = undefined;
          continue;
        }
        
        // Incrementa il conteggio totale delle celle da compilare
        totalCells++;
        
        // Controlla se la cella è vuota
        if (!userGrid[r][c] || userGrid[r][c] === '') {
          emptyCells++;
          newStatus[r][c] = 'incorrect';
          console.log(`[${r},${c}] VUOTA: dovrebbe essere "${solution[r][c]}"`);
        } 
        // Controlla se la cella è corretta (case insensitive)
        else if (userGrid[r][c].toUpperCase() === solution[r][c].toUpperCase()) {
          filledCells++;
          correctCells++;
          newStatus[r][c] = 'correct';
          console.log(`[${r},${c}] CORRETTA: "${userGrid[r][c]}"`);
        } 
        // Altrimenti la cella è sbagliata
        else {
          filledCells++;
          wrongCells++;
          newStatus[r][c] = 'incorrect';
          console.log(`[${r},${c}] ERRATA: "${userGrid[r][c]}" invece di "${solution[r][c]}"`);
        }
      }
    }
    
    // Statistiche sul completamento
    console.log("Totale celle da compilare:", totalCells);
    console.log("Celle compilate dall'utente:", filledCells);
    console.log("Celle lasciate vuote:", emptyCells);
    console.log("Celle corrette:", correctCells);
    console.log("Celle errate:", wrongCells);
    
    // Il punteggio è 0 se nessuna cella è stata compilata
    let finalScore = 0;
    
    // Solo se l'utente ha compilato almeno una cella
    if (filledCells > 0) {
      // Calcola il punteggio sulla base delle celle totali (incluse vuote)
      finalScore = Math.round((correctCells / totalCells) * 100);
    }
    
    console.log("PUNTEGGIO FINALE:", finalScore);
    
    // Aggiorna lo stato
    setCellStatus(newStatus);
    setScore(finalScore);
    setShowResults(true);
    setGameCompleted(true);
    
    // Notifica il completamento al componente padre
    if (onComplete) {
      // Otteniamo il chatId dalle diverse possibili strutture dei dati
      let chatId = gameData?.chatId;
      if (!chatId && gameData?.data) {
        chatId = gameData.data.chatId || gameData.data.id;
      }
      if (!chatId && gameData?.id) {
        chatId = gameData.id;
      }
      
      // Prepariamo i risultati dettagliati per ogni parola
      const wordResults = words.map(word => {
        // Recuperiamo la risposta utente per questa parola
        let userAnswer = "";
        const r = word.row - 1;
        const c = word.col - 1;
        const answer = word.word || word.answer;
        const direction = word.vertical === 1 ? 'verticale' : 'orizzontale';
        
        if (userGrid && r >= 0 && c >= 0) {
          // Ricostruiamo la risposta utente dai caratteri nella griglia
          if (word.vertical === 0) {  // Orizzontale
            for (let i = 0; i < answer.length; i++) {
              if (c + i < userGrid[r].length && userGrid[r][c + i]) {
                userAnswer += userGrid[r][c + i];
              } else {
                userAnswer += "_";  // Contrassegna le celle vuote
              }
            }
          } else {  // Verticale
            for (let i = 0; i < answer.length; i++) {
              if (r + i < userGrid.length && userGrid[r + i][c]) {
                userAnswer += userGrid[r + i][c];
              } else {
                userAnswer += "_";  // Contrassegna le celle vuote
              }
            }
          }
        }
        
        // Verifica se la parola è corretta
        const isCorrect = checkWordCorrect(word);
        
        return {
          wordNumber: word.number,
          clue: word.clue,
          direction: direction,
          correctAnswer: answer,
          userAnswer: userAnswer,
          isCorrect: isCorrect
        };
      });
      
      // Calcoliamo il numero di parole corrette
      const correctWords = wordResults.filter(result => result.isCorrect).length;
      const totalWords = wordResults.length;
      console.log(`Parole corrette: ${correctWords}/${totalWords}`);
      
      const result = {
        type: 'crossword',
        chatId: chatId,
        score: finalScore,
        completed: true,
        isCompleted: true,
        totalQuestions: totalCells,
        correctAnswers: correctCells,
        wrongAnswers: wrongCells + emptyCells,
        completedAt: new Date().toISOString(),
        // Aggiungiamo i risultati dettagliati delle parole e il conteggio
        wordResults: wordResults,
        correctWords: correctWords,
        totalWords: totalWords,
        details: {
          gameType: 'crossword',
          totalCells: totalCells,
          filledCells: filledCells,
          emptyCells: emptyCells,
          correctCells: correctCells,
          wrongCells: wrongCells,
          percentage: finalScore
        }
      };
      
      if (isLastGame) {
        result.goToSummary = true;
      }
      
      // Log per debug
      console.log('CrosswordGame - Invio risultato:', result);
      
      onComplete(result);
    }
  };
  
  // Verifica se una parola è stata compilata correttamente
  const checkWordCorrect = (word) => {
    try {
      if (!word) {
        console.error('checkWordCorrect: parola non valida', word);
        return false;
      }
      
      if (!userGrid || !solution) {
        console.error('checkWordCorrect: userGrid o solution non disponibili');
        return false;
      }
      
      // Verifica i campi necessari
      if (word.row === undefined || word.col === undefined || 
          (word.word === undefined && word.answer === undefined) ||
          word.vertical === undefined) {
        console.error('checkWordCorrect: parola con campi mancanti', word);
        return false;
      }
      
      const r = word.row - 1;
      const c = word.col - 1;
      const answer = word.word || word.answer;
      
      // Verifica che gli indici siano validi
      if (r < 0 || r >= userGrid.length || c < 0 || 
          (word.vertical === 0 && c + answer.length > userGrid[0].length) ||
          (word.vertical === 1 && r + answer.length > userGrid.length)) {
        console.error('checkWordCorrect: indici non validi', { r, c, answer, vertical: word.vertical });
        return false;
      }
      
      if (word.vertical === 0) {
        // Parola orizzontale
        for (let i = 0; i < answer.length; i++) {
          // Verifica che l'indice sia valido
          if (c + i >= userGrid[r].length) {
            console.error(`checkWordCorrect: indice non valido per parola orizzontale alla posizione i=${i}`, { r, c, i });
            return false;
          }
          
          // Verifica che la cella dell'utente sia stata completata
          if (!userGrid[r][c + i]) {
            console.log(`Parola ${answer} non completa: cella vuota a [${r},${c+i}]`);
            return false;
          }
          
          // Verifica che la risposta sia corretta (case insensitive)
          if (userGrid[r][c + i].toUpperCase() !== solution[r][c + i].toUpperCase()) {
            console.log(`Parola ${answer} errata: [${r},${c+i}] = ${userGrid[r][c + i]}, atteso ${solution[r][c + i]}`);
            return false;
          }
        }
      } else {
        // Parola verticale
        for (let i = 0; i < answer.length; i++) {
          // Verifica che l'indice sia valido
          if (r + i >= userGrid.length) {
            console.error(`checkWordCorrect: indice non valido per parola verticale alla posizione i=${i}`, { r, c, i });
            return false;
          }
          
          // Verifica che la cella dell'utente sia stata completata
          if (!userGrid[r + i][c]) {
            console.log(`Parola ${answer} non completa: cella vuota a [${r+i},${c}]`);
            return false;
          }
          
          // Verifica che la risposta sia corretta (case insensitive)
          if (userGrid[r + i][c].toUpperCase() !== solution[r + i][c].toUpperCase()) {
            console.log(`Parola ${answer} errata: [${r+i},${c}] = ${userGrid[r + i][c]}, atteso ${solution[r + i][c]}`);
            return false;
          }
        }
      }
      
      console.log(`Parola ${answer} corretta!`);
      return true;
    } catch (error) {
      console.error('Errore in checkWordCorrect:', error, word);
      return false;
    }
  };
  
  // Quando l'utente clicca su un indizio nella legenda
  const handleClueClick = (word) => {
    if (!word) return;
    
    setActiveWord(word);
    setActiveCell({ row: word.row - 1, col: word.col - 1 });
    setActiveDirection(word.vertical);
  };
  
  // Cambia la tab attiva nella legenda
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  // Restart del gioco
  const handleRestart = () => {
    // Reset della griglia utente e stati
    const emptyGrid = solution.map(row =>
      row.map(cell => cell === '-' ? '-' : '')
    );
    setUserGrid(emptyGrid);
    setShowResults(false);
    setGameCompleted(false);
    
    // Resetta la prima parola attiva
    const firstWord = words.find(word => word.vertical === 0) || words[0];
    if (firstWord) {
      setActiveWord(firstWord);
      setActiveCell({ row: firstWord.row - 1, col: firstWord.col - 1 });
      setActiveDirection(firstWord.vertical);
    }
  };

  // Filtra le parole per la direzione attiva
  const getFilteredWords = () => {
    if (!words.length) return [];
    
    return words
      .filter(word => (activeTab === 'orizzontali' ? word.vertical === 0 : word.vertical === 1))
      .sort((a, b) => (a.number || a.id) - (b.number || b.id));
  };
  
  if (!solution || !userGrid.length) {
    return <div>Caricamento cruciverba...</div>;
  }
  
  return (
    <div className="crossword-game">
      <div className="crossword-header">
        <h2>Cruciverba</h2>
      </div>
      
      <div className="crossword-content">
        <div className="crossword-grid-container">
          <div className="crossword-grid-wrapper">
            <table className="crossword-grid">
              <tbody>
                {userGrid.map((row, rIndex) => (
                  <tr key={rIndex} className="crossword-row">
                    {row.map((cell, cIndex) => {
                      if (solution[rIndex][cIndex] === '-') {
                        return <td key={cIndex} className="crossword-cell blocked" />;
                      }
                      
                      let cellClass = "crossword-cell";
                      if (activeCell.row === rIndex && activeCell.col === cIndex) {
                        cellClass += " active";
                      } else if (isInActiveWord(rIndex, cIndex)) {
                        cellClass += " in-active-word";
                      }
                      
                      if (showResults) {
                        if (cellStatus[rIndex][cIndex] === 'correct') {
                          cellClass += " correct";
                        } else if (cellStatus[rIndex][cIndex] === 'incorrect') {
                          cellClass += " incorrect";
                        }
                      }
                      
                      const cellNumber = cellNumbers[`${rIndex}-${cIndex}`];
                      const cellKey = `${rIndex}-${cIndex}`;
                      
                      return (
                        <td
                          key={cIndex}
                          className={cellClass}
                          onClick={() => handleCellClick(rIndex, cIndex)}
                        >
                          {cellNumber && (
                            <div className="crossword-number">{cellNumber}</div>
                          )}
                          <input
                            ref={el => cellRefs.current[cellKey] = el}
                            type="text"
                            maxLength={1}
                            value={showResults && cellStatus[rIndex][cIndex] === 'incorrect' ? '' : cell}
                            onChange={(e) => handleCellChange(rIndex, cIndex, e.target.value)}
                            className="crossword-input"
                            disabled={showResults}
                            data-row={rIndex}
                            data-col={cIndex}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="crossword-legend">
            <div className="crossword-tabs">
              <button 
                className={`crossword-tab ${activeTab === 'orizzontali' ? 'active' : ''}`}
                onClick={() => handleTabChange('orizzontali')}
              >
                Orizzontali
              </button>
              <button 
                className={`crossword-tab ${activeTab === 'verticali' ? 'active' : ''}`}
                onClick={() => handleTabChange('verticali')}
              >
                Verticali
              </button>
            </div>
            
            <div className="crossword-clues-panel">
              <ul className="crossword-clues">
                {getFilteredWords().map(word => {
                  let clueClass = "crossword-clue";
                  if (activeWord && activeWord.id === word.id) {
                    clueClass += " active";
                  }
                  if (showResults) {
                    const isCorrect = checkWordCorrect(word);
                    clueClass += isCorrect ? " correct" : " incorrect";
                  }
                  
                  return (
                    <li 
                      key={word.id} 
                      className={clueClass}
                      onClick={() => handleClueClick(word)}
                    >
                      <span className="crossword-clue-number">{word.number || word.id}.</span> 
                      <span className="crossword-clue-text">{word.clue}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        
        <div className="crossword-actions">
          {!showResults ? (
            <button 
              className="crossword-button" 
              onClick={handleCheckAnswers}
            >
              Verifica risposte
            </button>
          ) : (
            <div className="crossword-score">Punteggio: {score}%</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrosswordGame;
