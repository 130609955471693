// File: src/components/GamesPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Tooltip,
  CircularProgress,
  CardMedia,
  Paper,
  Button,
  useTheme,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, removeTokens } from '../../api/authUtils';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Mappa per le immagini dei vari tipi di giochi
const agentImages = {
  quiz: '/people/quiz.png',
  fillthebox: '/people/fillthebox.png',
  selectgroup: '/people/selectgroup.png',
  matchpairs: '/people/matchpairs.png',
  rearrange: '/people/rearrange.png',
  crossword: '/people/crossword.png',
  scrittura: '/people/scrittura.png',
  video: '/people/video.png', // Icona per i video di YouTube
  immaginai: '/people/immaginai.png',
  montessori: '/people/montessori.png',
  malaguzzi: '/people/malaguzzi.png',
  metodologie: '/people/metodologie.png',
  laboratori: '/people/laboratori.png',
  minerva: '/people/minerva.png',
  supporto: '/people/supporto.png',
  attività: '/people/attivita.png',
  generic: '/people/generic.png',
};

// Mappa per i nomi leggibili degli agenti
const agentDisplayNames = {
  quiz: 'Quiz',
  fillthebox: 'Completa Frase',
  selectgroup: 'Raggruppa',
  matchpairs: 'Abbina Coppie',
  rearrange: 'Riordina',
  crossword: 'Cruciverba',
  scrittura: 'Testo',
  video: 'Video YouTube',
  immaginai: 'ImmaginAI',
  montessori: 'Montessori',
  malaguzzi: 'Malaguzzi',
  metodologie: 'Metodologie',
  laboratori: 'Laboratori',
  minerva: 'Minerva',
  supporto: 'Supporto',
  attività: 'Attività',
  generic: 'Generico',
};

// Elenco dei "tipi di giochi"
const interactiveAgents = [
  {
    name: 'Quiz',
    description: 'Genera quiz per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Completa frase',
    description: 'Esercizi per completare frasi con opzioni multiple.',
    image: '/people/fillthebox.png',
    agentKey: 'fillthebox',
  },
  {
    name: 'Raggruppa',
    description: 'Crea liste di parole suddivise in gruppi tematici.',
    image: '/people/selectgroup.png',
    agentKey: 'selectgroup',
  },
  {
    name: 'Abbina coppie',
    description: 'Crea esercizi di abbinamento (match pairs).',
    image: '/people/matchpairs.png',
    agentKey: 'matchpairs',
  },
  {
    name: 'Riordina',
    description: 'Crea esercizi dove si devono riordinare le frasi.',
    image: '/people/rearrange.png',
    agentKey: 'rearrange',
  },
  {
    name: 'Cruciverba',
    description: 'Genera cruciverba personalizzati a partire da parole chiave o concetti.',
    image: '/people/crossword.png',
    agentKey: 'crossword',
  },
];

// Funzione per registrare errori critici (potrebbe inviare a un servizio di log/monitoraggio come Sentry)
const logCriticalError = (errorInfo) => {
  // In produzione, questo potrebbe inviare l'errore a un servizio come Sentry
  console.error('CRITICAL ERROR:', errorInfo);
  
  // In un'implementazione reale, qui si potrebbe chiamare un endpoint API per registrare l'errore
  // o utilizzare una libreria di monitoraggio degli errori
  
  // Per ora, logghiamo solo l'errore nella console con un formato che lo rende più visibile
  console.error('%c⚠️ ERRORE CRITICO NEL SISTEMA ⚠️', 'background: #ff0000; color: white; font-size: 14px; font-weight: bold; padding: 5px;');
  console.error('Dettagli:', errorInfo);
};

const GamesPage = ({ onCreateGame, handleNewGlobalChat }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isApiUnavailable, setIsApiUnavailable] = useState(false);

  // Stati per dialog di conferma eliminazione
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);

  // Stati per la Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Stato per evidenziare la card selezionata
  const [selectedGameType, setSelectedGameType] = useState(null);

  // ---------------------------
  // Autenticazione & Refresh
  // ---------------------------
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }
    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (!response.ok) throw new Error('Failed to refresh token');

      const data = await response.json();
      localStorage.setItem('access', data.access);
      if (data.refresh) localStorage.setItem('refresh', data.refresh);
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
      return null;
    }
    const headers = { ...options.headers, Authorization: `Bearer ${accessToken}` };

    try {
      let response = await fetch(url, { ...options, headers });
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) return null;
        const retryHeaders = { ...options.headers, Authorization: `Bearer ${accessToken}` };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }
      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // ---------------------------
  // Caricamento lista giochi
  // ---------------------------
  const fetchGames = async () => {
    setLoading(true);
    setError(null);
    setIsApiUnavailable(false);
    
    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/advantage_games/saved-games/`, {
        method: 'GET',
      });

      if (!response) {
        throw new Error("Errore di connessione al server");
      }
      
      if (response.status === 401) {
        navigate('/login');
        return;
      }
      
      if (response.status === 403) {
        navigate('/abbonamento');
        return;
      }
      
      if (response.status === 500) {
        const errorData = await response.json();
        console.error('Server error 500 details:', errorData);
        
        // Verifica se l'errore è relativo a "SavedGame is not defined"
        if (errorData.detail && errorData.detail.includes("SavedGame is not defined")) {
          // Log dell'errore critico per notificare il team di sviluppo
          logCriticalError({
            type: 'BACKEND_MODEL_ERROR',
            message: errorData.detail,
            timestamp: new Date().toISOString(),
            endpoint: `${baseUrl}/api/advantage_games/saved-games/`,
            user: localStorage.getItem('user_id') || 'unknown'
          });
          
          setIsApiUnavailable(true);
          throw new Error("Servizio temporaneamente non disponibile. I nostri tecnici sono stati avvisati del problema.");
        }
        throw new Error("Errore interno del server. Il servizio potrebbe essere temporaneamente non disponibile.");
      }
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching advantage games:', errorData);
        throw new Error(errorData.detail || "Errore nel recupero dei giochi.");
      }

      const data = await response.json();
      console.log('Dati ricevuti dall\'API advantage_games/saved-games:', data);
      
      // Gestire sia il caso in cui l'API restituisca un array diretto che quello in cui i dati sono in una proprietà 'results'
      const gamesData = Array.isArray(data) ? data : (data.results || []);
      setGames(gamesData);
    } catch (error) {
      console.error('Error in fetchGames:', error);
      setError(error.message);
      setSnackbarSeverity('error');
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------------------------------------------------
  // RENDER per le box di selezione del tipo di gioco
  // -----------------------------------------------------
  const renderGameTypePaper = (agent) => (
    <Paper
      key={agent.agentKey}
      elevation={3}
      sx={{
        padding: '0.75rem',
        borderRadius: '1rem',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        border:
          selectedGameType?.agentKey === agent.agentKey
            ? '2px solid #DF4634'
            : '2px solid transparent',
        '&:hover': {
          backgroundColor: 'rgba(223, 70, 52, 0.05)',
        },
      }}
      onClick={() => setSelectedGameType(agent)}
    >
      <Box
        component="img"
        src={agent.image}
        alt={agent.name}
        sx={{
          width: '3.3rem',
          height: '3.3rem',
          margin: '0 auto 0.5rem',
        }}
      />
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          marginBottom: '0.1rem',
          fontSize: '0.9rem',
          lineHeight: '1.2',
          color: '#333',
        }}
      >
        {agent.name}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#6B7280',
          flexGrow: 1,
          fontSize: '0.75rem',
          lineHeight: '1.2',
        }}
      >
        {agent.description}
      </Typography>

      {/* Pulsante per creare un nuovo gioco di questo tipo */}
      <Button
        variant="contained"
        sx={{
          mt: 1,
          backgroundColor: '#DF4634',
          color: '#FFFFFF',
          borderRadius: '1rem',
          textTransform: 'none',
          fontSize: '0.75rem',
          padding: '0.45rem 1rem',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (handleNewGlobalChat) {
            handleNewGlobalChat();
          } else {
            onCreateGame('verifica');
          }
        }}
      >
        Crea
      </Button>
    </Paper>
  );

  // ---------------------------
  // Navigazione al dettaglio
  // ---------------------------
  const handleGameClick = (gameId, gameTypeName) => {
    // Naviga alla pagina del gioco rimanendo nella stessa scheda
    navigate(`/games/${gameId}`);
  };

  // ---------------------------
  // Eliminazione gioco
  // ---------------------------
  const handleOpenDeleteDialog = (game) => {
    setGameToDelete(game);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setGameToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!gameToDelete) return;

    try {
      const { game_id, title } = gameToDelete;
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/advantage_games/games/${game_id}/delete/`,
        { method: 'DELETE' }
      );

      if (!response) {
        throw new Error("Errore di connessione al server");
      }

      if (response.status === 500) {
        const errorData = await response.json();
        console.error('Server error 500 details on delete:', errorData);
        
        try {
          // Tenta di analizzare l'errore JSON
          const errorDetail = errorData.detail || JSON.stringify(errorData);
          
          // Verifica se l'errore è relativo a "SavedGame is not defined"
          if (errorDetail.includes("SavedGame is not defined")) {
            // Log dell'errore critico per notificare il team di sviluppo
            logCriticalError({
              type: 'BACKEND_MODEL_ERROR',
              message: errorDetail,
              timestamp: new Date().toISOString(),
              endpoint: `${baseUrl}/api/advantage_games/games/${game_id}/delete/`,
              method: 'DELETE',
              user: localStorage.getItem('user_id') || 'unknown'
            });
            
            throw new Error("Servizio temporaneamente non disponibile. I nostri tecnici sono stati avvisati del problema.");
          }
          throw new Error(errorDetail || "Errore interno del server. Il servizio potrebbe essere temporaneamente non disponibile.");
        } catch (parseError) {
          throw new Error("Errore interno del server. Il servizio potrebbe essere temporaneamente non disponibile.");
        }
      }

      if (response.status === 204) {
        setGames((prevGames) => prevGames.filter((g) => g.game_id !== game_id));
        setSnackbarSeverity('success');
        setSnackbarMessage(`Verifica "${title}" eliminata con successo.`);
        setSnackbarOpen(true);
      } else if (response.status === 404) {
        const errorData = await response.json();
        console.error('Error deleting advantage game:', errorData);
        throw new Error(errorData.detail || "Questa verifica non esiste più o non sei autorizzato a eliminarla.");
      } else {
        const errorData = await response.json();
        console.error('Error deleting advantage game:', errorData);
        throw new Error(errorData.detail || "Errore nell'eliminazione della verifica.");
      }
    } catch (error) {
      console.error('Error in handleConfirmDelete:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }

    handleCloseDeleteDialog();
  };

  // ---------------------------
  // Snackbar
  // ---------------------------
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // Navigazione verso la pagina della chat globale per modificare il gioco
  const handleEditGame = (e, globalChatId) => {
    e.stopPropagation();
    
    // Navigare direttamente alla pagina della GlobalChat con l'URL corretto
    navigate(`/chat/global/${globalChatId}`);
  };

  // Rendering condizionale quando l'API non è disponibile
  if (isApiUnavailable) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '70vh',
          padding: '2rem',
          backgroundColor: '#F3F6FB',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700, color: '#333', textAlign: 'center', mb: 2 }}>
          Servizio in manutenzione
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', textAlign: 'center', maxWidth: '600px', mb: 3 }}>
          Ci scusiamo per l'inconveniente. Il servizio Advantage Games è temporaneamente non disponibile per manutenzione tecnica.
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', textAlign: 'center', maxWidth: '600px', mb: 4 }}>
          I nostri tecnici sono già al lavoro per ripristinare il servizio il prima possibile.
        </Typography>
        <Button 
          variant="contained"
          onClick={fetchGames}
          sx={{
            backgroundColor: '#DF4634',
            color: '#FFFFFF',
            borderRadius: '1rem',
            textTransform: 'none',
            padding: '0.5rem 2rem',
            '&:hover': {
              backgroundColor: '#E57373',
            },
          }}
        >
          Riprova
        </Button>
      </Box>
    );
  }

  // ---------------------------
  // Render
  // ---------------------------
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // Usa minHeight per permettere alla pagina di crescere e scrollare:
        minHeight: '100vh',
        // Con pb: 4, aggiungiamo extra spazio in fondo per non "tagliare" le box
        pb: { xs: 12, sm: 8, md: 6, lg: 4 }, // Padding bottom extra per garantire scorrimento completo
        overflowY: 'auto',
        backgroundColor: '#F3F6FB',
      }}
    >
      {/* PRIMO TITOLO con design migliorato */}
      <Box 
        sx={{ 
          padding: isMobile ? '1.5rem 1rem' : '2.5rem 2rem 2rem', 
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            position: 'relative',
            padding: '0 1rem',
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 700, 
              color: '#333',
              display: 'inline-block',
              position: 'relative',
              zIndex: 2,
            }}
          >
            Template Verifiche
          </Typography>
          {/* Elemento decorativo sotto il testo */}
          <Box
            sx={{
              position: 'absolute',
              height: '12px',
              width: '60%',
              backgroundColor: 'rgba(223, 70, 52, 0.2)',
              bottom: '2px',
              left: '20%',
              zIndex: 1,
              borderRadius: '6px',
            }}
          />
        </Box>
        {/* Elementi decorativi */}
        <Box
          sx={{
            position: 'absolute',
            top: isMobile ? '10px' : '20px',
            left: isMobile ? '10px' : '40px',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'rgba(223, 70, 52, 0.2)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: isMobile ? '10px' : '20px',
            right: isMobile ? '10px' : '40px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: 'rgba(223, 70, 52, 0.15)',
          }}
        />
      </Box>

      {/* GRIGLIA "Tipi di giochi" */}
      <Box sx={{ px: isMobile ? '1rem' : '2rem', pb: isMobile ? '1rem' : '2rem' }}>
        <Grid container spacing={2} sx={{ mb: { xs: 6, sm: 4 } }}>
          {interactiveAgents.map((agent) => (
            <Grid item xs={6} sm={4} md={2.4} key={agent.agentKey}>
              {renderGameTypePaper(agent)}
            </Grid>
          ))}
        </Grid>

        {/* SECONDO TITOLO con design migliorato */}
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
            position: 'relative',
            padding: '1rem 0',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '0',
              right: '0',
              height: '1px',
              backgroundColor: 'rgba(0,0,0,0.08)',
              zIndex: 1,
            }
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: '#F3F6FB',
              padding: '0 1.5rem',
              zIndex: 2,
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: '8px',
                height: '30px',
                backgroundColor: '#DF4634',
                borderRadius: '4px',
                marginRight: '1rem',
              }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, color: '#333' }}
            >
              Le mie Verifiche interattive
            </Typography>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: '8px',
                height: '30px',
                backgroundColor: '#DF4634',
                borderRadius: '4px',
                marginLeft: '1rem',
              }}
            />
          </Box>
        </Box>

        {/* ELENCO DEI GIOCHI ESISTENTI */}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '200px',
              gap: '1rem',
            }}
          >
            <CircularProgress 
              sx={{ 
                color: '#DF4634',
                '& .MuiCircularProgress-circle': {
                  strokeLinecap: 'round',
                },
              }} 
            />
            <Typography variant="body1" sx={{ color: '#666', fontWeight: 500 }}>
              Caricamento giochi...
            </Typography>
          </Box>
        ) : error ? (
          <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
            <Typography variant="h6" sx={{ color: '#555' }}>
              Errore nel caricamento dei giochi
            </Typography>
            <Typography variant="body2" sx={{ color: '#777', marginTop: '0.5rem', marginBottom: '1rem' }}>
              {error}
            </Typography>
            <Button 
              variant="contained"
              onClick={fetchGames}
              sx={{
                backgroundColor: '#DF4634',
                color: '#FFFFFF',
                borderRadius: '1rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#E57373',
                },
              }}
            >
              Riprova
            </Button>
          </Box>
        ) : games.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            marginTop: '3rem',
            padding: '2rem',
            backgroundColor: 'rgba(223, 70, 52, 0.03)',
            borderRadius: '1rem',
            maxWidth: '600px',
            margin: '3rem auto',
          }}>
            <Box
              component="img"
              src="/people/empty-state.png" 
              alt="Nessun gioco"
              sx={{
                width: '120px',
                height: '120px',
                opacity: 0.8,
                mb: 2,
              }}
            />
            <Typography variant="h6" sx={{ color: '#333', fontWeight: 600, mb: 1 }}>
              Nessuna verifica creata
            </Typography>
            <Typography variant="body1" sx={{ color: '#666', marginBottom: '1.5rem' }}>
              Puoi iniziare creando una nuova verifica selezionando uno dei template qui sopra.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#DF4634',
                borderRadius: '2rem',
                padding: '0.5rem 1.5rem',
                textTransform: 'none',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: '#c83a2a',
                },
              }}
              onClick={() => {
                // Scorrimento automatico verso la sezione dei template
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }}
            >
              Esplora i template
            </Button>
          </Box>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 2 }} sx={{ pb: { xs: 8, sm: 4 } }}>
            {games.map((game) => {
              // Estrai le informazioni dai giochi di vantaggio
              const gameData = game.game_data || {};
              
              // Estrai tutti gli agenti unici dalle chat
              const uniqueAgents = [];
              const uniqueAgentKeys = new Set();
              
              if (gameData.chats && gameData.chats.length > 0) {
                gameData.chats.forEach(chat => {
                  if (chat.agent) {
                    // Controlla se è una chat di tipo "scrittura" e se contiene riferimenti a YouTube
                    let agentKey = chat.agent;
                    
                    // Se è una chat di tipo "scrittura", verifica se contiene "youtube"
                    if (chat.agent === 'scrittura' && chat.last_message) {
                      const messageText = chat.last_message.text || '';
                      let isYouTubeContent = false;
                      
                      // Verifica se contiene direttamente la parola "youtube"
                      if (messageText.toLowerCase().includes('youtube')) {
                        isYouTubeContent = true;
                      } else {
                        // Tenta di analizzare il testo come JSON per trovare riferimenti a YouTube
                        try {
                          const jsonData = JSON.parse(messageText);
                          if (jsonData && 
                              (jsonData.type === 'youtube' || 
                               jsonData.videoId || 
                               (jsonData.url && jsonData.url.includes('youtube')))) {
                            isYouTubeContent = true;
                          }
                        } catch (e) {
                          // Non è un JSON valido, continua con l'analisi testuale
                          // Cerca altri possibili pattern di URL YouTube
                          if (messageText.toLowerCase().includes('youtu.be') || 
                              messageText.toLowerCase().includes('youtube.com')) {
                            isYouTubeContent = true;
                          }
                        }
                      }
                      
                      // Log per debug
                      if (isYouTubeContent) {
                        console.log('Trovato contenuto YouTube in chat di tipo scrittura:', {
                          chatId: chat.id,
                          title: chat.title,
                          messagePreview: messageText.substring(0, 50) + '...'
                        });
                        agentKey = 'video';
                      }
                    }
                    
                    // Aggiungi l'agente all'elenco solo se non è già presente
                    if (!uniqueAgentKeys.has(agentKey)) {
                      uniqueAgentKeys.add(agentKey);
                      uniqueAgents.push(agentKey);
                    }
                  }
                });
              }
              
              // Se non ci sono agenti, usiamo 'generic'
              if (uniqueAgents.length === 0) {
                uniqueAgents.push('generic');
              }
              
              // Formatta la data di creazione
              const formattedDate = new Date(game.created_at).toLocaleDateString('it-IT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              });
              
              // Conteggio delle attività
              const activityCount = gameData.chats ? gameData.chats.length : 0;

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={game.game_id}>
                  <Card
                    sx={{
                      borderRadius: '1rem',
                      overflow: 'hidden',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
                      transition: 'all 0.3s ease',
                      cursor: 'pointer',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                      },
                    }}
                    onClick={() => handleGameClick(game.game_id, uniqueAgents[0])}
                  >
                    {/* Intestazione della Card con sfondo colorato */}
                    <Box
                      sx={{
                        background: 'linear-gradient(135deg, #DF4634 0%, #FF7863 100%)',
                        color: 'white',
                        padding: '1rem',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          mb: 0.5,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          pr: '2rem',
                        }}
                      >
                        {game.title}
                      </Typography>
                      
                      {/* Data di creazione */}
                      <Typography
                        variant="caption"
                        sx={{
                          opacity: 0.9,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            opacity: 0.7,
                          }}
                        />
                        Creato il {formattedDate}
                      </Typography>
                      
                      {/* Decorazione di sfondo */}
                      <Box
                        sx={{
                          position: 'absolute',
                          right: '-10px',
                          top: '-10px',
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%',
                          backgroundColor: 'rgba(255,255,255,0.1)',
                        }}
                      />
                    </Box>

                    {/* Corpo della Card */}
                    <Box
                      sx={{
                        p: 2,
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {/* Badge numero di attività */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '6px',
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: 'rgba(223, 70, 52, 0.1)',
                            padding: '0.25rem 0.75rem',
                            borderRadius: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              backgroundColor: '#DF4634',
                              borderRadius: '50%',
                              width: '8px',
                              height: '8px',
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 500,
                              fontSize: '0.8rem',
                              color: '#DF4634',
                            }}
                          >
                            {activityCount} attività
                          </Typography>
                        </Box>
                      </Box>

                      {/* Area delle tipologie di attività */}
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '0.75rem',
                          color: '#666',
                          mb: 1,
                          fontWeight: 500,
                        }}
                      >
                        Tipologie di attività:
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '0.5rem',
                          mb: 2,
                        }}
                      >
                        {uniqueAgents.map((agentKey, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: 'rgba(0,0,0,0.04)',
                              padding: '0.3rem 0.6rem',
                              borderRadius: '0.5rem',
                              gap: '6px',
                            }}
                          >
                            <Box
                              component="img"
                              src={agentImages[agentKey] || '/people/generic.png'}
                              alt={agentKey}
                              sx={{
                                width: '20px',
                                height: '20px',
                                objectFit: 'contain',
                              }}
                            />
                            <Typography
                              variant="caption"
                              sx={{
                                fontSize: '0.7rem',
                                fontWeight: 500,
                                color: '#333',
                                textTransform: 'capitalize',
                              }}
                            >
                              {agentDisplayNames[agentKey] || agentKey}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    {/* Footer della Card */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '0.5rem 1rem 0.5rem 0.5rem',
                        pt: 1,
                        borderTop: '1px solid rgba(0,0,0,0.06)',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 0.5, 
                        flexWrap: 'wrap',
                        flex: 1,
                      }}>
                        <Button
                          variant="text"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGameClick(game.game_id, uniqueAgents[0]);
                          }}
                          sx={{
                            textTransform: 'none',
                            color: '#DF4634',
                            fontWeight: 500,
                            padding: '2px 6px',
                            minWidth: '60px',
                            fontSize: '0.8rem',
                            '&:hover': {
                              backgroundColor: 'rgba(223, 70, 52, 0.05)',
                            }
                          }}
                        >
                          Apri
                        </Button>
                        
                        <Tooltip 
                          title="Modifica i contenuti della verifica nella chat originale" 
                          arrow
                          placement="top"
                        >
                          <Button
                            variant="text"
                            size="small"
                            onClick={(e) => handleEditGame(e, game.global_chat)}
                            sx={{
                              textTransform: 'none',
                              color: '#555',
                              fontWeight: 500,
                              padding: '2px 6px',
                              minWidth: '80px',
                              fontSize: '0.8rem',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                color: '#333',
                              }
                            }}
                            startIcon={
                              <Box 
                                component="span" 
                                sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  '& svg': {
                                    fontSize: '0.9rem',
                                  }
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                </svg>
                              </Box>
                            }
                          >
                            Modifica
                          </Button>
                        </Tooltip>
                      </Box>

                      <Tooltip title="Elimina verifica" arrow placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenDeleteDialog(game);
                          }}
                          sx={{
                            color: 'rgba(0,0,0,0.5)',
                            '&:hover': {
                              backgroundColor: 'rgba(223, 70, 52, 0.1)',
                              color: '#DF4634',
                            }
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>

      {/* DIALOG DI CONFERMA ELIMINAZIONE */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: '1rem',
            padding: '0.5rem',
            maxWidth: '400px',
          }
        }}
      >
        <DialogTitle 
          id="delete-dialog-title"
          sx={{
            borderBottom: '1px solid rgba(0,0,0,0.08)',
            pb: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
            Elimina verifica
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ pt: 3, pb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box
              sx={{
                backgroundColor: 'rgba(223, 70, 52, 0.1)',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 2,
              }}
            >
              <DeleteIcon sx={{ color: '#DF4634', fontSize: '1.3rem' }} />
            </Box>
            <Typography variant="body1" sx={{ color: '#333', fontWeight: 500 }}>
              {gameToDelete?.title}
            </Typography>
          </Box>
          <DialogContentText 
            id="delete-dialog-description"
            sx={{ color: '#666', fontSize: '0.9rem' }}
          >
            Sei sicuro di voler eliminare questa verifica?<br/>
            <Box component="span" sx={{ mt: 1, display: 'block', fontWeight: 500, color: '#DF4634' }}>
              Questa operazione non può essere annullata.
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem', pt: 0 }}>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{ 
              textTransform: 'none',
              color: '#666',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.05)',
              }
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#DF4634',
              fontWeight: 500,
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: '#c83a2a',
              },
            }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '1rem',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
          }
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ 
            width: '100%',
            alignItems: 'center',
            '&.MuiAlert-standardSuccess': {
              backgroundColor: '#4CAF50',
              color: 'white',
            },
            '&.MuiAlert-standardError': {
              backgroundColor: '#DF4634',
              color: 'white',
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Indicatore di fine pagina */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          py: { xs: 4, sm: 3 },
          opacity: 0.5,
        }}
      >
        <Box
          sx={{
            width: '40px',
            height: '4px',
            backgroundColor: 'rgba(0,0,0,0.15)',
            borderRadius: '2px',
          }}
        />
      </Box>
    </Box>
  );
};

export default GamesPage;
