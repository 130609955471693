// File: src/utils/gameMessageUtils.js

/**
 * Ritorna true se il messaggio è un "quiz", false altrimenti.
 */
export function isQuizMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.quiz && Array.isArray(parsed.quiz);
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come quiz.
   */
  export function parseQuiz(message) {
    return JSON.parse(message.text);
  }
  
  
  /**
   * Ritorna true se il messaggio è un "fill_the_box", false altrimenti.
   */
  export function isFillTheBoxMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.fill_the_box && Array.isArray(parsed.fill_the_box);
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come fill_the_box.
   */
  export function parseFillTheBox(message) {
    return JSON.parse(message.text);
  }
  
  
  /**
   * Ritorna true se il messaggio è un "selectgroup", false altrimenti.
   */
  export function isSelectGroupMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.selectgroup && Array.isArray(parsed.selectgroup);
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come selectgroup.
   */
  export function parseSelectGroup(message) {
    return JSON.parse(message.text);
  }
  
  
  /**
   * Ritorna true se il messaggio è un "matchpairs", false altrimenti.
   */
  /* 
  export function isMatchPairsMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.matchpairs && Array.isArray(parsed.matchpairs);
    } catch {
      return false;
    }
  }
  */
  
  /**
   * Versione temporanea che ritorna sempre false - il gioco "abbina coppie" è disabilitato
   */
  export function isMatchPairsMessage(message) {
    return false; // Il gioco "abbina coppie" è temporaneamente disabilitato
  }
  
  /**
   * Effettua il parse del testo del messaggio come matchpairs.
   */
  /* 
  export function parseMatchPairs(message) {
    return JSON.parse(message.text);
  }
  */
  
  /**
   * Versione temporanea che ritorna un oggetto vuoto - il gioco "abbina coppie" è disabilitato
   */
  export function parseMatchPairs(message) {
    return { matchpairs: [] }; // Ritorna un oggetto vuoto per evitare errori
  }
  
  
  /**
   * Ritorna true se il messaggio è un "rearrange", false altrimenti.
   */
  export function isRearrangeMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.rearrange && Array.isArray(parsed.rearrange);
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come rearrange.
   */
  export function parseRearrange(message) {
    return JSON.parse(message.text);
  }
  
  
  /**
   * Ritorna true se il messaggio è un "crossword", false altrimenti.
   */
  export function isCrosswordMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return (
        parsed.crossword &&
        parsed.crossword.grid &&
        Array.isArray(parsed.crossword.grid) &&
        parsed.crossword.words
      );
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come crossword.
   */
  export function parseCrossword(message) {
    return JSON.parse(message.text);
  }
  
  /**
   * Ritorna true se il messaggio è un video YouTube, false altrimenti.
   */
  export function isYoutubeMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      const parsed = JSON.parse(message.text);
      return parsed.type === 'youtube' && parsed.videoId;
    } catch {
      return false;
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come video YouTube.
   */
  export function parseYoutube(message) {
    return JSON.parse(message.text);
  }
  
  /**
   * Ritorna true se il messaggio è di tipo scrittura (testo libero), false altrimenti.
   */
  export function isWritingMessage(message) {
    if (message.sender !== 'assistant') return false;
    try {
      // Prova a fare il parse come JSON
      JSON.parse(message.text);
      // Se arriva qui, è un JSON valido, quindi non è un messaggio di scrittura
      return false;
    } catch {
      // Se non è un JSON valido, potrebbe essere un messaggio di scrittura
      return message.agent === 'scrittura';
    }
  }
  
  /**
   * Effettua il parse del testo del messaggio come scrittura.
   */
  export function parseWriting(message) {
    return { text: message.text };
  }
  
  