import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/home/Home';
import Login from './components/Login';
import WelcomePage from './components/WelcomePage';
import Signup from './components/Signup';
import Chat from './components/chat/Chat';
import Profile from './components/profile/Profile';
import Subscription from './components/Subscription';
import ResetPassword from './components/ResetPassword';
import CheckoutSuccess from './components/CheckoutSuccess';
import CheckoutCancel from './components/CheckoutCancel';
import ChangePassword from './components/ChangePassword';
import DocumentViewer from './components/DocumentViewer';
import Resources from './components/Resources';
import DocumentDetail from './components/DocumentDetail';
import ActivationSuccess from './components/ActivationSuccess';
import AlreadyActivated from './components/AlreadyActivated';
import TutorialPage from './components/tutorial/Tutorial';


// Importa la pagina dei modelli e la pagina community
import ModelsPage from './components/GameModelsPage';
import GameCommunityPage from './components/GameCommunityPage';
import InteractiveGamePage from './components/games/InteractiveGamePage';

import apiClient from './api/apiClient';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from './api/authUtils';
import { jwtDecode } from 'jwt-decode';

import './App.css';

const App = () => {
  const location = useLocation();

  // Stati globali per autenticazione e menu mobile
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Effetto per gestire script (es. cookie policy) – già presente
  useEffect(() => {
    const excludedRoutes = ['/chat'];
    const isExcludedRoute = excludedRoutes.includes(location.pathname);
    const scriptId = 'iubenda-script';

    if (!isExcludedRoute) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        script.charset = 'UTF-8';
        script.async = true;
        document.body.appendChild(script);
      }
    } else {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
      const iubendaElements = document.querySelectorAll('[class*="iubenda"]');
      iubendaElements.forEach((element) => element.remove());
    }
  }, [location.pathname]);

  // Effetto per la correzione del viewport su mobile
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effetto per controllare l'autenticazione a livello globale
  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;
          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/accounts/token/refresh/', {
                refresh: refreshToken,
              });
              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/accounts/token/refresh/', {
            refresh: refreshToken,
          });
          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* ROUTE PUBBLICHE/NON AUTENTICATE */}
        <Route path="/" element={<Home />} />
        <Route path="/welcomepage" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* ROUTE CHAT -> Con l'asterisco '/*' per gestire sottopercorsi */}
        <Route path="/chat/*" element={<Chat />} />

        {/* ROUTE PER ALTRI COMPONENTI */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/abbonamento" element={<Subscription />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/checkout-cancel" element={<CheckoutCancel />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/documents/:documentType" element={<DocumentViewer />} />
        <Route path="/documents/detail/:documentName" element={<DocumentDetail />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/activation-success" element={<ActivationSuccess />} />
        <Route path="/already-activated" element={<AlreadyActivated />} />
        <Route path="/tutorial" element={<TutorialPage />} />

        {/* ROUTE GESTIONE DEI GIOCHI SPECIFICI */}
        <Route path="/games/:gameId" element={<InteractiveGamePage />} />

        {/* Nuova route per la pagina dei modelli */}
        <Route
          path="/games/models"
          element={
            <ModelsPage
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          }
        />

        {/* Nuova route per la pagina della community dei giochi */}
        <Route
          path="/games/community"
          element={
            <GameCommunityPage
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default App;
