import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AGENTS } from './constants';
import MessageRenderer from './MessageRenderer';
import { getLastAssistantMessage } from './utils';

/**
 * Componente che mostra la lista delle sub-chat create
 */
const SubChatList = ({ 
  subChats, 
  onOpenDetail, 
  onOpenDeleteDialog,
  onMoveUp,
  onMoveDown
}) => {
  return (
    <>
      {subChats.map((chat, index) => {
        const lastMsg = getLastAssistantMessage(chat);
        const isFirst = index === 0;
        const isLast = index === subChats.length - 1;

        return (
          <Box key={chat.id} sx={{ marginBottom: '2rem' }}>
            <Card
              variant="outlined"
              sx={{
                borderRadius: '1rem',
                boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
                cursor: 'default',
              }}
            >
              <CardContent>
                {/* Riga: agent name, penna e cestino */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {AGENTS.find(a => a.key === chat.agent)?.label || chat.agent.toUpperCase()}
                    </Typography>
                    {chat.temp && (
                      <CircularProgress 
                        size={20} 
                        thickness={4} 
                        sx={{ color: '#DF4634', ml: 1 }} 
                      />
                    )}
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* Pulsante per spostare su */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onMoveUp(chat, index);
                      }}
                      disabled={isFirst}
                      sx={{
                        color: isFirst ? '#bdbdbd' : '#757575',
                        mr: 1,
                        '&:hover': {
                          backgroundColor: isFirst ? 'transparent' : 'rgba(117, 117, 117, 0.1)',
                        },
                        transform: 'rotate(90deg)', // Ruoto per renderlo verticale
                      }}
                    >
                      <ChevronLeftIcon fontSize="small" />
                    </IconButton>
                    
                    {/* Pulsante per spostare giù */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onMoveDown(chat, index);
                      }}
                      disabled={isLast}
                      sx={{
                        color: isLast ? '#bdbdbd' : '#757575',
                        mr: 1,
                        '&:hover': {
                          backgroundColor: isLast ? 'transparent' : 'rgba(117, 117, 117, 0.1)',
                        },
                        transform: 'rotate(90deg)', // Ruoto per renderlo verticale
                      }}
                    >
                      <ChevronRightIcon fontSize="small" />
                    </IconButton>
                    
                    <IconButton
                      size="small"
                      onClick={(e) => onOpenDetail(chat, e)}
                      sx={{
                        color: '#757575',
                        mr: 1,
                        '&:hover': {
                          backgroundColor: 'rgba(117, 117, 117, 0.1)',
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    
                    <IconButton
                      size="small"
                      onClick={(e) => onOpenDeleteDialog(chat, e)}
                      sx={{
                        color: '#d32f2f',
                        '&:hover': {
                          backgroundColor: 'rgba(211,47,47,0.1)',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                {/* Ultimo messaggio => stile mini */}
                <Box sx={{ marginTop: '0.5rem' }}>
                  <MessageRenderer 
                    message={lastMsg} 
                    chat={chat} 
                    onOpenDetail={onOpenDetail}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </>
  );
};

export default SubChatList; 