import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

// I dati dei 3 step video
const stepsData = [
  {
    title: 'Come creare la tua prima attività didattica con Minera AI',
    videoUrl: '/video/Minerva_Tutorial_Parte_1.mp4',
  },
  {
    title: 'Come semplificare contenuti velocemente con Minerva',
    videoUrl: '/video/Minerva_Tutorial_personalizzazione_risorse.mp4',
  },
  {
    title: 'Come creare quiz interattivi e altri giochi su Minerva',
    videoUrl: '/video/Minerva_Tutorial_Creazione_Quiz.mp4',
  },
];

/**
 * Wizard con 3 video e 1 step finale.
 * L’utente deve vedere almeno 1 minuto 
 * per non far comparire il "Sei sicuro di saltare?".
 *
 * @param {Function} onFinishWizard - callback quando si clicca "Ho capito!" nello step finale
 */
const NoChatsWizard = ({ onFinishWizard }) => {
  // Indice dello step corrente
  const [stepIndex, setStepIndex] = useState(0);
  // Se stepIndex === stepsData.length, siamo nello step finale di testo
  const isFinalStep = stepIndex === stepsData.length;

  // Stato che tiene traccia di quanti secondi sono stati visti del video corrente
  const [watchedTime, setWatchedTime] = useState(0);

  // Stato per il dialog "Sei sicuro di saltare?"
  const [openSkipDialog, setOpenSkipDialog] = useState(false);

  // Listener per l'avanzamento del video
  const handleTimeUpdate = (event) => {
    const current = event.target.currentTime;
    setWatchedTime(current);
  };

  // Clic su "Visto"
  const handleNext = () => {
    // Se l’utente NON ha guardato 60 secondi => chiedi conferma
    if (watchedTime < 60) {
      setOpenSkipDialog(true);
    } else {
      // Altrimenti avanza direttamente
      goToNextStep();
    }
  };

  // Funzione che avanza di step e resetta il tempo di visione
  const goToNextStep = () => {
    setStepIndex((prev) => prev + 1);
    setWatchedTime(0);
  };

  // Clic su "Indietro"
  const handleBack = () => {
    setStepIndex((prev) => Math.max(0, prev - 1));
    setWatchedTime(0);
  };

  // Conferma skip dal Dialog
  const handleConfirmSkip = () => {
    setOpenSkipDialog(false);
    goToNextStep();
  };

  // Annulla skip -> rimani qui
  const handleCancelSkip = () => {
    setOpenSkipDialog(false);
  };

  // Stili per mostrare il secondo video più piccolo (è verticale ma senza rotazione)
  const getVideoStyles = (index) => {
    // Applica uno stile "ridotto" solo al secondo video (indice 1)
    if (index === 1) {
      return {
        display: 'block',
        margin: '0 auto',       // per centrarlo orizzontalmente
        width: 'auto',
        maxWidth: '300px',      // limite orizzontale (puoi modificare)
        height: 'auto',
        objectFit: 'contain',
        borderRadius: '0.5rem',
        marginBottom: '1.5rem',
      };
    }
    // Per gli altri video (orizzontali) va bene il 100% classico
    return {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      borderRadius: '0.5rem',
      marginBottom: '1.5rem',
    };
  };

  return (
    <>
      {/* Overlay scuro */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.4)',
          zIndex: 1100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={isFinalStep ? 'final-step' : `step-${stepIndex}`}
            initial={{ opacity: 0, x: 40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -40 }}
            transition={{ duration: 0.4 }}
            style={{
              backgroundColor: '#FFF',
              borderRadius: '0.75rem',
              boxShadow: '0px 3px 12px rgba(0,0,0,0.25)',
              width: '90%',
              maxWidth: '600px',
              maxHeight: '85vh',
              overflowY: 'auto',
              padding: '1.5rem',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            {/* Se NON step finale => video */}
            {!isFinalStep ? (
              <>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, marginBottom: '1rem' }}
                >
                  {stepsData[stepIndex].title}
                </Typography>

                {/* PROGRESS BAR + Contatore step solo per i video */}
                <Box sx={{ marginBottom: '1rem' }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, marginBottom: '0.25rem' }}
                  >
                    Video {stepIndex + 1} di {stepsData.length}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={((stepIndex + 1) / stepsData.length) * 100}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: '#FFD7D2',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#DF4634',
                      },
                    }}
                  />
                </Box>

                <video
                  src={stepsData[stepIndex].videoUrl}
                  controls
                  onTimeUpdate={handleTimeUpdate}
                  style={getVideoStyles(stepIndex)}
                />

                {/* Bottoni Indietro / Visto */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {stepIndex > 0 ? (
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{
                        borderRadius: '1.5rem',
                        textTransform: 'none',
                        borderColor: '#DF4634',
                        color: '#DF4634',
                        '&:hover': {
                          backgroundColor: 'rgba(223,70,52,0.1)',
                          borderColor: '#DF4634',
                        },
                      }}
                    >
                      Indietro
                    </Button>
                  ) : (
                    <Box />
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      backgroundColor: '#DF4634',
                      textTransform: 'none',
                      borderRadius: '1.5rem',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                  >
                    Visto
                  </Button>
                </Box>
              </>
            ) : (
              // Step finale => testo
              <>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, marginBottom: '1rem', fontSize: '1.2rem' }}
                >
                  Ora provaci tu!
                </Typography>
                <Typography sx={{ fontSize: '0.95rem', marginBottom: '1.5rem' }}>
                  Sei pronta a creare la tua prima attività didattica con Minerva?
                  Ti accompagneremo passo dopo passo con un mini-tutorial pratico,
                  mostrandoti esattamente quali pulsanti cliccare e come impostare
                  la tua attività in pochi minuti. Iniziamo!
                </Typography>

                <Button
                  variant="contained"
                  onClick={onFinishWizard}
                  sx={{
                    backgroundColor: '#DF4634',
                    textTransform: 'none',
                    borderRadius: '1.5rem',
                    paddingX: '2rem',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#E57373',
                    },
                  }}
                >
                  Ho capito!
                </Button>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Dialog "Sei sicuro di saltare?" */}
      <Dialog open={openSkipDialog} onClose={handleCancelSkip}>
        <DialogTitle>Saltare il tutorial?</DialogTitle>
        <DialogContent>
          <Typography>
            Sei sicuro di non voler terminare la visione di questo video tutorial?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSkip} sx={{ textTransform: 'none' }}>
            Annulla
          </Button>
          <Button
            onClick={handleConfirmSkip}
            variant="contained"
            sx={{
              backgroundColor: '#DF4634',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#E57373',
              },
            }}
          >
            Sì, salta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoChatsWizard;
