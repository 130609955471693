import React from 'react';
import { Box, IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SidebarContent from './SidebarContent';

const MobileSidebar = ({ onClose, onMyGamesClick, ...props }) => {
  return (
    <Drawer
      anchor="left"
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '260px',
          padding: '0.5rem',
          zIndex: (theme) => theme.zIndex.appBar + 2,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {/* Header con il pulsante di chiusura */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.25rem 0.5rem',
            borderBottom: '1px solid #e0e0e0',
            minHeight: '2.5rem',
          }}
        >
          <IconButton onClick={onClose} size="small" sx={{ padding: '0.25rem' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Contenuto del sidebar */}
        <SidebarContent
          {...props}
          onClose={onClose}
          onMyGamesClick={onMyGamesClick}
        />
      </Box>
    </Drawer>
  );
};

export default MobileSidebar;
