// src/components/DocumentDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import FloatingMenu from './FloatingMenu';
import useTeachingDocument from '../hooks/useTeachingDocument';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../api/authUtils';
import { jwtDecode } from 'jwt-decode';
import apiClient from '../api/apiClient';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  Chip,
  Divider,
  Grid,
  Link,
  Tooltip,
  Paper,
  Button,
  Container,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import TikTokIcon from '@mui/icons-material/MusicNote';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArticleIcon from '@mui/icons-material/Article';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TopicIcon from '@mui/icons-material/Topic';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './styles/DocumentDetail.css';

const DocumentDetail = () => {
  const { documentName } = useParams();
  const { document, loading, error, fetchDocumentByName } = useTeachingDocument();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedImage, setSelectedImage] = useState(0); // Per gestire le thumbnails
  const navigate = useNavigate();

  const checkAuthStatus = async () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken) {
      try {
        const decodedAccessToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
          setIsLoggedIn(true);
          setHasSubscription(decodedAccessToken.has_subscription);
        } else if (refreshToken) {
          try {
            const response = await apiClient.post('/api/accounts/token/refresh/', {
              refresh: refreshToken,
            });

            if (response.status === 200) {
              const data = response.data;
              setAccessToken(data.access);
              const decodedNewAccessToken = jwtDecode(data.access);
              setIsLoggedIn(true);
              setHasSubscription(decodedNewAccessToken.has_subscription);
            } else {
              removeTokens();
              setIsLoggedIn(false);
              setHasSubscription(false);
              navigate('/abbonamento');
            }
          } catch (error) {
            console.error('Errore durante il refresh del token:', error);
            removeTokens();
            setIsLoggedIn(false);
            setHasSubscription(false);
            navigate('/abbonamento');
          }
        } else {
          removeTokens();
          setIsLoggedIn(false);
          setHasSubscription(false);
          navigate('/abbonamento');
        }
      } catch (error) {
        console.error('Errore durante la decodifica del token di accesso:', error);
        removeTokens();
        setIsLoggedIn(false);
        setHasSubscription(false);
        navigate('/abbonamento');
      }
    } else if (refreshToken) {
      try {
        const response = await apiClient.post('/api/accounts/token/refresh/', {
          refresh: refreshToken,
        });

        if (response.status === 200) {
          const data = response.data;
          setAccessToken(data.access);
          const decodedNewAccessToken = jwtDecode(data.access);
          setIsLoggedIn(true);
          setHasSubscription(decodedNewAccessToken.has_subscription);
        } else {
          removeTokens();
          setIsLoggedIn(false);
          setHasSubscription(false);
          navigate('/abbonamento');
        }
      } catch (error) {
        console.error('Errore durante il refresh del token:', error);
        removeTokens();
        setIsLoggedIn(false);
        setHasSubscription(false);
        navigate('/abbonamento');
      }
    } else {
      setIsLoggedIn(false);
      setHasSubscription(false);
      navigate('/abbonamento');
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (documentName) {
      fetchDocumentByName(documentName).catch((err) => {
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate('/abbonamento');
        }
      });
    }
  }, [documentName, fetchDocumentByName, navigate]);

  // Formatta la data in un formato più leggibile
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('it-IT', options);
  };

  // Render delle etichette (tags) con tooltip
  const renderTags = (items) => {
    if (!items || items.length === 0) return null;
    
    // Limitiamo il numero di tag visibili per ogni categoria
    const maxVisibleTags = 3;
    const visibleItems = items.slice(0, maxVisibleTags);
    const hiddenItems = items.slice(maxVisibleTags);
    
    return (
      <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
        {visibleItems.map((item) => (
          <Tooltip key={item.id} title={item.nome} placement="top">
            <Chip
              label={item.nome}
              size="small"
              className="documentDetail_Tag"
            />
          </Tooltip>
        ))}
        
        {/* Mostra un chip "+X" se ci sono più tag */}
        {hiddenItems.length > 0 && (
          <Tooltip 
            title={hiddenItems.map(item => item.nome).join(', ')} 
            placement="top"
          >
            <Chip
              label={`+${hiddenItems.length}`}
              size="small"
              className="documentDetail_MoreTag"
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  // Render dei social media links
  const renderSocialLinks = (doc) => {
    if (!doc) return null;

    const hasSocialLinks = doc.instagram_link || doc.facebook_link || doc.website_link || 
                           doc.generic_link || doc.eduki_link || doc.edudoro_link || 
                           doc.freeed_link || doc.tiktok_link;
    
    if (!hasSocialLinks) return null;

    return (
      <Box className="documentDetail_SocialLinks">
        <Typography variant="subtitle2" fontWeight="medium" gutterBottom>
          Link utili:
        </Typography>
        <Grid container spacing={1} className="documentDetail_SocialGrid">
          {doc.instagram_link && (
            <Grid item>
              <Tooltip title="Instagram">
                <Link href={doc.instagram_link} target="_blank" rel="noopener noreferrer" className="documentDetail_SocialIcon">
                  <InstagramIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
          
          {doc.facebook_link && (
            <Grid item>
              <Tooltip title="Facebook">
                <Link href={doc.facebook_link} target="_blank" rel="noopener noreferrer" className="documentDetail_SocialIcon">
                  <FacebookIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
          
          {doc.website_link && (
            <Grid item>
              <Tooltip title="Sito Web">
                <Link href={doc.website_link} target="_blank" rel="noopener noreferrer" className="documentDetail_SocialIcon">
                  <LanguageIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
          
          {doc.tiktok_link && (
            <Grid item>
              <Tooltip title="TikTok">
                <Link href={doc.tiktok_link} target="_blank" rel="noopener noreferrer" className="documentDetail_SocialIcon">
                  <TikTokIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
          
          {doc.generic_link && (
            <Grid item>
              <Tooltip title="Link Generico">
                <Link href={doc.generic_link} target="_blank" rel="noopener noreferrer" className="documentDetail_SocialIcon">
                  <LinkIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        {(doc.eduki_link || doc.edudoro_link || doc.freeed_link) && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Altre risorse dell'autrice su:
            </Typography>
            <Grid container spacing={1} mt={1}>
              {doc.eduki_link && (
                <Grid item>
                  <Link href={doc.eduki_link} target="_blank" rel="noopener noreferrer" className="documentDetail_PlatformLink">
                    Eduki
                  </Link>
                </Grid>
              )}
              
              {doc.edudoro_link && (
                <Grid item>
                  <Link href={doc.edudoro_link} target="_blank" rel="noopener noreferrer" className="documentDetail_PlatformLink">
                    Edudoro
                  </Link>
                </Grid>
              )}
              
              {doc.freeed_link && (
                <Grid item>
                  <Link href={doc.freeed_link} target="_blank" rel="noopener noreferrer" className="documentDetail_PlatformLink">
                    FreeEd
                  </Link>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  // Render dell'autore
  const renderAuthor = (doc) => {
    if (!doc || (!doc.author_name && !doc.logo)) return null;
    
    return (
      <Box className="documentDetail_AuthorSection">
        <Box display="flex" alignItems="center" gap={2}>
          {doc.logo ? (
            <Avatar 
              src={doc.logo} 
              alt={doc.author_name || "Logo"} 
              className="documentDetail_AuthorLogo"
              sx={{ width: 48, height: 48 }}
            />
          ) : (
            doc.author_name && (
              <Avatar 
                className="documentDetail_AuthorLogo"
                sx={{ width: 48, height: 48, bgcolor: '#df4634' }}
              >
                {doc.author_name.charAt(0)}
              </Avatar>
            )
          )}
          <Box>
            <Typography variant="caption" color="textSecondary">
              Creato da:
            </Typography>
            {doc.author_name && (
              <Typography variant="body2" fontWeight="medium">
                {doc.author_name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  // Simula le thumbnails di pagine diverse del PDF
  const renderThumbnails = (doc) => {
    if (!doc) return null;
    
    // Immagini di anteprima per le pagine (in un'implementazione reale, queste verrebbero dal backend)
    const thumbnails = doc.thumbnail ? [doc.thumbnail] : [];
    
    // Simuliamo una visualizzazione con l'anteprima e il PDF completo
    return (
      <Box className="documentDetail_Thumbnails">
        {/* Prima miniatura - Anteprima */}
        <Box 
          className={`documentDetail_Thumbnail ${selectedImage === 0 ? 'active' : ''}`}
          onClick={() => setSelectedImage(0)}
        >
          {thumbnails.length > 0 ? (
            <img 
              src={thumbnails[0]} 
              alt="Anteprima" 
            />
          ) : (
            <div className="documentDetail_ThumbnailPlaceholder">
              <span>Anteprima</span>
            </div>
          )}
          <div className="documentDetail_ThumbnailPageNumber">Anteprima</div>
        </Box>
        
        {/* Seconda miniatura - PDF Completo */}
        <Box 
          className={`documentDetail_Thumbnail ${selectedImage === 1 ? 'active' : ''}`}
          onClick={() => setSelectedImage(1)}
        >
          <div className="documentDetail_ThumbnailPlaceholder documentDetail_PdfIcon">
            <PictureAsPdfIcon />
            <span>PDF</span>
          </div>
          <div className="documentDetail_ThumbnailPageNumber">Documento PDF</div>
        </Box>
      </Box>
    );
  };

  // Funzione per visualizzare la pagina PDF corretta
  const renderPreview = (doc) => {
    if (!doc) return null;
    
    if (selectedImage === 0 && doc.thumbnail) {
      // Per la prima pagina, mostriamo l'anteprima se disponibile
      return (
        <img 
          src={doc.thumbnail}
          alt={doc.document_name}
          className="documentDetail_MainPreview"
        />
      );
    } else {
      // Utilizziamo object con embed come fallback per una migliore compatibilità
      return (
        <Box className="documentDetail_IframeContainer">
          <object
            data={document.file}
            type="application/pdf"
            className="documentDetail_Iframe"
          >
            <embed
              src={document.file}
              type="application/pdf"
              className="documentDetail_Iframe"
            />
            <p>
              Il tuo browser non può visualizzare i PDF. 
              <a href={document.file} target="_blank" rel="noopener noreferrer">
                Clicca qui per scaricare il PDF
              </a>.
            </p>
          </object>
        </Box>
      );
    }
  };

  return (
    <Box className="documentDetail_MainContainer">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu isLoggedIn={isLoggedIn} setMobileMenuOpen={setMobileMenuOpen} />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
            setHasSubscription(false);
          }}
        />
      )}

      <Container maxWidth="lg" className="documentDetail_ContentContainer">
        <main className="documentDetail_MainContent">
          {loading ? (
            <Box className="documentDetail_LoadingBox">
              <CircularProgress sx={{ color: '#df4634' }} />
            </Box>
          ) : error ? (
            <Typography color="error">Errore nel caricamento del documento: {error.message}</Typography>
          ) : document ? (
            <>
              <Typography variant="h4" component="h1" className="documentDetail_MainTitle" gutterBottom>
                {document.document_name}
              </Typography>
              
              {document.subtitle && (
                <Typography variant="h6" color="textSecondary" gutterBottom className="documentDetail_MainSubtitle">
                  {document.subtitle}
                </Typography>
              )}

              <Grid container spacing={3} className="documentDetail_ContentGrid">
                {/* Colonna sinistra con thumbnails */}
                <Grid item xs={12} md={2}>
                  {renderThumbnails(document)}
                </Grid>
                
                {/* Colonna centrale con anteprima */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={0} className="documentDetail_PreviewContainer">
                    {renderPreview(document)}
                    <Box className="documentDetail_PreviewActions">
                      <Button
                        variant="contained"
                        startIcon={<CloudDownloadIcon />}
                        className="documentDetail_DownloadBtn"
                        component="a" 
                        href={document.file}
                        download={`${document.document_name}.pdf`}
                        fullWidth
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(document.file, '_blank');
                        }}
                      >
                        Scarica PDF
                      </Button>
                      
                      <Button
                        variant="outlined"
                        startIcon={<ArticleIcon />}
                        className="documentDetail_ViewBtn"
                        component="a"
                        href={document.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(document.file, '_blank');
                        }}
                      >
                        Apri documento completo
                      </Button>
                    </Box>
                    
                    {/* Sposta le informazioni dell'autore e la descrizione qui */}
                    <Box className="documentDetail_AdditionalContent" mt={3}>
                      {renderAuthor(document)}
                      
                      {/* Descrizione */}
                      {document.description && (
                        <Box className="documentDetail_InfoSection" mt={2}>
                          <Typography variant="subtitle2" fontWeight="medium" mb={1}>
                            Descrizione:
                          </Typography>
                          <Typography variant="body2" className="documentDetail_Description">
                            {document.description}
                          </Typography>
                        </Box>
                      )}
                      
                      {renderSocialLinks(document)}
                    </Box>
                  </Paper>
                </Grid>
                
                {/* Colonna destra con informazioni */}
                <Grid item xs={12} md={4}>
                  <Box className="documentDetail_InfoPanel">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      {document.premium && (
                        <Chip 
                          label="Premium" 
                          size="small" 
                          className="documentDetail_PremiumChip"
                        />
                      )}
                      
                      {!document.premium && (
                        <Chip 
                          label="Nuova pubblicazione" 
                          size="small" 
                          className="documentDetail_NewChip"
                        />
                      )}
                      
                      <Chip
                        icon={<CalendarTodayIcon sx={{ fontSize: '0.8rem !important' }} />}
                        label={formatDate(document.uploaded_at)}
                        size="small"
                        variant="outlined"
                        className="documentDetail_DateChip"
                      />
                    </Box>
                    
                    {/* Informazioni in forma compatta - modificato per layout orizzontale */}
                    <Box className="documentDetail_CompactInfo">
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                        Informazioni sul documento
                      </Typography>
                      
                      <Grid container spacing={2}>
                        {/* Argomenti */}
                        {document.argomenti && document.argomenti.length > 0 && (
                          <Grid item xs={12}>
                            <Box className="documentDetail_InfoItem">
                              <Typography variant="body2" className="documentDetail_InfoLabel">
                                <TopicIcon sx={{ fontSize: 16, mr: 1 }} />
                                Argomenti
                              </Typography>
                              <Box className="documentDetail_InfoValue">
                                {renderTags(document.argomenti)}
                              </Box>
                            </Box>
                          </Grid>
                        )}
                        
                        {/* Anni scolastici e Tipi di materiale in layout orizzontale */}
                        <Grid item xs={12} container spacing={2}>
                          {/* Anni scolastici */}
                          {document.anni_scolastici && document.anni_scolastici.length > 0 && (
                            <Grid item xs={12} md={6}>
                              <Box className="documentDetail_InfoItem">
                                <Typography variant="body2" className="documentDetail_InfoLabel">
                                  <SchoolIcon sx={{ fontSize: 16, mr: 1 }} />
                                  Anni
                                </Typography>
                                <Box className="documentDetail_InfoValue">
                                  {renderTags(document.anni_scolastici)}
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          
                          {/* Tipi di materiale */}
                          {document.tipi_materiale && document.tipi_materiale.length > 0 && (
                            <Grid item xs={12} md={6}>
                              <Box className="documentDetail_InfoItem">
                                <Typography variant="body2" className="documentDetail_InfoLabel">
                                  <MenuBookIcon sx={{ fontSize: 16, mr: 1 }} />
                                  Tipi
                                </Typography>
                                <Box className="documentDetail_InfoValue">
                                  {renderTags(document.tipi_materiale)}
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>Nessun documento selezionato.</Typography>
          )}
        </main>
      </Container>

      <Footer />

      {/* Snackbar per le notifiche */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DocumentDetail;