import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  useMediaQuery,
  useTheme,
  Dialog,
  Slide,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AGENTS, GAME_ICONS } from './constants';
import { keyframes } from '@emotion/react';

const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const pulseBorder = keyframes`
  0% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
  50% { box-shadow: 0 0 0 8px rgba(223,70,52,0.2); }
  100% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
`;

const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '2rem',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Dialog mobile per la scelta del tipo di attività/gioco da creare
 */
const AgentDialogMobile = ({ open, onClose, onChooseAgent }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      const imageUrls = Object.values(GAME_ICONS);
      preloadImages(imageUrls)
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
    }
  }, [open]);

  if (!open) return null;

  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          },
        }}
      >
        <LoadingIndicator />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: '#FFFFFF',
          padding: '1rem',
          width: '100%',
          maxWidth: '100%',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: '16px',
          margin: '16px',
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 'bold', 
            color: '#DF4634',
            fontSize: '1.25rem',
            flexGrow: 1,
            textAlign: 'center'
          }}
        >
          Scegli il tipo di attività
        </Typography>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Contenuto */}
      <Box sx={{ padding: '0' }}>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 2,
            color: 'text.secondary',
            fontSize: '0.9rem',
            textAlign: 'center'
          }}
        >
          Scegli il tipo di attività da creare in base alle tue esigenze didattiche:
        </Typography>
        
        <Grid container spacing={1.5} justifyContent="center">
          {AGENTS.map((agent) => (
            <Grid item xs={4} key={agent.key}>
              <Card 
                variant="outlined" 
                onClick={() => onChooseAgent(agent.key)}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '10px',
                  padding: '6px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: '110px',
                  transition: 'all 0.3s ease',
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                  borderColor: '#e0e0e0',
                  '&:hover': {
                    backgroundColor: 'rgba(223, 70, 52, 0.1)',
                    borderColor: '#DF4634',
                    transform: 'translateY(-2px)',
                  },
                  '&:active': {
                    boxShadow: 'inset 0px 0px 5px rgba(0,0,0,0.2)',
                  }
                }}
              >
                <Box 
                  sx={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(223, 70, 52, 0.1)',
                    mb: 0.5
                  }}
                >
                  <Box
                    component="img"
                    src={GAME_ICONS[agent.key]}
                    alt={agent.label}
                    sx={{ 
                      width: '16px',
                      height: '16px'
                    }}
                    loading="lazy"
                  />
                </Box>
                
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    fontWeight: 'bold',
                    fontSize: '0.75rem'
                  }}
                >
                  {agent.label}
                </Typography>
                
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ 
                    fontSize: '0.6rem', 
                    mt: 0.25,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {agent.key === 'quiz' && "Crea domande a scelta multipla"}
                  {agent.key === 'fillthebox' && "Completa frasi con parole mancanti"}
                  {agent.key === 'selectgroup' && "Raggruppa elementi in categorie"}
                  {agent.key === 'matchpairs' && "Abbina coppie di elementi"}
                  {agent.key === 'rearrange' && "Riordina parole o frasi"}
                  {agent.key === 'crossword' && "Crea cruciverba interattivi"}
                  {agent.key === 'scrittura' && "Genera o inserisci un testo"}
                  {agent.key === 'video' && "Aggiungi video da YouTube"}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default AgentDialogMobile; 