import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import FloatingMenu from './FloatingMenu';
import AccessDeniedMessage from './AccessDeniedMessage';
import SearchBar from './SearchBar';
import useTeachingSearch from '../hooks/useTeachingSearch';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../api/authUtils';
import { jwtDecode } from 'jwt-decode';
import apiClient from '../api/apiClient';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './styles/Resources.css';
import useGetProfile from '../api/profile/useGetProfile';

const Resources = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { 
    documents, 
    loading, 
    error, 
    searchParams,
    filterOptions,
    totalCount,
    currentPage,
    totalPages,
    updateSearchParams,
    resetFilters
  } = useTeachingSearch();
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const navigate = useNavigate();

  // Utilizza l'hook useGetProfile
  const { profile, profileLoading, profileError } = useGetProfile();
  const subscriptionType = profile?.subscription_type || 'free';

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/api/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/api/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const handleDocumentClick = (doc) => {
    if (doc.premium) {
      if (isLoggedIn && (subscriptionType === 'monthly' || subscriptionType === 'annual')) {
        navigate(`/documents/detail/${encodeURIComponent(doc.document_name)}`);
      } else {
        setShowAccessDenied(true);
      }
    } else {
      navigate(`/documents/detail/${encodeURIComponent(doc.document_name)}`);
    }
  };

  // Gestore per il cambio pagina
  const handlePageChange = (event, page) => {
    updateSearchParams({ page });
    window.scrollTo(0, 0); // Scorrere all'inizio della pagina quando si cambia pagina
  };

  return (
    <Box className="resourcesPage_MainContainer">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu 
          isLoggedIn={isLoggedIn} 
          setIsLoggedIn={setIsLoggedIn} 
          setMobileMenuOpen={setMobileMenuOpen} 
        />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box className="resourcesPage_ContentContainer">
        <main className="resourcesPage_MainContent">
          <motion.section
            className="resourcesHeader_UniqueSection"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Risorse Didattiche Minerva
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Accedi ad attività didattiche pronte create da Minerva AI
            </Typography>
          </motion.section>

          <SearchBar 
            searchParams={searchParams}
            filterOptions={filterOptions}
            updateSearchParams={updateSearchParams}
            resetFilters={resetFilters}
            totalCount={totalCount}
          />

          <motion.section
            className="resourcesList_UniqueSection"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            {loading || profileLoading ? (
              <Box className="resourcesList_LoadingBox">
                <CircularProgress />
              </Box>
            ) : error || profileError ? (
              <Typography color="error">Si è verificato un errore durante il caricamento delle risorse.</Typography>
            ) : documents && documents.length > 0 ? (
              <>
                <Box className="categoriesContainer_UniqueWrapper">
                  <Box className="resourcesGrid_Display">
                    {documents.map((doc) => (
                      <Box className="resourceItem_Box" key={doc.id} onClick={() => handleDocumentClick(doc)}>
                        {/* Layout orizzontale della card */}
                        <Box className="resourceCard_Container">
                          {/* Colonna sinistra con l'immagine (ora in alto) */}
                          <Box className="resourceImage_Container">
                            {doc.thumbnail ? (
                              <img
                                src={doc.thumbnail}
                                alt={`${doc.document_name} Thumbnail`}
                                className="resourceThumbnail_Img"
                              />
                            ) : (
                              <Box className="resourceThumbnail_Placeholder">
                                <Typography variant="body2" color="white">
                                  Anteprima non disponibile
                                </Typography>
                              </Box>
                            )}
                            {/* Mostro solo il numero delle pagine se disponibile */}
                            {doc.file_pages && (
                              <Typography className="resourcePages_Count">
                                {doc.file_pages} Pagine
                              </Typography>
                            )}
                          </Box>
                          
                          {/* Area informazioni (ora sotto l'immagine) */}
                          <Box className="resourceInfo_Container">
                            {/* Header: Titolo, prezzo e stato premium */}
                            <Box className="resourceHeader_Container">
                              <Box className="resourceTitle_Wrapper">
                                <Typography variant="h6" className="resourceTitle_Text">
                                  {doc.document_name}
                                </Typography>
                                {doc.premium && (
                                  <img
                                    src="/resources/premium_resources.png"
                                    alt="Premium"
                                    className="premiumIcon_Small"
                                  />
                                )}
                              </Box>
                              {/* Rimuovo il riferimento al prezzo per i contenuti premium */}
                              {doc.price && !doc.premium && (
                                <Typography variant="h6" className="resourcePrice_Text">
                                  {doc.price} €
                                </Typography>
                              )}
                            </Box>
                            
                            {/* Autore */}
                            {doc.author_name && (
                              <Typography 
                                variant="subtitle2" 
                                className="resourceAuthor_Text"
                                sx={{ color: '#df4634' }}
                              >
                                {doc.author_name}
                              </Typography>
                            )}
                            
                            {/* Descrizione */}
                            {doc.description ? (
                              <Typography variant="body2" className="resourceDescription_Text">
                                {doc.description.length > 140 
                                  ? `${doc.description.substring(0, 140)}...` 
                                  : doc.description}
                              </Typography>
                            ) : (
                              <Typography variant="body2" className="resourceDescription_Text">
                                Risorsa didattica per supportare l'apprendimento degli studenti.
                              </Typography>
                            )}
                            
                            {/* Tag e informazioni aggiuntive */}
                            <Box className="resourceTags_Container">
                              {/* Tags per anni scolastici */}
                              <Box className="resourceTagsGroup">
                                {doc.anni_scolastici && doc.anni_scolastici.length > 0 && (
                                  <Box className="resourceTagsList">
                                    <Typography variant="caption" className="resourceTagLabel">
                                      Gradi:
                                    </Typography>
                                    {doc.anni_scolastici.map((anno) => (
                                      <Box key={anno.id} className="resourceTag">
                                        {anno.nome}
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                              
                              {/* Tags per argomenti */}
                              <Box className="resourceTagsGroup">
                                {doc.argomenti && doc.argomenti.length > 0 && (
                                  <Box className="resourceTagsList">
                                    <Typography variant="caption" className="resourceTagLabel">
                                      Argomenti:
                                    </Typography>
                                    {doc.argomenti.map((arg) => (
                                      <Box key={arg.id} className="resourceTag">
                                        {arg.nome}
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                
                {totalPages > 1 && (
                  <Box className="resourcesPagination" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                    <Pagination 
                      count={totalPages} 
                      page={currentPage} 
                      onChange={handlePageChange} 
                      color="primary"
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h6">Nessuna risorsa trovata</Typography>
                <Typography variant="body2" color="textSecondary">
                  Prova a modificare i filtri di ricerca
                </Typography>
              </Box>
            )}
          </motion.section>
          
          <Box sx={{ height: '60px' }} />
        </main>
      </Box>

      <Footer />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <AccessDeniedMessage
        open={showAccessDenied}
        handleClose={() => setShowAccessDenied(false)}
        isLoggedIn={isLoggedIn}
        profile={profile}
      />
    </Box>
  );
};

export default Resources;