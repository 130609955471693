import React, { useState, useEffect } from 'react';
import './styles/SelectGroupGame.css';

const SelectGroupGame = ({ gameData, onComplete, isLastGame, onSubmitFinal, previousAnswers = null }) => {
  const [groups, setGroups] = useState([]);
  const [availableWords, setAvailableWords] = useState([]);
  const [placedWords, setPlacedWords] = useState({});
  const [draggedWord, setDraggedWord] = useState(null);
  const [dragSourceGroup, setDragSourceGroup] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Stati per la modalità clic su mobile
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedWordSource, setSelectedWordSource] = useState(null);
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  
  // Chiave unica per il localStorage basata sul chatId
  const getLocalStorageKey = () => {
    return gameData && gameData.chatId ? `selectgroup_partial_answers_${gameData.chatId}` : null;
  };
  
  // Setup game data e caricamento stato precedente
  useEffect(() => {
    if (gameData && gameData.selectgroup) {
      setGroups(gameData.selectgroup || []);
      
      // Raccoglie tutte le parole
      const allWords = [];
      gameData.selectgroup.forEach(group => {
        group.words.forEach(word => {
          allWords.push(word);
        });
      });
      
      // Inizializza placedWords con gruppi vuoti
      const initialPlaced = {};
      gameData.selectgroup.forEach(group => {
        initialPlaced[group.id] = [];
      });
      
      // Chiave per il localStorage
      const lsKey = getLocalStorageKey();
      let loadedPlacedWords = {}; 
      let wordsToPlace = [...allWords];
      
      // Priorità 1: Controlla se ci sono dati salvati in localStorage
      if (lsKey) {
        try {
          const savedData = localStorage.getItem(lsKey);
          if (savedData) {
            loadedPlacedWords = JSON.parse(savedData);
            console.log('Parole posizionate caricate da localStorage:', loadedPlacedWords);
            
            // Rimuoviamo le parole già posizionate dalla lista di parole disponibili
            Object.values(loadedPlacedWords).forEach(groupWords => {
              groupWords.forEach(word => {
                wordsToPlace = wordsToPlace.filter(w => w !== word);
              });
            });
          }
        } catch (e) {
          console.error('Errore nel caricamento delle parole da localStorage:', e);
        }
      }
      
      // Priorità 2: Se non ci sono dati in localStorage ma ci sono risultati precedenti
      if (Object.keys(loadedPlacedWords).length === 0 && previousAnswers && previousAnswers.groupResults) {
        console.log('Risultati precedenti trovati, carico le parole posizionate:', previousAnswers);
        
        // Ricostruiamo lo stato delle parole posizionate dai risultati precedenti
        previousAnswers.groupResults.forEach(groupResult => {
          const groupId = groupResult.groupId;
          loadedPlacedWords[groupId] = [...groupResult.userSelection];
          
          // Rimuoviamo le parole già posizionate dalla lista di parole disponibili
          groupResult.userSelection.forEach(word => {
            wordsToPlace = wordsToPlace.filter(w => w !== word);
          });
        });
        
        console.log('Parole posizionate caricate da risultati precedenti:', loadedPlacedWords);
      }
      
      // Se abbiamo caricato parole posizionate, le utilizziamo
      if (Object.keys(loadedPlacedWords).length > 0) {
        setPlacedWords(loadedPlacedWords);
      } else {
        setPlacedWords(initialPlaced);
      }
      
      // Mischia le parole rimanenti
      const shuffledWords = shuffleArray(wordsToPlace);
      setAvailableWords(shuffledWords);
    }
  }, [gameData, previousAnswers]);
  
  // Salva le parole posizionate in localStorage ogni volta che vengono aggiornate
  useEffect(() => {
    const lsKey = getLocalStorageKey();
    if (lsKey && Object.keys(placedWords).length > 0) {
      try {
        localStorage.setItem(lsKey, JSON.stringify(placedWords));
        console.log('Parole posizionate salvate in localStorage:', placedWords);
      } catch (e) {
        console.error('Errore nel salvataggio delle parole in localStorage:', e);
      }
    }
  }, [placedWords]);
  
  // Funzione per mischiare un array
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };
  
  // Gestione del drag di una parola
  const handleDragStart = (e, word) => {
    setDraggedWord(word);
    setDragSourceGroup(null);
    e.dataTransfer.setData('text/plain', word);
    e.dataTransfer.effectAllowed = 'move';
  };
  
  // Gestione del drag di una parola già posizionata
  const handleDragStartFromGroup = (e, groupId, word) => {
    setDraggedWord(word);
    setDragSourceGroup(groupId);
    e.dataTransfer.setData('text/plain', word);
    e.dataTransfer.effectAllowed = 'move';
  };
  
  // Fine del drag
  const handleDragEnd = () => {
    setDraggedWord(null);
    setDragSourceGroup(null);
  };
  
  // Permette il drop
  const handleDragOver = (e, groupId) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };
  
  // Gestione del drop in un gruppo
  const handleDrop = (e, groupId) => {
    e.preventDefault();
    
    if (!draggedWord) return;
    
    // Se la parola viene dall'area disponibile
    if (dragSourceGroup === null) {
      setAvailableWords(prev => prev.filter(w => w !== draggedWord));
      setPlacedWords(prev => ({
        ...prev,
        [groupId]: [...prev[groupId], draggedWord]
      }));
    } 
    // Se la parola viene da un altro gruppo
    else if (dragSourceGroup !== null) {
      setPlacedWords(prev => ({
        ...prev,
        [dragSourceGroup]: prev[dragSourceGroup].filter(w => w !== draggedWord),
        [groupId]: [...prev[groupId], draggedWord]
      }));
    }
    
    setDraggedWord(null);
    setDragSourceGroup(null);
  };
  
  // Gestione del drop nell'area disponibile
  const handleDropToAvailable = (e) => {
    e.preventDefault();
    
    if (!draggedWord || dragSourceGroup === null) return;
    
    // Rimuovi la parola dal gruppo di origine e aggiungila all'area disponibile
    setPlacedWords(prev => ({
      ...prev,
      [dragSourceGroup]: prev[dragSourceGroup].filter(w => w !== draggedWord)
    }));
    setAvailableWords(prev => [...prev, draggedWord]);
    
    setDraggedWord(null);
    setDragSourceGroup(null);
  };
  
  // Rimuovi una parola da un gruppo (cliccando)
  const handleRemoveWord = (groupId, word) => {
    setPlacedWords(prev => ({
      ...prev,
      [groupId]: prev[groupId].filter(w => w !== word)
    }));
    setAvailableWords(prev => [...prev, word]);
  };
  
  // Funzione per gestire il clic su una parola nell'area disponibile
  const handleWordClick = (word) => {
    setSelectedWord(word);
    setSelectedWordSource(null);
    setShowGroupSelector(true);
  };

  // Funzione per gestire il clic su una parola già in un gruppo
  const handlePlacedWordClick = (e, groupId, word) => {
    // Previene il comportamento di rimozione della parola quando si vuole selezionare
    e.stopPropagation();
    
    // Se c'è già una 'x' visibile per rimuovere, usiamo quel comportamento
    if (e.target.className.includes('selectgroup-remove-icon')) {
      handleRemoveWord(groupId, word);
      return;
    }
    
    setSelectedWord(word);
    setSelectedWordSource(groupId);
    setShowGroupSelector(true);
  };

  // Funzione per assegnare la parola selezionata a un gruppo
  const handleAssignToGroup = (groupId) => {
    if (!selectedWord) return;
    
    // Se la parola proviene dall'area disponibile
    if (selectedWordSource === null) {
      setAvailableWords(prev => prev.filter(w => w !== selectedWord));
      setPlacedWords(prev => ({
        ...prev,
        [groupId]: [...prev[groupId], selectedWord]
      }));
    } 
    // Se la parola proviene da un altro gruppo
    else {
      setPlacedWords(prev => ({
        ...prev,
        [selectedWordSource]: prev[selectedWordSource].filter(w => w !== selectedWord),
        [groupId]: [...prev[groupId], selectedWord]
      }));
    }
    
    // Reset degli stati di selezione
    setSelectedWord(null);
    setSelectedWordSource(null);
    setShowGroupSelector(false);
  };

  // Funzione per annullare la selezione
  const handleCancelSelection = () => {
    setSelectedWord(null);
    setSelectedWordSource(null);
    setShowGroupSelector(false);
  };
  
  // Funzione per la consegna finale che salta la visualizzazione dei risultati
  const handleSubmitFinal = () => {
    console.log('SelectGroupGame - handleSubmitFinal chiamata');
    setIsSubmitting(true);
    
    // Calcola il punteggio
    const result = calculateResults();
    console.log('SelectGroupGame - Risultato calcolato:', result);
    
    // Invia i risultati alla pagina principale
    if (onComplete) {
      console.log('SelectGroupGame - Chiamata onComplete');
      
      // Rimuoviamo i dati parziali dal localStorage dopo il completamento
      const lsKey = getLocalStorageKey();
      if (lsKey) {
        try {
          localStorage.removeItem(lsKey);
          console.log('Dati parziali rimossi da localStorage dopo completamento');
        } catch (e) {
          console.error('Errore nella rimozione dei dati da localStorage:', e);
        }
      }
      
      onComplete(result);
    } else {
      console.error('SelectGroupGame - onComplete non definita');
    }
    
    // Vai direttamente al riepilogo finale
    if (onSubmitFinal) {
      console.log('SelectGroupGame - Chiamata onSubmitFinal');
      onSubmitFinal();
    } else {
      console.error('SelectGroupGame - onSubmitFinal non definita');
    }
  };
  
  // Funzione per calcolare i risultati senza mostrare la schermata dei risultati
  const calculateResults = () => {
    // Crea una mappa che associa ogni parola al suo gruppo corretto
    const correctGroupMap = {};
    let totalWordsInGroups = 0;
    
    groups.forEach(group => {
      group.words.forEach(word => {
        correctGroupMap[word] = group.id;
        totalWordsInGroups++; // Contiamo il numero totale di parole nei gruppi
      });
    });
    
    // Calcola il punteggio
    let correctCount = 0;
    let totalPlacedWords = 0;
    
    Object.entries(placedWords).forEach(([groupId, words]) => {
      words.forEach(word => {
        totalPlacedWords++;
        if (correctGroupMap[word] === parseInt(groupId)) {
          correctCount++;
        }
      });
    });
    
    // Calcola il punteggio percentuale
    const percentageScore = totalWordsInGroups > 0 
      ? Math.round((correctCount / totalWordsInGroups) * 100) 
      : 0;
    
    // Genera i risultati dettagliati dei gruppi per la visualizzazione
    const groupResults = groups.map(group => {
      const groupId = group.id;
      const userSelectedWords = placedWords[groupId] || [];
      
      // Trova le parole corrette per questo gruppo
      const correctWordsForGroup = group.words;
      
      // Determina se tutti gli elementi nel gruppo sono stati selezionati correttamente
      const allCorrect = correctWordsForGroup.every(word => userSelectedWords.includes(word)) &&
                         userSelectedWords.every(word => correctWordsForGroup.includes(word));
      
      return {
        groupId: groupId,
        groupName: group.topic,
        userSelection: userSelectedWords,
        correctSelection: correctWordsForGroup,
        isCorrect: allCorrect
      };
    });
    
    return {
      type: 'selectgroup',
      chatId: gameData.chatId,
      score: percentageScore,
      correctAnswers: correctCount,
      totalQuestions: totalWordsInGroups,
      totalWords: totalPlacedWords,
      completed: true,
      completedAt: new Date().toISOString(),
      details: {
        correctWords: correctCount,
        totalWords: totalWordsInGroups,
        percentage: percentageScore
      },
      groupResults: groupResults
    };
  };
  
  // Verifica le risposte
  const handleCheckAnswers = () => {
    console.log('SelectGroupGame - handleCheckAnswers chiamata, isLastGame =', isLastGame);
    
    // Se è l'ultimo gioco e l'utente sta consegnando, non mostriamo i risultati
    if (isLastGame) {
      console.log('SelectGroupGame - È l\'ultimo gioco, chiamata handleSubmitFinal');
      handleSubmitFinal();
      return;
    }
    
    console.log('SelectGroupGame - Non è l\'ultimo gioco, procedo normalmente');
    
    // Calcola il punteggio e invia i risultati senza mostrare la schermata dei risultati
    const result = calculateResults();
    
    if (onComplete) {
      // Rimuoviamo i dati parziali dal localStorage dopo il completamento
      const lsKey = getLocalStorageKey();
      if (lsKey) {
        try {
          localStorage.removeItem(lsKey);
          console.log('Dati parziali rimossi da localStorage dopo completamento');
        } catch (e) {
          console.error('Errore nella rimozione dei dati da localStorage:', e);
        }
      }
      
      onComplete(result);
    }
  };
  
  // Se non ci sono gruppi, mostra un messaggio
  if (!groups || groups.length === 0) {
    return (
      <div className="selectgroup-game">
        <h2>Nessun gruppo disponibile</h2>
      </div>
    );
  }
  
  // Rendering del gioco
  return (
    <div className="selectgroup-game">
      <div className="selectgroup-instructions">
        <h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#df4634" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          Raggruppa le parole in base alle loro categorie
        </h3>
        <p className="selectgroup-subtitle">Trascina ciascuna parola nel gruppo appropriato oppure clicca sulla parola per selezionarla</p>
      </div>
      
      {/* Area delle parole disponibili */}
      <div 
        className="selectgroup-available-words"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDropToAvailable}
      >
        <h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
          </svg>
          Parole disponibili
        </h3>
        <div className="selectgroup-words-container">
          {availableWords.map((word, index) => (
            <div
              key={`${word}-${index}`}
              className={`selectgroup-word-chip ${selectedWord === word && selectedWordSource === null ? 'selected' : ''}`}
              draggable
              onDragStart={(e) => handleDragStart(e, word)}
              onDragEnd={handleDragEnd}
              onClick={() => handleWordClick(word)}
            >
              {word}
            </div>
          ))}
        </div>
      </div>
      
      {/* Colonne per i gruppi */}
      <div className="selectgroup-columns">
        {groups.map(group => (
          <div 
            key={group.id} 
            className="selectgroup-column"
            onDragOver={(e) => handleDragOver(e, group.id)}
            onDrop={(e) => handleDrop(e, group.id)}
          >
            <h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                <path d="M12 2l9 4.9V17L12 22l-9-5V7L12 2z"></path>
              </svg>
              {group.topic}
            </h3>
            <div className="selectgroup-words-container">
              {placedWords[group.id] && placedWords[group.id].map((word, index) => (
                <div
                  key={`${word}-${index}`}
                  className={`selectgroup-word-chip placed ${selectedWord === word && selectedWordSource === group.id ? 'selected' : ''}`}
                  draggable
                  onDragStart={(e) => handleDragStartFromGroup(e, group.id, word)}
                  onDragEnd={handleDragEnd}
                  onClick={(e) => handlePlacedWordClick(e, group.id, word)}
                >
                  {word}
                  <span className="selectgroup-remove-icon">×</span>
                </div>
              ))}
            </div>
            
            {/* Pulsante per aggiungere la parola selezionata al gruppo corrente */}
            {showGroupSelector && selectedWord && (
              <button 
                className="selectgroup-assign-button"
                onClick={() => handleAssignToGroup(group.id)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Aggiungi "{selectedWord}" a questo gruppo
              </button>
            )}
          </div>
        ))}
      </div>
      
      {/* Modal/overlay di selezione gruppo (visibile solo quando una parola è selezionata) */}
      {showGroupSelector && selectedWord && (
        <div className="selectgroup-modal-overlay" onClick={handleCancelSelection}>
          <div className="selectgroup-modal" onClick={(e) => e.stopPropagation()}>
            <h4>Seleziona un gruppo per "{selectedWord}"</h4>
            <div className="selectgroup-modal-groups">
              {groups.map(group => (
                <button 
                  key={group.id}
                  className="selectgroup-modal-group-btn"
                  onClick={() => handleAssignToGroup(group.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                    <path d="M12 2l9 4.9V17L12 22l-9-5V7L12 2z"></path>
                  </svg>
                  {group.topic}
                </button>
              ))}
            </div>
            <button className="selectgroup-modal-cancel" onClick={handleCancelSelection}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
              Annulla
            </button>
          </div>
        </div>
      )}
      
      <button 
        className={`selectgroup-button ${isLastGame ? 'submit-final' : ''}`}
        onClick={handleCheckAnswers}
        disabled={isSubmitting}
      >
        {isLastGame ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
            Consegna
          </>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            Verifica
          </>
        )}
      </button>
      
      {availableWords.length > 0 && (
        <p className="selectgroup-warning">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
          Hai {availableWords.length} {availableWords.length === 1 ? 'parola' : 'parole'} non ancora posizionate. Puoi comunque verificare, ma il punteggio sarà più basso.
        </p>
      )}
    </div>
  );
};

export default SelectGroupGame; 