import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import SidebarContent from './SidebarContent';

const Sidebar = ({
  chats,
  globalChats,
  selectedChat,
  selectedGlobalChat,
  selectChat,
  selectGlobalChat,
  handleNewChat,
  handleDeleteChat,
  handleDeleteGlobalChat,
  handleLogout,
  navigate,
  selectedOption,
  onMyGamesClick,
  loadingChats,
  loadingGlobalChats,
  handleCreateNewGlobalChat
}) => {
  // Usa location per determinare se siamo nella pagina di una GlobalChat
  const location = window?.location?.pathname || '';

  return (
    <Box
      component={motion.div}
      initial={{ x: -250 }}
      animate={{ x: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
      sx={{
        minWidth: '18%',
        maxWidth: '18%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '1.875rem',
        padding: '1.25rem',
        margin: '1.25rem',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2.5rem)',
        alignItems: 'center',
        zIndex: 1000,
        overflow: 'hidden',
        position: 'relative',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(0, 0, 0, 0.05)',
      }}
    >
      <SidebarContent 
        chats={chats}
        globalChats={globalChats}
        selectedChat={selectedChat}
        selectedGlobalChat={selectedGlobalChat}
        selectChat={selectChat}
        selectGlobalChat={selectGlobalChat}
        handleNewChat={handleNewChat}
        handleDeleteChat={handleDeleteChat}
        handleDeleteGlobalChat={handleDeleteGlobalChat}
        handleLogout={handleLogout}
        navigate={navigate}
        selectedOption={selectedOption}
        onMyGamesClick={onMyGamesClick}
        loadingChats={loadingChats}
        loadingGlobalChats={loadingGlobalChats}
        handleCreateNewGlobalChat={handleCreateNewGlobalChat}
        location={location}
      />
    </Box>
  );
};

export default Sidebar;
