import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import './styles/FillTheBoxGame.css';

const FillTheBoxGame = ({ gameData, onComplete, isLastGame, onSubmitFinal, previousAnswers = null }) => {
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0); // Indice per la frase corrente
  const [optionsPositionsFixed, setOptionsPositionsFixed] = useState(false); // Stato per tracciare se le posizioni sono state fissate
  
  const gameRef = useRef(null); // Riferimento al contenitore del gioco
  const optionsGridRef = useRef(null); // Riferimento alla griglia delle opzioni
  const optionsRefs = useRef({}); // Riferimenti per ogni singola opzione

  const exercises = gameData.fill_the_box || [];
  const currentExercise = exercises[currentExerciseIndex] || null;
  
  // Chiave unica per il localStorage basata sul chatId
  const getLocalStorageKey = () => {
    if (!gameData || !gameData.chatId) {
      console.error('FillTheBoxGame: gameData o chatId mancante per localStorage key');
      return null;
    }
    
    const key = `fillthebox_partial_answers_${gameData.chatId}`;
    console.log('FillTheBoxGame: Generata chiave localStorage:', key);
    return key;
  };
  
  // Fissa le posizioni delle opzioni dopo il rendering iniziale
  useLayoutEffect(() => {
    if (!optionsGridRef.current || optionsPositionsFixed) return;
    
    // Funzione per fissare le posizioni delle opzioni
    const fixOptionsPositions = () => {
      if (!optionsGridRef.current) return;
      
      // Ottieni tutte le opzioni
      const optionsElements = optionsGridRef.current.querySelectorAll('.fillthebox-option');
      if (!optionsElements || optionsElements.length === 0) return;
      
      // Controlliamo se siamo su mobile
      const isMobile = window.innerWidth <= 768;
      
      if (!isMobile) {
        // Per desktop, manteniamo il layout a griglia
        optionsGridRef.current.style.display = 'grid';
        optionsGridRef.current.style.gridTemplateColumns = 'repeat(auto-fill, minmax(140px, 1fr))';
        optionsGridRef.current.style.position = 'relative';
        optionsGridRef.current.style.gap = '10px';
        optionsGridRef.current.style.contain = 'layout size';
        
        // Fissa le dimensioni e posizioni di ogni opzione
        optionsElements.forEach((option, index) => {
          const rect = option.getBoundingClientRect();
          
          // Assegna dimensioni precise
          option.style.width = `${rect.width}px`;
          option.style.height = `${rect.height}px`;
          option.style.gridArea = `auto / auto / span 1 / span 1`;
          option.style.contain = 'layout size';
          option.style.position = 'relative';
          option.style.margin = '0';
          
          // Memorizza il riferimento
          const optionId = option.getAttribute('data-option-id');
          if (optionId) {
            optionsRefs.current[optionId] = option;
          }
        });
      } else {
        // Per mobile, usiamo un layout flex per garantire la visibilità di tutte le opzioni
        optionsGridRef.current.style.display = 'flex';
        optionsGridRef.current.style.flexWrap = 'wrap';
        optionsGridRef.current.style.justifyContent = 'center';
        optionsGridRef.current.style.gap = '8px';
        optionsGridRef.current.style.padding = '10px';
        optionsGridRef.current.style.position = 'static';
        optionsGridRef.current.style.contain = 'none';
        
        // Su mobile, impostiamo le opzioni per essere visibili tutte
        optionsElements.forEach((option, index) => {
          option.style.flex = '0 0 calc(50% - 10px)';
          option.style.width = 'calc(50% - 10px)';
          option.style.height = 'auto';
          option.style.minHeight = '40px';
          option.style.position = 'static';
          option.style.margin = '0';
          option.style.contain = 'none';
          option.style.display = 'flex';
          option.style.alignItems = 'center';
          option.style.justifyContent = 'center';
          
          // Memorizza il riferimento
          const optionId = option.getAttribute('data-option-id');
          if (optionId) {
            optionsRefs.current[optionId] = option;
          }
        });
      }
      
      // Imposta lo stato per non ripetere questa operazione
      setOptionsPositionsFixed(true);
    };
    
    // Esegui con un piccolo ritardo per assicurarsi che il rendering sia completo
    const timer = setTimeout(fixOptionsPositions, 100);
    
    return () => clearTimeout(timer);
  }, [currentExerciseIndex, optionsPositionsFixed]);
  
  // Reset dello stato al cambio di esercizio o al ridimensionamento della finestra
  useEffect(() => {
    setOptionsPositionsFixed(false);
    
    // Aggiungiamo un ascoltatore per il ridimensionamento della finestra
    const handleResize = () => {
      setOptionsPositionsFixed(false);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentExerciseIndex]);
  
  // Gestione dello scroll per prevenire problemi di layout
  useEffect(() => {
    const handleScroll = () => {
      document.documentElement.classList.add('scrolling');
      
      // Su mobile non blocchiamo nulla per consentire lo scrolling naturale
      const isMobile = window.innerWidth <= 768;
      if (!isMobile && optionsGridRef.current) {
        optionsGridRef.current.style.willChange = 'transform';
        optionsGridRef.current.style.transform = 'translateZ(0)';
      }
      
      // Rimuove la classe dopo che lo scroll è terminato
      clearTimeout(window.scrollEndTimer);
      window.scrollEndTimer = setTimeout(() => {
        document.documentElement.classList.remove('scrolling');
        
        if (!isMobile && optionsGridRef.current) {
          optionsGridRef.current.style.willChange = 'auto';
        }
      }, 150);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(window.scrollEndTimer);
    };
  }, []);
  
  // Previene la perdita degli abbinamenti durante lo scroll
  useEffect(() => {
    if (!gameRef.current) return;
    
    // Su mobile, non interferiamo con lo scrolling
    const isMobile = window.innerWidth <= 768;
    if (isMobile) return;
    
    const preventDefaultOnWheel = (e) => {
      // Solo su desktop preveniamo il comportamento predefinito
      if (e.target.closest('.fillthebox-options-grid') || 
          e.target.closest('.fillthebox-option')) {
        e.preventDefault();
      }
    };
    
    const gameElement = gameRef.current;
    gameElement.addEventListener('wheel', preventDefaultOnWheel, { passive: false });
    
    return () => {
      gameElement.removeEventListener('wheel', preventDefaultOnWheel);
    };
  }, []);
  
  // Carica lo stato iniziale al montaggio del componente
  useEffect(() => {
    console.log('FillTheBoxGame [INIT]: Componente montato, verifico le risposte salvate');
    loadAnswersFromStorage();
  }, []); // Eseguito solo al montaggio
  
  // Ricarica le risposte quando cambia il chatId
  useEffect(() => {
    console.log(`FillTheBoxGame [CHAT_CHANGE]: Cambio di chat ID rilevato: ${gameData.chatId}`);
    loadAnswersFromStorage();
    // Reset dell'esercizio corrente quando cambia il gioco
    setCurrentExerciseIndex(0);
    setIsSubmitting(false);
  }, [gameData.chatId, previousAnswers]);
  
  // Funzione unificata per caricare risposte dal localStorage
  const loadAnswersFromStorage = () => {
    if (!gameData || !gameData.chatId) {
      console.log('FillTheBoxGame [LOAD]: Nessun gameData o chatId disponibile');
      return;
    }
    
    console.log(`FillTheBoxGame [LOAD]: Tentativo di caricamento risposte per chatId ${gameData.chatId}`);
    
    // Chiave per il localStorage
    const lsKey = getLocalStorageKey();
    let loadedAnswers = {};
    
    // Priorità 1: Controlla se ci sono risposte parziali salvate in localStorage
    if (lsKey) {
      try {
        const savedAnswers = localStorage.getItem(lsKey);
        console.log(`FillTheBoxGame [LOAD]: Verifica localStorage con chiave: ${lsKey}`, savedAnswers ? "TROVATO" : "NON TROVATO");
        
        if (savedAnswers) {
          try {
            const parsedAnswers = JSON.parse(savedAnswers);
            console.log('FillTheBoxGame [LOAD]: Risposte caricate da localStorage:', parsedAnswers);
            loadedAnswers = parsedAnswers;
          } catch (e) {
            console.error('FillTheBoxGame [LOAD]: Errore nel parsing JSON delle risposte:', e);
          }
        } else {
          console.log('FillTheBoxGame [LOAD]: Nessuna risposta salvata trovata nel localStorage');
        }
      } catch (e) {
        console.error('FillTheBoxGame [LOAD]: Errore nell\'accesso al localStorage:', e);
      }
    }
    
    // Priorità 2: Se non ci sono risposte in localStorage ma ci sono risposte precedenti
    if (Object.keys(loadedAnswers).length === 0 && previousAnswers && previousAnswers.results) {
      console.log('FillTheBoxGame [LOAD]: Carico risposte da previousAnswers:', previousAnswers);
      
      // Formatta le risposte precedenti nel formato corretto
      previousAnswers.results.forEach(result => {
        if (result.userAnswer) {
          loadedAnswers[result.id] = result.userAnswer;
        }
      });
      
      console.log('FillTheBoxGame [LOAD]: Risposte precedenti formattate:', loadedAnswers);
    }
    
    // Imposta le risposte caricate nello stato
    if (Object.keys(loadedAnswers).length > 0) {
      console.log('FillTheBoxGame [LOAD]: Impostazione delle risposte nello stato:', loadedAnswers);
      setAnswers(loadedAnswers);
      
      // Controlla se l'utente ha già risposto all'esercizio corrente e calcola l'ultimo esercizio risposto
      if (exercises && exercises.length > 0) {
        let lastAnsweredIndex = -1;
        
        // Trova l'ultima domanda risposta
        for (let i = 0; i < exercises.length; i++) {
          if (loadedAnswers[exercises[i]?.id]) {
            lastAnsweredIndex = i;
          } else {
            // Se troviamo una domanda senza risposta, ci fermiamo qui
            break;
          }
        }
        
        // Se abbiamo trovato almeno una risposta
        if (lastAnsweredIndex >= 0) {
          // Se non tutte le domande sono state risposte, vai alla prima senza risposta
          if (lastAnsweredIndex < exercises.length - 1) {
            setCurrentExerciseIndex(lastAnsweredIndex + 1);
            console.log(`FillTheBoxGame [LOAD]: Posizionamento alla prossima domanda senza risposta: ${lastAnsweredIndex + 1}`);
          } else {
            // Se tutte sono state risposte, vai all'ultima
            setCurrentExerciseIndex(exercises.length - 1);
            console.log(`FillTheBoxGame [LOAD]: Tutte le domande risposte, posizionamento all'ultima: ${exercises.length - 1}`);
          }
        } else {
          // Se non ci sono risposte, inizia dalla prima domanda
          setCurrentExerciseIndex(0);
          console.log('FillTheBoxGame [LOAD]: Nessuna risposta trovata, posizionamento alla prima domanda');
        }
      }
    } else {
      console.log('FillTheBoxGame [LOAD]: Nessuna risposta da caricare');
      setCurrentExerciseIndex(0);
    }
  };
  
  // Salva le risposte in localStorage ogni volta che vengono aggiornate
  useEffect(() => {
    if (!gameData || !gameData.chatId) {
      console.log('FillTheBoxGame [SAVE]: Nessun gameData o chatId disponibile per il salvataggio');
      return;
    }
    
    const lsKey = getLocalStorageKey();
    if (lsKey && Object.keys(answers).length > 0) {
      try {
        const answersToSave = JSON.stringify(answers);
        localStorage.setItem(lsKey, answersToSave);
        console.log('FillTheBoxGame [SAVE]: Risposte salvate in localStorage con chiave', lsKey, answers);
        
        // Verifica che il salvataggio sia avvenuto correttamente
        const savedItem = localStorage.getItem(lsKey);
        if (savedItem === answersToSave) {
          console.log('FillTheBoxGame [SAVE]: Verifica salvataggio OK');
        } else {
          console.error('FillTheBoxGame [SAVE]: ERRORE - Verifica salvataggio fallita!');
          // Riprova il salvataggio
          setTimeout(() => {
            try {
              localStorage.setItem(lsKey, answersToSave);
              console.log('FillTheBoxGame [SAVE]: Secondo tentativo di salvataggio');
            } catch (e) {
              console.error('FillTheBoxGame [SAVE]: Errore nel secondo tentativo di salvataggio:', e);
            }
          }, 100);
        }
      } catch (e) {
        console.error('FillTheBoxGame [SAVE]: Errore nel salvataggio delle risposte in localStorage:', e);
      }
    }
  }, [answers, gameData]);

  // Usa funzione memoizzata per l'handler di selezione delle opzioni
  const handleSelectOption = React.useCallback((exerciseId, optionId) => {
    console.log(`FillTheBoxGame [SELECT]: Selezionata opzione ${optionId} per esercizio ${exerciseId}`);
    
    // Preveniamo perdite di stato durante lo scroll usando una funzione setter
    setAnswers(prevAnswers => {
      // Crea una copia profonda dello stato precedente
      const newAnswers = { ...prevAnswers };
      newAnswers[exerciseId] = optionId;
      
      // Salva immediatamente in localStorage per maggiore sicurezza
      try {
        const lsKey = getLocalStorageKey();
        if (lsKey) {
          localStorage.setItem(lsKey, JSON.stringify(newAnswers));
          console.log('FillTheBoxGame [SELECT]: Salvataggio immediato in localStorage:', newAnswers);
          
          // Verifica che il salvataggio sia avvenuto correttamente
          const savedItem = localStorage.getItem(lsKey);
          if (savedItem) {
            console.log('FillTheBoxGame [SELECT]: Verifica salvataggio immediato OK');
          } else {
            console.error('FillTheBoxGame [SELECT]: ERRORE - Verifica salvataggio immediato fallita!');
          }
        }
      } catch (e) {
        console.error('FillTheBoxGame [SELECT]: Errore nel salvataggio immediato', e);
      }
      
      return newAnswers;
    });
  }, []);

  // Funzione per navigare alla frase successiva
  const handleNextExercise = () => {
    if (currentExerciseIndex < exercises.length - 1) {
      // Prima salva l'opzione selezionata per l'esercizio corrente, se presente
      if (currentExercise && answers[currentExercise.id]) {
        console.log(`FillTheBoxGame [NAV]: Salvata risposta per esercizio ${currentExerciseIndex}: ${answers[currentExercise.id]}`);
      }
      
      // Poi passa all'esercizio successivo
      setCurrentExerciseIndex(prevIndex => prevIndex + 1);
      console.log(`FillTheBoxGame [NAV]: Passaggio a esercizio ${currentExerciseIndex + 1}`);
    }
  };

  // Funzione per navigare alla frase precedente
  const handlePrevExercise = () => {
    if (currentExerciseIndex > 0) {
      // Prima salva l'opzione selezionata per l'esercizio corrente, se presente
      if (currentExercise && answers[currentExercise.id]) {
        console.log(`FillTheBoxGame [NAV]: Salvata risposta per esercizio ${currentExerciseIndex}: ${answers[currentExercise.id]}`);
      }
      
      // Poi passa all'esercizio precedente
      setCurrentExerciseIndex(prevIndex => prevIndex - 1);
      console.log(`FillTheBoxGame [NAV]: Passaggio a esercizio ${currentExerciseIndex - 1}`);
    }
  };
  
  // Funzione per la consegna finale che salta la visualizzazione dei risultati
  const handleSubmitFinal = () => {
    console.log('FillTheBoxGame [SUBMIT]: Avvio processo di consegna finale');
    setIsSubmitting(true);
    
    // Prima calcoliamo il punteggio e completiamo il gioco normalmente
    const result = calculateResults();
    console.log('FillTheBoxGame [SUBMIT]: Risultato calcolato:', result);
    
    // Inviamo i risultati alla pagina principale
    if (onComplete) {
      console.log('FillTheBoxGame [SUBMIT]: Chiamata onComplete con risultati');
      
      // Rimuoviamo i dati parziali dal localStorage dopo il completamento
      const lsKey = getLocalStorageKey();
      if (lsKey) {
        try {
          console.log(`FillTheBoxGame [SUBMIT]: Rimozione dati da localStorage chiave ${lsKey}`);
          localStorage.removeItem(lsKey);
          
          // Verifica che la rimozione sia avvenuta
          const check = localStorage.getItem(lsKey);
          console.log('FillTheBoxGame [SUBMIT]: Verifica rimozione -', check ? 'FALLITA' : 'OK');
        } catch (e) {
          console.error('FillTheBoxGame [SUBMIT]: Errore nella rimozione dei dati da localStorage:', e);
        }
      }
      
      onComplete(result);
    }
    
    // Poi andiamo direttamente al riepilogo finale
    if (onSubmitFinal) {
      console.log('FillTheBoxGame [SUBMIT]: Chiamata onSubmitFinal');
      onSubmitFinal();
    }
  };
  
  // Funzione per calcolare i risultati senza mostrare la schermata dei risultati
  const calculateResults = () => {
    console.log('FillTheBoxGame [CALC]: Calcolo dei risultati');
    
    if (!exercises || exercises.length === 0) {
      console.error('FillTheBoxGame [CALC]: Nessun esercizio disponibile per la verifica');
      return {
        type: 'fillthebox',
        chatId: gameData.chatId,
        score: 0,
        correctAnswers: 0,
        totalQuestions: 0,
        results: [],
        completed: true,
        completedAt: new Date().toISOString()
      };
    }
    
    let correct = 0;
    let exerciseResults = [];
    
    exercises.forEach(exercise => {
      const userAnswer = answers[exercise.id];
      const correctOption = exercise.options.find(option => option.is_correct);
      
      if (userAnswer) {
        if (correctOption && userAnswer === correctOption.id) {
          correct++;
        }
      }
      
      exerciseResults.push({
        id: exercise.id,
        userAnswer: userAnswer,
        userAnswerText: exercise.options.find(opt => opt.id === userAnswer)?.text || 'Non risposto',
        correctAnswer: correctOption ? correctOption.id : null,
        correctAnswerText: correctOption ? correctOption.text : 'Non disponibile',
        isCorrect: userAnswer === correctOption?.id
      });
    });
    
    const totalExercises = exercises.length;
    const finalScore = Math.round((correct / totalExercises) * 100);
    
    console.log(`FillTheBoxGame [CALC]: Punteggio ${correct}/${totalExercises} (${finalScore}%)`);
    
    return {
      type: 'fillthebox',
      chatId: gameData.chatId,
      score: finalScore,
      correctAnswers: correct,
      totalQuestions: totalExercises,
      results: exerciseResults,
      completed: true,
      completedAt: new Date().toISOString(),
      details: {
        totalCorrect: correct,
        totalExercises: totalExercises,
        percentage: finalScore
      }
    };
  };

  const handleSubmit = () => {
    console.log('FillTheBoxGame [SUBMIT]: Gestione invio risposte');
    
    // Se è l'ultimo gioco e l'utente sta consegnando, non mostriamo i risultati
    if (isLastGame) {
      console.log('FillTheBoxGame [SUBMIT]: È l\'ultimo gioco, chiamata handleSubmitFinal');
      handleSubmitFinal();
      return;
    }
    
    // Altrimenti procediamo normalmente
    if (!exercises || exercises.length === 0) {
      console.error('FillTheBoxGame [SUBMIT]: Nessun esercizio disponibile per la verifica');
      return;
    }

    console.log('FillTheBoxGame [SUBMIT]: Non è l\'ultimo gioco, procedo normalmente');
    console.log('FillTheBoxGame [SUBMIT]: Risposte utente:', answers);
    
    // Calcola il punteggio e invia i risultati senza mostrare la schermata dei risultati
    const result = calculateResults();
    
    if (onComplete) {
      console.log('FillTheBoxGame [SUBMIT]: Chiamata onComplete con risultato');
      
      // Rimuoviamo i dati parziali dal localStorage SOLO DOPO il completamento
      const lsKey = getLocalStorageKey();
      if (lsKey) {
        try {
          console.log(`FillTheBoxGame [SUBMIT]: Rimozione dati da localStorage chiave ${lsKey}`);
          localStorage.removeItem(lsKey);
        } catch (e) {
          console.error('FillTheBoxGame [SUBMIT]: Errore nella rimozione dei dati da localStorage:', e);
        }
      }
      
      onComplete(result);
    }
  };

  // Funzione per verificare se tutte le frasi hanno una risposta
  const areAllExercisesAnswered = () => {
    const allAnswered = exercises.every(exercise => answers[exercise.id]);
    console.log(`FillTheBoxGame [CHECK]: Tutte le domande risposte? ${allAnswered}`);
    return allAnswered;
  };

  if (!exercises || exercises.length === 0) {
    return (
      <div className="fillthebox-game" ref={gameRef}>
        <h2>Nessun esercizio disponibile</h2>
      </div>
    );
  }

  if (!currentExercise) {
    return (
      <div className="fillthebox-game" ref={gameRef}>
        <h2>Errore nel caricamento dell'esercizio</h2>
      </div>
    );
  }

  return (
    <div className="fillthebox-game" ref={gameRef}>
      <div className="fillthebox-instructions">
        <h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#DF4634" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
          Completa le frasi con le parole corrette
        </h3>
        <p className="fillthebox-subtitle">Seleziona una parola tra le opzioni fornite per completare ciascuna frase in modo appropriato</p>
        <div className="fillthebox-progress">
          <p>Frase {currentExerciseIndex + 1} di {exercises.length}</p>
          <div className="fillthebox-progress-bar">
            <div 
              className="fillthebox-progress-bar-fill" 
              style={{width: `${(currentExerciseIndex + 1) / exercises.length * 100}%`}}
            ></div>
          </div>
        </div>
      </div>
      
      <div key={currentExercise.id} className="fillthebox-exercise">
        <h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          Completa la frase:
        </h3>
        <div className="fillthebox-sentence-container">
          <p className="fillthebox-sentence">
            {currentExercise.sentence.split('____').map((part, i, arr) => (
              <React.Fragment key={i}>
                {part}
                {i < arr.length - 1 && (
                  <span className={`fillthebox-blank ${answers[currentExercise.id] ? 'filled' : ''}`}>
                    {answers[currentExercise.id] ? 
                      currentExercise.options.find(opt => opt.id === answers[currentExercise.id])?.text 
                      : '________'}
                  </span>
                )}
              </React.Fragment>
            ))}
          </p>
        </div>
        
        <div className="fillthebox-options">
          <p className="fillthebox-options-label">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
              <line x1="8" y1="6" x2="21" y2="6"></line>
              <line x1="8" y1="12" x2="21" y2="12"></line>
              <line x1="8" y1="18" x2="21" y2="18"></line>
              <line x1="3" y1="6" x2="3.01" y2="6"></line>
              <line x1="3" y1="12" x2="3.01" y2="12"></line>
              <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
            Scegli una parola:
          </p>
          <div className="fillthebox-options-grid" ref={optionsGridRef}>
            {currentExercise.options.map(option => (
              <div 
                key={option.id}
                className={`fillthebox-option ${answers[currentExercise.id] === option.id ? 'selected' : ''}`}
                onClick={() => handleSelectOption(currentExercise.id, option.id)}
                data-option-id={option.id}
              >
                {option.text}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="fillthebox-navigation">
        <button 
          className="fillthebox-nav-button prev"
          onClick={handlePrevExercise}
          disabled={currentExerciseIndex === 0}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          Precedente
        </button>

        {currentExerciseIndex < exercises.length - 1 ? (
          <button 
            className="fillthebox-nav-button next"
            onClick={handleNextExercise}
            disabled={!answers[currentExercise.id]}
          >
            Successiva
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        ) : (
          <button 
            className={`fillthebox-button ${isLastGame ? 'submit-final' : ''}`}
            onClick={handleSubmit}
            disabled={!areAllExercisesAnswered() || isSubmitting}
          >
            {isLastGame ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
                Verifica Finale
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
                Verifica
              </>
            )}
          </button>
        )}
      </div>
      
      {!areAllExercisesAnswered() && currentExerciseIndex === exercises.length - 1 && (
        <p className="fillthebox-warning">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{marginRight: '8px', verticalAlign: 'middle'}}>
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
          Attenzione: non hai risposto a tutte le frasi ({Object.keys(answers).length} su {exercises.length})
        </p>
      )}
    </div>
  );
};

export default FillTheBoxGame; 