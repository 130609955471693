import React from 'react';
import { Box, Typography, Button, Paper, Divider } from '@mui/material';
import './styles/AccessDeniedMessage.css'; // Creiamo un nuovo file CSS per questo componente

const AccessDeniedMessage = ({ open, handleClose, isLoggedIn, profile }) => {
  if (!open) return null;

  return (
    <div className="accessDeniedContainer">
      <Paper className="accessDeniedContent" elevation={3}>
        <Typography variant="h5" gutterBottom>
          Accesso Risorsa Premium Negato
        </Typography>
        
        <Divider sx={{ mb: 2 }} />
        
        <Typography variant="body1" paragraph>
          Questa risorsa è accessibile solo agli utenti con un abbonamento mensile o annuale.
        </Typography>
        
        <Typography variant="body1" paragraph>
          Passa a un abbonamento premium per accedere a contenuti esclusivi.
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Chiudi
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default AccessDeniedMessage;