import React from 'react';
import './styles/ScoreSummary.css';

const ScoreSummary = ({ 
  gameData, 
  completedGames, 
  onShowGameDetails, 
  onBackToGames, 
  onResetGame
}) => {
  // Verifichiamo se abbiamo giochi completati
  if (!completedGames || completedGames.length === 0) {
    return (
      <div className="score-summary-empty">
        <h2>Nessun gioco completato</h2>
        <p>Non hai ancora completato alcun gioco in questa lezione.</p>
      </div>
    );
  }
  
  // Calcoliamo le statistiche globali
  const stats = {
    totalGames: completedGames.length,
    gamesWithScore: 0,
    totalScore: 0,
    correctAnswers: 0,
    totalQuestions: 0,
    byType: {}
  };
  
  // Popoliamo le statistiche con i dati dai giochi completati
  completedGames.forEach(game => {
    // Conversione esplicita a numero per sicurezza
    const score = Number(game.score);
    if (!isNaN(score)) {
      stats.totalScore += score;
      stats.gamesWithScore++;
    }
    
    // Conteggio delle risposte corrette e domande totali
    if (game.correctAnswers !== undefined && game.totalQuestions !== undefined) {
      stats.correctAnswers += Number(game.correctAnswers);
      stats.totalQuestions += Number(game.totalQuestions);
    }
    
    // Statistiche per tipo di gioco
    const gameType = game.type || game.game_type || 'unknown';
    
    if (!stats.byType[gameType]) {
      stats.byType[gameType] = {
        count: 0,
        totalScore: 0,
        correctAnswers: 0,
        totalQuestions: 0,
        games: []
      };
    }
    
    const typeStats = stats.byType[gameType];
    typeStats.count++;
    
    if (!isNaN(score)) {
      typeStats.totalScore += score;
    }
    
    if (game.correctAnswers !== undefined) {
      typeStats.correctAnswers += Number(game.correctAnswers);
    }
    
    if (game.totalQuestions !== undefined) {
      typeStats.totalQuestions += Number(game.totalQuestions);
    }
    
    // Aggiungiamo il gioco all'elenco per questo tipo
    typeStats.games.push(game);
  });
  
  // Calcolo della media dei punteggi
  const averageScore = stats.gamesWithScore > 0 
    ? Math.round(stats.totalScore / stats.gamesWithScore) 
    : 0;
    
  // Funzione per ottenere una classe CSS in base al punteggio
  const getScoreClass = (score) => {
    if (score >= 80) return 'score-high';
    if (score >= 60) return 'score-medium';
    return 'score-low';
  };
  
  // Funzione per ottenere un messaggio in base al punteggio
  const getScoreMessage = (score) => {
    if (score >= 90) return 'Eccellente!';
    if (score >= 80) return 'Molto buono!';
    if (score >= 70) return 'Buono';
    if (score >= 60) return 'Discreto';
    if (score >= 50) return 'Sufficiente';
    return 'Da migliorare';
  };

  return (
    <div className="score-summary-container">
      <h2>Riepilogo dei Punteggi</h2>
      
      {/* Sezione punteggio complessivo */}
      <div className="score-summary-total">
        <div className={`score-circle ${getScoreClass(averageScore)}`}>
          <span className="score-value">{averageScore}%</span>
          <span className="score-label">{getScoreMessage(averageScore)}</span>
        </div>
        
        <div className="score-summary-stats">
          <p>Hai completato {stats.totalGames} {stats.totalGames === 1 ? 'gioco' : 'giochi'}</p>
          
          {stats.totalQuestions > 0 && (
            <p className="score-summary-correct-answers">
              Risposte: <strong>{stats.correctAnswers} su {stats.totalQuestions}</strong> 
              ({Math.round((stats.correctAnswers / stats.totalQuestions) * 100)}%)
            </p>
          )}
        </div>
      </div>
      
      {/* Elenco dettagliato di tutti i giochi completati */}
      <div className="score-summary-details">
        <h3>Dettagli per gioco</h3>
        
        {/* Organizziamo i giochi in una griglia */}
        <div className="score-summary-games-grid">
          {gameData && gameData.game_data && gameData.game_data.chats && 
            gameData.game_data.chats.map((chat, index) => {
              // Cerchiamo il risultato per questo gioco
              const gameResult = completedGames.find(game => game.chatId === chat.id);
              
              // Calcoliamo se abbiamo informazioni sufficienti per visualizzare dettagli
              const hasDetails = gameResult && (
                (gameResult.questionResults && gameResult.questionResults.length > 0) ||
                (gameResult.pairResults && gameResult.pairResults.length > 0) ||
                (gameResult.arrangementResults && gameResult.arrangementResults.length > 0) ||
                (gameResult.wordResults && gameResult.wordResults.length > 0) ||
                (gameResult.groupResults && gameResult.groupResults.length > 0) ||
                (gameResult.results && gameResult.results.length > 0)
              );
              
              return (
                <div 
                  key={chat.id} 
                  className="score-summary-card clickable"
                  onClick={() => onShowGameDetails(gameResult, {...chat, index})}
                >
                  <div className="score-card-header">
                    <span className="score-card-number">{index + 1}</span>
                    <h3 className="score-card-title">
                      {gameResult && gameResult.game_type 
                        ? `${gameResult.game_type.charAt(0).toUpperCase()}${gameResult.game_type.slice(1)}` 
                        : chat.agent || "Gioco"}
                    </h3>
                  </div>
                  
                  <div className="score-card-content">
                    {gameResult ? (
                      <>
                        <div className={`score-card-score ${getScoreClass(Number(gameResult.score))}`}>
                          <span className="score-value">{Number(gameResult.score)}%</span>
                        </div>
                        
                        {gameResult.correctAnswers !== undefined && gameResult.totalQuestions !== undefined && (
                          <div className="score-card-answers">
                            <span>{gameResult.correctAnswers}/{gameResult.totalQuestions}</span>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="score-not-available">
                        <span>Non completato</span>
                      </div>
                    )}
                  </div>
                  
                  <div className="score-card-arrow">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      
      <div className="score-summary-actions">
        <button className="score-summary-button reset" onClick={onResetGame}>
          Ricomincia tutta la verifica
        </button>
      </div>
    </div>
  );
};

export default ScoreSummary; 