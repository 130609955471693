import React from 'react';
import QuizGame from './QuizGame';
import FillTheBoxGame from './FillTheBoxGame';
import SelectGroupGame from './SelectGroupGame';
import MatchPairsGame from './MatchPairsGame';
import RearrangeGame from './RearrangeGame';
import CrosswordGame from './CrosswordGame';
import ScripturaGame from './ScripturaGame';
import './styles/GameContainer.css';

const GameContainer = ({ 
  gameData, 
  currentGameIndex, 
  completedGames, 
  onGameComplete, 
  onSubmitFinalResults 
}) => {
  if (!gameData || !gameData.game_data || !gameData.game_data.chats || gameData.game_data.chats.length === 0) {
    return (
      <div className="no-data-container">
        <h2>Nessun gioco disponibile</h2>
        <p>Non ci sono giochi interattivi disponibili per questa lezione.</p>
      </div>
    );
  }

  if (currentGameIndex < 0 || currentGameIndex >= gameData.game_data.chats.length) {
    return (
      <div className="error-container">
        <h2>Errore</h2>
        <p>Indice del gioco non valido.</p>
      </div>
    );
  }

  const currentChat = gameData.game_data.chats[currentGameIndex];
  
  // Verifichiamo se questo gioco è già stato completato
  const completedGame = completedGames.find(game => game.chatId === currentChat.id);
  const isCompleted = !!completedGame;
  
  // Creiamo un contenitore per i dati del gioco
  // Aggiungiamo sempre chatId e tipo a prescindere dal tipo di gioco
  let gameContent = {
    chatId: currentChat.id,
    type: currentChat.agent,
    title: currentChat.title || '',
    position: currentGameIndex + 1,
    totalGames: gameData.game_data.chats.length
  };

  try {
    // Prepariamo i dati in base al tipo di gioco
    if (currentChat.agent === 'scrittura') {
      // Per i giochi di tipo testo/video non facciamo parsing JSON
      gameContent = { 
        ...gameContent,
        text: currentChat.last_message.text
      };
    } else {
      // Per tutti gli altri giochi, proviamo a parsificare il JSON
      try {
        // Estraiamo i dati specifici dal messaggio JSON
        const parsedContent = JSON.parse(currentChat.last_message.text);
        
        // Uniamo i dati specifici con i metadati
        gameContent = {
          ...gameContent,
          ...parsedContent
        };
      } catch (parseError) {
        console.error('Errore nel parsing del JSON del gioco:', parseError);
        return (
          <div className="game-error">
            <h2>Errore nel formato dei dati</h2>
            <p>I dati del gioco non sono nel formato corretto.</p>
            <p className="error-details">Dettagli: {parseError.message}</p>
          </div>
        );
      }
    }
    
    // Debug
    console.log(`Dati preparati per il gioco ${currentChat.agent} (ID: ${currentChat.id}):`, gameContent);

    // Verifichiamo se è l'ultimo gioco
    const isLastGame = currentGameIndex === gameData.game_data.chats.length - 1;

    // Controlliamo se tutti gli altri giochi tranne quello corrente sono già completati
    const areOtherGamesCompleted = gameData.game_data.chats.every((chat, index) => 
      index === currentGameIndex || completedGames.some(game => game.chatId === chat.id)
    );
    
    // Log di debug
    console.log(`Gioco ${currentGameIndex + 1}/${gameData.game_data.chats.length} - È l'ultimo: ${isLastGame}`);
    console.log('Tutti gli altri giochi sono già completati?', areOtherGamesCompleted);

    // Rendiamo il componente appropriato in base al tipo di gioco
    const renderGameComponent = () => {
      switch (currentChat.agent) {
        case 'quiz':
          // Otteniamo le risposte precedenti se il gioco è già stato completato
          const previousQuizAnswers = isCompleted ? completedGame.results : null;
          return <QuizGame 
            key={`quiz-${currentChat.id}`} 
            quizData={gameContent} 
            onComplete={onGameComplete} 
            isLastGame={isLastGame}
            shouldGoToSummary={isLastGame && areOtherGamesCompleted}
            previousAnswers={previousQuizAnswers}
          />;
        case 'fillthebox':
          return <FillTheBoxGame 
            gameData={gameContent} 
            onComplete={onGameComplete} 
            isLastGame={isLastGame} 
            onSubmitFinal={onSubmitFinalResults}
          />;
        case 'selectgroup':
          return <SelectGroupGame 
            gameData={gameContent} 
            onComplete={onGameComplete} 
            isLastGame={isLastGame} 
            onSubmitFinal={onSubmitFinalResults}
          />;
        case 'matchpairs':
          return <MatchPairsGame 
            gameData={gameContent} 
            onComplete={onGameComplete} 
            isLastGame={isLastGame} 
            onSubmitFinal={onSubmitFinalResults}
          />;
        case 'rearrange':
          return <RearrangeGame 
            gameData={gameContent} 
            onComplete={onGameComplete} 
            isLastGame={isLastGame}
            onSubmitFinal={onSubmitFinalResults} 
          />;
        case 'crossword':
          return <CrosswordGame gameData={gameContent} onComplete={onGameComplete} isLastGame={isLastGame} />;
        case 'scrittura':
          return <ScripturaGame gameData={gameContent} onComplete={onGameComplete} isLastGame={isLastGame} />;
        default:
          return (
            <div className="game-not-implemented">
              <h2>Gioco non implementato</h2>
              <p>Il gioco di tipo "{currentChat.agent}" non è ancora disponibile.</p>
            </div>
          );
      }
    };

    // Aggiungiamo il pulsante per navigare al riepilogo finale se tutti i giochi sono completati
    const areAllGamesCompleted = gameData.game_data.chats.every(
      chat => completedGames.some(game => game.chatId === chat.id)
    );
    
    console.log('Tutti i giochi sono completati?', areAllGamesCompleted);

    return (
      <div className="game-wrapper">
        {/* Il componente del gioco */}
        {renderGameComponent()}
        
        {/* Pulsante per il riepilogo finale se tutti i giochi sono completati */}
        {areAllGamesCompleted && (
          <div className="submit-final-container">
            <button 
              className="submit-final-button"
              onClick={onSubmitFinalResults}
            >
              Consegna
            </button>
          </div>
        )}
      </div>
    );
  } catch (error) {
    console.error("Errore nel rendering del gioco:", error);
    console.log("Dati ricevuti:", currentChat.last_message.text);
    
    return (
      <div className="game-error">
        <h2>Errore nel caricamento del gioco</h2>
        <p>Si è verificato un errore durante il caricamento del gioco.</p>
        <p className="error-details">Dettagli: {error.message}</p>
      </div>
    );
  }
};

export default GameContainer; 