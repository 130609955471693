// File: src/components/chat/AgentStart.jsx

import React, { useState, useEffect } from 'react'; 
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

/* ==============================
   AGENTI PRINCIPALI
   ============================== */
const primaryAgents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.png',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.png',
    agentKey: 'attività',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Idea e sviluppa laboratori e progetti stimolanti.',
    image: '/people/laboratorio.png',
    agentKey: 'laboratori',
  },
  {
    name: 'Scrittura',
    description: 'Assistenza nella redazione di UDA, Programmazioni e verbali.',
    image: '/people/scrittura.png',
    agentKey: 'scrittura',
  },
];

/* ==============================
   AGENTI SECONDARI
   ============================== */
const secondaryAgents = [
  {
    name: 'Minerva',
    description: 'Un&apos;AI esperta del sistema scolastico italiano e normative.',
    image: '/people/minerva.png',
    agentKey: 'minerva',
  },
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
  {
    name: 'ImmaginAI',
    description: 'Crea immagini generate con AI.',
    image: '/people/immaginai.png',
    agentKey: 'immaginai',
  },
  {
    name: 'Verifica Interattiva',
    description: 'Crea verifiche interattive per la tua classe.',
    image: '/people/giochi.png',
    agentKey: 'verifica',
  },
];

/* Temporaneamente commentato (Giochi Interattivi)
// Blocco "Giochi Interattivi"
const interactiveBlock = {
  name: 'Giochi Interattivi', 
  description: 'Crea quiz, esercizi di fill, select group e match pairs.',
  image: '/people/giochi.png',
  agentKey: 'interactiveBlock',
};

// Sotto-agenti "interattivi" (submenu)
const interactiveAgents = [
  {
    name: 'Quiz',
    description: 'Genera quiz per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Completa frase',
    description: 'Esercizi per completare frasi con opzioni multiple.',
    image: '/people/fillthebox.png',
    agentKey: 'fillthebox',
  },
  {
    name: 'Raggruppa',
    description: 'Crea liste di parole suddivise in gruppi tematici.',
    image: '/people/selectgroup.png',
    agentKey: 'selectgroup',
  },
  {
    name: 'Abbina coppie',
    description: 'Crea esercizi di abbinamento (match pairs).',
    image: '/people/matchpairs.png',
    agentKey: 'matchpairs',
  },
  {
    name: 'Riordina',
    description: 'Crea esercizi dove si devono riordinare le frasi.',
    image: '/people/rearrange.png',
    agentKey: 'rearrange',
  },
  {
    name: 'Cruciverba',
    description: 'Genera cruciverba personalizzati a partire da parole chiave o concetti',
    image: '/people/crossword.png',
    agentKey: 'crossword',
  },
];
*/

/* ======================
   Loading indicator
   ====================== */
const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

/* ======================
   Preload immagini
   ====================== */
const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

/* ===============================================
   COMPONENTE PRINCIPALE: AgentStart
   tutorialFocusOnAttivita => se true, 
      rendiamo cliccabile SOLO "Attività" 
      (agentKey: 'attività'),
      TUTTI gli altri, incluso "Giochi Interattivi", 
      sono oscurati.
   =============================================== */
const AgentStart = ({ 
  open, 
  onClose, 
  handleNewChat, 
  handleNewGlobalChat,   // <--- nuova prop: creazione GlobalChat
  tutorialFocusOnAttivita = false, 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);
  const [showInteractiveSubmenu, setShowInteractiveSubmenu] = useState(false);

  useEffect(() => {
    if (open) {
      const imageUrls = [
        ...primaryAgents.map((a) => a.image),
        ...secondaryAgents.map((a) => a.image),
        // interactiveBlock.image,
        // ...interactiveAgents.map((a) => a.image),
      ];
      preloadImages(imageUrls)
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.error('Errore caricamento immagini:', err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      setShowInteractiveSubmenu(false);
    }
  }, [open]);

  if (!open) return null;

  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '80%', md: '60%' },
          backgroundColor: 'transparent',
          zIndex: 3000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  // Funzione che crea i riquadri Paper per i vari agenti
  const renderAgentPaper = (agent, handleClick) => {
    const isDisabled =
      tutorialFocusOnAttivita && agent.agentKey !== 'attività';

    return (
      <Paper
        elevation={3}
        sx={{
          padding: '0.75rem',
          borderRadius: '1rem',
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          transition: 'background-color 0.3s ease',

          // Se disabilitato => "oscuriamo" e disabilitiamo i click
          opacity: isDisabled ? 0.3 : 1,
          pointerEvents: isDisabled ? 'none' : 'auto',
          cursor: isDisabled ? 'default' : 'pointer',

          // Se l'agente è "attività" e tutorialFocusOnAttivita=true => bordo pulsante
          ...(tutorialFocusOnAttivita && agent.agentKey === 'attività'
            ? {
                boxShadow: '0 0 0 4px #DF4634',
                animation: 'pulse-border 1.5s infinite',
                '@keyframes pulse-border': {
                  '0%': { boxShadow: '0 0 0 4px rgba(223,70,52,0.5)' },
                  '50%': { boxShadow: '0 0 0 8px rgba(223,70,52,0.2)' },
                  '100%': { boxShadow: '0 0 0 4px rgba(223,70,52,0.5)' },
                },
              }
            : {}),

          '&:hover': {
            backgroundColor: isDisabled
              ? 'transparent'
              : 'rgba(223, 70, 52, 0.1)',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(agent.agentKey);
        }}
      >
        <Box
          component="img"
          src={agent.image}
          alt={agent.name}
          sx={{
            width: '3.3rem',
            height: '3.3rem',
            margin: '0 auto 0rem',
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            marginBottom: '0.1rem',
            fontSize: '0.9rem',
            lineHeight: '1.2',
          }}
        >
          {agent.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#6B7280',
            flexGrow: 1,
            fontSize: '0.75rem',
            lineHeight: '1.2',
          }}
        >
          {agent.description}
        </Typography>
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(agent.agentKey);
          }}
          sx={{
            marginTop: '0.4rem',
            backgroundColor: '#DF4634',
            color: '#FFFFFF',
            borderRadius: '1rem',
            textTransform: 'none',
            fontSize: '0.75rem',
            padding: '0.5rem 1rem',
            '&:hover': {
              backgroundColor: '#E57373',
            },
            // Se disabilitato => disabilitiamo pure il bottone
            opacity: isDisabled ? 0.5 : 1,
            pointerEvents: isDisabled ? 'none' : 'auto',
          }}
        >
          Inizia
        </Button>
      </Paper>
    );
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '95%', sm: '80%', md: '65%' },
        backgroundColor: '#FFFFFF',
        border: '1px solid #DF4634',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '1.875rem',
        padding: isMobile ? '1rem' : '2rem',
        zIndex: 3000,
        maxHeight: '90vh',
        overflowY: 'auto',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* HEADER */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginBottom: '1rem' 
        }}
      >
        {!showInteractiveSubmenu && (
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Scegli l'esperto di cui hai bisogno
          </Typography>
        )}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {tutorialFocusOnAttivita && !showInteractiveSubmenu && (
        <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
          <Typography
            sx={{
              color: '#DF4634',
              fontWeight: 'bold',
              marginBottom: '1rem',
              animation: 'bounceText 1.5s infinite',
              '@keyframes bounceText': {
                '0%, 100%': { transform: 'translateY(0)' },
                '50%': { transform: 'translateY(-5px)' },
              },
            }}
          >
            Clicca su "Attività" per iniziare il tutorial operativo!
          </Typography>
        </Box>
      )}

      {/* CONTENUTO PRINCIPALE */}
      {!showInteractiveSubmenu && (
        <>
          <Grid container spacing={2} alignItems="stretch">
            {primaryAgents.map((agent) => (
              <Grid 
                item 
                xs={6} 
                sm={4} 
                md={2.4}
                key={agent.agentKey}
                sx={{ display: 'flex' }}
              >
                {renderAgentPaper(agent, (agentKey) => {
                  handleNewChat(agentKey);
                  onClose();
                })}
              </Grid>
            ))}
          </Grid>

          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>

          {/* SECONDA RIGA (agents secondari + Giochi Interattivi) */}
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            justifyContent="center"
            sx={{ paddingBottom: '1rem' }}
          >
            {secondaryAgents.map((agent) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={2.4}
                key={agent.agentKey}
                sx={{ display: 'flex' }}
              >
                {renderAgentPaper(agent, (agentKey) => {
                  // Se l'agente è "verifica", creiamo una GlobalChat
                  if (agentKey === 'verifica' && handleNewGlobalChat) {
                    handleNewGlobalChat();
                  } else {
                    handleNewChat(agentKey);
                  }
                  onClose();
                })}
              </Grid>
            ))}

            {/* Temporaneamente commentato (Giochi Interattivi)
            <Grid
              item
              xs={6}
              sm={4}
              md={2.4}
              key={interactiveBlock.agentKey}
              sx={{ display: 'flex' }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: '0.75rem',
                  borderRadius: '1rem',
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'background-color 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(223, 70, 52, 0.1)',
                  },
                  // Se tutorialFocusOnAttivita => disabilitiamo pure "Giochi Interattivi"
                  opacity: tutorialFocusOnAttivita ? 0.3 : 1,
                  pointerEvents: tutorialFocusOnAttivita ? 'none' : 'auto',
                }}
                onClick={() => {
                  setShowInteractiveSubmenu(true);
                }}
              >
                <Box
                  component="img"
                  src={interactiveBlock.image}
                  alt={interactiveBlock.name}
                  sx={{
                    width: '3.3rem',
                    height: '3.3rem',
                    margin: '0 auto 0rem',
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    marginBottom: '0.1rem',
                    fontSize: '0.9rem',
                    lineHeight: '1.2',
                  }}
                >
                  {interactiveBlock.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#6B7280',
                    flexGrow: 1,
                    fontSize: '0.75rem',
                    lineHeight: '1.2',
                  }}
                >
                  {interactiveBlock.description}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: '0.4rem',
                    backgroundColor: '#DF4634',
                    color: '#FFFFFF',
                    borderRadius: '1rem',
                    textTransform: 'none',
                    fontSize: '0.75rem',
                    padding: '0.5rem 1rem',
                    '&:hover': {
                      backgroundColor: '#E57373',
                    },
                    opacity: tutorialFocusOnAttivita ? 0.5 : 1,
                    pointerEvents: tutorialFocusOnAttivita ? 'none' : 'auto',
                  }}
                >
                  Apri
                </Button>
              </Paper>
            </Grid>
            */}
          </Grid>

          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>

          {/* NUOVO BLOCCO: VERIFICA INTERATTIVA (CREA GLOBALCHAT) */}
          {/* Rimosso - Ora è integrato nella seconda riga di agenti */}
        </>
      )}

      {/* SUBMENU GIOCHI INTERATTIVI - Temporaneamente commentato */}
      {/* {showInteractiveSubmenu && (
        <>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
            Scegli il tipo di gioco interattivo
          </Typography>
          <Grid container spacing={2}>
            {interactiveAgents.map((agent) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={2.4}
                key={agent.agentKey}
                sx={{ display: 'flex' }}
              >
                {renderAgentPaper(agent, (agentKey) => {
                  handleNewChat(agentKey);
                  onClose();
                })}
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 3, textAlign: 'right' }}>
            <Button
              variant="outlined"
              onClick={() => setShowInteractiveSubmenu(false)}
              sx={{
                textTransform: 'none',
                borderColor: '#DF4634',
                color: '#DF4634',
                borderRadius: '1rem',
                '&:hover': {
                  backgroundColor: 'rgba(223,70,52,0.05)',
                },
              }}
            >
              Torna Indietro
            </Button>
          </Box>
        </>
      )} */}
    </Box>
  );
};

export default AgentStart;
