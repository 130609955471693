// File: src/components/SubscriptionPlan.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';

const SubscriptionPlan = ({
  title,
  description,
  price,
  planType,
  onPurchase,
  isActive,
  endDate,
  paymentMethodDisplay,
  isPurchaseDisabled,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#F9F9F9',
        boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
        borderRadius: '1.875rem',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      {/* Se vogliamo evidenziare di più il piano Annuale */}
      {planType === 'annual' && !isActive && (
        <Box
          sx={{
            position: 'absolute',
            top: '-1.5rem',
            right: '-1rem',
            backgroundColor: '#DF4634',
            color: '#FFFFFF',
            padding: '0.5rem 1.25rem',
            borderRadius: '1rem',
            fontWeight: 'bold',
            transform: 'rotate(10deg)',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
        >
          Miglior Offerta
        </Box>
      )}

      <Typography
        variant="h5"
        sx={{
          marginBottom: '1rem',
          textAlign: 'center',
          color: '#1D1F23',
          fontSize: '1.5rem',
          fontFamily: 'Inter',
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          marginBottom: '1rem',
          textAlign: 'center',
          color: '#2B2E34',
          fontSize: '1rem',
          fontFamily: 'Inter',
          fontWeight: 400,
        }}
      >
        {description}
      </Typography>

      <Typography
        variant="h6"
        sx={{
          marginBottom: '1rem',
          textAlign: 'center',
          color: '#1D1F23',
          fontSize: '1.25rem',
          fontFamily: 'Inter',
          fontWeight: 700,
        }}
      >
        {price}
      </Typography>

      {/* Informazione esclusione mesi estivi solo per piano annual e quando non attivo */}
      {planType === 'annual' && (
        <Box
          sx={{
            backgroundColor: '#FFEBEE',
            border: '1px solid #DF4634',
            borderRadius: '0.75rem',
            padding: '1rem',
            marginBottom: '1rem',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#DF4634',
              fontSize: '0.875rem',
              fontFamily: 'Inter',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            👉 Vuoi che il tuo abbonamento annuale copra solo i mesi scolastici, saltando l'estate? Dopo l’acquisto, scrivici a hello@minervaeducationai.it e ricalibreremo la durata per garantirti 12 mesi 🎓✨
          </Typography>
        </Box>
      )}

      {isActive && endDate && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: '1rem',
            textAlign: 'center',
            color: '#1D1F23',
            fontSize: '0.875rem',
            fontFamily: 'Inter',
            fontWeight: 500,
          }}
        >
          Scade il: {new Date(endDate).toLocaleDateString()}
        </Typography>
      )}

      {isActive && paymentMethodDisplay && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: '1rem',
            textAlign: 'center',
            color: '#1D1F23',
            fontSize: '0.875rem',
            fontFamily: 'Inter',
            fontWeight: 500,
          }}
        >
          Metodo di pagamento: {paymentMethodDisplay}
        </Typography>
      )}

      <Button
        variant="contained"
        onClick={onPurchase}
        sx={{
          width: '100%',
          backgroundColor: isActive ? '#F3F6FB' : '#2B2E34',
          color: isActive ? '#62140B' : '#FFFFFF',
          boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.2)',
          borderRadius: '1.875rem',
          padding: '0.75rem',
          fontSize: '1rem',
          fontFamily: 'Inter',
          fontWeight: 600,
          '&:hover': {
            backgroundColor: isActive ? '#E0E0E0' : '#1e1f22',
          },
          '&.Mui-disabled': {
            backgroundColor: isActive ? '#1976d2' : '#2B2E34',
            color: '#FFFFFF',
            opacity: 1,
          },
        }}
        disabled={isActive || isPurchaseDisabled}
      >
        {isActive ? 'Piano Attivo' : planType === 'free' ? 'Prova Gratis' : 'Acquista'}
      </Button>
    </Box>
  );
};

SubscriptionPlan.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
  onPurchase: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  endDate: PropTypes.string,
  paymentMethodDisplay: PropTypes.string,
  isPurchaseDisabled: PropTypes.bool,
};

SubscriptionPlan.defaultProps = {
  endDate: null,
  paymentMethodDisplay: null,
  isPurchaseDisabled: false,
};

export default SubscriptionPlan;
