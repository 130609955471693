import { useState, useEffect, useCallback } from 'react';
import { 
  searchTeachingDocuments, 
  filterTeachingDocuments,
  getArgomentiTags,
  getAnniScolasticiTags,
  getTipiMaterialeTags
} from '../api/documents/teachingArchiveApi';

const useTeachingSearch = () => {
  // Stato per i documenti filtrati/cercati
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  // Stato per i filtri e parametri di ricerca
  const [searchParams, setSearchParams] = useState({
    q: '',                  // Testo di ricerca
    author: '',             // Nome autore
    description: '',        // Descrizione della risorsa
    premium: null,          // null = tutti, true = solo premium, false = solo non premium
    argomenti: [],          // IDs degli argomenti selezionati
    anni_scolastici: [],    // IDs degli anni scolastici selezionati
    tipi_materiale: [],     // IDs dei tipi di materiale selezionati
    page: 1,                // Pagina corrente
    page_size: 12,          // Elementi per pagina
    ordering: 'newest',     // Ordinamento predefinito
  });
  
  // Stato per le opzioni di filtro disponibili
  const [filterOptions, setFilterOptions] = useState({
    argomenti: [],
    anni_scolastici: [],
    tipi_materiale: [],
  });
  
  // Carica le opzioni di filtro quando il componente viene montato
  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        setLoading(true);
        const [argomenti, anniScolastici, tipiMateriale] = await Promise.all([
          getArgomentiTags(),
          getAnniScolasticiTags(),
          getTipiMaterialeTags()
        ]);
        
        setFilterOptions({
          argomenti: argomenti,
          anni_scolastici: anniScolastici,
          tipi_materiale: tipiMateriale,
        });
      } catch (err) {
        setError(err);
        console.error('Errore nel caricamento delle opzioni di filtro:', err);
      } finally {
        setLoading(false);
      }
    };
    
    loadFilterOptions();
  }, []);
  
  // Funzione per eseguire la ricerca/filtro
  const searchDocuments = useCallback(async (params = searchParams) => {
    setLoading(true);
    setError(null);
    try {
      // Determina se utilizzare la ricerca o il filtro semplice
      let response;
      if (params.q || params.author || params.ordering || 
          Object.keys(params).some(key => key.startsWith('has_'))) {
        // Usa la ricerca avanzata
        response = await searchTeachingDocuments(params);
      } else if (params.argomenti.length > 0 || params.anni_scolastici.length > 0 || 
                 params.tipi_materiale.length > 0 || params.premium !== null) {
        // Usa il filtro semplice se ci sono tag selezionati o se c'è un filtro premium attivo
        response = await filterTeachingDocuments(params);
      } else {
        // Senza filtri, usa searchTeachingDocuments con paginazione
        response = await searchTeachingDocuments({
          page: params.page,
          page_size: params.page_size,
          ordering: params.ordering,
          premium: params.premium
        });
      }
      
      // Aggiorna lo stato con i risultati
      if (response && response.results) {
        setDocuments(response.results);
        setTotalCount(response.count);
        setTotalPages(Math.ceil(response.count / params.page_size));
        setCurrentPage(params.page);
      } else {
        setDocuments([]);
        setTotalCount(0);
        setTotalPages(1);
      }
    } catch (err) {
      setError(err);
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);
  
  // Aggiorna automaticamente la ricerca quando cambiano i parametri
  useEffect(() => {
    searchDocuments(searchParams);
  }, [searchParams, searchDocuments]);
  
  // Funzione per aggiornare i parametri di ricerca
  const updateSearchParams = (newParams) => {
    setSearchParams(prev => ({
      ...prev,
      ...newParams,
      // Reimposta la pagina a 1 se cambiano i parametri di ricerca (tranne se stiamo aggiornando esplicitamente la pagina)
      page: newParams.page || 1
    }));
  };
  
  // Funzione per ripristinare i filtri
  const resetFilters = () => {
    setSearchParams({
      q: '',
      author: '',
      description: '',
      premium: null,
      argomenti: [],
      anni_scolastici: [],
      tipi_materiale: [],
      page: 1,
      page_size: 12,
      ordering: 'newest',
    });
  };
  
  // Funzioni helper per gestire i filtri comuni
  const toggleFilter = (filterType, id) => {
    setSearchParams(prev => {
      const currentFilter = [...prev[filterType]];
      const index = currentFilter.indexOf(id);
      
      if (index === -1) {
        // Aggiungi il filtro
        return {
          ...prev,
          [filterType]: [...currentFilter, id],
          page: 1 // Reimposta la pagina a 1 quando cambia un filtro
        };
      } else {
        // Rimuovi il filtro
        currentFilter.splice(index, 1);
        return {
          ...prev,
          [filterType]: currentFilter,
          page: 1 // Reimposta la pagina a 1 quando cambia un filtro
        };
      }
    });
  };
  
  return {
    documents,
    loading,
    error,
    searchParams,
    filterOptions,
    totalCount,
    currentPage,
    totalPages,
    updateSearchParams,
    resetFilters,
    toggleFilter,
    searchDocuments
  };
};

export default useTeachingSearch; 