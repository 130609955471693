import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Radio,
  TextField
} from '@mui/material';

/**
 * Dialog per l'inserimento di testo manuale o generato da AI
 */
const TextDialog = ({
  open,
  onClose,
  textSource,
  onChangeTextSource,
  customTextTitle,
  onChangeCustomTextTitle,
  customText,
  onChangeCustomText,
  onSubmitCustomText,
  onOpenTextChat
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        }
      }}
    >
      <DialogTitle sx={{ 
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '1.5rem',
        fontWeight: 'bold'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box 
            component="img" 
            src="/people/scrittura.png" 
            alt="Testo" 
            sx={{ width: '30px', height: '30px' }} 
          />
          <Typography variant="h6">Crea Nuovo Testo</Typography>
        </Box>
      </DialogTitle>
      
      <DialogContent sx={{ padding: '2rem' }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: {xs: 'column', md: 'row'}, 
          gap: 3, 
          mb: 3,
          alignItems: 'stretch'
        }}>
          {/* Opzione AI */}
          <Card 
            variant="outlined" 
            sx={{ 
              flex: 1,
              borderRadius: '0.8rem',
              borderColor: textSource === 'ai' ? '#2196f3' : '#e0e0e0',
              borderWidth: textSource === 'ai' ? '2px' : '1px',
              boxShadow: textSource === 'ai' ? '0 4px 12px rgba(33,150,243,0.2)' : 'none',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#2196f3',
                boxShadow: '0 4px 12px rgba(33,150,243,0.2)',
              }
            }}
            onClick={() => onChangeTextSource('ai')}
          >
            <CardContent sx={{ padding: '1.5rem', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Radio 
                  checked={textSource === 'ai'} 
                  onChange={() => onChangeTextSource('ai')}
                  color="primary"
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
                  Genera con AI
                </Typography>
              </Box>
              
              <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                Lascia che l'intelligenza artificiale generi un testo per te in base alle tue indicazioni.
              </Typography>
              
              <Box sx={{ 
                backgroundColor: '#f5f9ff', 
                padding: '1rem', 
                borderRadius: '0.5rem',
                my: 2,
                borderLeft: '4px solid #2196f3'
              }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                  Potrai specificare il tipo di testo, l'argomento, la lunghezza e qualsiasi altro dettaglio.
                </Typography>
              </Box>
              
              {textSource === 'ai' && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={onOpenTextChat}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Continua
                </Button>
              )}
            </CardContent>
          </Card>
          
          {/* Opzione testo manuale */}
          <Card 
            variant="outlined" 
            sx={{ 
              flex: 1,
              borderRadius: '0.8rem',
              borderColor: textSource === 'manual' ? '#ff9800' : '#e0e0e0',
              borderWidth: textSource === 'manual' ? '2px' : '1px',
              boxShadow: textSource === 'manual' ? '0 4px 12px rgba(255,152,0,0.2)' : 'none',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#ff9800',
                boxShadow: '0 4px 12px rgba(255,152,0,0.2)',
              }
            }}
            onClick={() => onChangeTextSource('manual')}
          >
            <CardContent sx={{ padding: '1.5rem', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Radio 
                  checked={textSource === 'manual'} 
                  onChange={() => onChangeTextSource('manual')}
                  color="warning"
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
                  Inserisci tuo testo
                </Typography>
              </Box>
              
              <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                Utilizza un testo che hai già preparato o scritto in precedenza.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        
        {/* Sezione per l'inserimento del testo manuale */}
        {textSource === 'manual' && (
          <Box sx={{ 
            mt: 3, 
            pt: 3, 
            borderTop: '1px solid #e0e0e0',
            animation: 'fadeIn 0.3s ease-in-out',
            '@keyframes fadeIn': {
              '0%': { opacity: 0, transform: 'translateY(10px)' },
              '100%': { opacity: 1, transform: 'translateY(0)' }
            }
          }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              Inserisci il tuo testo
            </Typography>
            
            <TextField
              label="Titolo o descrizione breve (opzionale)"
              value={customTextTitle}
              onChange={(e) => onChangeCustomTextTitle(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Es: Testo sulla storia romana"
              InputProps={{
                sx: { borderRadius: '0.5rem' }
              }}
              helperText="Questo testo verrà mostrato come tuo messaggio"
            />
            
            <TextField
              label="Contenuto del testo"
              value={customText}
              onChange={(e) => onChangeCustomText(e.target.value)}
              multiline
              rows={10}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              placeholder="Incolla o scrivi qui il tuo testo..."
              InputProps={{
                sx: { 
                  borderRadius: '0.5rem',
                  fontFamily: 'inherit',
                  '& .MuiInputBase-input': {
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    lineHeight: '1.5'
                  }
                }
              }}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& textarea': {
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap'
                  }
                }
              }}
              helperText="Questo testo verrà utilizzato come risposta dell'assistente"
            />
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button 
                onClick={onClose} 
                variant="outlined"
                sx={{ mr: 2 }}
              >
                Annulla
              </Button>
              <Button 
                onClick={onSubmitCustomText} 
                variant="contained" 
                color="warning"
                disabled={!customText.trim()}
              >
                Salva testo
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TextDialog;