import React, { useState } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Chip,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Divider,
  Tabs,
  Tab,
  Paper,
  Collapse,
  useMediaQuery,
  useTheme,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SchoolIcon from '@mui/icons-material/School';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import StarIcon from '@mui/icons-material/Star';
import './styles/SearchBar.css';

// Definizione del tema colore principale
const ACCENT_COLOR = '#df4634';

const SearchBar = ({ 
  searchParams, 
  filterOptions, 
  updateSearchParams, 
  resetFilters, 
  totalCount 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  // Stato per la gestione dei filtri
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isOrderingOpen, setIsOrderingOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState({...searchParams});
  const [isInputHovered, setIsInputHovered] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  
  // Funzioni per gestire l'hover sull'input
  const handleInputMouseEnter = () => {
    setIsInputHovered(true);
  };
  
  const handleInputMouseLeave = () => {
    setIsInputHovered(false);
  };
  
  // Gestori per i filtri
  const handleFilterClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
    if (!isFiltersOpen) {
      setTempFilters({...searchParams});
      setIsOrderingOpen(false);
    }
  };
  
  const handleOrderingClick = () => {
    setIsOrderingOpen(!isOrderingOpen);
    if (!isOrderingOpen) {
      setIsFiltersOpen(false);
    }
  };
  
  const handleApplyFilters = () => {
    updateSearchParams(tempFilters);
    setIsFiltersOpen(false);
  };
  
  // Handlers per i vari tipi di filtri
  const handleSearchTextChange = (e) => {
    // Utilizziamo solo il parametro 'q' per la ricerca generale
    // che include titolo, sottotitolo, descrizione e autore
    updateSearchParams({ 
      q: e.target.value,
      author: '' // Reset del filtro autore per evitare conflitti
    });
  };
  
  // Nuovo handler per la ricerca specifica per autore
  const handleAuthorSearch = (authorName) => {
    updateSearchParams({
      q: '', // Reset della ricerca generale
      author: authorName
    });
  };
  
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Il submit del form aggiorna già i filtri grazie all'onChange
  };
  
  const handleOrderingChange = (event) => {
    updateSearchParams({ ordering: event.target.value });
    setIsOrderingOpen(false);
  };
  
  const handlePremiumChange = (value) => {
    setTempFilters(prev => ({
      ...prev,
      premium: value
    }));
  };
  
  const handleTagToggle = (filterType, id) => {
    setTempFilters(prev => {
      const currentFilter = [...prev[filterType]];
      const index = currentFilter.indexOf(id);
      
      if (index === -1) {
        return {
          ...prev,
          [filterType]: [...currentFilter, id]
        };
      } else {
        currentFilter.splice(index, 1);
        return {
          ...prev,
          [filterType]: currentFilter
        };
      }
    });
  };
  
  // Gestione dei tab
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Calcola il totale dei filtri attivi
  const activeFiltersCount = () => {
    let count = 0;
    if (searchParams.premium !== null) count++;
    count += searchParams.argomenti.length;
    count += searchParams.anni_scolastici.length;
    count += searchParams.tipi_materiale.length;
    return count;
  };
  
  // Rimuovi un filtro specifico
  const removeFilter = (type, id) => {
    if (type === 'premium') {
      updateSearchParams({ premium: null });
    } else {
      const newFilters = [...searchParams[type]];
      const index = newFilters.indexOf(id);
      if (index !== -1) {
        newFilters.splice(index, 1);
        updateSearchParams({ [type]: newFilters });
      }
    }
  };
  
  // Renderizza i chip per i filtri attivi
  const renderActiveFilters = () => {
    const filters = [];
    
    // Chip per premium
    if (searchParams.premium !== null) {
      filters.push(
        <Chip 
          key="premium"
          label={searchParams.premium ? "Premium" : "Standard"}
          onDelete={() => removeFilter('premium')}
          className="searchBar_activeFilterChip"
        />
      );
    }
    
    // Chip per argomenti
    searchParams.argomenti.forEach(id => {
      const argomento = filterOptions.argomenti.find(a => a.id === id);
      if (argomento) {
        filters.push(
          <Chip 
            key={`argomento-${id}`}
            label={argomento.nome}
            onDelete={() => removeFilter('argomenti', id)}
            className="searchBar_activeFilterChip"
          />
        );
      }
    });
    
    // Chip per anni scolastici
    searchParams.anni_scolastici.forEach(id => {
      const anno = filterOptions.anni_scolastici.find(a => a.id === id);
      if (anno) {
        filters.push(
          <Chip 
            key={`anno-${id}`}
            label={anno.nome}
            onDelete={() => removeFilter('anni_scolastici', id)}
            className="searchBar_activeFilterChip"
          />
        );
      }
    });
    
    // Chip per tipi di materiale
    searchParams.tipi_materiale.forEach(id => {
      const tipo = filterOptions.tipi_materiale.find(t => t.id === id);
      if (tipo) {
        filters.push(
          <Chip 
            key={`tipo-${id}`}
            label={tipo.nome}
            onDelete={() => removeFilter('tipi_materiale', id)}
            className="searchBar_activeFilterChip"
          />
        );
      }
    });
    
    return filters;
  };
  
  // Renderizza i contenuti dei filtri in base al tab attivo
  const renderFilterContent = () => {
    switch (activeTab) {
      case 0: // Tipo (Premium/Standard)
        return (
          <Box className="searchBar_tabContent">
            <RadioGroup 
              row={!isMobile}
              value={tempFilters.premium === null ? 'all' : tempFilters.premium ? 'premium' : 'standard'}
              onChange={(e) => {
                const value = e.target.value;
                handlePremiumChange(value === 'all' ? null : value === 'premium');
              }}
            >
              <FormControlLabel value="all" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Tutti" />
              <FormControlLabel value="premium" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Premium" />
              <FormControlLabel value="standard" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Standard" />
            </RadioGroup>
          </Box>
        );
      
      case 1: // Argomenti
        return (
          <Box className="searchBar_tabContent">
            <Box className="searchBar_horizontalCheckboxGroup">
              {filterOptions.argomenti.map(argomento => (
                <Tooltip title={argomento.nome} key={argomento.id}>
                  <Chip
                    label={argomento.nome}
                    clickable
                    onClick={() => handleTagToggle('argomenti', argomento.id)}
                    color={tempFilters.argomenti.includes(argomento.id) ? "primary" : "default"}
                    sx={{
                      backgroundColor: tempFilters.argomenti.includes(argomento.id) ? 'rgba(223, 70, 52, 0.12)' : 'transparent',
                      color: tempFilters.argomenti.includes(argomento.id) ? ACCENT_COLOR : 'inherit',
                      borderColor: tempFilters.argomenti.includes(argomento.id) ? ACCENT_COLOR : 'rgba(0, 0, 0, 0.23)',
                      '&:hover': {
                        backgroundColor: 'rgba(223, 70, 52, 0.08)',
                      },
                      m: 0.5
                    }}
                    variant={tempFilters.argomenti.includes(argomento.id) ? "filled" : "outlined"}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        );
        
      case 2: // Anni scolastici
        return (
          <Box className="searchBar_tabContent">
            <Box className="searchBar_horizontalCheckboxGroup">
              {filterOptions.anni_scolastici.map(anno => (
                <Tooltip title={anno.nome} key={anno.id}>
                  <Chip
                    label={anno.nome}
                    clickable
                    onClick={() => handleTagToggle('anni_scolastici', anno.id)}
                    color={tempFilters.anni_scolastici.includes(anno.id) ? "primary" : "default"}
                    sx={{
                      backgroundColor: tempFilters.anni_scolastici.includes(anno.id) ? 'rgba(223, 70, 52, 0.12)' : 'transparent',
                      color: tempFilters.anni_scolastici.includes(anno.id) ? ACCENT_COLOR : 'inherit',
                      borderColor: tempFilters.anni_scolastici.includes(anno.id) ? ACCENT_COLOR : 'rgba(0, 0, 0, 0.23)',
                      '&:hover': {
                        backgroundColor: 'rgba(223, 70, 52, 0.08)',
                      },
                      m: 0.5
                    }}
                    variant={tempFilters.anni_scolastici.includes(anno.id) ? "filled" : "outlined"}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        );
        
      case 3: // Tipi di materiale
        return (
          <Box className="searchBar_tabContent">
            <Box className="searchBar_horizontalCheckboxGroup">
              {filterOptions.tipi_materiale.map(tipo => (
                <Tooltip title={tipo.nome} key={tipo.id}>
                  <Chip
                    label={tipo.nome}
                    clickable
                    onClick={() => handleTagToggle('tipi_materiale', tipo.id)}
                    color={tempFilters.tipi_materiale.includes(tipo.id) ? "primary" : "default"}
                    sx={{
                      backgroundColor: tempFilters.tipi_materiale.includes(tipo.id) ? 'rgba(223, 70, 52, 0.12)' : 'transparent',
                      color: tempFilters.tipi_materiale.includes(tipo.id) ? ACCENT_COLOR : 'inherit',
                      borderColor: tempFilters.tipi_materiale.includes(tipo.id) ? ACCENT_COLOR : 'rgba(0, 0, 0, 0.23)',
                      '&:hover': {
                        backgroundColor: 'rgba(223, 70, 52, 0.08)',
                      },
                      m: 0.5
                    }}
                    variant={tempFilters.tipi_materiale.includes(tipo.id) ? "filled" : "outlined"}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        );
        
      default:
        return null;
    }
  };
  
  return (
    <Box className="searchBar_mainContainer">
      {/* Barra di ricerca */}
      <Box className="searchBar_inputWrapper">
        <form onSubmit={handleSearchSubmit} className="searchBar_form">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Cerca per titolo, descrizione, autore..."
            value={searchParams.q}
            onChange={handleSearchTextChange}
            className="searchBar_input"
            onMouseEnter={handleInputMouseEnter}
            onMouseLeave={handleInputMouseLeave}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ 
                    color: ACCENT_COLOR,
                    transform: isInputHovered ? 'scale(1.1)' : 'scale(1)',
                    transition: 'transform 0.3s ease'
                  }} />
                </InputAdornment>
              ),
              endAdornment: searchParams.q && (
                <InputAdornment position="end">
                  <IconButton 
                    size="small" 
                    onClick={() => updateSearchParams({ q: '' })}
                    aria-label="Cancella ricerca"
                    sx={{ color: ACCENT_COLOR }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        
        {/* Bottoni per filtri e ordinamento */}
        <Box className="searchBar_actionButtons">
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            endIcon={isFiltersOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={handleFilterClick}
            className="searchBar_filterButton"
            color={activeFiltersCount() > 0 ? "primary" : "inherit"}
            sx={{ 
              borderColor: ACCENT_COLOR, 
              color: ACCENT_COLOR,
              backgroundColor: isFiltersOpen ? 'rgba(223, 70, 52, 0.08)' : 'transparent'
            }}
          >
            Filtri {activeFiltersCount() > 0 && `(${activeFiltersCount()})`}
          </Button>
          
          <Button
            variant="outlined"
            startIcon={<SortIcon />}
            endIcon={isOrderingOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={handleOrderingClick}
            className="searchBar_sortButton"
            sx={{ 
              borderColor: ACCENT_COLOR, 
              color: ACCENT_COLOR,
              backgroundColor: isOrderingOpen ? 'rgba(223, 70, 52, 0.08)' : 'transparent'
            }}
          >
            Ordina
          </Button>
        </Box>
      </Box>
      
      {/* Pannello dei filtri espandibile */}
      <Collapse in={isFiltersOpen} timeout="auto" unmountOnExit>
        <Paper className="searchBar_expandedFilterPanel" elevation={0}>
          <Box className="searchBar_filterTabs">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant={isTablet ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              aria-label="filtri risorse"
              sx={{
                '& .Mui-selected': {
                  color: `${ACCENT_COLOR} !important`,
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: ACCENT_COLOR,
                },
              }}
            >
              <Tab 
                icon={<StarIcon />} 
                label="Tipo" 
                sx={{ textTransform: 'none', minWidth: isMobile ? '80px' : '120px' }}
              />
              <Tab 
                icon={<CategoryIcon />} 
                label="Argomenti" 
                sx={{ textTransform: 'none', minWidth: isMobile ? '80px' : '120px' }}
              />
              <Tab 
                icon={<SchoolIcon />} 
                label="Anni scolastici" 
                sx={{ textTransform: 'none', minWidth: isMobile ? '80px' : '120px' }}
              />
              <Tab 
                icon={<ClassIcon />} 
                label="Tipi di materiale" 
                sx={{ textTransform: 'none', minWidth: isMobile ? '80px' : '120px' }}
              />
            </Tabs>
          </Box>
          
          <Box className="searchBar_filterContent">
            {renderFilterContent()}
          </Box>
          
          <Divider />
          
          <Box className="searchBar_filterActions">
            <Button 
              onClick={() => {
                setTempFilters({
                  ...tempFilters,
                  premium: null,
                  argomenti: [],
                  anni_scolastici: [],
                  tipi_materiale: []
                });
              }}
              sx={{ color: ACCENT_COLOR }}
            >
              Cancella filtri
            </Button>
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: ACCENT_COLOR,
                '&:hover': {
                  backgroundColor: '#c13e2d'
                }
              }}
              onClick={handleApplyFilters}
            >
              Applica filtri
            </Button>
          </Box>
        </Paper>
      </Collapse>
      
      {/* Pannello ordinamento espandibile */}
      <Collapse in={isOrderingOpen} timeout="auto" unmountOnExit>
        <Paper className="searchBar_expandedSortPanel" elevation={0}>
          <RadioGroup 
            row={!isMobile}
            value={searchParams.ordering}
            onChange={handleOrderingChange}
            className="searchBar_sortOptions"
          >
            <FormControlLabel value="newest" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Più recenti" />
            <FormControlLabel value="oldest" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Meno recenti" />
            <FormControlLabel value="name_asc" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Nome (A-Z)" />
            <FormControlLabel value="name_desc" control={<Radio sx={{ '&.Mui-checked': { color: ACCENT_COLOR } }} />} label="Nome (Z-A)" />
          </RadioGroup>
        </Paper>
      </Collapse>
      
      {/* Mostra risultati e filtri attivi */}
      <Box className="searchBar_resultsInfo">
        <Typography variant="body2" className="searchBar_resultsCount">
          {totalCount} risultati trovati
        </Typography>
        
        <Box className="searchBar_activeFilters">
          {renderActiveFilters().map((chip, index) => (
            React.cloneElement(chip, {
              key: `filter-chip-${index}`,
              sx: { 
                backgroundColor: 'rgba(223, 70, 52, 0.12)',
                color: ACCENT_COLOR,
                '& .MuiChip-deleteIcon': {
                  color: ACCENT_COLOR,
                  '&:hover': {
                    color: '#c13e2d'
                  }
                }
              }
            })
          ))}
          
          {activeFiltersCount() > 0 && (
            <Button 
              variant="text" 
              size="small" 
              onClick={resetFilters}
              className="searchBar_resetButton"
              startIcon={<CloseIcon fontSize="small" />}
              sx={{ color: ACCENT_COLOR }}
            >
              Cancella filtri
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBar; 