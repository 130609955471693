import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { showdownConverter, isVideoMessage, parseVideo } from './utils';

import {
  QuizMini,
  FillTheBoxMini,
  SelectGroupMini,
  MatchPairsMini,
  RearrangeMini,
  CrosswordMini
} from './GameComponents';

import {
  isQuizMessage,
  parseQuiz,
  isFillTheBoxMessage,
  parseFillTheBox,
  isSelectGroupMessage,
  parseSelectGroup,
  isMatchPairsMessage,
  parseMatchPairs,
  isRearrangeMessage,
  parseRearrange,
  isCrosswordMessage,
  parseCrossword
} from '../utils/gameMessageUtils';

/**
 * Componente che si occupa del rendering dei messaggi dell'assistente
 * Supporta i vari tipi di giochi e contenuti speciali come video
 */
const MessageRenderer = ({ message, chat, onOpenDetail }) => {
  if (!message) {
    return (
      <Button 
        variant="contained"
        onClick={(e) => {
          // Impedisce che l'evento si propaghi alla card
          e.stopPropagation();
          if (onOpenDetail) {
            onOpenDetail(chat, e);
          }
        }}
        sx={{
          backgroundColor: '#DF4634',
          color: '#FFFFFF',
          borderRadius: '1.5rem',
          padding: '0.5rem 1.5rem',
          textTransform: 'none',
          mt: 1,
          '&:hover': {
            backgroundColor: '#E57373',
          }
        }}
      >
        Crea
      </Button>
    );
  }

  // Visualizzazione basata sul tipo di messaggio
  if (isQuizMessage(message)) {
    return <QuizMini quizData={parseQuiz(message)} />;
  }
  if (isFillTheBoxMessage(message)) {
    return <FillTheBoxMini fillData={parseFillTheBox(message)} />;
  }
  if (isSelectGroupMessage(message)) {
    return <SelectGroupMini data={parseSelectGroup(message)} />;
  }
  /* Il gioco "abbina coppie" è temporaneamente disabilitato
  if (isMatchPairsMessage(message)) {
    return <MatchPairsMini data={parseMatchPairs(message)} />;
  }
  */
  if (isRearrangeMessage(message)) {
    return <RearrangeMini data={parseRearrange(message)} />;
  }
  if (isCrosswordMessage(message)) {
    return <CrosswordMini data={parseCrossword(message)} />;
  }
  
  // Caso per i video
  if (isVideoMessage(message)) {
    const videoData = parseVideo(message);
    if (videoData.type === 'youtube') {
      return (
        <Box sx={{ mt: 2, textAlign: 'left' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Video</Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">{videoData.description}</Typography>
          </Box>
          <Box 
            sx={{ 
              position: 'relative',
              paddingBottom: '56.25%', // Aspect ratio 16:9
              height: 0,
              overflow: 'hidden',
              borderRadius: '8px',
              maxWidth: '100%'
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              src={`https://www.youtube.com/embed/${videoData.videoId}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Box>
      );
    }
  }

  // Controllo per allegati video
  const hasVideoAttachment = message.attachments && 
                          message.attachments.length > 0 && 
                          message.attachments.some(att => 
                            att.file.toLowerCase().endsWith('.mp4') || 
                            att.file.toLowerCase().endsWith('.webm') || 
                            att.file.toLowerCase().endsWith('.mov')
                          );

  if (hasVideoAttachment) {
    const videoAtt = message.attachments.find(att => 
      att.file.toLowerCase().endsWith('.mp4') || 
      att.file.toLowerCase().endsWith('.webm') || 
      att.file.toLowerCase().endsWith('.mov')
    );
    
    return (
      <Box sx={{ mt: 2, textAlign: 'left' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Video</Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">{message.text}</Typography>
        </Box>
        <Box sx={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
          <video 
            controls 
            style={{ width: '100%', maxHeight: '300px', borderRadius: '8px' }}
            src={videoAtt.file}
          >
            Il tuo browser non supporta la riproduzione di video.
          </video>
        </Box>
      </Box>
    );
  }

  // Fallback: rendering markdown per testo normale
  const cleanedText = (message.text || '') + '\n\n';
  const rawHtml = showdownConverter.makeHtml(cleanedText);
  
  // Stili per il contenuto HTML
  const styledHtml = `
    <style>
      p {
        margin: 0;
        padding: 0;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        margin: 1rem 0;
      }
      th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
    </style>
    ${rawHtml}
  `;
  
  return (
    <Box
      sx={{ 
        marginTop: '0.5rem', 
        color: '#333',
        textAlign: 'left',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        maxWidth: '100%',
        '& p': { 
          maxWidth: '100%',
          whiteSpace: 'pre-wrap'
        },
        '& pre': {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          overflowX: 'auto',
          maxWidth: '100%'
        },
        '& code': {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }
      }}
      dangerouslySetInnerHTML={{ __html: styledHtml }}
    />
  );
};

export default MessageRenderer; 