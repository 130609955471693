import React, { useEffect, useRef } from 'react';
import './styles/GameNavigationBar.css';

const GAME_ICONS = {
  quiz: '/people/quiz.png',
  fillthebox: '/people/fillthebox.png',
  selectgroup: '/people/selectgroup.png',
  matchpairs: '/people/matchpairs.png',
  rearrange: '/people/rearrange.png',
  crossword: '/people/crossword.png',
  scrittura: '/people/scrittura.png',
  video: '/people/video.png',
};

const GameNavigationBar = ({ chats, currentGameIndex, completedGames, onNavigate }) => {
  const navBarRef = useRef(null);
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (navBarRef.current && activeItemRef.current) {
      // Calcola la posizione di scroll ottimale
      const navBar = navBarRef.current;
      const activeItem = activeItemRef.current;
      
      // Ottieni la posizione dell'elemento attivo
      const activeItemRect = activeItem.getBoundingClientRect();
      const navBarRect = navBar.getBoundingClientRect();
      
      // Determina il centro dell'elemento attivo relativo alla barra
      const activeItemCenter = activeItem.offsetLeft + (activeItem.offsetWidth / 2);
      
      // Determina il centro della barra di navigazione
      const navBarCenter = navBarRect.width / 2;
      
      // Calcola lo scroll necessario per centrare l'elemento attivo
      const scrollPosition = activeItemCenter - navBarCenter;
      
      // Applica lo scroll con animazione
      navBar.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [currentGameIndex]); // Si attiva quando cambia l'indice del gioco

  const hasYoutubeVideo = (chat) => {
    if (!chat || !chat.last_message || !chat.last_message.text) return false;
    try {
      const messageContent = JSON.parse(chat.last_message.text);
      if (messageContent.type === 'youtube' && messageContent.videoId) {
        return true;
      }
    } catch (error) {
      const textContent = chat.last_message.text;
      return textContent.includes('youtube.com') || textContent.includes('youtu.be');
    }
    return false;
  };

  return (
    <nav className="game-navigation-bar" ref={navBarRef}>
      {chats.map((chat, index) => {
        const gameType = chat.agent && chat.agent.toLowerCase();
        let iconPath = null;

        if (gameType && GAME_ICONS[gameType]) {
          if (gameType === 'scrittura' && hasYoutubeVideo(chat)) {
            iconPath = GAME_ICONS['video'];
          } else {
            iconPath = GAME_ICONS[gameType];
          }
        }

        const isActive = index === currentGameIndex;
        const isCompleted = completedGames.some(g => g.chatId === chat.id);

        return (
          <div 
            key={chat.id}
            className={`game-nav-item ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}
            onClick={() => onNavigate(index)}
            ref={isActive ? activeItemRef : null}
          >
            {iconPath ? (
              <div className="game-nav-icon-wrapper">
                <img src={iconPath} alt={gameType || "gioco"} className="game-nav-icon" />
              </div>
            ) : (
              <span className="game-nav-number">{index + 1}</span>
            )}
            <span className="game-nav-title">{chat.title || chat.agent}</span>
          </div>
        );
      })}
    </nav>
  );
};

export default GameNavigationBar;
