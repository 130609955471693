import React from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';

// Mappatura da agentKey a nome effettivo
const agentNames = {
  metodologie: 'Metodologie',
  attività: 'Attività',
  laboratori: 'Laboratori e progetti',
  scrittura: 'Scrittura',
  schedeoperative: 'Schede Operative',
  minerva: 'Minerva',
  montessori: 'Montessori',
  malaguzzi: 'Malaguzzi',
  immaginai: 'ImmaginAI',
  interactiveBlock: 'Giochi Interattivi',
  quiz: 'Quiz',
  fillthebox: 'Completa frase',
  selectgroup: 'Raggruppa',
  matchpairs: 'Abbina coppie',
  rearrange: 'Riordina',
  crossword: 'Cruciverba',
};

const AgentSwitch = ({ selectedOption, isGlobalChatPage = false }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  
  // Se siamo in una GlobalChat, mostriamo "Verifica" invece dell'agente
  const displayText = isGlobalChatPage ? 'Verifica' : (agentNames[selectedOption] || 'Agente sconosciuto');

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#DF4634',
          color: '#FFFFFF',
          borderRadius: '1.875rem',
          minWidth: '7rem', // Larghezza minima del pulsante
          padding: '0 1.5rem', // Aggiunge spazio orizzontale
          height: '2.5rem',
          position: isMobile ? 'relative' : 'fixed',
          left: isMobile ? 'auto' : '20%',
          top: isMobile ? 'auto' : '2.5rem',
          textTransform: 'none',
          fontSize: '0.875rem',
          fontWeight: 500,
          textAlign: 'center',
          zIndex: isMobile ? 0 : 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap', // Evita l'andata a capo
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
      >
        {displayText}
      </Button>
    </Box>
  );
};

export default AgentSwitch;
