import React, { useState } from 'react';
import './styles/GameDetails.css';

const GameDetails = ({ gameResult, chat, onBackToSummary, onNavigateToGame }) => {
  const [showSolutions, setShowSolutions] = useState(false);

  if (!gameResult) {
    return (
      <div className="game-details-error">
        <h3>Dati non disponibili</h3>
        <p>Non sono disponibili dettagli per questo gioco.</p>
        <button className="back-button" onClick={onBackToSummary}>
          Torna al riepilogo
        </button>
      </div>
    );
  }

  // Funzione per ottenere una classe CSS in base al punteggio
  const getScoreClass = (score) => {
    if (score >= 80) return 'score-high';
    if (score >= 60) return 'score-medium';
    return 'score-low';
  };
  
  // Funzione per formattare una data ISO in un formato leggibile
  const formatDate = (isoDate) => {
    try {
      const date = new Date(isoDate);
      return new Intl.DateTimeFormat('it-IT', { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (e) {
      return 'Data non disponibile';
    }
  };

  // Toggle per mostrare/nascondere le soluzioni
  const toggleSolutions = () => {
    setShowSolutions(!showSolutions);
  };

  // Funzione per renderizzare dettagli specifici in base al tipo di gioco
  const renderGameSpecificDetails = () => {
    // Determina il tipo di gioco utilizzando sia game_type che type (se disponibile)
    const gameType = gameResult.game_type || gameResult.type || 'unknown';
    
    // Se non ci sono risultati disponibili (gioco non completato)
    if (!gameResult.completed || 
        (gameResult.score === 0 && 
         !gameResult.wordResults?.length && 
         !gameResult.questionResults?.length && 
         !gameResult.pairResults?.length && 
         !gameResult.groupResults?.length && 
         !gameResult.results?.length)) {
      return (
        <div className="game-not-completed">
          <h3>Gioco non completato</h3>
          <p>Non hai ancora completato questo {gameType === 'crossword' ? 'cruciverba' : gameType}.</p>
          <p>Completa il gioco per vedere i dettagli e le statistiche.</p>
          <button className="back-to-game-button" onClick={() => onNavigateToGame(chat.index)}>
            Vai al gioco
          </button>
        </div>
      );
    }

    switch (gameType.toLowerCase()) {
      case 'quiz':
        return renderQuizDetails();
      case 'fillthebox':
        return renderFillTheBoxDetails();
      case 'matchpairs':
        return renderMatchPairsDetails();
      case 'rearrange':
        return renderRearrangeDetails();
      case 'selectgroup':
        return renderSelectGroupDetails();
      case 'crossword':
        return renderCrosswordDetails();
      case 'scrittura':
        return renderScrittura();
      default:
        return renderGenericDetails();
    }
  };

  // Rendering dettagli Quiz
  const renderQuizDetails = () => (
    <div className="quiz-details">
      <h3>Dettagli Quiz</h3>
      
      <div className="quiz-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="quiz-stats-info">
          {gameResult.correctAnswers !== undefined && (
            <p className="quiz-score-details">
              <strong>Risposte corrette:</strong> {gameResult.correctAnswers} su {gameResult.totalQuestions || 0}
              {gameResult.totalQuestionsWithOpenEnded && gameResult.totalQuestionsWithOpenEnded > gameResult.totalQuestions && (
                <span className="open-ended-note"> (più {gameResult.totalQuestionsWithOpenEnded - gameResult.totalQuestions} domande a risposta aperta)</span>
              )}
            </p>
          )}
          <p className="quiz-completion-time">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
        </div>
      </div>
      
      {gameResult.questionResults && gameResult.questionResults.length > 0 ? (
        <div className="quiz-questions-results">
          <h4>Risposte Fornite</h4>
          {gameResult.questionResults.map((result, index) => (
            <div 
              key={result.id || index} 
              className={
                result.type === 'open_ended' 
                  ? 'question-result open-ended' 
                  : `question-result ${result.isCorrect ? 'correct' : 'incorrect'}`
              }
            >
              <p className="question-text">
                <strong>Domanda {index + 1}:</strong> {result.question}
              </p>
              <div className="question-details">
                <p className="answer-text">
                  <strong>La tua risposta:</strong> {
                    result.userAnswer ? (
                      result.type === 'open_ended' ? 
                        result.userAnswer : 
                        (result.userAnswerText || `Opzione selezionata: ${result.userAnswer}`)
                    ) : "Non risposto"
                  }
                </p>
                {showSolutions && result.type === 'multiple_choice' && result.correctAnswer && (
                  <p className="correct-answer-text">
                    <strong>Risposta corretta:</strong> {result.correctAnswerText || result.correctAnswer}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sulle domande.</p>
      )}
    </div>
  );

  // Rendering dettagli FillTheBox
  const renderFillTheBoxDetails = () => (
    <div className="fillthebox-details">
      <h3>Dettagli Esercizio</h3>
      
      <div className="fillthebox-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="fillthebox-stats-info">
          <p className="fillthebox-completion-time">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
        </div>
      </div>
      
      {gameResult.results && gameResult.results.length > 0 ? (
        <div className="fillthebox-results-list">
          <h4>Risposte Fornite</h4>
          {gameResult.results.map((result, index) => (
            <div 
              key={result.id || index} 
              className={`fillthebox-result ${result.isCorrect ? 'correct' : 'incorrect'}`}
            >
              <p className="fillthebox-exercise">
                <strong>Esercizio {index + 1}</strong>
              </p>
              <div className="fillthebox-details">
                <p><strong>La tua risposta:</strong> {result.userAnswerText || result.userAnswer || "Non risposto"}</p>
                {showSolutions && (
                  <p><strong>Risposta corretta:</strong> {result.correctAnswerText || result.correctAnswer}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sugli esercizi.</p>
      )}
    </div>
  );

  // Rendering dettagli MatchPairs
  const renderMatchPairsDetails = () => (
    <div className="matchpairs-details">
      <h3>Dettagli Abbinamento Coppie</h3>
      
      <div className="matchpairs-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="matchpairs-stats-info">
          <p className="matchpairs-completion-time">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
          {gameResult.pairResults && (
            <p className="matchpairs-score-details">
              <strong>Coppie corrette:</strong> {gameResult.pairResults.filter(r => r.isCorrect).length} su {gameResult.pairResults.length}
            </p>
          )}
        </div>
      </div>
      
      {gameResult.pairResults && gameResult.pairResults.length > 0 ? (
        <div className="matchpairs-results-list">
          <h4>Abbinamenti Effettuati</h4>
          {gameResult.pairResults.map((result, index) => (
            <div 
              key={index} 
              className={`matchpairs-result ${result.isCorrect ? 'correct' : 'incorrect'}`}
            >
              <div className="matchpairs-pair">
                <strong>Elemento {index + 1}:</strong> {result.leftItem}
              </div>
              <div className="matchpairs-assignment">
                <p><strong>La tua scelta:</strong> {result.userAssignment || "Non risposto"}</p>
                {showSolutions && (
                  <p><strong>Abbinamento corretto:</strong> {result.rightItem}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sugli abbinamenti.</p>
      )}
    </div>
  );

  // Rendering dettagli Rearrange
  const renderRearrangeDetails = () => (
    <div className="rearrange-details">
      <h3>Riepilogo esercizi di riordinamento</h3>
      
      <div className="rearrange-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="rearrange-stats-info">
          <p className="rearrange-completion-info">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
          <p className="rearrange-total-info">
            <strong>Frasi corrette:</strong> {gameResult.arrangementResults?.filter(r => r.isCorrect).length || 0} 
            su {gameResult.arrangementResults?.length || 0}
          </p>
        </div>
      </div>
      
      {gameResult.arrangementResults && gameResult.arrangementResults.length > 0 ? (
        <div className="rearrange-results-container">
          <h4>Dettaglio dei tuoi esercizi</h4>
          
          {/* Ordinamenti Corretti */}
          {gameResult.arrangementResults.some(result => result.isCorrect) && (
            <div className="rearrange-section correct-section">
              <h5>✓ Frasi corrette</h5>
              <div className="rearrange-exercises-grid">
                {gameResult.arrangementResults.filter(result => result.isCorrect).map((result, index) => (
                  <div key={index} className="rearrange-exercise-card correct">
                    <div className="exercise-card-header">
                      <h6>{result.topic || `Esercizio ${index + 1}`}</h6>
                      <div className="exercise-status-badge correct">Corretto</div>
                    </div>
                    <div className="exercise-card-content">
                      <p><strong>La tua frase:</strong></p>
                      <div className="sentence-container">
                        {result.userArrangement.join(' ')}
                      </div>
                      <p className="exercise-feedback success">
                        Ottimo lavoro! Hai ordinato correttamente questa frase.
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {/* Ordinamenti Errati */}
          {gameResult.arrangementResults.some(result => !result.isCorrect) && (
            <div className="rearrange-section incorrect-section">
              <h5>✗ Frasi da rivedere</h5>
              <div className="rearrange-exercises-grid">
                {gameResult.arrangementResults.filter(result => !result.isCorrect).map((result, index) => (
                  <div key={index} className="rearrange-exercise-card incorrect">
                    <div className="exercise-card-header">
                      <h6>{result.topic || `Esercizio ${index + 1}`}</h6>
                      <div className="exercise-status-badge incorrect">Da rivedere</div>
                    </div>
                    <div className="exercise-card-content">
                      <p><strong>Il tuo ordinamento:</strong></p>
                      <div className="sentence-container incorrect">
                        {result.userArrangement.join(' ')}
                      </div>
                      
                      {showSolutions && result.correctArrangement && (
                        <div className="correct-solution">
                          <p><strong>Ordinamento corretto:</strong></p>
                          <div className="sentence-container correct">
                            {result.correctArrangement.join(' ')}
                          </div>
                        </div>
                      )}
                      
                      <p className="exercise-feedback error">
                        Questa frase non è stata ordinata correttamente.
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sugli ordinamenti.</p>
      )}
    </div>
  );

  // Rendering dettagli SelectGroup
  const renderSelectGroupDetails = () => (
    <div className="selectgroup-details">
      <h3>Dettagli Selezione Gruppi</h3>
      
      <div className="selectgroup-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="selectgroup-stats-info">
          <p className="selectgroup-completion-time">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
        </div>
      </div>
      
      {gameResult.groupResults && gameResult.groupResults.length > 0 ? (
        <div className="selectgroup-results-container">
          <h4>I tuoi gruppi</h4>
          
          {/* Gruppi Corretti */}
          {gameResult.groupResults.some(result => result.isCorrect) && (
            <div className="selectgroup-section correct-section">
              <h5>Gruppi corretti</h5>
              <div className="selectgroup-groups-grid">
                {gameResult.groupResults.filter(result => result.isCorrect).map((result, index) => (
                  <div key={index} className="selectgroup-group-card correct">
                    <div className="group-card-header">
                      <h6>{result.groupName}</h6>
                      <div className="group-status-badge correct">Corretto</div>
                    </div>
                    <div className="group-card-content">
                      <p>Le tue selezioni:</p>
                      <div className="group-words-container">
                        {result.userSelection.map((word, i) => (
                          <span key={i} className="group-word-chip">{word}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {/* Gruppi Errati */}
          {gameResult.groupResults.some(result => !result.isCorrect) && (
            <div className="selectgroup-section incorrect-section">
              <h5>Gruppi da rivedere</h5>
              <div className="selectgroup-groups-grid">
                {gameResult.groupResults.filter(result => !result.isCorrect).map((result, index) => (
                  <div key={index} className="selectgroup-group-card incorrect">
                    <div className="group-card-header">
                      <h6>{result.groupName}</h6>
                      <div className="group-status-badge incorrect">Da rivedere</div>
                    </div>
                    <div className="group-card-content">
                      <p>Le tue selezioni:</p>
                      <div className="group-words-container">
                        {result.userSelection.map((word, i) => (
                          <span key={i} className="group-word-chip">{word}</span>
                        ))}
                      </div>
                      {showSolutions && result.correctSelection && (
                        <div className="correct-selection">
                          <p>Selezione corretta:</p>
                          <div className="group-words-container correct">
                            {result.correctSelection.map((word, i) => (
                              <span key={i} className="group-word-chip correct">{word}</span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sulle selezioni dei gruppi.</p>
      )}
    </div>
  );

  // Rendering dettagli Crossword
  const renderCrosswordDetails = () => (
    <div className="crossword-details">
      <h3>Dettagli Cruciverba</h3>
      
      <div className="crossword-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="crossword-stats-info">
          {gameResult.correctWords !== undefined && gameResult.totalWords !== undefined && (
            <p className="crossword-correct-words">
              <strong>Parole trovate:</strong> {gameResult.correctWords} su {gameResult.totalWords}
            </p>
          )}
          <p className="crossword-completion-time">
            <strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
        </div>
      </div>
      
      {gameResult.wordResults && gameResult.wordResults.length > 0 ? (
        <div className="crossword-results-container">
          <h4>Risultati del cruciverba</h4>
          
          {/* Orizzontali */}
          <div className="crossword-section">
            <h5>Orizzontali</h5>
            <div className="crossword-words-grid">
              {gameResult.wordResults
                .filter(result => result.direction === 'orizzontale' || result.direction === 'horizontal')
                .map((result, index) => (
                  <div 
                    key={index} 
                    className={`crossword-word-card ${result.isCorrect ? 'correct' : 'incorrect'}`}
                  >
                    <div className="word-card-header">
                      <span className="word-number">{result.wordNumber}</span>
                      <h6 className="word-clue">{result.clue}</h6>
                      <div className={`word-status-badge ${result.isCorrect ? 'correct' : 'incorrect'}`}>
                        {result.isCorrect ? 'Corretto' : 'Errato'}
                      </div>
                    </div>
                    <div className="word-card-content">
                      <div className="word-answer">
                        <strong>La tua risposta:</strong> 
                        <span className={`user-answer ${result.isCorrect ? 'correct' : 'incorrect'}`}>
                          {result.userAnswer || "Non risposto"}
                        </span>
                      </div>
                      {showSolutions && (
                        <div className="word-solution">
                          <strong>Risposta corretta:</strong> 
                          <span className="correct-answer">{result.correctAnswer}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          
          {/* Verticali */}
          <div className="crossword-section">
            <h5>Verticali</h5>
            <div className="crossword-words-grid">
              {gameResult.wordResults
                .filter(result => result.direction === 'verticale' || result.direction === 'vertical')
                .map((result, index) => (
                  <div 
                    key={index} 
                    className={`crossword-word-card ${result.isCorrect ? 'correct' : 'incorrect'}`}
                  >
                    <div className="word-card-header">
                      <span className="word-number">{result.wordNumber}</span>
                      <h6 className="word-clue">{result.clue}</h6>
                      <div className={`word-status-badge ${result.isCorrect ? 'correct' : 'incorrect'}`}>
                        {result.isCorrect ? 'Corretto' : 'Errato'}
                      </div>
                    </div>
                    <div className="word-card-content">
                      <div className="word-answer">
                        <strong>La tua risposta:</strong> 
                        <span className={`user-answer ${result.isCorrect ? 'correct' : 'incorrect'}`}>
                          {result.userAnswer || "Non risposto"}
                        </span>
                      </div>
                      {showSolutions && (
                        <div className="word-solution">
                          <strong>Risposta corretta:</strong> 
                          <span className="correct-answer">{result.correctAnswer}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <p>Nessun dettaglio disponibile sulle parole del cruciverba.</p>
      )}
    </div>
  );

  // Rendering dettagli Scrittura
  const renderScrittura = () => (
    <div className="scrittura-details">
      <h3>Dettagli Contenuto</h3>
      
      <div className="scrittura-stats">
        <div className="score-circle score-high">
          <span className="score-value">100%</span>
        </div>
        
        <div className="scrittura-stats-info">
          <p className="scrittura-type">
            <strong>Tipo:</strong> {
              gameResult.contentType === 'youtube' ? 'Video YouTube' : 
              gameResult.contentType === 'text' ? 'Testo' : 
              gameResult.contentType || 'Contenuto'
            }
          </p>
          <p className="scrittura-completion-time">
            <strong>Visualizzato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}
          </p>
        </div>
      </div>
      
      <div className="scrittura-content-info">
        {gameResult.details && gameResult.details.message && (
          <p className="scrittura-message">{gameResult.details.message}</p>
        )}
        <p className="scrittura-explanation">
          Questo tipo di contenuto è sempre considerato completato con successo una volta visualizzato.
        </p>
      </div>
    </div>
  );

  // Rendering dettagli generici (fallback)
  const renderGenericDetails = () => (
    <div className="generic-game-details">
      <h3>Dettagli {gameResult.title || (gameResult.game_type || gameResult.type)}</h3>
      
      <div className="game-stats">
        <div className={`score-circle ${getScoreClass(Number(gameResult.score))}`}>
          <span className="score-value">{Number(gameResult.score)}%</span>
        </div>
        
        <div className="game-completion">
          <p><strong>Completato il:</strong> {formatDate(gameResult.completed_at || gameResult.completedAt)}</p>
        </div>
      </div>
      
      <div className="additional-stats">
        {gameResult.correctAnswers !== undefined && (
          <div className="stat-item">
            <strong>Risposte corrette:</strong> {gameResult.correctAnswers}
          </div>
        )}
        {gameResult.totalQuestions !== undefined && (
          <div className="stat-item">
            <strong>Domande totali:</strong> {gameResult.totalQuestions}
          </div>
        )}
        {gameResult.percentage !== undefined && (
          <div className="stat-item">
            <strong>Percentuale:</strong> {gameResult.percentage}%
          </div>
        )}
      </div>
    </div>
  );

  // Helper per il colore del punteggio
  const getScoreColor = (score) => {
    if (score >= 80) return '#4CAF50';
    if (score >= 60) return '#FF9800';
    return '#F44336';
  };

  return (
    <div className="game-details-container">
      <div className="game-details-header">
        <button className="back-button" onClick={onBackToSummary}>
          <i className="fa fa-arrow-left"></i> Torna al riepilogo punteggi
        </button>
        <h2>
          {gameResult.game_type === 'crossword' ? 'Dettagli Cruciverba' : 
           gameResult.game_type ? `Dettagli ${gameResult.game_type.charAt(0).toUpperCase()}${gameResult.game_type.slice(1)}` : 
           gameResult.type ? `Dettagli ${gameResult.type.charAt(0).toUpperCase()}${gameResult.type.slice(1)}` : 
           chat.agent ? chat.agent : 'Dettagli Gioco'}
        </h2>
        
        <div className="game-type">
          {gameResult.game_type === 'crossword' ? 'Cruciverba' : 
           gameResult.game_type ? `${gameResult.game_type.charAt(0).toUpperCase()}${gameResult.game_type.slice(1)}` : 
           gameResult.type ? `${gameResult.type.charAt(0).toUpperCase()}${gameResult.type.slice(1)}` : 
           chat.agent ? chat.agent : 'Gioco'}
        </div>
      </div>
      
      {renderGameSpecificDetails()}
    </div>
  );
};

export default GameDetails; 