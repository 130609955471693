import apiClient from '../../api/apiClient';

/**
 * Crea una nuova subChat con una migliore gestione degli stati di caricamento e errori
 * 
 * @param {string} globalChatId - ID della GlobalChat
 * @param {string} agentKey - Tipo di agente per la nuova chat
 * @param {Function} setSubChats - Funzione per aggiornare lo stato delle subChat
 * @param {Function} showSnackbar - Funzione per mostrare messaggi all'utente
 * @returns {Promise<Object|null>} - La nuova chat creata o null in caso di errore
 */
export async function createSubChat(globalChatId, agentKey, setSubChats, showSnackbar) {
  if (!globalChatId) {
    showSnackbar('Errore: ID della GlobalChat non definito');
    return null;
  }
  
  // Creo un ID temporaneo per l'aggiornamento ottimistico
  const tempId = Date.now();
  
  // Creo la nuova chat temporanea
  const newChat = {
    id: tempId,
    agent: agentKey,
    messages: [],
    temp: true // flag per identificare chat temporanee
  };
  
  // Aggiorno lo stato locale con la nuova chat
  setSubChats(prev => [...prev, newChat]);
  
  try {
    // Chiamata API reale
    const chatResponse = await apiClient.post(`/api/advantage_games/globalchats/${globalChatId}/chats/`, {
      agent: agentKey,
    });
    
    // Recupero l'ID reale assegnato dal server
    const realId = chatResponse.data.id || null;
    
    if (!realId) {
      throw new Error('ID chat non ricevuto dal server');
    }
    
    // Recupero tutti i dati aggiornati dal server
    const res = await apiClient.get(`/api/advantage_games/globalchats/${globalChatId}/`);
    
    // Trovo la chat appena creata nei dati aggiornati
    const createdChat = res.data.chats.find(chat => chat.id === realId);
    
    if (!createdChat) {
      throw new Error('Chat non trovata dopo la creazione');
    }
    
    // Aggiorno lo stato con i dati reali
    setSubChats(res.data.chats || []);
    
    // Restituisco la chat creata
    return createdChat;
  } catch (err) {
    console.error('Errore creazione sub chat:', err);
    showSnackbar('Errore nella creazione della chat');
    
    // Rimuovo la chat temporanea in caso di errore
    setSubChats(prev => prev.filter(chat => !chat.temp));
    
    return null;
  }
} 