// Icone dei giochi
export const GAME_ICONS = {
  quiz: '/people/quiz.png',
  fillthebox: '/people/fillthebox.png',
  selectgroup: '/people/selectgroup.png',
  matchpairs: '/people/matchpairs.png',
  rearrange: '/people/rearrange.png',
  crossword: '/people/crossword.png',
  scrittura: '/people/scrittura.png',
  video: '/people/video.png',
};

// Definizione degli agenti disponibili
export const AGENTS = [
  { key: 'quiz', label: 'Quiz' },
  { key: 'fillthebox', label: 'Completa frase' },
  { key: 'selectgroup', label: 'Raggruppa' },
  // Gioco "abbina coppie" temporaneamente disabilitato
  // { key: 'matchpairs', label: 'Abbina coppie' },
  { key: 'rearrange', label: 'Riordina' },
  { key: 'crossword', label: 'Cruciverba' },
  { key: 'scrittura', label: 'Testo' },
  { key: 'video', label: 'Video' },
];

// Messaggi di benvenuto per ogni tipo di gioco
export const WELCOME_MESSAGES = {
  quiz: `Benvenuto nella creazione di un Quiz! 🎯

Per creare un nuovo quiz, scrivi semplicemente la tua richiesta specificando:
• L'argomento o il tema del quiz
• Il livello di difficoltà desiderato
• Il numero di domande (opzionale)
• Se vuoi domande a scelta multipla o a risposta aperta

Ad esempio: "Crea un quiz di storia romana di livello intermedio con 5 domande a scelta multipla sulla Repubblica"`,

  fillthebox: `Benvenuto nella creazione di un esercizio Completa la frase! ✍️

Per creare un nuovo esercizio, scrivi la tua richiesta specificando:
• L'argomento o il concetto da esercitare
• Il livello di difficoltà
• Eventuali parole o concetti specifici da includere

Ad esempio: "Crea un esercizio per completare frasi sui verbi irregolari inglesi, livello base"`,

  selectgroup: `Benvenuto nella creazione di un esercizio di Raggruppamento! 🎯

Per creare un nuovo esercizio, specifica:
• Il tema o l'argomento principale
• I criteri di raggruppamento
• Il numero di gruppi desiderato (opzionale)

Ad esempio: "Crea un esercizio per raggruppare animali in base al loro habitat: terrestre, acquatico e aereo"`,

  matchpairs: `Benvenuto nella creazione di un esercizio Abbina le Coppie! 🔄

Per creare un nuovo esercizio, indica:
• L'argomento o il tema
• Il tipo di associazioni da creare
• Il numero di coppie (opzionale)

Ad esempio: "Crea un esercizio per abbinare capitali europee ai rispettivi paesi, con 8 coppie"`,

  rearrange: `Benvenuto nella creazione di un esercizio Riordina! 📝

Per creare un nuovo esercizio, specifica:
• Il tipo di frasi da riordinare
• Il livello di difficoltà
• Eventuali temi o argomenti specifici

Ad esempio: "Crea un esercizio per riordinare frasi in inglese al Present Simple, livello base"`,

  crossword: `Benvenuto nella creazione di un Cruciverba! 🎯

Per creare un nuovo cruciverba, indica:
• L'argomento o il tema
• Il numero approssimativo di parole
• Eventuali parole specifiche da includere

Ad esempio: "Crea un cruciverba sulla geografia italiana con 10 parole, includendo i nomi dei mari"`,

  scrittura: `Benvenuto nella creazione di un Testo! ✍️

Puoi scegliere di:
1. Far generare un testo dall'intelligenza artificiale
2. Inserire direttamente un testo che hai già preparato

Se scegli la generazione AI, scrivi una richiesta dettagliata specificando:
• Il tipo di testo (narrativo, descrittivo, argomentativo, ecc.)
• L'argomento o tema principale
• La lunghezza approssimativa
• Eventuali requisiti specifici

Ad esempio: "Genera un testo narrativo sul tema dell'amicizia, adatto per bambini di 10 anni, di circa 200 parole"`,

  video: `Benvenuto nella sezione Video! 🎬

Qui puoi arricchire i tuoi contenuti aggiungendo un video da:
• Link YouTube (inserisci l'URL completo)
• Upload di un file video MP4 dal tuo dispositivo

Accompagna il video con un testo descrittivo che aiuti a contestualizzare il contenuto.`
}; 