import React from 'react';
import './styles/ResetConfirmModal.css';

const ResetConfirmModal = ({ isVisible, onConfirm, onCancel }) => {
  if (!isVisible) return null;

  return (
    <div className="reset-confirm-overlay">
      <div className="reset-confirm-modal">
        <div className="reset-confirm-header">
          <h3>Conferma Reset</h3>
        </div>
        <div className="reset-confirm-content">
          <div className="reset-confirm-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
              <path fill="#e74c3c" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"/>
            </svg>
          </div>
          <div className="reset-confirm-text">
            <p>Sei sicuro di voler resettare la verifica?</p>
            <p className="reset-confirm-warning">Tutti i punteggi saranno cancellati e non potranno essere recuperati.</p>
          </div>
        </div>
        <div className="reset-confirm-actions">
          <button className="reset-confirm-button cancel" onClick={onCancel}>
            Annulla
          </button>
          <button className="reset-confirm-button confirm" onClick={onConfirm}>
            Resetta Verifica
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetConfirmModal; 