// src/api/documents/teachingArchiveApi.js

import apiClient from '../apiClient';
import { getAccessToken } from '../authUtils';

// Funzione per ottenere la lista dei documenti
export const getTeachingDocuments = async () => {
  try {
    const response = await apiClient.get('/api/teachingarchive/documents/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Funzione per caricare un nuovo documento (se necessario)
export const uploadTeachingDocument = async (documentName, file) => {
  try {
    const formData = new FormData();
    formData.append('document_name', documentName);
    formData.append('file', file);

    const response = await apiClient.post('/api/teachingarchive/documents/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Nuova funzione per ottenere un documento per titolo
export const getTeachingDocumentByName = async (documentName) => {
  try {
    const encodedName = encodeURIComponent(documentName);
    const accessToken = getAccessToken();
    
    // Aggiungiamo esplicitamente l'header di autorizzazione
    const config = {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined
      }
    };
    
    const response = await apiClient.get(`/api/teachingarchive/documents/${encodedName}/`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Funzione per la ricerca avanzata di documenti
export const searchTeachingDocuments = async (params) => {
  try {
    // Costruisci la stringa di query dai parametri
    const queryParams = new URLSearchParams();
    
    // Gestione intelligente dei parametri di ricerca
    // Se abbiamo sia q che author, diamo priorità alla ricerca per 'q'
    if (params.q && params.q.trim() !== '') {
      queryParams.append('q', params.q);
      // Se c'è una ricerca generale, non inviamo author per evitare conflitti
    } else if (params.author && params.author.trim() !== '') {
      // Se non c'è una ricerca generale ma c'è un autore, cerchiamo solo per autore
      queryParams.append('author', params.author);
    }
    
    // Gestione del parametro premium
    // Il backend si aspetta una stringa 'true' o 'false'
    if (params.premium === true) {
      queryParams.append('premium', 'true');
    } else if (params.premium === false) {
      queryParams.append('premium', 'false');
    }
    // Se params.premium è null, non inviamo il parametro per mostrare entrambi i tipi
    
    // Gestione dei tag (argomenti, anni scolastici, tipi materiale)
    if (params.argomenti && params.argomenti.length > 0) {
      queryParams.append('argomenti', params.argomenti.join(','));
    }
    
    if (params.anni_scolastici && params.anni_scolastici.length > 0) {
      queryParams.append('anni_scolastici', params.anni_scolastici.join(','));
    }
    
    if (params.tipi_materiale && params.tipi_materiale.length > 0) {
      queryParams.append('tipi_materiale', params.tipi_materiale.join(','));
    }
    
    // Flag per i link
    const linkFlags = ['has_instagram', 'has_facebook', 'has_tiktok', 'has_website', 
                       'has_generic_link', 'has_eduki', 'has_edudoro', 'has_freeed', 'has_logo'];
    
    linkFlags.forEach(flag => {
      if (params[flag] !== undefined) {
        queryParams.append(flag, params[flag]);
      }
    });
    
    // Paginazione e ordinamento
    if (params.page) queryParams.append('page', params.page);
    if (params.page_size) queryParams.append('page_size', params.page_size);
    if (params.ordering) queryParams.append('ordering', params.ordering);
    
    const response = await apiClient.get(`/api/teachingarchive/documents/search/?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Funzione per filtrare i documenti in base ai tag
export const filterTeachingDocuments = async (params) => {
  try {
    const queryParams = new URLSearchParams();
    
    // Gestione del parametro premium
    if (params.premium === true) {
      queryParams.append('premium', 'true');
    } else if (params.premium === false) {
      queryParams.append('premium', 'false');
    }
    
    // Aggiungi i parametri di filtro se presenti
    if (params.argomenti && params.argomenti.length > 0) {
      params.argomenti.forEach(id => queryParams.append('argomenti', id));
    }
    
    if (params.anni_scolastici && params.anni_scolastici.length > 0) {
      params.anni_scolastici.forEach(id => queryParams.append('anni_scolastici', id));
    }
    
    if (params.tipi_materiale && params.tipi_materiale.length > 0) {
      params.tipi_materiale.forEach(id => queryParams.append('tipi_materiale', id));
    }
    
    const response = await apiClient.get(`/api/teachingarchive/documents/filter/?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Funzione per ottenere tutti i tag disponibili per gli argomenti
export const getArgomentiTags = async () => {
  try {
    const response = await apiClient.get('/api/teachingarchive/tags/argomenti/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Funzione per ottenere tutti i tag disponibili per gli anni scolastici
export const getAnniScolasticiTags = async () => {
  try {
    const response = await apiClient.get('/api/teachingarchive/tags/anni-scolastici/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Funzione per ottenere tutti i tag disponibili per i tipi di materiale
export const getTipiMaterialeTags = async () => {
  try {
    const response = await apiClient.get('/api/teachingarchive/tags/tipi-materiale/');
    return response.data;
  } catch (error) {
    throw error;
  }
};
