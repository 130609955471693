import React, { useState, useEffect } from 'react';
import './styles/MatchPairsGame.css';

const MatchPairsGame = ({
  matchData = {},     // Valore predefinito: oggetto vuoto
  onComplete,         // Funzione callback da chiamare quando il gioco è completato
  isLastGame,         // Boolean: indica se questo è l'ultimo gioco nella sequenza
  shouldGoToSummary,  // Boolean: se true, dopo il completamento si va direttamente al riepilogo
  previousAnswers     // (opzionale) Risposte precedenti già salvate, da caricare
}) => {
  // Indice del "topic" corrente da mostrare
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);

  // userAnswers[topicId] = array di stringhe che indicano, per ogni item di sinistra,
  // quale opzione di destra è stata scelta
  const [userAnswers, setUserAnswers] = useState({});

  // Eventuale stato di "fine gioco" locale (non più utilizzato nel flusso principale,
  // ma lasciato come fallback)
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);

  // Chiave di localStorage basata sul chatId
  const getLocalStorageKey = () => {
    return matchData && matchData.chatId
      ? `matchpairs_partial_answers_${matchData.chatId}`
      : null;
  };

  // Al cambio di matchData (in particolare chatId), cerchiamo di caricare risposte esistenti
  useEffect(() => {
    if (matchData && matchData.chatId) {
      console.log('MatchPairsGame: cambio di chatId rilevato =', matchData.chatId);

      const lsKey = getLocalStorageKey();
      let loadedAnswers = {};

      // 1. Prova a caricare da localStorage
      if (lsKey) {
        try {
          const savedAnswers = localStorage.getItem(lsKey);
          if (savedAnswers) {
            loadedAnswers = JSON.parse(savedAnswers);
            console.log('Risposte parziali caricate da localStorage:', loadedAnswers);
          }
        } catch (e) {
          console.error('Errore nel caricamento delle risposte da localStorage:', e);
        }
      }

      // 2. Se non ci sono dati in localStorage e abbiamo previousAnswers, li usiamo
      if (Object.keys(loadedAnswers).length === 0 && previousAnswers) {
        console.log('Caricamento di risposte precedenti:', previousAnswers);
        
        // Supponiamo che previousAnswers abbia una struttura simile a:
        // {
        //   pairResults: [
        //     {
        //       topicId: 1,
        //       pairs: [
        //         { left: "Cammello", userRight: "Deserto" },
        //         { left: "Orso Polare", userRight: "Artico" },
        //         ...
        //       ]
        //     },
        //     ...
        //   ]
        // }
        // Se la tua struttura è diversa, adatta il parsing di conseguenza.
        if (previousAnswers.pairResults) {
          previousAnswers.pairResults.forEach((topic) => {
            const topicId = topic.topicId;
            loadedAnswers[topicId] = topic.pairs.map(pairObj => pairObj.userRight || '');
          });
        }
        console.log('Risposte precedenti caricate:', loadedAnswers);
      }

      setUserAnswers(loadedAnswers);
      setCurrentTopicIndex(0);
      setShowResults(false);
      setScore(0);
    }
  }, [matchData && matchData.chatId, previousAnswers]);

  // Salvataggio delle risposte in localStorage ogni volta che cambiano
  useEffect(() => {
    const lsKey = getLocalStorageKey();
    if (lsKey && Object.keys(userAnswers).length > 0) {
      try {
        localStorage.setItem(lsKey, JSON.stringify(userAnswers));
        console.log('Risposte salvate in localStorage:', userAnswers);
      } catch (e) {
        console.error('Errore nel salvataggio delle risposte in localStorage:', e);
      }
    }
  }, [userAnswers]);

  // Estraggo i topic (equivalente delle domande in un quiz)
  const topics = (matchData && matchData.matchpairs) || [];

  // Se non ci sono topic da mostrare
  if (!topics || topics.length === 0) {
    return (
      <div className="matchpairs-game">
        <h2>Nessun abbinamento disponibile</h2>
        <p>Questo gioco non contiene coppie da abbinare.</p>
      </div>
    );
  }

  // Gestione selezione dell'opzione di destra per un determinato elemento di sinistra
  const handleSelectRight = (topicId, leftIndex, userSelectedRight) => {
    setUserAnswers((prevAnswers) => {
      const currentTopicAnswers = prevAnswers[topicId]
        ? [...prevAnswers[topicId]]
        : Array(topics.find(t => t.id === topicId)?.pairs.length).fill('');

      currentTopicAnswers[leftIndex] = userSelectedRight;

      return {
        ...prevAnswers,
        [topicId]: currentTopicAnswers
      };
    });
  };

  const handleNextTopic = () => {
    if (currentTopicIndex < topics.length - 1) {
      setCurrentTopicIndex(currentTopicIndex + 1);
    } else {
      // Se siamo all'ultimo, calcoliamo il punteggio
      calculateScore();
    }
  };

  const handlePrevTopic = () => {
    if (currentTopicIndex > 0) {
      setCurrentTopicIndex(currentTopicIndex - 1);
    }
  };

  const calculateScore = () => {
    if (!topics) return;

    console.log('========== CALCOLO PUNTEGGIO MATCH PAIRS ==========');
    console.log('Chat ID corrente:', matchData && matchData.chatId);
    console.log('Risposte utente:', userAnswers);
    console.log('È l\'ultimo gioco?', isLastGame);
    console.log('Dovrebbe andare al riepilogo?', shouldGoToSummary);

    let totalCorrect = 0;
    let totalPairs = 0;
    let pairResults = [];

    // Calcolo correzione
    topics.forEach((topic) => {
      const topicId = topic.id;
      const pairs = topic.pairs || [];
      totalPairs += pairs.length;

      const userTopicAnswers = userAnswers[topicId] || [];

      const singleTopicResults = pairs.map((pairObj, index) => {
        const userRight = userTopicAnswers[index] || '';
        const correct = (userRight === pairObj.right);
        if (correct) totalCorrect++;

        return {
          topicId: topicId,
          topicTitle: topic.topic,
          left: pairObj.left,
          correctRight: pairObj.right,
          userRight,
          isCorrect: correct
        };
      });

      pairResults = [...pairResults, ...singleTopicResults];
    });

    const finalScore = totalPairs > 0
      ? Math.round((totalCorrect / totalPairs) * 100)
      : 100; // Se non ci fossero coppie, punteggio = 100%

    console.log('Punteggio calcolato:', finalScore, '%');

    // Oggetto risultato da passare al parent
    const matchResult = {
      type: 'matchpairs',
      chatId: matchData && matchData.chatId,
      score: Number(finalScore),
      correctAnswers: totalCorrect,
      totalQuestions: totalPairs,
      pairResults: pairResults,
      completed: true,
      completedAt: new Date().toISOString(),
      details: {
        totalCorrect,
        totalPairs,
        percentage: finalScore
      }
    };

    // Se è l'ultimo gioco e va al riepilogo, segnalo con goToSummary
    if (shouldGoToSummary) {
      matchResult.goToSummary = true;
    }

    // Rimuovo le risposte parziali dal localStorage
    const lsKey = getLocalStorageKey();
    if (lsKey) {
      try {
        localStorage.removeItem(lsKey);
        console.log('Risposte parziali rimosse da localStorage dopo completamento');
      } catch (e) {
        console.error('Errore nella rimozione delle risposte da localStorage:', e);
      }
    }

    if (onComplete && typeof onComplete === 'function') {
      onComplete(matchResult);
    } else {
      console.error('onComplete non è definito o non è una funzione');
    }
  };

  // (Facoltativo) Funzione di reset per test/DEBUG
  const handleRestart = () => {
    setUserAnswers({});
    setCurrentTopicIndex(0);
    setShowResults(false);
    setScore(0);

    const lsKey = getLocalStorageKey();
    if (lsKey) {
      try {
        localStorage.removeItem(lsKey);
      } catch (e) {
        console.error('Errore nella rimozione da localStorage:', e);
      }
    }
  };

  // Se per qualche ragione showResults fosse true, completiamo di forza il gioco
  if (showResults) {
    setTimeout(() => calculateScore(), 0);
    return (
      <div className="matchpairs-game">
        <div className="matchpairs-loading">
          <div className="matchpairs-spinner"></div>
          <p>Caricamento risultati...</p>
        </div>
      </div>
    );
  }

  // Topic e coppie correnti
  const currentTopic = topics[currentTopicIndex];
  const currentPairs = currentTopic.pairs || [];
  const currentAnswers = userAnswers[currentTopic.id] || [];

  return (
    <div className="matchpairs-game">
      {/* Barra di progresso */}
      <div className="matchpairs-progress">
        <span className="matchpairs-progress-text">
          {currentTopicIndex + 1} / {topics.length}
        </span>
        <div className="matchpairs-progress-bar">
          <div
            className="matchpairs-progress-fill"
            style={{
              width: `${((currentTopicIndex + 1) / topics.length) * 100}%`
            }}
          />
        </div>
      </div>

      {/* Titolo del topic */}
      <h2 className="matchpairs-topic-title">
        {currentTopic.topic || "Abbinamenti"}
      </h2>

      {/* Elenco di righe da abbinare */}
      <div className="matchpairs-table">
        {currentPairs.map((pairObj, index) => {
          const userSelected = currentAnswers[index] || '';

          // Costruiamo la lista di opzioni "di destra" a partire dai pair esistenti
          const allRightOptions = currentPairs.map(p => p.right);

          return (
            <div key={index} className="matchpairs-row">
              <div className="matchpairs-left-item">
                {pairObj.left}
              </div>
              <div className="matchpairs-right-select">
                <select
                  value={userSelected}
                  onChange={(e) => handleSelectRight(currentTopic.id, index, e.target.value)}
                >
                  <option value="">-- Seleziona --</option>
                  {allRightOptions.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pulsanti navigazione */}
      <div className="matchpairs-navigation">
        <button
          className="matchpairs-button secondary"
          onClick={handlePrevTopic}
          disabled={currentTopicIndex === 0}
        >
          Indietro
        </button>
        <button
          className="matchpairs-button"
          onClick={handleNextTopic}
          disabled={
            // Disabilito se non tutte le righe hanno una risposta
            currentAnswers.length < currentPairs.length ||
            currentAnswers.some(answer => !answer)
          }
        >
          {currentTopicIndex < topics.length - 1
            ? 'Avanti'
            : 'Verifica Abbinamenti'
          }
        </button>
      </div>
    </div>
  );
};

export default MatchPairsGame;
