import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/FloatingMenu.css';
import PropTypes from 'prop-types';
import useDeleteAccount from '../api/profile/useDeleteAccount';
import useSupportEmail from '../api/auth/useSupportEmail';
import apiClient from '../api/apiClient';
import { removeTokens } from '../api/authUtils';

// Componenti modali
import DeleteAccountModal from './DeleteAccountModal';
import SupportModal from './SupportModal';

const FloatingMenu = ({ isLoggedIn, setIsLoggedIn }) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [gamesMenuOpen, setGamesMenuOpen] = useState(false); // Per aprire/chiudere la tendina "Giochi"
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isIdle, setIsIdle] = useState(false); // Per l’inattività
  const navigate = useNavigate();

  const { deleteAccount, loading: deleteLoading, error: deleteError, success: deleteSuccess } = useDeleteAccount();
  const {
    sendSupportEmail,
    loading: supportLoading,
    error: supportError,
    message: supportMessage,
  } = useSupportEmail();

  // Recupero dell'email utente
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await apiClient.get('/api/accounts/profile/');
        setUserEmail(response.data.email);
      } catch (error) {
        console.error("Errore nel recupero dell'email utente:", error);
      }
    };

    if (isLoggedIn) {
      fetchUserEmail();
    }
  }, [isLoggedIn]);

  // Logout
  const handleLogout = () => {
    removeTokens();
    setIsLoggedIn(false);
    navigate('/', { replace: true });
  };

  // Apertura/chiusura del menu profilo
  const handleProfileClick = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  // Eliminazione account
  const handleAccountDelete = async () => {
    try {
      await deleteAccount();
      setIsLoggedIn(false);
      navigate('/', { replace: true });
    } catch (err) {
      console.error("Errore nella cancellazione dell'account:", err);
    }
  };

  // Invio email di supporto
  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    const message = e.target.message.value;
    if (!message) return;

    try {
      await sendSupportEmail(message);
      e.target.reset();
      setTimeout(() => {
        setSupportModalOpen(false);
      }, 2000);
    } catch (err) {
      // Già gestito nel custom hook
    }
  };

  // Gestione inattività utente (5s di inattività => menu nascosto)
  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      setIsIdle(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsIdle(true);
      }, 5000); // 5 secondi
    };

    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(timeout);
    };
  }, []);

  // Se l'utente è inattivo, non mostrare il menu
  if (isIdle) {
    return null;
  }

  return (
    <div className="floating-menu">
      <nav className="floating-menu-nav">
        <Link to="/" className="floating-menu-item">Home</Link>
        <Link to="/resources" className="floating-menu-item">Risorse</Link>

        {/* Sezione "Giochi" con dropdown */}
        <div
          className="floating-menu-item games-menu"
          onMouseEnter={() => setGamesMenuOpen(true)}
          onMouseLeave={() => setGamesMenuOpen(false)}
        >
          Giochi
          {gamesMenuOpen && (
            <div className="games-dropdown">
              <Link
                to="/games/models"
                className="games-item"
                onClick={() => setGamesMenuOpen(false)}
              >
                Modelli Giochi
              </Link>
              <Link
                to="/games/community"
                className="games-item"
                onClick={() => setGamesMenuOpen(false)}
              >
                Giochi Community
              </Link>
              {/* 
                "I Miei Giochi" è commentato per ora.
                Rimuovi i commenti per riattivarlo quando vuoi mostrarlo.
              */}
              {/*
              {isLoggedIn && (
                <Link
                  to="/games/my"
                  className="games-item"
                  onClick={() => setGamesMenuOpen(false)}
                >
                  I Miei Giochi
                </Link>
              )}
              */}
            </div>
          )}
        </div>

        <Link to="/abbonamento" className="floating-menu-item">Abbonamento</Link>
        <Link to="/tutorial" className="floating-menu-item">Tutorial</Link>
        <Link to="/chat" className="floating-menu-item">Chat AI</Link>

        {/* Se loggato -> mostra menu profilo */}
        {isLoggedIn ? (
          <div className="profile-menu">
            <div className="profile-link" onClick={handleProfileClick}>
              <img src="/menu_icon/profile.png" alt="Profilo" className="profile-image" />
            </div>
            {profileMenuOpen && (
              <div className="profile-dropdown">
                <Link to="/profile" className="profile-item">
                  Profilo
                </Link>
                <Link to="/change-password" className="profile-item">
                  Cambia password
                </Link>
                <Link to="/change-card" className="profile-item">
                  Cambia carta
                </Link>
                <div
                  className="profile-item"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  Elimina account
                </div>
                <div
                  className="profile-item"
                  onClick={() => setSupportModalOpen(true)}
                >
                  Hai problemi?
                </div>
                <div className="profile-item" onClick={handleLogout}>
                  Esci
                </div>
              </div>
            )}
          </div>
        ) : (
          <Link to="/welcomepage" className="floating-menu-item">
            Accedi
          </Link>
        )}
      </nav>

      {/* Modale di conferma eliminazione account */}
      <DeleteAccountModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleAccountDelete}
        loading={deleteLoading}
        error={deleteError}
        success={deleteSuccess}
      />

      {/* Modale di supporto */}
      <SupportModal
        isOpen={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
        onSubmit={handleSupportSubmit}
        loading={supportLoading}
        error={supportError}
        message={supportMessage}
        userEmail={userEmail}
      />
    </div>
  );
};

FloatingMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default FloatingMenu;
