import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { AGENTS } from './constants';

/**
 * Dialog di conferma eliminazione chat
 */
const DeleteDialog = ({ 
  open, 
  chatToDelete, 
  onClose, 
  onConfirmDelete 
}) => {
  if (!chatToDelete) return null;
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Elimina Chat</DialogTitle>
      <DialogContent>
        <Typography>
          Sei sicuro di voler eliminare la chat "
          {AGENTS.find(a => a.key === chatToDelete?.agent)?.label || chatToDelete?.agent.toUpperCase()}" (ID #{chatToDelete?.id})?
          L'operazione è irreversibile.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ marginRight: '1rem' }}
          >
            Annulla
          </Button>
          <Button onClick={onConfirmDelete} variant="contained" color="error">
            Elimina
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog; 