// File: src/components/DocumentViewer/DocumentViewer.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import useGetDocument from '../api/documents/useGetDocument'; // Assicurati che il percorso sia corretto

const DocumentViewer = () => {
  const { documentType } = useParams();
  const navigate = useNavigate();
  const { document, loading, error, fetchDocument } = useGetDocument();

  useEffect(() => {
    if (documentType) {
      fetchDocument(documentType);
    }
  }, [documentType, fetchDocument]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Box className="document-viewer">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="document-viewer">
        <Typography color="error">Errore: {error}</Typography>
        <Button variant="contained" color="primary" onClick={handleBack}>
          Torna Indietro
        </Button>
      </Box>
    );
  }

  if (!document) {
    return null;
  }

  return (
    <Box className="document-viewer" p={4}>
      <Button variant="contained" color="primary" onClick={handleBack} sx={{ mb: 2 }}>
        Torna Indietro
      </Button>
      <Typography variant="h5" gutterBottom>
        Visualizzatore Documento: {documentType}
      </Typography>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          height: '80vh',
          overflow: 'hidden',
        }}
      >
        <iframe
          src={document.file_url}
          title="Documento PDF"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default DocumentViewer;
