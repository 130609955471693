import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Radio,
  TextField
} from '@mui/material';

/**
 * Dialog per l'inserimento di video (YouTube o upload)
 */
const VideoDialog = ({
  open,
  onClose,
  videoSource,
  onChangeVideoSource,
  videoUrl,
  onChangeVideoUrl,
  videoDescription,
  onChangeVideoDescription,
  onSubmitVideo
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        }
      }}
    >
      <DialogTitle sx={{ 
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '1.5rem',
        fontWeight: 'bold'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box 
            component="img" 
            src="/people/video.png" 
            alt="Video" 
            sx={{ width: '30px', height: '30px' }} 
          />
          <Typography variant="h6">Aggiungi Nuovo Video</Typography>
        </Box>
      </DialogTitle>
      
      <DialogContent sx={{ padding: '2rem' }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: {xs: 'column', md: 'row'}, 
          gap: 3, 
          mb: 3,
          alignItems: 'stretch'
        }}>
          {/* Opzione YouTube */}
          <Card 
            variant="outlined" 
            sx={{ 
              flex: 1,
              borderRadius: '0.8rem',
              borderColor: videoSource === 'youtube' ? '#FF0000' : '#e0e0e0',
              borderWidth: videoSource === 'youtube' ? '2px' : '1px',
              boxShadow: videoSource === 'youtube' ? '0 4px 12px rgba(255,0,0,0.2)' : 'none',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#FF0000',
                boxShadow: '0 4px 12px rgba(255,0,0,0.2)',
              }
            }}
            onClick={() => onChangeVideoSource('youtube')}
          >
            <CardContent sx={{ padding: '1.5rem', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Radio 
                  checked={videoSource === 'youtube'} 
                  onChange={() => onChangeVideoSource('youtube')}
                  sx={{ 
                    color: '#FF0000', 
                    '&.Mui-checked': { color: '#FF0000' } 
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
                  Video YouTube
                </Typography>
              </Box>
              
              <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                Incorpora un video da YouTube inserendo l'URL completo.
              </Typography>
              
              <Box sx={{ 
                backgroundColor: '#f5f5f5', 
                padding: '1rem', 
                borderRadius: '0.5rem',
                my: 2,
                borderLeft: '4px solid #FF0000'
              }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                  Copia l'URL da YouTube, ad esempio: https://www.youtube.com/watch?v=XXXXXXXXXX
                </Typography>
              </Box>
            </CardContent>
          </Card>
          
          {/* Opzione Upload */}
          <Card 
            variant="outlined" 
            sx={{ 
              flex: 1,
              borderRadius: '0.8rem',
              borderColor: videoSource === 'upload' ? '#673ab7' : '#e0e0e0',
              borderWidth: videoSource === 'upload' ? '2px' : '1px',
              boxShadow: videoSource === 'upload' ? '0 4px 12px rgba(103,58,183,0.2)' : 'none',
              cursor: 'not-allowed',
              transition: 'all 0.2s ease',
              opacity: 0.7,
              position: 'relative',
              '&:hover': {
                borderColor: '#673ab7',
                boxShadow: '0 4px 12px rgba(103,58,183,0.2)',
              }
            }}
          >
            {/* Badge "Coming Soon" */}
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: '#FF9800',
                color: 'white',
                borderRadius: '4px',
                padding: '2px 10px',
                fontSize: '0.7rem',
                fontWeight: 'bold',
                zIndex: 1,
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
              Disponibile presto
            </Box>
            
            <CardContent sx={{ padding: '1.5rem', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Radio 
                  checked={videoSource === 'upload'} 
                  disabled={true}
                  sx={{ 
                    color: '#673ab7', 
                    '&.Mui-checked': { color: '#673ab7' } 
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
                  Carica Video
                </Typography>
              </Box>
              
              <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                Carica un file video MP4 dal tuo dispositivo.
              </Typography>
              
              <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Questa funzionalità sarà disponibile prossimamente. Nel frattempo, puoi utilizzare i video da YouTube.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        
        {/* Sezione per l'inserimento dettagli video */}
        <Box sx={{ 
          mt: 3, 
          pt: 3, 
          borderTop: '1px solid #e0e0e0',
          animation: 'fadeIn 0.3s ease-in-out',
          '@keyframes fadeIn': {
            '0%': { opacity: 0, transform: 'translateY(10px)' },
            '100%': { opacity: 1, transform: 'translateY(0)' }
          }
        }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Inserisci URL YouTube
          </Typography>
          
          {/* Mostriamo sempre l'opzione YouTube, indipendentemente dal videoSource */}
          <TextField
            label="URL YouTube"
            value={videoUrl}
            onChange={(e) => onChangeVideoUrl(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="https://www.youtube.com/watch?v=..."
            InputProps={{
              sx: { borderRadius: '0.5rem' }
            }}
            helperText="Inserisci l'URL completo del video YouTube"
          />
          
          {/* Descrizione del video */}
          <TextField
            label="Descrizione del video"
            value={videoDescription}
            onChange={(e) => onChangeVideoDescription(e.target.value)}
            multiline
            rows={4}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            placeholder="Descrivi brevemente il contenuto del video..."
            InputProps={{
              sx: { borderRadius: '0.5rem' }
            }}
            helperText="Una breve descrizione che accompagnerà il video"
          />
          
          {/* Bottoni di azione */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button 
              onClick={onClose} 
              variant="outlined"
              sx={{ mr: 2 }}
            >
              Annulla
            </Button>
            <Button 
              onClick={onSubmitVideo} 
              variant="contained" 
              color="error"
              disabled={!videoUrl.trim() || !videoDescription.trim()}
            >
              Aggiungi video
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoDialog; 