import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@mui/material';
import { AGENTS, GAME_ICONS } from './constants';
import { keyframes } from '@emotion/react';

const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const pulseBorder = keyframes`
  0% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
  50% { box-shadow: 0 0 0 8px rgba(223,70,52,0.2); }
  100% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
`;

const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '2rem',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

/**
 * Dialog per la scelta del tipo di attività/gioco da creare
 */
const AgentDialog = ({ open, onClose, onChooseAgent }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      const imageUrls = Object.values(GAME_ICONS);
      preloadImages(imageUrls)
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
    }
  }, [open]);

  if (!open) return null;

  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '80%', md: '65%' },
          backgroundColor: 'transparent',
          zIndex: 3000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '95%', sm: '80%', md: '65%' },
        backgroundColor: '#FFFFFF',
        border: '1px solid #DF4634',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '1.875rem',
        padding: isMobile ? '1rem' : '2rem',
        zIndex: 3000,
        maxHeight: '90vh',
        overflowY: 'auto',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 'bold', 
            color: '#DF4634',
            fontSize: { xs: '1.25rem', sm: '1.4rem' }
          }}
        >
          Scegli il tipo di attività
        </Typography>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: 'grey.500',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
          }}
        >
          <Typography 
            variant="h6" 
            sx={{
              lineHeight: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            ×
          </Typography>
        </IconButton>
      </Box>

      {/* Contenuto */}
      <Box sx={{ padding: { xs: '0', sm: '0.5rem' } }}>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 3,
            color: 'text.secondary',
            fontSize: { xs: '0.9rem', sm: '1rem' },
            textAlign: 'center'
          }}
        >
          Scegli il tipo di attività da creare in base alle tue esigenze didattiche:
        </Typography>
        
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: {
            xs: '1fr 1fr',  
            sm: '1fr 1fr 1fr', 
            md: '1fr 1fr 1fr 1fr'
          },
          gap: { xs: 1.5, sm: 2 }
        }}>
          {AGENTS.map((agent) => (
            <Card 
              key={agent.key} 
              variant="outlined" 
              onClick={() => onChooseAgent(agent.key)}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                padding: { xs: '12px', sm: '16px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: { xs: '150px', sm: '170px' },
                transition: 'all 0.3s ease',
                boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                borderColor: '#e0e0e0',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                  borderColor: '#DF4634',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  boxShadow: 'inset 0px 0px 5px rgba(0,0,0,0.2)',
                }
              }}
            >
              <Box 
                sx={{
                  width: { xs: '45px', sm: '55px' },
                  height: { xs: '45px', sm: '55px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                  mb: 1.5
                }}
              >
                <Box
                  component="img"
                  src={GAME_ICONS[agent.key]}
                  alt={agent.label}
                  sx={{ 
                    width: { xs: '25px', sm: '30px' }, 
                    height: { xs: '25px', sm: '30px' }
                  }}
                  loading="lazy"
                />
              </Box>
              
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '0.85rem', sm: '0.95rem' }
                }}
              >
                {agent.label}
              </Typography>
              
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ 
                  fontSize: { xs: '0.7rem', sm: '0.8rem' }, 
                  mt: 0.5,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {agent.key === 'quiz' && "Crea domande a scelta multipla"}
                {agent.key === 'fillthebox' && "Completa frasi con parole mancanti"}
                {agent.key === 'selectgroup' && "Raggruppa elementi in categorie"}
                {agent.key === 'matchpairs' && "Abbina coppie di elementi"}
                {agent.key === 'rearrange' && "Riordina parole o frasi"}
                {agent.key === 'crossword' && "Crea cruciverba interattivi"}
                {agent.key === 'scrittura' && "Genera o inserisci un testo"}
                {agent.key === 'video' && "Aggiungi video da YouTube"}
              </Typography>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AgentDialog;