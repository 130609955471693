// File: UDAFormatDialog.jsx

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';     
import DescriptionIcon from '@mui/icons-material/Description';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import useGetDocuments from '../../api/documents/useGetDocuments';

const UDAFormatDialog = ({
  open,
  onClose,
  onConfirmNoFormat,
  onConfirmFromProfile,
  onConfirmFromDevice,
}) => {
  const [showProfileDocs, setShowProfileDocs] = useState(false);

  // Hook per leggere i documenti dal profilo (come in MessageInput)
  const { documents, loading } = useGetDocuments();

  // Handler per click su “Carica Format da Profilo”
  const handleFromProfile = () => {
    setShowProfileDocs(true);
  };

  // Handler per la selezione di un documento
  const handleSelectDocument = (doc) => {
    // Chiudo la dialog subito
    onClose();
    // Poi passo il doc al parent
    onConfirmFromProfile(doc);
  };

  // Handler per il click “Carica Nessun Format”
  const handleNoFormatClick = () => {
    // Chiudo la dialog subito
    onClose();
    // Lancio la funzione
    onConfirmNoFormat();
  };

  // Se vogliamo includere anche l’upload da device direttamente qui
  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      // Chiudo la dialog subito
      onClose();
      // Passiamo solo il primo file (o tutti, a tua scelta)
      onConfirmFromDevice(selectedFiles[0]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ borderRadius: '1.875rem' }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Scegli come creare l’UDA
        </Typography>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: '1.5rem' }}>
        {!showProfileDocs ? (
          <>
            <Typography sx={{ marginBottom: '1.5rem', fontSize: '0.95rem' }}>
              Come vuoi procedere per il formato?
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* Bottone: Nessun Format Predefinito */}
              <Button
                variant="outlined"
                onClick={handleNoFormatClick}
                sx={{
                  borderRadius: '3.125rem',
                  borderColor: '#DF4634',
                  color: '#000',
                  width: '100%',
                  textTransform: 'none',
                  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0.6rem 1rem',
                  '&:hover': {
                    borderColor: '#DF4634',
                    backgroundColor: 'rgba(223, 70, 52, 0.07)',
                  },
                }}
              >
                <AutoAwesomeIcon sx={{ marginRight: '0.5rem' }} />
                Nessun Format Predefinito
              </Button>

              {/* Bottone: Carica Format da Profilo */}
              <Button
                variant="outlined"
                onClick={handleFromProfile}
                sx={{
                  borderRadius: '3.125rem',
                  borderColor: '#DF4634',
                  color: '#000',
                  width: '100%',
                  textTransform: 'none',
                  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0.6rem 1rem',
                  '&:hover': {
                    borderColor: '#DF4634',
                    backgroundColor: 'rgba(223, 70, 52, 0.07)',
                  },
                }}
              >
                <FolderOpenIcon sx={{ marginRight: '0.5rem' }} />
                Carica Format da Profilo
              </Button>

              {/* Bottone: Carica Format da Dispositivo */}
              <Button
                variant="outlined"
                component="label"
                sx={{
                  borderRadius: '3.125rem',
                  borderColor: '#DF4634',
                  color: '#000',
                  width: '100%',
                  textTransform: 'none',
                  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0.6rem 1rem',
                  '&:hover': {
                    borderColor: '#DF4634',
                    backgroundColor: 'rgba(223, 70, 52, 0.07)',
                  },
                }}
              >
                <UploadFileIcon sx={{ marginRight: '0.5rem' }} />
                Carica Format da Dispositivo
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  hidden
                  onChange={handleFileSelect}
                />
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
              Seleziona un documento dal tuo profilo:
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <List>
                {documents.map((doc) => (
                  <ListItem
                    button
                    key={doc.id}
                    onClick={() => handleSelectDocument(doc)}
                    sx={{
                      borderRadius: '0.75rem',
                      marginBottom: '0.5rem',
                      '&:hover': {
                        backgroundColor: 'rgba(223,70,52,0.06)',
                      },
                    }}
                  >
                    <InsertDriveFileIcon sx={{ marginRight: '0.5rem', color: '#999' }} />
                    <ListItemText primary={doc.document_name} />
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: '0.5rem 1.5rem' }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            padding: '0.4rem 1.2rem',
            fontSize: '0.8rem',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UDAFormatDialog;
