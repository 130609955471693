import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { AGENTS, WELCOME_MESSAGES } from './constants';
import MessageRenderer from './MessageRenderer';
import {
  QuizEditable,
  FillTheBoxEditable,
  SelectGroupEditable,
  MatchPairsEditable,
  RearrangeEditable,
  CrosswordEditable
} from './GameComponents';

import {
  isQuizMessage,
  parseQuiz,
  isFillTheBoxMessage,
  parseFillTheBox,
  isSelectGroupMessage,
  parseSelectGroup,
  isMatchPairsMessage,
  parseMatchPairs,
  isRearrangeMessage,
  parseRearrange,
  isCrosswordMessage,
  parseCrossword,
  isYoutubeMessage,
  isWritingMessage
} from '../utils/gameMessageUtils';

/**
 * Componente che mostra il dettaglio della chat quando si clicca sulla penna
 */
const GlobalChatDetail = ({
  open,
  onClose,
  selectedChat,
  lastAssistantMessage,
  editMode,
  onStartEdit,
  onSaveEdit,
  onCancelEdit,
  editedText,
  onChangeEditedText,
  handleEditableUpdate,
  newUserMessage,
  onChangeNewUserMessage,
  generating,
  onSendMessage,
  detailLoading,
}) => {
  // Funzione per verificare se il messaggio è di un tipo che non può essere modificato
  const isNotEditableMessage = () => {
    if (!lastAssistantMessage) return false;
    return isYoutubeMessage(lastAssistantMessage) || isCrosswordMessage(lastAssistantMessage) || isFillTheBoxMessage(lastAssistantMessage);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 300 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '900px',
            height: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #ddd',
            boxShadow: 24,
            borderRadius: '1rem',
            display: 'flex',
            flexDirection: 'column',
            p: 2
          }}
        >
          {/* Header modal */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {AGENTS.find(a => a.key === selectedChat?.agent)?.label || selectedChat?.agent.toUpperCase()}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {lastAssistantMessage && !editMode && !isNotEditableMessage() && (
                <Button onClick={onStartEdit} variant="text" sx={{ 
                  borderRadius: '1rem',
                  color: '#757575'
                }}>
                  Modifica
                </Button>
              )}
              {editMode && (
                <>
                  <Button onClick={onSaveEdit} variant="contained" sx={{ 
                    borderRadius: '1rem',
                    backgroundColor: '#757575',
                    '&:hover': {
                      backgroundColor: '#616161'
                    }
                  }}>
                    Salva
                  </Button>
                  <Button onClick={onCancelEdit} variant="outlined" sx={{ 
                    borderRadius: '1rem',
                    color: '#757575',
                    borderColor: '#757575',
                    '&:hover': {
                      borderColor: '#616161',
                      backgroundColor: 'rgba(117, 117, 117, 0.04)'
                    }
                  }}>
                    Annulla
                  </Button>
                </>
              )}
              <Button onClick={onClose} variant="outlined" sx={{ 
                borderRadius: '1rem',
                color: '#757575',
                borderColor: '#757575',
                '&:hover': {
                  borderColor: '#616161',
                  backgroundColor: 'rgba(117, 117, 117, 0.04)'
                }
              }}>
                Chiudi
              </Button>
            </Box>
          </Box>

          {/* Contenuto principale: ultima risposta e form di input */}
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {/* SPINNER mentre si caricano i dettagli */}
            {detailLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress sx={{ color: '#DF4634' }} />
              </Box>
            ) : (
              <>
                {/* SPINNER mentre GPT elabora */}
                {generating && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      color: 'red',
                      mb: 2,
                    }}
                  >
                    <CircularProgress size={24} sx={{ color: 'red' }} />
                    <Typography sx={{ color: 'red' }}>
                      Minerva sta creando l'attività
                    </Typography>
                  </Box>
                )}

                {/* ULTIMA RISPOSTA (Assistant) */}
                {lastAssistantMessage ? (
                  <>
                    {!editMode ? (
                      <Box sx={{ mb: 2 }}>
                        {/* Rendering del messaggio "mini" o markdown */}
                        <MessageRenderer message={lastAssistantMessage} chat={selectedChat} />
                      </Box>
                    ) : (
                      <Box sx={{ mb: 2 }}>
                        {/* Verifica il tipo di messaggio e visualizza il componente di editing appropriato */}
                        {isQuizMessage(lastAssistantMessage) ? (
                          <QuizEditable 
                            quizData={parseQuiz(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : isFillTheBoxMessage(lastAssistantMessage) ? (
                          <FillTheBoxEditable 
                            fillData={parseFillTheBox(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : isSelectGroupMessage(lastAssistantMessage) ? (
                          <SelectGroupEditable 
                            data={parseSelectGroup(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : isMatchPairsMessage(lastAssistantMessage) ? (
                          <MatchPairsEditable 
                            data={parseMatchPairs(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : isRearrangeMessage(lastAssistantMessage) ? (
                          <RearrangeEditable 
                            data={parseRearrange(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : isCrosswordMessage(lastAssistantMessage) ? (
                          <CrosswordEditable 
                            data={parseCrossword(lastAssistantMessage)} 
                            onUpdate={handleEditableUpdate} 
                          />
                        ) : (
                          /* Per gli altri tipi di messaggi, usa ancora il TextField */
                          <TextField
                            multiline
                            rows={6}
                            value={editedText}
                            onChange={(e) => onChangeEditedText(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ 
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '1rem'
                              }
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  <Box sx={{ 
                    p: 3, 
                    backgroundColor: 'rgba(223, 70, 52, 0.05)', 
                    borderRadius: '1rem',
                    border: '1px dashed #DF4634'
                  }}>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>
                      {WELCOME_MESSAGES[selectedChat?.agent] || 'Seleziona un tipo di gioco per iniziare.'}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>

          {/* Form di input per l'utente */}
          {/* Mostra il form solo se NON è una chat di scrittura con un video YouTube come ultimo messaggio */}
          {!(selectedChat?.agent === 'scrittura' && lastAssistantMessage && isYoutubeMessage(lastAssistantMessage)) && (
            <Box sx={{ borderTop: '1px solid #ddd', pt: 2 }}>
              <form onSubmit={onSendMessage} style={{ display: 'flex', gap: '8px' }}>
                <TextField
                  placeholder="Scrivi la tua richiesta..."
                  fullWidth
                  multiline
                  rows={2}
                  value={newUserMessage}
                  onChange={(e) => onChangeNewUserMessage(e.target.value)}
                  sx={{ 
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '1rem',
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused fieldset': { 
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderWidth: '1px'
                      }
                    }
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ 
                    width: '120px', 
                    alignSelf: 'flex-end',
                    borderRadius: '1rem',
                    backgroundColor: '#DF4634',
                    '&:hover': {
                      backgroundColor: '#E57373',
                      boxShadow: '0 4px 10px rgba(223, 70, 52, 0.3)',
                    }
                  }}
                  disabled={generating}
                >
                  Invia
                </Button>
              </form>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default GlobalChatDetail; 