import showdown from 'showdown';

// Inizializzazione del convertitore markdown
export const showdownConverter = new showdown.Converter({
  flavor: 'github',
  tables: true,
  ghCodeBlocks: true,
});

// Funzione per controllare se un messaggio è un video
export const isVideoMessage = (message) => {
  try {
    if (!message || !message.text) return false;
    const data = JSON.parse(message.text);
    return data.type === 'youtube';
  } catch (e) {
    return false;
  }
};

// Funzione per estrarre i dati del video dal messaggio
export const parseVideo = (message) => {
  try {
    return JSON.parse(message.text);
  } catch (e) {
    return { type: 'unknown', description: 'Video non valido' };
  }
};

// Funzione per trovare l'ultimo messaggio dell'assistente in una chat
export const getLastAssistantMessage = (chat) => {
  if (!chat.messages) return null;
  const asst = chat.messages.filter((m) => m.sender === 'assistant');
  if (asst.length === 0) return null;
  return asst[asst.length - 1];
};

// Funzione per estrarre l'ID del video da un URL YouTube
export const extractYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : null;
};

// Funzione per verificare se un URL YouTube è valido
export const isValidYoutubeUrl = (url) => {
  // Regex aggiornata per accettare parametri aggiuntivi nell'URL (come &t=9s)
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}|youtu\.be\/[a-zA-Z0-9_-]{11})(&.*)?$/;
  return youtubeRegex.test(url);
};

// Funzione per trovare la chat più recente per un dato agente
export const getMostRecentChatByAgent = (subChats, agentKey) => {
  // Filtra le chat per l'agente specificato
  const agentChats = subChats.filter(chat => chat.agent === agentKey);
  
  // Se non ci sono chat, ritorna null
  if (agentChats.length === 0) {
    return null;
  }
  
  // Altrimenti trova quella con position o id maggiore (la più recente)
  return agentChats.reduce((latest, current) => {
    // Utilizziamo position se disponibile (è incrementale), altrimenti id
    return (current.position > latest.position) || 
           (!current.position && !latest.position && current.id > latest.id) 
           ? current : latest;
  }, agentChats[0]);
}; 