// src/components/ActivationSuccess.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import VideoComponent from './VideoComponent';
import { Box, Typography, Button } from '@mui/material';

const ActivationSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',  // Distribuisce gli elementi verticalmente
        height: '100vh',
        position: 'relative',
        fontFamily: 'Inter, sans-serif',
        padding: '1rem',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          Account Attivato!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Il tuo account è stato attivato con successo.
        </Typography>
      </Box>

      {/*
        Qui limitiamo la larghezza del box che contiene il video 
        per renderlo più piccolo e ben centrato.
      */}
      <Box sx={{ width: '70%', maxWidth: '400px' }}>
        <VideoComponent videoSrc="/video/subscription.mp4" />
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          onClick={() => navigate('/login')}
          sx={{
            width: 320,
            height: 50,
            backgroundColor: '#DF4634',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '30px',
            textTransform: 'none',
            fontSize: '15px',
            lineHeight: '27px',
            color: '#FFFFFF',
            marginTop: '16px',
            '&:hover': {
              backgroundColor: '#DF4634',
            },
          }}
        >
          Vai al Login
        </Button>
      </Box>
    </Box>
  );
};

export default ActivationSuccess;
