import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Skeleton,
  Tooltip,
  Collapse,
} from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SidebarContent = ({
  chats,
  globalChats = [], // Array di GlobalChat
  selectedChat,
  selectedGlobalChat,
  selectChat,
  selectGlobalChat,
  handleNewChat,
  handleDeleteChat,
  handleDeleteGlobalChat,
  handleLogout,
  navigate,
  selectedOption,
  onClose,
  onMyGamesClick,
  loadingChats = false,
  loadingGlobalChats = false,
  handleCreateNewGlobalChat = () => {}, // Funzione per creare una nuova GlobalChat
  location = '' // Percorso corrente della pagina
}) => {
  // Aggiungo lo stato per controllare l'apertura del menu Primi Passi
  const [primiPassiOpen, setPrimiPassiOpen] = useState(false);

  // Agenti di tipo "gioco"
  const gameAgents = ['quiz', 'fillthebox', 'selectgroup', 'matchpairs', 'rearrange', 'crossword'];

  // Determiniamo se esiste almeno una chat con quegli agent e title != null
  const hasAttivita = chats.some((chat) => chat.agent === 'attività' && chat.title !== null);
  const hasVerificaInterattiva = globalChats.some(gc => gc.chats && gc.chats.length > 0); // Verifica se esiste almeno una GlobalChat con sottochat
  const hasLaboratori = chats.some((chat) => chat.agent === 'laboratori' && chat.title !== null);

  // Se tutte e tre sono vere => missioni completate
  const allMissionsCompleted = hasAttivita && hasVerificaInterattiva && hasLaboratori;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      {/* Contenitore per il logo e il titolo */}
      <Box sx={{ padding: '1rem', paddingTop: '0.5rem' }}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            marginTop: '-0.5rem'
          }}
          onClick={() => navigate('/')}
        >
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Minerva AI
            </Typography>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                fontFamily: "'Times New Roman', Times, serif",
                fontStyle: 'italic', 
                fontSize: '0.7rem', 
                color: 'rgba(107, 114, 128, 0.9)',
                marginTop: '-0.4rem',
                textAlign: 'left',
                letterSpacing: '0.5px'
              }}
            >
              Teach Different
            </Typography>
          </Box>
          <Box
            sx={{
              width: '2.5rem',
              height: '2.5rem',
              backgroundColor: '#DF4634',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            <Box
              component="img"
              src="/logo/logobianco.svg"
              alt="Logo"
              sx={{ width: '2.8rem', height: '2.8rem' }}
            />
          </Box>
        </Box>
        
        {/* Bottone "Nuova Chat" */}
        <Box sx={{ marginTop: '1rem' }}>
          <Button
            variant="contained"
            onClick={() => {
              handleNewChat(selectedOption);
              if (typeof onClose === 'function') {
                onClose();
              }
            }}
            sx={{
              backgroundColor: '#DF4634',
              borderRadius: '1.875rem',
              width: '100%',
              textTransform: 'none',
              fontSize: '0.7rem',
              fontWeight: 400,
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            + Nuova chat
          </Button>
        </Box>

        {/* Sezione PRIMI PASSI => visibile solo se !allMissionsCompleted */}
        {!allMissionsCompleted && (
          <Box 
            sx={{ marginTop: '0.8rem', width: '100%' }}
          >
            {/* Header cliccabile */}
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.05)',
                },
                borderRadius: '4px',
                padding: '2px 4px',
              }}
              onClick={() => setPrimiPassiOpen(!primiPassiOpen)}
            >
              {primiPassiOpen ? 
                <KeyboardArrowDownIcon sx={{ fontSize: '14px', color: '#6B7280' }} /> : 
                <KeyboardArrowRightIcon sx={{ fontSize: '14px', color: '#6B7280' }} />
              }
              <Typography
                sx={{
                  color: '#6B7280',
                  fontFamily: 'Inter',
                  fontSize: '10px',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textAlign: 'left',
                  marginLeft: '4px',
                }}
              >
                PRIMI PASSI
              </Typography>
            </Box>
            
            {/* Contenuto collassabile */}
            <Collapse in={primiPassiOpen}>
              <FormGroup sx={{ '& .MuiFormControlLabel-root': { margin: 0 }, ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        color: '#DF4634',
                        '&.Mui-checked': {
                          color: '#DF4634',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem',
                        },
                        padding: '2px',
                      }}
                    />
                  }
                  label={
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNewChat('attività');
                        if (typeof onClose === 'function') {
                          onClose();
                        }
                      }}
                      sx={{ fontSize: '0.7rem', cursor: 'pointer' }}
                    >
                      Crea la tua prima attività didattica
                    </Typography>
                  }
                  checked={hasAttivita}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        color: '#DF4634',
                        '&.Mui-checked': {
                          color: '#DF4634',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem',
                        },
                        padding: '2px',
                      }}
                    />
                  }
                  label={
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCreateNewGlobalChat();
                        if (typeof onClose === 'function') {
                          onClose();
                        }
                      }}
                      sx={{ fontSize: '0.7rem', cursor: 'pointer' }}
                    >
                      Crea la tua prima verifica interattiva
                    </Typography>
                  }
                  checked={hasVerificaInterattiva}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        color: '#DF4634',
                        '&.Mui-checked': {
                          color: '#DF4634',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem',
                        },
                        padding: '2px',
                      }}
                    />
                  }
                  label={
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNewChat('laboratori');
                        if (typeof onClose === 'function') {
                          onClose();
                        }
                      }}
                      sx={{ fontSize: '0.7rem', cursor: 'pointer' }}
                    >
                      Crea un laboratorio didattico
                    </Typography>
                  }
                  checked={hasLaboratori}
                />
              </FormGroup>
            </Collapse>
          </Box>
        )}
      </Box>

      {/* Lista delle conversazioni */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '0 1rem',
          maxHeight: 'calc(100vh - 300px)',
          position: 'relative', // Per posizionamento corretto dell'intestazione sticky
        }}
      >
        {/* SEZIONE HISTORY UNIFICATA - Intestazione fissa */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#FFFFFF',
            zIndex: 10,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <Typography
            sx={{
              color: '#6B7280',
              fontFamily: 'Inter',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: 'normal',
              textAlign: 'left',
              width: '100%',
            }}
          >
            CONVERSAZIONI
          </Typography>
        </Box>

        {(loadingChats || loadingGlobalChats) ? (
          // Skeleton durante il caricamento
          <>
            <Skeleton variant="rounded" height={40} sx={{ mb: 1, borderRadius: '0.5rem' }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 1, borderRadius: '0.5rem' }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 1, borderRadius: '0.5rem' }} />
          </>
        ) : (chats.length === 0 && globalChats.length === 0) ? (
          <Typography 
            variant="body2" 
            sx={{
              fontStyle: 'italic',
              color: '#999',
              fontSize: '0.8rem',
              padding: '0.5rem 0',
              textAlign: 'center'
            }}
          >
            Non hai ancora chat attive
          </Typography>
        ) : (
          <List disablePadding>
            {/* Combina le chat normali e le GlobalChat in un unico array */}
            {[
              ...chats.map(chat => ({
                ...chat,
                type: 'normal',
                creation_date: new Date(chat.created_at)
              })),
              ...globalChats.map(globalChat => ({
                ...globalChat,
                type: 'global',
                creation_date: new Date(globalChat.created_at)
              }))
            ]
              // Ordina per data di creazione (più recenti in cima)
              .sort((a, b) => b.creation_date - a.creation_date)
              .map((item) => {
                if (item.type === 'normal') {
                  // Elemento normale (chat singola)
                  const chat = item;
                  const isGameAgent = gameAgents.includes(chat.agent);
                  
                  // Determina l'icona in base al tipo di agente
                  let iconSrc;
                  if (isGameAgent) {
                    // Mantieni la logica esistente per gli agenti di gioco
                    iconSrc = selectedChat?.id === chat.id
                      ? '/chat_icon/gamehistoryred.png'
                      : '/chat_icon/gamehistory.png';
                  } else {
                    // Per gli altri agenti, seleziona l'icona appropriata
                    switch (chat.agent) {
                      case 'metodologie':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/metodologie.png'
                          : '/people/metodologie.png';
                        break;
                      case 'attività':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/attività.png'
                          : '/people/attività.png';
                        break;
                      case 'laboratori':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/laboratorio.png'
                          : '/people/laboratorio.png';
                        break;
                      case 'scrittura':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/scrittura.png'
                          : '/people/scrittura.png';
                        break;
                      case 'minerva':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/minerva.png'
                          : '/people/minerva.png';
                        break;
                      case 'montessori':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/montessori.png'
                          : '/people/montessori.png';
                        break;
                      case 'malaguzzi':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/malaguzzi.png'
                          : '/people/malaguzzi.png';
                        break;
                      case 'immaginai':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/immaginai.png'
                          : '/people/immaginai.png';
                        break;
                      case 'verifica':
                        iconSrc = selectedChat?.id === chat.id
                          ? '/people/giochi.png'
                          : '/people/giochi.png';
                        break;
                      default:
                        // Icona predefinita per gli agenti non riconosciuti
                        iconSrc = selectedChat?.id === chat.id
                          ? '/chat_icon/chatred.png'
                          : '/chat_icon/chat.png';
                    }
                  }

                  return (
                    <ListItem
                      key={`chat-${chat.id}`}
                      button
                      onClick={() => {
                        selectChat(chat);
                        if (typeof onClose === 'function') {
                          onClose();
                        }
                      }}
                      sx={{
                        padding: '0.5rem 0.5rem',
                        borderRadius: '0.5rem',
                        marginBottom: '0.2rem',
                        position: 'relative',
                        backgroundColor: selectedChat?.id === chat.id 
                          ? 'rgba(223, 70, 52, 0.1)'
                          : 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(223, 70, 52, 0.1)',
                        },
                        '&:hover .delete-icon': {
                          visibility: 'visible',
                        },
                        width: '100%',
                        minWidth: '100%',
                      }}
                    >
                      <Box
                        component="img"
                        src={iconSrc}
                        alt="Chat Icon"
                        sx={{
                          width: isGameAgent || chat.agent === 'default' ? '1.2rem' : '1.5rem',
                          height: isGameAgent || chat.agent === 'default' ? '1.2rem' : '1.5rem',
                          marginRight: '0.5rem',
                          filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3))',
                        }}
                      />
                      <ListItemText
                        primary={chat.title || `Nuova Chat`}
                        secondary={new Date(chat.created_at).toLocaleDateString('it-IT', { 
                          year: 'numeric', 
                          month: 'short', 
                          day: 'numeric' 
                        })}
                        sx={{
                          margin: 0,
                          '& .MuiTypography-root.MuiTypography-body1': {
                            color: selectedChat?.id === chat.id ? '#DF4634' : 'inherit',
                            fontWeight: selectedChat?.id === chat.id ? 600 : 400,
                            fontSize: '0.8rem',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            lineHeight: '1.2em',
                            maxHeight: '2.4em'
                          },
                          '& .MuiTypography-root.MuiTypography-body2': {
                            fontSize: '0.7rem',
                            color: '#6B7280'
                          }
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteChat(chat.id);
                        }}
                        className="delete-icon"
                        sx={{
                          position: 'absolute',
                          right: '0.25rem',
                          visibility: 'hidden',
                          padding: '0.25rem',
                        }}
                      >
                        <img
                          src="/chat_icon/remove.png"
                          alt="Remove Chat"
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      </IconButton>
                    </ListItem>
                  );
                } else {
                  // Elemento GlobalChat (verifica interattiva)
                  const globalChat = item;
                  const isSelected = selectedGlobalChat?.id === globalChat.id;
                  
                  return (
                    <ListItem
                      key={`globalchat-${globalChat.id}`}
                      button
                      onClick={() => {
                        selectGlobalChat(globalChat, true);
                        if (typeof onClose === 'function') {
                          onClose();
                        }
                      }}
                      sx={{
                        padding: '0.5rem 0.5rem',
                        borderRadius: '0.5rem',
                        marginBottom: '0.2rem',
                        position: 'relative',
                        backgroundColor: isSelected 
                          ? 'rgba(223, 70, 52, 0.1)'
                          : 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(223, 70, 52, 0.1)',
                        },
                        '&:hover .delete-icon': {
                          visibility: 'visible',
                        },
                        width: '100%',
                        minWidth: '100%',
                      }}
                    >
                      <Box
                        component="img"
                        src={isSelected ? '/people/giochi.png' : '/people/giochi.png'}
                        alt="Verifica interattiva"
                        sx={{
                          width: '1.5rem',
                          height: '1.5rem',
                          marginRight: '0.5rem',
                          filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3))',
                        }}
                      />
                      <ListItemText
                        primary={globalChat.title || `Verifica #${globalChat.id}`}
                        secondary={new Date(globalChat.created_at).toLocaleDateString('it-IT', { 
                          year: 'numeric', 
                          month: 'short', 
                          day: 'numeric' 
                        })}
                        sx={{
                          margin: 0,
                          '& .MuiTypography-root.MuiTypography-body1': {
                            color: isSelected ? '#DF4634' : 'inherit',
                            fontWeight: isSelected ? 600 : 400,
                            fontSize: '0.8rem',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            lineHeight: '1.2em',
                            maxHeight: '2.4em'
                          },
                          '& .MuiTypography-root.MuiTypography-body2': {
                            fontSize: '0.7rem',
                            color: '#6B7280'
                          }
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteGlobalChat(globalChat.id);
                        }}
                        className="delete-icon"
                        sx={{
                          position: 'absolute',
                          right: '0.25rem',
                          visibility: 'hidden',
                          padding: '0.25rem',
                        }}
                      >
                        <img
                          src="/chat_icon/remove.png"
                          alt="Remove GlobalChat"
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      </IconButton>
                    </ListItem>
                  );
                }
              })}
          </List>
        )}
      </Box>

      {/* Bottoni in fondo al sidebar */}
      <Box
        sx={{
          padding: '1rem',
          borderTop: '1px solid #e0e0e0',
          backgroundColor: '#fff',
          zIndex: 1,
        }}
      >
        {/* BOTTONE GIOCHI */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            onMyGamesClick(true);
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/games.png"
              alt="Games Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Verifiche
          </Box>
        </Button>

        {/* BOTTONE HOME - RIATTIVATO */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/');
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/home.png"
              alt="Home Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Home
          </Box>
        </Button>

        {/* BOTTONE RISORSE */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/resources');
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/resources.png"
              alt="Resources Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Risorse
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarContent;
