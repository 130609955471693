export const agentExamples = {
  quiz: [
    "Crea un quiz di matematica per la quinta primaria sulle frazioni, includendo domande a scelta multipla.",
    "Prepara una verifica di storia sui Sumeri e i Babilonesi con 1 domanda aperte e 9 a scelta multipla",
    "Prepara una verifica di comprensione del testo con una lettura sull’amicizia e 10 domande",
    "Prepara una verifica di inglese sui giorni, i mesi e le stagioni per bambini di classe terza "
  ],
  laboratori: [
    "Mi serve un laboratorio di scienze per una seconda primaria, utilizzando materiali riciclati per costruire un mini orto.",
    "Proponi un laboratorio di arte e storia per la quarta primaria, in cui si creano maschere ispirate alle civiltà antiche.",
    "Suggerisci un laboratorio per la scuola dell'infanzia in cui i bambini esplorano i colori primari e secondari attraverso esperimenti con gli acquerelli .",
    "Crea un laboratorio per celebrare la Giornata della Lingua Madre per una classe terza di scuola primaria",
    "Crea un laboratorio d’arte su Van Gogh per una classe quinta di scuola primaria",
    "Crea un laboratorio di tecnologia per insegnare a bambini di classe quarta a utilizzare Scratch Junior"
  ],
  attività: [
    "Crea un'attività narrativa per la quinta primaria con una storia ambientata in una foresta pluviale, seguita da esercizi di comprensione, fai più tipologie di esercizi anche con tabelle",
    "Progetta un'attività didattica sul ciclo dell'acqua per una quarta primaria, includendo un esperimento pratico e una breve relazione scritta.",
    "Progetta un'attività sulla storia dell'arte per la terza primaria, con focus su Leonardo da Vinci e un esercizio creativo per disegnare un'invenzione propria.",
    "Idea un’attività interdisciplinare in cui una classe quarta primaria simula un mercato: i bambini preparano cartellini dei prezzi, calcolano il resto e discutono di economia locale.",
    "Prepara un esercizio utile per imparare a scrivere un testo descrittivo ",
    "Prepara esercizi utili per imparare a scrivere riassunti",
    "Prepara esercizi per imparare a inventare il testo di problemi partendo da dati noti",
  ],
  metodologie: [
    "Quali metodologie innovative posso usare per insegnare storia in modo coinvolgente alla scuola media?",
    "Quali tecniche posso applicare per supportare un bambino nello spettro autistico in una classe mista di prima primaria?",
    "Suggerisci approcci basati sull'apprendimento cooperativo per insegnare matematica in una quinta primaria.",
    "Quali strategie posso utilizzare per insegnare coding in modo interdisciplinare nella scuola secondaria di primo grado?",
    "Spiega come gamificare una lezione di storia per una terza media, utilizzando punteggi e sfide tematiche per coinvolgere gli studenti.",
    "Quale metodologia posso utilizzare per insegnare le tabelline in modo divertente e efficace?",
    "Quali metodologie consigli di utilizzare per avviare i bambini alla scrittura in corsivo?",
    "Quale metodo di letto-scrittura ritieni più efficace utilizzare con bambini dsa?",
  ],
  montessori: [
    "Come applicare il metodo Montessori per insegnare geometria con materiali concreti in una quarta primaria?",
    "Suggerisci attività Montessori che aiutino i bambini di 5 anni a sviluppare la concentrazione e la motricità fine",
    "Proponi un’attività pratica Montessori per insegnare il concetto di moltiplicazione in una terza primaria",
    "Come posso adattare il metodo Montessori per un progetto di scienze sulla crescita delle piante per una seconda primaria?",
    "Idee per attività artistiche Montessori che combinino arte e musica per bambini della scuola dell'infanzia",
    "Progetta un’attività Montessori per insegnare a leggere l’orologio a bambini di classe seconda",
    "Progetta un’attività per insegnare a utilizzare il compasso",
    "Progetta un’attività per insegnare il concetto di giorni, mesi e anni",
  ],
  malaguzzi: [
    "Idee per un progetto educativo seguendo l'approccio di Reggio Emilia sul tema delle stagioni per bambini di 6 anni.",
    "Come incoraggiare l'espressione artistica e l’autonomia secondo Malaguzzi in una classe prima primaria?",
    "Proponi un progetto interdisciplinare che coinvolga arte, scienze e musica per una scuola dell'infanzia sull'inverno",
    "Suggerisci un’attività che aiuti i bambini di 7 anni a esplorare la loro città attraverso l’approccio Reggio Emilia",
    "Proponi un progetto per sviluppare e coltivare  life skills in una classe terza",
    "Proponi un progetto per sviluppare il pensiero divergente",
    "Suggerisci attività per esplorare il concetto di perimetro in modo concreto e creativo",
  ],
  scrittura: [
    "Aiutami a scrivere un verbale per la riunione dei genitori di una classe terza primaria.",
    "Ho bisogno di una UDA interdisciplinare per italiano per una quarta primaria, con focus sull’ecosistema marino.",
    "Progetta una lettera da inviare alle famiglie per introdurre il progetto annuale della scuola dell’infanzia.",
    "Aiutami a scrivere un regolamento interno per la gestione dei laboratori di scienze nella scuola media.",
    "Crea una scheda di valutazione delle competenze interdisciplinari per una classe seconda primaria.",
    "Aiutami a stendere un PEI per un alunno con disabilità in una quarta primaria.",
    "Scrivi il verbale della programmazione settimanale includendo i seguenti punti: proposte gite, confronto sulle dinamiche di gruppo della classe…",
    "Scrivi una mail alla segreteria per segnalare il malfunzionamento della LIM",
    "Scrivi un avviso ai genitori per segnalare la presenza di pidocchi"
  ],
  immaginai: [
    "Crea un’immagine che rappresenti il ciclo dell’acqua con illustrazioni per bambini.",
    "Crea un’immagine di un castello medievale da colorare per la scuola primaria.",
    "Genera un’illustrazione che rappresenti un ecosistema marino, con pesci, alghe e coralli.",
    "Disegna una mappa di un’isola immaginaria per un laboratorio di geografia e creatività.",
    "Crea un’immagine che raffiguri la rivoluzione industriale, con treni a vapore e fabbriche, per studenti di terza media.",
    "Elabora un’illustrazione che mostri il ciclo vitale di una farfalla con quattro stadi (uovo, bruco, crisalide, adulto), pensata per bambini di seconda primaria.",
    "Crea una maschera di carnevale ispirata agli antichi Romani",
    "Crea un mandala da colorare per i momenti di pausa",
    "Crea l’immagine di un faraone dell’antico Egitto",
  ],
  minerva: [
    "Quali sono le Indicazioni Nazionali per il curricolo della scuola primaria relative alle scienze?",
    "Puoi spiegarmi come redigere un PTOF per una scuola primaria, includendo attività interdisciplinari?",
    "Quali normative regolano l'insegnamento di educazione civica nella scuola media?",
    "Quali strategie di inclusione sono suggerite per una classe con alunni stranieri?",
    "Spiegami cosa prevede il ruolo del team docenti nelle scuole dell'infanzia.",
    "Come strutturare un progetto di educazione civica e costituzione per la quinta primaria, in linea con le linee guida ministeriali?"
  ],
  fillthebox: [
    "Crea un esercizio in cui gli studenti completano frasi sulle piante, usando opzioni legate a radici, foglie e fiori",
    "Genera un’attività per completare frasi sui pianeti del sistema solare, usando nomi e caratteristiche",
    "Proponi un esercizio in cui i bambini completano frasi sul ciclo della vita delle farfalle",
    "Progetta frasi da completare in inglese, legate agli animali della fattoria",
    "Crea un esercizio di completamento con rime semplici in italiano, per stimolare la creatività di una classe prima primaria",
    "Crea un esercizio con frasi in cui segnare se inserire ho, oh, o",
    "Crea un esercizio in cui inserire gli articoli indeterminativi",
    "Crea un esercizio in cui inserire e oppure è",
    "Crea un esercizio in cui trasformare i verbi al presente in verbi al passato prossimo",
  ],
  selectgroup: [
    "Crea un esercizio in cui gli studenti raggruppano animali in base al loro habitat (terra, acqua, aria).",
    "Proponi un’attività in cui gli studenti dividono parole in categorie grammaticali: verbi, sostantivi e aggettivi.",
    "Genera un esercizio di raggruppamento basato su strumenti musicali: a corda, a fiato, a percussione.",
    "Crea un’attività in cui i bambini dividono oggetti in materiali: legno, plastica, metallo.",
    "Raggruppa i verbi in base al modo",
    "Raggruppa i verbi al modo indicativo in base al tempo",
    "Raggruppa le frazioni proprie, improprie e apparenti"
  ],
  matchpairs: [
    "Crea un’attività in cui gli studenti abbinano animali alle loro caratteristiche principali (es. elefante - proboscide).",
    "Proponi un esercizio di abbinamento in inglese: parole e loro significati.",
    "Crea un’attività in cui gli studenti abbinano monumenti alle città in cui si trovano.",
    "Progetta un’attività in cui gli studenti abbinano le parti del corpo umano ai loro nomi scientifici. (al sistema di appartenenza)",
    "Abbina il verbo al tempo corretto",
    "Abbina il soggetto al predicato corretto",
    "Abbina la moltiplicazione al risultato corretto",
    "Abbina il numero in cifra al numero scritto in lettere",
  ],
  crossword: [
    "Crea un cruciverba di scienze per la classe quarta primaria con parole come fotosintesi, energia, foglia, ossigeno.",
    "Genera un cruciverba in inglese per studenti di seconda media sulle parti del corpo.",
    "Genera un cruciverba matematico per la classe quinta con termini come frazione, moltiplicazione, area, parallelogramma.",
    "Proponi un cruciverba sulle regioni italiane e i loro capoluoghi, per una classe quarta primaria che studia la geografia del Paese.",
    "Proponi un cruciverba con le parole dell’acqua",
    "Proponi un cruciverba sugli egizi per una classe quarta",
    "Proponi un cruciverba sul corpo umano per bambini di classe quinta",
  ],
  rearrange: [
    "Progetta un esercizio di riordino per frasi in inglese, in cui gli studenti ricostruiscono domande corrette per la classe prima media.",
    "Genera un esercizio per una quarta primaria in cui gli studenti riordinano i pianeti del sistema solare in base alla loro distanza dal Sole",
    "Crea un’attività per la prima primaria in cui gli studenti riordinano le lettere di parole semplici per formare i nomi di animali",
    "Crea un’attività con frasi con soggetto, predicato e due complementi da riordinare",
    "Crea un’attività con frasi da ordinare sul tema della gentilezza",
  ]
};
